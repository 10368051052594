const errorHandler = {
    process(data, baseClass) {
      var formErrors = {};
      if(data.errorFields != null) {
        data.errorFields.forEach((value, index) => {
          /*if(value.className.length > 0)
            formErrors[value.className + "_" + value.fieldName] = value.message;
          else */
          var classSplit = value.className.split('[');
          if(classSplit.length > 1) {
            var myClass = classSplit[0];
            var myId = classSplit[1].replace(']', '');
            if(!formErrors[myClass]) formErrors[myClass] = [];
            if(!formErrors[myClass][myId]) formErrors[myClass][myId] =[];
            formErrors[myClass][myId][value.fieldName.split("_").pop()] = value.message;
          }
          else {
            formErrors[value.fieldName] = value.message;
          }
        });
      }
      return formErrors;
    }
  }
  
  export default errorHandler;