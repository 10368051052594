import api from './api';
import tokenService from "./token.service";

class WebService {

  async sendContact(formData, defaultData) {
    try {
      await tokenService.awaitSessionRefresh();
      const response = await api.post(
        "/web/contact", this.toSubmitData(formData, defaultData)
      );
      return response;
    }
    catch (error) {
      return error.response;
    }
  }
  
  toSubmitData(formData, defaultData) {
    const submitData = {...formData};
    for(const [key, val] of Object.entries(submitData)) {
      if(!isNaN(val)) continue;
      if(defaultData[key] && val == defaultData[key][1]) {
        submitData[key] = null;
      }
    }
    return submitData;
  }
}
export default new WebService();