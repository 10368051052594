<template>
  <div class="content info" v-if="this.renderStore.options.activeSideMenu === 'docinfo'">
    <div class="cm" @click="closeSideMenu()" title="Scherm sluiten">
      <img src="img/ico/close-1.png">
    </div>
    <h3>Over dit document</h3>
    <label>
      Naam
      <span :title="document.name">{{ document.name }}</span>
    </label>
    <label>
      Versie
      <span>{{ document.version }}</span>
    </label>
    <label>
      Identifier
      <span>{{ document.currentUuid }}</span>
    </label>
    
    <label>
      Gedeeld door
      <span :title="document.sharedBy">{{ document.sharedBy }}</span>
    </label>
    <label>
      Gedeeld op
      <span>{{ document.dateAdded }}</span>
    </label>
    <label>
      Gecached
      <span>{{ Math.round(10 * 0.00000095367432 * this.renderStore.usedStorage) / 10 }} MB <img v-if="this.store.imageThreads > 0" src="img/spinner-inline.gif"></span>
    </label>

    <template v-if="store.isAdmin">
      <h3 style="margin-top:50px">Downloads</h3>
      <p>
        Via onderstaande links kunt u verschillende versies van het document downloaden.
      </p>
    </template>
    <div class="submit small" @click="download" style="margin-top:10px" :style="{opacity:(pending)?0.3:1}">
      <img src="img/ico/download.png">
      Origineel document
    </div>
    <div v-if="store.isAdmin" class="submit small" @click="exportMarkup" style="margin-top:10px" :style="{opacity:(pending)?0.3:1}">
      <img src="img/ico/download.png">
      Backup met markeringen
    </div>
    <div class="clear"></div>

    <div v-if="pending" class="not-avail" style="margin-top:20px;line-height:1.3em">
      Uw document wordt momenteel voorbereid door de server, afhankelijk van de omvang kan dit even duren...
    </div>
    <a href="" class="href-hidden" ref="file-download"></a>
  </div>
</template>

<script>
import documentService from '@/services/app/document.service'

export default {
  data() {
    return {
      pending: false
    }
  },
  props: {
    document: Object,
    getData: Object
  },
  computed: {
    store() {
      return this.$parent.store;
    },
    renderStore() {
      return this.$parent.renderStore;
    },
    changelogCollapsed() {
      return this.renderStore.options.showChangelog;
    }
  },
  methods: {
    async download() {
      if(this.pending) return;
      this.pending = true;
      
      const url = await documentService.downloadPdf(
        this.document,
        this.getData.userEmail,
        this.getData.userToken,
        this.getData.accessKey
      );

      var a = this.$refs["file-download"];
      a.href = url;
      a.download = this.document.currentUuid+" ("+this.document.name+").pdf";
      a.click();
      window.URL.revokeObjectURL(url);

      this.pending = false;
    },
    async exportMarkup() {

      if(this.pending) return;
      this.pending = true;
      
      const url = await documentService.exportMarkup(
        this.document,
        this.getData.userEmail,
        this.getData.userToken,
        this.getData.accessKey
      );

      var a = this.$refs["file-download"];
      a.href = url;
      a.download = this.document.currentUuid+" ("+this.document.name+").pdf";
      a.click();
      window.URL.revokeObjectURL(url);

      this.pending = false;
    },
    closeSideMenu() {
      this.renderStore.options.activeSideMenu = null;
    }
  }
}
</script>