<template>
  <div class="lead">
    <div class="lt lt60">
      <div class="in">
        <h2>
          Probeer Foundation nu kosteloos uit tot het moment van 
          lancering
        </h2>
        <p>
          Foundation is momenteel nog volop in ontwikkeling. Heeft u interesse
          om het pakket te testen, dan nodigen wij u graag uit om kosteloos
          deel te nemen aan onze proefperiode die naar verwachting nog tot 
          begin 2025 loopt.
        </p>
        <p>

        </p>
        <div class="button" @click="$router.push('/contact#aanvraag-proefperiode')">Vraag uw gratis proefperiode aan</div>
      </div>
    </div>
    <div class="rt40">
      <div class="ov">
        <!--<div class="in">
          <div class="yt">
            <iframe 
              width="560" height="315"
              src="https://www.youtube.com/embed/eNdl6KnRdtc?si=yLIXJLaA1qVQWivr" 
              title="YouTube video player" 
              frameborder="0" 
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
              referrerpolicy="strict-origin-when-cross-origin" 
              allowfullscreen
            />
          </div>
        </div>-->
        <img src="img/web/20240724_tablet_screenshot.jpg">
        <div class="clear"></div>
      </div>
      <div class="clear"></div>
    </div>
    <div class="clear"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {

    };
  },
  computed: {
    
  },
  components: { 
    
  }
};
</script>

<style scoped>
h2 {
  font-size: 24px;
  font-style: normal;
  font-weight: bold;
  color: #fff;
}

p {
  font-size: 14px;
}

.lead {
  color: #fff;
  height: 430px;
}

.lt60 {
  width: 50%;
  float: left;
  box-sizing: border-box;

  .in {
    width: 500px;
    margin-right: 0;
    float: right;
    box-sizing: border-box;
    padding: 75px 0;
    padding-right: 100px;
  }
}

.rt40 {
  width: 50%;
  float: right;
  box-sizing: border-box;

  .ov {
    border-radius: 50px;
    border-left: 10px solid rgba(255,255,255,0.9);
    border-right: 10px solid rgba(255,255,255,0.9);
    background: rgba(0,0,0,0.2);
    width: 500px;
    height: 450px;
    margin-left: 0;
    margin-top: -10px;
    margin-bottom: -10px;
    box-sizing: border-box;
    overflow: hidden;
    
    img {
      max-height: 100%;
      object-fit: cover;
    }
  }

  .in {
    margin-left: 40px;
    width: 500px;
    float: left;
    box-sizing: border-box;
    padding: 100px 0;

    p {
      font-size: 14px;
    }

    .button {
      background-color: #fff;
      color: #494949;
      padding: 10px 25px;
      border-radius: 10px;
      float: left;
      font-size: 14px;
      margin-top: 25px;
      font-weight: bold;
      cursor: pointer;
    }

    .button:hover {
      background: #eee;
      transition-duration: 0.3s;
    }

    .yt {
      position: relative;
      width: 100%;
      padding-bottom: 56.25%;
      height: 0;
      border-radius: 8px;
    }

    iframe {
      position: absolute;
      top: 0; left: 0;
      width: 100%;
      height: 100%;
      border-radius: 5px;
    }
  }
}
</style>