<template>
  <transition name="fade">
    <div class="popup" v-if="store.current.popup.includes('manage-pdf-document-history')">
      <div>
        <perfect-scrollbar style="height:100%">
          <div class="closer" @click="close()">Scherm sluiten</div>
          <div class="adder" @click="revise()">Nieuwe versie uploaden</div>
          <h1>{{ title }}</h1>
          <template v-if="init">
            <template v-if="documentHistory != null && documentHistory.length > 0">
              <table class="listing">
                <tbody>
                  <tr>
                    <th style="width:10%">Versie</th>
                    <th style="width:25%">Aangemaakt op</th>
                    <th>Toegangelijk voor</th>
                    <th class="opth" style="width:0%"></th>
                  </tr>
                  <tr v-for="(item, index) in documentHistory" v-bind:key="index">
                    <td v-if="item.version != document.latestVersion">{{ item.version }}</td>
                    <td v-else>{{ item.version }}</td>
                    <td>{{ item.dateImported }}</td>
                    <td v-if="item.permissionCount > 0">{{ item.permissionCount }} gebruikers</td>
                    <td v-else></td>
                    <td class="opt">
                      <div>
                        <div>
                          Opties bij regel
                          <a href="#" @click.stop="viewDocument(item)">Bekijken</a>
                          <a href="#" @click.stop="manageAccess(item)">Toegang beheren</a>
                          <!--<a href="#" @click="backup(item)">Backup maken</a> 
                          <a v-if="item.version > document.currentVersion" href="#" @click.stop="migrate(item)">Migratie initiëren</a>
                          <a v-if="item.permissionCount == 0 && item.version == document.latestVersion" href="#" @click.stop="unlink(item)">Verwijderen</a>-->
                          <div class="clear"></div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <!--<Pagination @change-offset="changeOffset" :query-args="queryArgs"></Pagination>-->
            </template>
            <div v-else>
              <p>
                Er zijn geen versies van dit document beschikbaar. Mogelijk is de verwerking van uw bestand mislukt of 
                heeft u alle versie van het document verwijderd.
              </p>
            </div>
          </template>
          <div v-else class="loader">
            <div>Even geduld, content wordt geladen...</div>
          </div>
        </perfect-scrollbar>
      </div>
    </div>
  </transition>
</template>

<script>
import documentService from '@/services/app/document.service'
import documentHistoryService from '@/services/app/document.history.service'
import errorHandler from '@/common/ErrorHandler'

export default {
  emits: ['showMessage', 'updateParent'],
  data() {
    return {
      document: {
        projectId: 0,
        documentId: 0
      },
      documentHistory: [],
      queryArgs: {
        orderType: "desc",
        orderBy: "documentHistoryId",
        offset: 0,
        pageSize: 3
      },
      labelData: {},
      formErrors: {},
      title: "Versie- en toegangsbeheer",
      submitText: "Wijzigingen opslaan",
      init: false,
      pending: false
    };
  },
  computed: {
    store() {
      return this.$parent.store;
    },
    currentPopup() {
      return this.store.current.popup[this.store.current.popup.length-1];
    },
    isLocked() {
      if(this.pending || !this.init) return true;
      else return false;
    }
  },
  watch: {
    async currentPopup(value) {
      if(value == "manage-pdf-document-history") {
        this.init = false;
        this.clear();
        
        this.document.documentId = this.store.current.data.pdf.document.documentId;
        await this.fetch();
        
        this.init = true;
        this.pending = false;
      }
    }
  },
  methods: {
    close() {
      this.store.current.popup.splice(
        this.store.current.popup.indexOf('manage-pdf-document-history'), 
        1
      );
      this.store.current.data.pdf.document = null;
      this.$emit('updateParent');
    },
    async fetch() {
      if(this.document.documentId) {
        this.document = await documentService.select(this.document);
        this.queryArgs.documentId = this.document.documentId;

        const response = await documentHistoryService.listing(this.queryArgs);
        if(response) {
          if(response.status == 200) {
            this.documentHistory = response.data.results;
            this.queryArgs.count = response.data.count;
          }
          else {
            this.$emit('showMessage', response);
          }
        }
      }
    },
    manageAccess(documentHistory) {
      this.store.current.data.pdf.documentHistory = {...documentHistory};
      this.store.current.popup = ["manage-pdf-permissions"];
    },
    viewDocument(documentHistory) {
      this.store.current.data.pdf.document.version = documentHistory.version;
      this.store.current.data.pdf.contribution = null;
      this.store.current.form = "view-pdf-document";
      this.store.current.popup = [];
    },
    backup(documentHistory) {
      this.store.current.data.pdf.documentHistory = {...documentHistory};
      this.store.current.popup = ["backup-pdf-document-history"];
    },
    migrate(documentHistory) {
      this.store.current.data.pdf.documentHistory = {...documentHistory};
      this.store.current.popup = ["manage-pdf-document-history-migrate"];
    },
    revise() {
      this.store.current.popup = ["revise-pdf-document"];
    },
    unlink(documentHistory) {
      this.store.current.data.pdf.documentHistory = {...documentHistory};
      this.store.current.popup = ["unlink-pdf-document-history"];
    },
    clear() {

    }
  },
  components: {
    
  }
}
</script>
