<template>
  <transition name="fade">
    <div class="popup" v-if="store.current.popup.includes('add-pdf-transaction')">
      <div>
        <perfect-scrollbar style="height:100%">
          <form @submit.prevent="add">
            <div class="closer" @click="close()">Scherm sluiten</div>
            <h1>{{ title }}</h1>
            <div v-if="init">
              <h2>Generieke gegevens</h2>
              <div v-if="labelData.description" class="before-input" :class="{active:labelData.description.active}">{{ labelData.description.text }}</div>
              <input type="text" 
                name="description"
                v-model="transaction.description" 
                :class="{hasLabel:labelData.description.active}"
                @focusin="empty($event)" 
                @focusout="reset($event)">
              <div v-if="formErrors.transaction_description" class="error-field">{{ defaultData.description[0] + " " + formErrors.transaction_description }}!</div>

              <div v-if="labelData.amount" class="before-input" :class="{active:labelData.amount.active}">{{ labelData.amount.text }}</div>
              <input type="text" 
                name="amount"
                v-model="transaction.amount" 
                :class="{hasLabel:labelData.amount.active}"
                @focusin="empty($event)" 
                @focusout="reset($event)">
              <div v-if="formErrors.transaction_amount" class="error-field">{{ defaultData.amount[0] + " " + formErrors.transaction_amount }}!</div>
              <div style="clear:both;height:50px"></div>

              <input type="submit" :value="submitText" :class="{locked:isLocked}">
            </div>
            <div v-else class="loader">
              <div>Even geduld, content wordt geladen...</div>
            </div>
          </form>
        </perfect-scrollbar>
      </div>
    </div>
  </transition>
</template>

<script>
import transactionService from '@/services/app/transaction.service'
import errorHandler from '@/common/ErrorHandler'

export default {
  emits: ['showMessage', 'updateParent'],
  data() {
    return {
      transaction: {
        companyId: 0,
        description: '',
        amount: 0
      },
      defaultData: {
        description: ['Omschrijving', 'Omschrijf hier de transactie.'],
        amount: ['Waarde', 'Hoeveel tokens wilt u toekennen?']
      },
      labelData: {},
      formErrors: {},
      title: "",
      submitText: "",
      init: false,
      pending: false
    };
  },
  computed: {
    store() {
      return this.$parent.store;
    },
    currentPopup() {
      return this.store.current.popup[this.store.current.popup.length-1];
    },
    isLocked() {
      if(this.pending || !this.init) return true;
      else return false;
    }
  },
  watch: {
    async currentPopup(value) {
      if(value == "add-pdf-transaction") {
        this.init = false;
        this.clear();

        this.title = "Tokens toekennen";
        this.submitText = "Record aanmaken";
        this.transaction.companyId = this.store.current.data.company.companyId;

        this.init = true;
        this.pending = false;
      }
    },
    transaction(listing) {
      for(const[key, value] of Object.entries(listing)) {
        if(!this.defaultData[key]) continue;
        if(value && value.toString().length > 0) {
          this.labelData[key] = {text: this.defaultData[key][0], active: true};
        }
        else {
          this.labelData[key] = {text: '', active: false};
          this.transaction[key] = this.defaultData[key][1];
        }
      }
    }
  },
  methods: {
    async add() {
      if(this.pending) return;
      this.pending = true;
      
      let response = await transactionService.add(this.transaction, this.defaultData);
      if(response) {
        if(response.status == 200) {
          this.close();
        }
        else {
          this.$emit('showMessage', response);
          this.formErrors = errorHandler.process(response.data, "");
        }
      }
      this.pending = false;
    },
    empty(e) {
      const val = this.transaction[e.target.name];
      if(this.defaultData[e.target.name] && (val == "" || val == this.defaultData[e.target.name][1])) {
        this.labelData[e.target.name] = {text: this.defaultData[e.target.name][0], active: true};
        this.transaction[e.target.name] = "";
      }
    },
    reset(e) {
      const val = this.transaction[e.target.name];
      if(val == "") {
        this.labelData[e.target.name] = {text: '', active: false};
        this.transaction[e.target.name] = this.defaultData[e.target.name][1];
      }
    },
    clear() {
      for(const[key, val] of Object.entries(this.defaultData)) {
        this.labelData[key] = {text: '', active: false};
        this.transaction[key] = val[1];
      }
      this.formErrors = {};
    },
    close() {
      this.store.current.popup.splice(
        this.store.current.popup.indexOf('add-pdf-transaction'), 
        1
      );
      this.store.current.data.transaction = null;
      this.$emit('updateParent');
    }
  },
  components: {
    
  }
}
</script>
