import api from '../api';
import tokenService from "../token.service";

class PermissionService {

  async select(permission) {
    try {
      await tokenService.awaitSessionRefresh();
      const response = await api.post(
        "/app/permission/select", {
          permissionId: permission.permissionId
        }
      );
      return response.data;
    }
    catch (error) {
      return null;
    }
  }

  async add(formData, defaultData) {
    try {
      var submitData = this.toSubmitData(formData, defaultData);
      submitData.filter = JSON.stringify(submitData.filter);
      delete submitData.dataColumns;
      
      await tokenService.awaitSessionRefresh();
      const response = await api.post(
        "/app/permission/add", submitData
      );
      return response;
    }
    catch (error) {
      return error.response;
    }
  }

  async update(formData, defaultData) {
    try {
      var submitData = this.toSubmitData(formData, defaultData);
      submitData.filter = JSON.stringify(submitData.filter);
      delete submitData.dataColumns;

      await tokenService.awaitSessionRefresh();
      const response = await api.post(
        "/app/permission/update", submitData
      );
      return response;
    }
    catch (error) {
      return error.response;
    }
  }

  async unlink(id) {
    try {
      await tokenService.awaitSessionRefresh();
      const response = await api.post(
        "/app/permission/unlink", {
          permissionId: id
        }
      );
      return response;
    }
    catch (error) {
      return error.response;
    }
  }

  async listing(queryArgs) {
    try {
      await tokenService.awaitSessionRefresh();
      const response = await api.post(
        "/app/permission/listing", queryArgs
      );
      return response;
    }
    catch (error) {
      return error.response;
    }
  }

  async shares(queryArgs) {
    try {
      await tokenService.awaitSessionRefresh();
      const response = await api.post(
        "/app/permission/shares", queryArgs
      );
      return response;
    }
    catch (error) {
      return error.response;
    }
  }

  async findPresets(formData, defaultData) {
    try {
      await tokenService.awaitSessionRefresh();
      const response = await api.post(
        "/app/permission/find-presets", this.toSubmitData(formData, defaultData)
      );
      return response;
    }
    catch (error) {
      return error.response;
    }
  }

  async contacts(documentHistoryId) {
    try {
      await tokenService.awaitSessionRefresh();
      const response = await api.post("/app/permission/contacts", {
        documentHistoryId: documentHistoryId
      });
      return response.data;
    }
    catch (error) {
      return error.response;
    }
  }

  toSubmitData(formData, defaultData) {
    const submitData = {...formData};
    for(const [key, val] of Object.entries(submitData)) {
      if(!isNaN(val)) continue;
      if(defaultData[key] && val == defaultData[key][1]) {
        submitData[key] = null;
      }
    }
    return submitData;
  }
}
export default new PermissionService();