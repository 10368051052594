import api from './api';
import tokenService from "./token.service";

class CompanyService {

  async select(id) {
    try {
      await tokenService.awaitSessionRefresh();
      const response = await api.post(
        "/company/select", {
          companyId: id
        }
      );
      return response.data;
    }
    catch (error) {
      return null;
    }
  }

  async add(company, addresses, defaultData) {
    try {
      var companyData = this.toSubmitData(company, defaultData);
      
      companyData.address = [];
      for(const index in addresses) {
        var addressData = this.toSubmitData(addresses[index], defaultData.address);
        companyData.address.push(addressData);
      }

      await tokenService.awaitSessionRefresh();
      const response = await api.post(
        "/company/add", companyData
      );
      return response;
    }
    catch (error) {
      return error.response;
    }
  }

  async update(company, addresses, defaultData) {
    try {
      var companyData = this.toSubmitData(company, defaultData);
    
      companyData.address = [];
      for(const index in addresses) {
        var addressData = this.toSubmitData(addresses[index], defaultData.address);
        companyData.address.push(addressData);
      }
      
      await tokenService.awaitSessionRefresh();
      const response = await api.post(
        "/company/update", companyData
      );
      return response;
    }
    catch (error) {
      return error.response;
    }
  }

  /*async unlink(id) {
    try {
      const response = await api.post(
        "/company/unlink", {
          companyId: id
        }
      );
      return response;
    }
    catch (error) {
      return error.response;
    }
  }*/

  async listing(queryArgs) {
    try {
      await tokenService.awaitSessionRefresh();
      const response = await api.post(
        "/company/listing", queryArgs
      );
      return response;
    }
    catch (error) {
      return error.response;
    }
  }
  
  toSubmitData(formData, defaultData) {
    const submitData = {...formData};
    for(const [key, val] of Object.entries(submitData)) {
      if(!isNaN(val)) continue;
      if(defaultData[key] && val == defaultData[key][1]) {
        submitData[key] = null;
      }
    }
    return submitData;
  }
}
export default new CompanyService();