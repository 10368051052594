<template>
  <transition name="fade">
    <div class="popup small" v-if="store.current.popup.includes('activate-user-success')">
      <div>
        <div class="closer" @click="close()">Scherm sluiten</div>
        <h1>Activatie succesvol</h1>
        <p>
          Het activeren van uw account was succesvol. U ontvangt van ons bericht wanneer
          uw inschrijving is geverifieerd. Pas daarna krijgt u toegang tot de 
          applicaties.
        </p>
      </div>
    </div>
  </transition>
  <transition name="fade">
    <div class="popup small" v-if="store.current.popup.includes('activate-user-fail')">
      <div>
        <div class="closer" @click="close()">Scherm sluiten</div>
        <h1>Activatie mislukt</h1>
        <p>
          De door u gebruikte activatielink is al gebruikt of is niet geldig. Neem contact 
          met ons op als u uw account niet kunt gebruiken.
        </p>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  emits: ['showMessage'],
  computed: {
    store() {
      return this.$parent.store;
    }
  },
  methods: {
    close() {
      this.$router.push('/');
      this.store.current.popup = [];
    }
  }
}
</script>
