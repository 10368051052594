import api from '../api';
import tokenService from "../token.service";

class ContributionService {

  async select(contribution, getData) {
    try {
      await tokenService.awaitSessionRefresh();
      const response = await api.post(
        "/app/contribution/select", {
          contributionId: contribution.contributionId,
          userEmail: getData.userEmail,
          userToken: getData.userToken,
          accessKey: getData.accessKey
        }
      );
      return response.data;
    }
    catch (error) {
      return null;
    }
  }

  async add(contribution, pageNumber, document, defaultData, files, getData) {
    try {
      var submitData = this.toSubmitData(contribution.values, defaultData);
      var formData = new FormData();
      for(const [field, value] of Object.entries(contribution)) {
        if(field != "values" && value != null) formData.append(field, value);
      }
      formData.append("values", JSON.stringify(submitData));

      formData.append("documentId", document.documentId);
      formData.append("documentUuid", document.uuid);
      formData.append("documentVersion", document.version);
      formData.append("pageNumber", pageNumber);
      formData.append("userEmail", getData.userEmail);
      formData.append("userToken", getData.userToken);
      formData.append("accessKey", getData.accessKey);

      var i = 1;
      files.forEach(file => {
        formData.append("file"+i, file.data);
        i++;
      });

      await tokenService.awaitSessionRefresh();
      const response = await api.post(
        "/app/contribution/add", formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
      });
      return response;
    }
    catch (error) {
      return error.response;
    }
  }

  async update(contribution, defaultData, filesToKeep, files, getData) {
    try {
      var submitData = this.toSubmitData(contribution.values, defaultData);
      var formData = new FormData();
      for(const [field, value] of Object.entries(contribution)) {
        if(field != "values" && value != null) formData.append(field, value);
      }
      formData.append("values", JSON.stringify(submitData));
      formData.append("filesToKeep", filesToKeep);
      formData.append("userEmail", getData.userEmail);
      formData.append("userToken", getData.userToken);
      formData.append("accessKey", getData.accessKey);

      var i = 1;
      files.forEach(file => {
        formData.append("file"+i, file.data);
        i++;
      });

      await tokenService.awaitSessionRefresh();
      const response = await api.post(
        "/app/contribution/update", formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
      });
      return response;
    }
    catch (error) {
      return error.response;
    }
  }

  async updateMulti(contribution, defaultData, getData) {
    try {
      var submitData = this.toSubmitData(contribution.values, defaultData);

      const response = await api.post(
        "/app/contribution/update-multi", {
          contributionIds: contribution.contributionIds,
          values: JSON.stringify(submitData),
          userEmail: getData.userEmail,
          userToken: getData.userToken,
          accessKey: getData.accessKey
        }
      );
      return response;
    }
    catch (error) {
      return error.response;
    }
  }

  async unlink(id, getData) {
    try {
      await tokenService.awaitSessionRefresh();
      const response = await api.post(
        "/app/contribution/unlink", {
          contributionId: id,
          userEmail: getData.userEmail,
          userToken: getData.userToken,
          accessKey: getData.accessKey
        }
      );
      return response;
    }
    catch (error) {
      return error.response;
    }
  }

  async listing(queryArgs, getData) {
    try {
      await tokenService.awaitSessionRefresh();
      const response = await api.post(
        "/app/contribution/listing", Object.assign(queryArgs, getData)
      );
      return response;
    }
    catch (error) {
      return error.response;
    }
  }

  async setValue(contributionId, fieldName, value, getData) {
    try {
      await tokenService.awaitSessionRefresh();
      const response = await api.post(
        "/app/contribution/set-value", {
          "contributionId": contributionId,
          "fieldName": fieldName,
          "value": value,
          "userEmail": getData.userEmail,
          "userToken": getData.userToken,
          "accessKey": getData.accessKey
        }
      );
      return response;
    }
    catch (error) {
      console.log(error);
      return error.response;
    }
  }

  async fetchTemplates(document, getData) {
    try {
      await tokenService.awaitSessionRefresh();
      var response = await api.post(
        "/app/contribution/fetch-templates", {
          "uuid": document.uuid,
          "version": document.version,
          "userEmail": getData.userEmail,
          "userToken": getData.userToken,
          "accessKey": getData.accessKey
        }
      );

      var result = [];
      if(response.data != null) {
        response.data.contributionTemplates.forEach(template => {
          result[template.contributionTemplateId] = template;
        });
      }
      return result;
    }
    catch (error) {
      return null;
    }
  }

  async fetchAttachments(contribution, getData) {
    try {
      await tokenService.awaitSessionRefresh();
      const response = await api.post(
        "/app/contribution/fetch-attachments", {
          "contributionId": contribution.contributionId,
          "userEmail": getData.userEmail,
          "userToken": getData.userToken,
          "accessKey": getData.accessKey
        }
      );

      var result = [];
      if(response.data != null) {
        response.data.attachments.forEach(attachment => {
          result.push(attachment);
        });
      }
      return result;
    }
    catch (error) {
      return null;
    }
  }

  toSubmitData(formData, defaultData) {
    const submitData = {...formData};
    for(const [key, val] of Object.entries(submitData)) {
      if(!isNaN(val)) continue;
      if(defaultData[key] && val == defaultData[key][1]) {
        submitData[key] = null;
      }
    }
    return submitData;
  }
}
export default new ContributionService();