<template>
  <div class="lead">
    <div class="in">
      <div class="tab25">
        <h3>Persoonsgegevens (AVG)</h3>
        <p>
          In de database van Foundation staan enkel persoonsgegevens opgeslagen
          die kritisch zijn voor het functioneren van de tool. Verder 
          verzamelen we beperkt gebruiksgegevens die nodig zijn voor het 
          optimaliseren van onze services.
        </p>

        <h3>Versleutelde verbinding</h3>
        <p>
          Wanneer u communiceert met onze server worden gegevens altijd 
          versleuteld verstuurd tussen de server en uw apparaat. Zo kan niemand 
          de data onderweg onderscheppen. Ons domein is voorzien van een 
          SHA-256 certificaat.
        </p>
      </div>
      <div class="tab25">
        <h3>Encryptie op dataopslag</h3>
        <p>
          Documenten die u uploadt worden niet in originele vorm opgeslagen 
          op de server, maar eerst door ons systeem versleuteld. Zo kan niemand 
          ongeoorloofd gegevens inzien, zelfs onze ontwikkelaars niet.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {

    };
  },
  computed: {
    
  },
  components: { 
    
  }
};
</script>

<style scoped>
h2 {
  width: 1000px;
  text-align: center;
  margin: 25px auto;
  margin-bottom: 0;
  font-size: 28px;
  line-height: 1em;
  font-style: normal;
}

h3 {
  font-style: normal;
  line-height: 1.5em;
  font-size: 16px;
}

p {
  font-size: 14px;
  line-height: 1.5em;
}

.lead {
  background: #fff;
  position: relative;
  text-align: left;
  padding-top: 50px;
  padding-bottom: 75px;
}

.lead > span {
  display: block;
  font-size: 24px;
  line-height: 1.3em;
  font-style: italic;
  font-weight: normal;
  width: 1000px;
  margin: 0 auto;
  margin-top: 75px;
  margin-bottom: 25px;
}

.tab span {
  display: block;
  font-size: 16px;
  line-height: 1.3em;
  font-weight: normal;
}

.tab25 {
  width: 30%;
  display: table-cell;
  padding: 0 25px;
  vertical-align: top;

  img {
    height: 32px;
    width: auto;
    margin-bottom: 0;
  }
}

.tab50 {
  width: 40%;
  display: table-cell;
  padding: 0 25px;
  vertical-align: middle;
}

.in {
  width: 1050px;
  margin: 0 auto;
  display: table;
  padding: 0;
  padding-top: 10px;
}

.tab {
  display: table-cell;
  vertical-align: top;
}

.button {
  float: none !important;
  display: inline-block;
  background-color: rgb(81, 111, 157) !important;
  color: #fff !important;
  opacity: 0.7;
  margin-top: -25px !important;
  margin-bottom: 75px;
}

.button:hover {
  opacity: 0.9;
}

span {
  text-align: center !important;
  margin-top: 25px !important;
}

span.lt {
  font-size: 14px;
}

span.sm {
  font-weight: bold;
  font-size: 12px;
  opacity: 0.3;
  margin: 0 auto;
  margin-top: 10px !important;
  margin-bottom: 20px !important;
}

img {
  margin: 15px 0;
  width: 100%;
}
</style>