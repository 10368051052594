import api from '../api'
import axios from 'axios'
import { useStore } from '@/store/pinia'
import { useRenderStore } from '@/store/render'
import tokenService from "../token.service";

class DocumentService {

  async select(document) {
    try {
      await tokenService.awaitSessionRefresh();
      const response = await api.post(
        "/app/document/select", {
          documentId: document.documentId,
          uuid: document.uuid,
          version: document.version,
          userEmail: document.userEmail,
          userToken: document.userToken,
          accessKey: document.accessKey
        }
      );
      return response.data;
    }
    catch (error) {
      return null;
    }
  }

  async add(formData, defaultData) {
    try {
      await tokenService.awaitSessionRefresh();
      const response = await api.post(
        "/app/document/add", this.toSubmitData(formData, defaultData)
      );
      return response;
    }
    catch (error) {
      return error.response;
    }
  }

  async update(formData, defaultData) {
    try {
      await tokenService.awaitSessionRefresh();
      const response = await api.post(
        "/app/document/update", this.toSubmitData(formData, defaultData)
      );
      return response;
    }
    catch (error) {
      return error.response;
    }
  }

  async unlink(id) {
    try {
      await tokenService.awaitSessionRefresh();
      const response = await api.post(
        "/app/document/unlink", {
          documentId: id
        }
      );
      return response;
    }
    catch (error) {
      return error.response;
    }
  }

  async listing(queryArgs) {
    try {
      await tokenService.awaitSessionRefresh();
      const response = await api.post(
        "/app/document/listing", queryArgs
      );
      return response;
    }
    catch (error) {
      return error.response;
    }
  }

  async upload(formData, defaultData, file) {
    var store = useStore();
    const CancelToken = axios.CancelToken;
    let cancel;

    try {
      var submitData = this.toSubmitData(formData, defaultData);
      var formData = new FormData();
      formData.append("projectId", submitData.projectId);
      formData.append("file", file);
      for(const [field, value] of Object.entries(submitData)) {
        formData.append(field, value);
      }

      await tokenService.awaitSessionRefresh();
      const response = await api.post(
        "/app/document/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        },
        cancelToken: new CancelToken(function executor(c) {
          cancel = c;
        }),
        onUploadProgress: function( progressEvent ) {
          store.uploadProgress = parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ) );
          if(store.fileToUpload == null) {
            cancel();
            store.uploadProgress = 0;
          }
        }.bind(this)
      });
      return response;
    }
    catch (error) {
      return error.response;
    }
  }

  async revise(formData, defaultData, file) {
    var store = useStore();
    const CancelToken = axios.CancelToken;
    let cancel;
    try {
      var submitData = this.toSubmitData(formData, defaultData);
      var formData = new FormData();
      formData.append("documentId", submitData.documentId);
      formData.append("file", file);
      for(const [field, value] of Object.entries(submitData)) {
        formData.append(field, value);
      }

      await tokenService.awaitSessionRefresh();
      const response = await api.post(
        "/app/document/revise", formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        },
        cancelToken: new CancelToken(function executor(c) {
          cancel = c;
        }),
        onUploadProgress: function( progressEvent ) {
          store.uploadProgress = parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ) );
          if(store.fileToUpload == null) {
            cancel();
            store.uploadProgress = 0;
          }
        }.bind(this)
      });
      return response;
    }
    catch (error) {
      return error.response;
    }
  }

  async fetchPageData(document, pageNumber, userEmail, userToken, accessKey) {
    try {
      let config = {
        responseType: 'arraybuffer',
        headers: {
          'content-type': 'application/json',
          'Accept': 'application/zip'
        }
      };

      await tokenService.awaitSessionRefresh();
      const response = await api.post(
        "/app/document/fetch-pagedata", {
          documentId: document.documentId,
          uuid: document.uuid,
          version: document.version,
          pageNumber: pageNumber,
          userEmail: userEmail,
          userToken: userToken,
          accessKey: accessKey
        }, config
      );
      return response.data;
    }
    catch (error) {
      return null;
    }
  }

  async fetchSnapMap(document, userEmail, userToken, accessKey) {
    try {
      let config = {
        responseType: 'arraybuffer',
        headers: {
          'content-type': 'application/json',
          'Accept': 'application/zip'
        }
      };

      await tokenService.awaitSessionRefresh();
      const response = await api.post(
        "/app/document/fetch-snapmap", {
          documentId: document.documentId,
          uuid: document.uuid,
          version: document.version,
          userEmail: userEmail,
          userToken: userToken,
          accessKey: accessKey
        }, config
      );
      return response.data;
    }
    catch (error) {
      return null;
    }
  }

  async verify(document) {
    try {
      await tokenService.awaitSessionRefresh();
      const response = await api.post(
        "/app/document/verify", {
          documentId: document.documentId,
          uuid: document.uuid,
          version: document.version
        }
      );
      return response;
    }
    catch (error) {
      return null;
    }
  }

  async fetchImages(document, pageNumber, step, userEmail, userToken, accessKey) {
    try {
      const store = useStore();
      const renderStore = useRenderStore();
      let config = {
        responseType: 'arraybuffer',
        headers: {
          'content-type': 'application/json',
          'Accept': 'application/zip'
        }
      };

      await tokenService.awaitSessionRefresh();
      await tokenService.awaitImageThreads();
      if(renderStore.data.length == 0) return null;

      store.imageThreads++;
      try {
        const response = await api.post(
          "/app/document/fetch-images", {
            documentId: document.documentId,
            uuid: document.uuid,
            version: document.version,
            pageNumber: pageNumber,
            step: step,
            userEmail: userEmail,
            userToken: userToken,
            accessKey: accessKey
          }, config
        );


        store.imageThreads--;

        setTimeout(function() {
          if(store.imageThreads == 0) {
            renderStore.imagesReady = true;
          }
        }, 300);

        if(response != null && response.data != null && response.data.byteLength > 0) {
          renderStore.fetchImagesRetryCount = 0;
          return response.data;
        }
        else if(renderStore.fetchImagesRetryCount < 3) {
          renderStore.fetchImagesRetryCount++;
          return null;
        }
        else {
          this.renderStore.data = [];
          return null;
        }
      }
      catch { 
        const store = useStore();
        store.imageThreads--;
        setTimeout(function() {
          if(store.imageThreads == 0) {
            renderStore.imagesReady = true;
          }
        }, 300);
        return null;
      }
    }
    catch (error) {
      const store = useStore();
      store.imageThreads--;
      setTimeout(function() {
        if(store.imageThreads == 0) {
          renderStore.imagesReady = true;
        }
      }, 300);
      return null;
    }
  }

  async fetchContributions(document, pageNumber, userEmail, userToken, accessKey, lastUpdate) {
    try {
      await tokenService.awaitSessionRefresh();
      const response = await api.post(
        "/app/document/fetch-contributions", {
          documentId: document.documentId,
          uuid: document.uuid,
          version: document.version,
          pageNumber: pageNumber,
          userEmail: userEmail,
          userToken: userToken,
          accessKey: accessKey,
          lastUpdateUnix: lastUpdate
        }
      );
      return response;
    }
    catch (error) {
      return null;
    }
  }

  async downloadPdf(document, userEmail, userToken, accessKey) {
    try {
      let config = {
        responseType: 'arraybuffer',
        headers: {
          'content-type': 'application/json',
          'Accept': 'application/zip'
        }
      };

      await tokenService.awaitSessionRefresh();
      const response = await api.post(
        "/app/document/download-pdf", {
          documentId: document.documentId,
          uuid: document.uuid,
          version: document.version,
          userEmail: userEmail,
          userToken: userToken,
          accessKey: accessKey
        }, config
      );

      var blob = new Blob([response.data], {type: "application/pdf"});
      return URL.createObjectURL(blob);
    }
    catch (error) {
      return null;
    }
  }

  async exportMarkup(document, userEmail, userToken, accessKey) {
    try {
      let config = {
        responseType: 'arraybuffer',
        headers: {
          'content-type': 'application/json',
          'Accept': 'application/zip'
        }
      };

      await tokenService.awaitSessionRefresh();
      const response = await api.post(
        "/app/document/export-markup-pdf", {
          documentId: document.documentId,
          uuid: document.uuid,
          version: document.version,
          userEmail: userEmail,
          userToken: userToken,
          accessKey: accessKey
        }, config
      );

      var blob = new Blob([response.data], {type: "application/pdf"});
      return URL.createObjectURL(blob);
    }
    catch (error) {
      return null;
    }
  }

  async downloadAnnotations(document, pivotData, columns, userEmail, userToken, accessKey) {
    try {
      let config = {
        responseType: 'arraybuffer',
        headers: {
          'content-type': 'application/json',
          'Accept': 'application/zip'
        }
      };

      await tokenService.awaitSessionRefresh();
      const response = await api.post(
        "/app/document/download-pivot", {
          documentId: document.documentId,
          uuid: document.uuid,
          version: document.version,
          userEmail: userEmail,
          userToken: userToken,
          accessKey: accessKey,
          pivotData: pivotData,
          columns: columns
        }, config
      );

      var blob = new Blob([response.data], {type: "application/vnd.ms-excel"});
      return URL.createObjectURL(blob);
    }
    catch (error) {
      return null;
    }
  }

  async downloadContributions(document, filteredContributions, userEmail, userToken, accessKey) {
    try {
      const renderStore = useRenderStore();
      let config = {
        responseType: 'arraybuffer',
        headers: {
          'content-type': 'application/json',
          'Accept': 'application/zip'
        }
      };

      Object.values(filteredContributions).forEach(contribution => {
        if(contribution.annotationId != null) {
          var thisAnnot = null;
          Object.values(renderStore.data[contribution.pageNumber].annotations["ANNOTATION"]).forEach(annot => {
            if(annot.id == contribution.annotationId) thisAnnot = annot;
          });
          if(thisAnnot != null) {
            renderStore.options.columns.forEach(column => {
              contribution.values["a_"+column.name] = thisAnnot.columnData[column.name];
            });
          }
        }
      });

      await tokenService.awaitSessionRefresh();
      const response = await api.post(
        "/app/document/download-contributions", {
          documentId: document.documentId,
          uuid: document.uuid,
          version: document.version,
          userEmail: userEmail,
          userToken: userToken,
          accessKey: accessKey,
          filteredContributions: filteredContributions,
        }, config
      );

      var blob = new Blob([response.data], {type: "application/vnd.ms-excel"});
      return URL.createObjectURL(blob);
    }
    catch (error) {
      return null;
    }
  }

  async migrate(document, doMigrate, userEmail, userToken, accessKey) {
    try {
      await tokenService.awaitSessionRefresh();
      const response = await api.post(
        "/app/document/migrate", {
          doMigrate: doMigrate,
          documentId: document.documentId,
          uuid: document.uuid,
          version: document.version,
          userEmail: userEmail,
          userToken: userToken,
          accessKey: accessKey,
        }
      );
      return response;
    }
    catch (error) {
      return error.response;
    }
  }

  toSubmitData(formData, defaultData) {
    const submitData = {...formData};
    for(const [key, val] of Object.entries(submitData)) {
      if(!isNaN(val)) continue;
      if(defaultData[key] && val == defaultData[key][1]) {
        submitData[key] = null;
      }
    }
    return submitData;
  }
}
export default new DocumentService();