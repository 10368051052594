import api from './api';
import tokenService from "./token.service";

class UserService {

  async select(id) {
    try {
      await tokenService.awaitSessionRefresh();
      const response = await api.post(
        "/user/select", {
          userId: id
        }
      );
      return response.data;
    }
    catch (error) {
      return null;
    }
  }

  async findByEmail(formData, defaultData) {
    try {
      await tokenService.awaitSessionRefresh();
      const response = await api.post(
        "/user/find-by-email", this.toSubmitData(formData, defaultData)
      );
      return response;
    }
    catch (error) {
      return error.response;
    }
  }

  async add(formData, defaultData) {
    try {
      await tokenService.awaitSessionRefresh();
      const response = await api.post(
        "/user/add", this.toSubmitData(formData, defaultData)
      );
      return response;
    }
    catch (error) {
      return error.response;
    }
  }

  async update(formData, defaultData) {
    try {
      await tokenService.awaitSessionRefresh();
      const response = await api.post(
        "/user/update", this.toSubmitData(formData, defaultData)
      );
      return response;
    }
    catch (error) {
      return error.response;
    }
  }

  async unlink(id) {
    try {
      await tokenService.awaitSessionRefresh();
      const response = await api.post(
        "/user/unlink", {
          userId: id
        }
      );
      return response;
    }
    catch (error) {
      return error.response;
    }
  }

  async listing(queryArgs) {
    try {
      await tokenService.awaitSessionRefresh();
      const response = await api.post(
        "/user/listing", queryArgs
      );
      return response;
    }
    catch (error) {
      return error.response;
    }
  }

  async reactivate(id) {
    try {
      await tokenService.awaitSessionRefresh();
      const response = await api.post(
        "/user/reactivate", {
          userId: id
        }
      );
      return response;
    }
    catch (error) {
      return error.response;
    }
  }

  async setColumnLayout(columns) {
    try {
      await tokenService.awaitSessionRefresh();
      const response = await api.post(
        "/user/column-layout", { config: JSON.stringify(columns) }
      );
      return response;
    }
    catch (error) {
      return error.response;
    }
  }
  
  toSubmitData(formData, defaultData) {
    const submitData = {...formData};
    for(const [key, val] of Object.entries(submitData)) {
      if(!isNaN(val)) continue;
      if(defaultData[key] && val == defaultData[key][1]) {
        submitData[key] = null;
      }
    }
    return submitData;
  }
}
export default new UserService();