<template>
  <div class="hl" @mouseenter="pause" @mouseleave="resume">
    <div class="in">
      <!--<h4>Natuurlijk zijn er méér professionals die gebruik maken van onze tools</h4>-->
      <div class="rd2 car">
        <div v-if="slide == 1" :style="{opacity:this.opacity}">
          <div class="rg">
            <img src="img/logo/digitable_eindhoven.png">
          </div>
          <div class="rg">
            <img src="img/logo/digitable_eindhoven.png">
          </div>
          <div class="rg">
            <img src="img/logo/digitable_eindhoven.png">
          </div>
          <div class="rg">
            <img src="img/logo/digitable_eindhoven.png">
          </div>
          <div class="rg">
            <img src="img/logo/digitable_eindhoven.png">
          </div>
          <div class="clear"></div>
        </div>

        <div v-if="slide == 2" :style="{opacity:this.opacity}">
          <div class="rg">
            <img src="img/logo/digitable_eindhoven.png">
          </div>
          <div class="rg">
            <img src="img/logo/digitable_eindhoven.png">
          </div>
          <div class="rg">
            <img src="img/logo/digitable_eindhoven.png">
          </div>
          <div class="rg">
            <img src="img/logo/digitable_eindhoven.png">
          </div>
          <div class="rg">
            <img src="img/logo/digitable_eindhoven.png">
          </div>
          <div class="clear"></div>
        </div>

        <div v-if="slide == 3" :style="{opacity:this.opacity}">
          <div class="rg">
            <img src="img/logo/digitable_eindhoven.png">
          </div>
          <div class="rg">
            <img src="img/logo/digitable_eindhoven.png">
          </div>
          <div class="rg">
            <img src="img/logo/digitable_eindhoven.png">
          </div>
          <div class="rg">
            <img src="img/logo/digitable_eindhoven.png">
          </div>
          <div class="rg">
            <img src="img/logo/digitable_eindhoven.png">
          </div>
          <div class="clear"></div>
        </div>

        <span class="nxt" @click="next"></span>
        <span class="prv" @click="prev"></span>
      </div>
      <div class="rb">
        <div :style="{width: (this.progress/this.nextAfter*100)+'%'}"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from '@/store/pinia'

export default {
  data() {
    return {
      store: useStore(),
      progress: 0,
      nextAfter: 10000,
      slide: 1,
      opacity: 1,
      rotation: 0,
      paused: false,
      progressFunc: null
    };
  },
  computed: {

  },
  created() {
    this.create();
  },
  methods: {
    create() {
      if(this.progressFunc != null) {
        clearInterval(this.progressFunc);
      }
      this.progressFunc = setInterval(() => {
        if(!this.paused) {
          this.progress += 25;
        }
        if(this.progress > this.nextAfter) {
          //this.next();
          this.progress = 0;
        }
      }, 25);
    },
    pause() {
      this.paused = true;
    },
    resume() {
      this.paused = false;
    },
    prev() {
      this.opacity = 0;
      this.rotation++;
      setTimeout(() => {
        if(this.slide > 1) this.slide--;
        else this.slide = 3;
        setTimeout(() => {
          this.opacity = 1;
          this.create();
        }, 100);
      }, 500);
    },
    next() {
      this.opacity = 0;
      this.rotation++;
      setTimeout(() => {
        if(this.slide < 3) this.slide++;
        else this.slide = 1;
        setTimeout(() => {
          this.opacity = 1;
          this.create();
        }, 100);
      }, 500);
    }
  }
};
</script>

<style scoped>
.hl {
  background: linear-gradient(135deg, rgba(255,255,255,1) 0%, rgba(230,230,230,1) 100%);
  position: relative;
}

.in {
  padding: 50px 0 !important;
}

.lf {
  width: 50%;
  display: table-cell;
  text-align: left;
  vertical-align: middle;
  margin: 0;
  padding-right: 50px;
}

.rg {
  width: 20%;
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  margin: 0; padding: 0;
}

.rg img {
  width: 60%;
  margin: 0;
  filter: grayscale();
}

.rd2.car {
  margin: 0 !important;
  padding: 0 !important;
  width: 100%;
  text-align: center;
  display: table;
  table-layout: fixed;
}

.rd2.car > div {
  width: 1000px;
  margin: 0 auto;
  transition-duration: 0.5s;
}

.prv, .nxt {
  position: absolute;
  color: #474747;
  font-size: 100px;
  top: calc(50% - 75px);
  opacity: 0.5;
}

.prv {
  left: -50px;
}

.nxt {
  right: -50px;
}

.prv:before {
  position: relative;
  float: left;
  content: "‹";
}

.prv:hover {
  cursor: pointer;
  padding-left: 2px;
  transition-duration: 0.5s;
  opacity: 1;
}

.nxt:before {
  position: relative;
  float: right;
  content: "›";
}

.nxt:hover {
  cursor: pointer;
  padding-right: 2px;
  transition-duration: 0.5s;
  opacity: 1;
}

.button {
  float: left;
  margin: 0 auto;
}

.button:hover {
  background: #474747 !important;
  color: #fff !important;
}

img {
  width: 100px;
  margin: 0 auto;
  margin-bottom: 25px;
  transition-duration: 2s;
}

.rb {
  display: block;
  position: absolute;
  bottom: 0;
  left: 0; right: 0;
  background: #fff;
  height: 10px;
  overflow: hidden;
  opacity: 0.2;
}

.rb div {
  background-color: #474747;
  height: 100%;
}

h4 {
  display: block;
  font-size: 24px;
  line-height: 1.3em;
  text-align: center;
  font-style: italic;
  font-weight: normal;
  width: 900px;
  margin: 0 auto;
  margin-bottom: 50px;
}
</style>