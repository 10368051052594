<template>
  <div class="form" v-if="store.isRegistrar && store.current.form === 'list-users'">
    <div class="adder" @click="add()">Toevoegen</div>
    <SearchField @search="search" />
    <h1>Overzicht gebruikers</h1>
    <div class="loader" v-if="!init">
      <div>Even geduld, content wordt geladen...</div>
    </div>
    <div class="nodata" v-else-if="users.length === 0">
      Er zijn geen resultaten die hier kunnen worden getoond.
      Wilt u misschien iets <a href="#" @click="add()">toevoegen</a>?
    </div>
    <div v-else>
      <table class="listing">
        <tbody>
          <tr>
            <th title="Gebruiker" style="width:50%"><span>Gebruiker</span></th>
            <th title="Registratiedatum"><span>Registratiedatum</span></th>
            <th class="opth" style="width:0%"></th>
          </tr>
          <tr v-for="(item, index) in users" v-bind:key="index">
            <td>{{ item.genderSalutation }} {{ item.name }} {{ item.surname }} ({{  item.email }})</td>
            <td>
              <label v-if="!item.isActive">
                <img class="icon" src="img/icon-sync.png">
                Niet actief
              </label>
              {{ item.dateRegistered }}
            </td>
            <td class="opt">
              <div>
                <div>
                  Opties bij regel
                  <a href="#" @click="edit(item)">Pas aan</a>
                  <a href="#" @click="unlink(item)">Verwijder</a>
                  <div class="clear"></div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <Pagination @change-offset="changeOffset" :query-args="queryArgs"></Pagination>
    </div>
  </div>
  <PopupAddUser 
    @fetch-listing="fetch"
    @show-message="showMessage" 
  />
  <PopupUnlinkUser 
    @fetch-listing="fetch"
    @show-message="showMessage"
  />
</template>

<script>
import userService from '@/services/user.service'
import PopupAddUser from '@/popups/AddUser.vue'
import PopupUnlinkUser from '@/popups/UnlinkUser.vue'
import Pagination from '@/components/Pagination.vue'
import SearchField from '@/components/SearchField.vue'

export default {
  emits: ['showMessage'],
  data() {
    return {
      users: [],
      queryArgs: {
        orderType: "desc",
        orderBy: "dateRegistered",
        query: "",
        offset: 0,
        pageSize: 5,
        count: 0
      },
      init: false,
      eventHandlers: {
        'fetch-user-listing': this.fetch
      }
    };
  },
  computed: {
    store() {
      return this.$parent.store;
    },
    currentForm() {
      return this.store.current.form;
    }
  },
  watch: {
    currentForm(value) {
      if(value == "list-users") {
        document.title = "Overzicht gebruikers | " + this.store.appName;
        this.queryArgs.offset = 0;
        this.queryArgs.query = "";
        this.fetch();
      }
    }
  },
  methods: {
    add() {
      this.store.current.popup = ["add-user"];
    },
    edit(item) {
      this.store.current.data.user = item;
      this.store.current.popup = ["add-user"];
    },
    unlink(item) {
      this.store.current.data.user = item;
      this.store.current.popup = ["unlink-user"];
    },
    async fetch() {
      this.init = false;
      this.users = [];
      const response = await userService.listing(this.queryArgs);
      if(response && response.status == 200) {
        this.users = response.data.results;
        this.queryArgs.count = response.data.count;
      }
      else {
        this.$emit('showMessage', response);
      }
      this.init = true;
    },
    changeOffset(offset) {
      this.queryArgs.offset = offset;
      this.fetch();
    },
    search(query) {
      if(this.queryArgs.query != query) {
        this.queryArgs.query = query;
        this.queryArgs.offset = 0;
        this.fetch();
      }
    },
    showMessage(e) {
      this.$emit("showMessage", e);
    }
  },
  components: {
    PopupAddUser,
    PopupUnlinkUser,
    Pagination,
    SearchField
  }
}
</script>
