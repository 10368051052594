<template>
  <div class="viewer-loader" :style="{ 'left': this.leftOffset+'px', 'width': 'calc(100% - '+this.leftOffset+'px)'}">
    <div>
      <img src="img/spinner.gif"><br/>
      {{loadStatus }}...
    </div>
  </div>
</template>

<script>
export default {
  props: ["loadStatus"],
  computed: {
    renderStore() {
      return this.$parent.renderStore;
    },
    leftOffset() {
      try { return this.renderStore.canvas.leftOffset; }
      catch { return null }
    }
  }
}
</script>