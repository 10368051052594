import api from '../api';
import tokenService from "../token.service";

class ToolboxService {

  async selectChapter(toolboxChapter) {
    try {
      await tokenService.awaitSessionRefresh();
      const response = await api.post(
        "/app/toolbox/select-chapter", {
          toolboxChapterId: toolboxChapter.toolboxChapterId
        }
      );
      return response.data;
    }
    catch (error) {
      return null;
    }
  }

  async selectItem(toolboxItem) {
    try {
      await tokenService.awaitSessionRefresh();
      const response = await api.post(
        "/app/toolbox/select-item", {
          toolboxItemId: toolboxItem.toolboxItemId
        }
      );
      return response.data;
    }
    catch (error) {
      return null;
    }
  }

  async addChapter(formData, defaultData) {
    try {
      await tokenService.awaitSessionRefresh();
      const response = await api.post(
        "/app/toolbox/add-chapter", this.toSubmitData(formData, defaultData)
      );
      return response;
    }
    catch (error) {
      return error.response;
    }
  }

  async addItem(toolboxItem, defaultData) {
    try {
      var formData = new FormData();
      var submitData = this.toSubmitData(toolboxItem.values, defaultData);
      var submitData2 = this.toSubmitData(toolboxItem, defaultData);
      for(const [field, value] of Object.entries(submitData2)) {
        if(field != "values" && value != null) formData.append(field, value);
        if(field == "name") submitData["sj"] = value;
      }
      formData.append("values", JSON.stringify(submitData));
      
      await tokenService.awaitSessionRefresh();
      const response = await api.post(
        "/app/toolbox/add-item", formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
      });
      return response;
    }
    catch (error) {
      return error.response;
    }
  }

  async unlinkChapter(id) {
    try {
      await tokenService.awaitSessionRefresh();
      const response = await api.post(
        "/app/toolbox/unlink-chapter", {
          toolboxChapterId: id
        }
      );
      return response;
    }
    catch (error) {
      return error.response;
    }
  }

  async unlinkItem(id) {
    try {
      await tokenService.awaitSessionRefresh();
      const response = await api.post(
        "/app/toolbox/unlink-item", {
          toolboxItemId: id
        }
      );
      return response;
    }
    catch (error) {
      return error.response;
    }
  }

  async listing(id) {
    try {
      await tokenService.awaitSessionRefresh();
      const response = await api.post(
        "/app/toolbox/listing", {
          toolboxChapterId: id
        }
      );
      return response;
    }
    catch (error) {
      //console.log(error);
      return error.response;
    }
  }

  toSubmitData(formData, defaultData) {
    const submitData = {...formData};
    for(const [key, val] of Object.entries(submitData)) {
      if(!isNaN(val)) continue;
      if(defaultData[key] && val == defaultData[key][1]) {
        submitData[key] = null;
      }
    }
    return submitData;
  }
}
export default new ToolboxService();