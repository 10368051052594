<template>
  <transition name="fade">
    <div class="popup" v-if="store.current.popup.includes('register-user')">
      <div v-if="init">
        <perfect-scrollbar style="height:100%">
          <form @submit.prevent="register">
            <div class="closer" @click="close()">Scherm sluiten</div>
            <h1>{{ title }}</h1>

            <div v-if="!success">
              <p>
                Via het formulier hieronder kunt u zich registreren voor onze AI-omgeving.
                We zullen uw registratie handmatig beoordelen en waar nodig kan het zijn dat
                we extra informatie vereisen. Bent u nog geen klant? Dan
                kunt u zich helaas nu nog niet registreren.
              </p>

              <label>
                {{ defaultData.genderId[0] }}
                <Multiselect 
                  v-model="user.genderId" 
                  :options="genders" 
                  label="name"
                  placeholder="Maak een keuze..."
                  noOptionsText="Geen opties gevonden.">
                </Multiselect>
              </label>
              <div v-if="formErrors.genderId" class="error-field">{{ defaultData.genderId[0] + " " + formErrors.genderId }}!</div>

              <div v-if="labelData.name" class="before-input" :class="{active:labelData.name.active}">{{ labelData.name.text }}</div>
              <input type="text" 
                name="name"
                v-model="user.name" 
                :class="{hasLabel:labelData.name.active}"
                @focusin="empty($event)" 
                @focusout="reset($event)">
              <div v-if="formErrors.name" class="error-field">{{ defaultData.name[0] + " " + formErrors.name }}!</div>

              <div v-if="labelData.surname" class="before-input" :class="{active:labelData.surname.active}">{{ labelData.surname.text }}</div>
              <input type="text" 
                name="surname"
                v-model="user.surname" 
                :class="{hasLabel:labelData.surname.active}"
                @focusin="empty($event)" 
                @focusout="reset($event)">
              <div v-if="formErrors.surname" class="error-field">{{ defaultData.surname[0] + " " + formErrors.surname }}!</div>

              <div v-if="labelData.email" class="before-input" :class="{active:labelData.email.active}">{{ labelData.email.text }}</div>
              <input type="text" 
                name="email"
                v-model="user.email" 
                :class="{hasLabel:labelData.email.active}"
                @focusin="empty($event)" 
                @focusout="reset($event)">
              <div v-if="formErrors.email" class="error-field">{{ defaultData.email[0] + " " + formErrors.email }}!</div>

              <div v-if="labelData.company" class="before-input" :class="{active:labelData.company.active}">{{ labelData.company.text }}</div>
              <input type="text" 
                name="company"
                v-model="user.company" 
                :class="{hasLabel:labelData.company.active}"
                @focusin="empty($event)" 
                @focusout="reset($event)">
              <div v-if="formErrors.company" class="error-field">{{ defaultData.company[0] + " " + formErrors.company }}!</div>

              <input type="submit" :value="submitText" :class="{locked:isLocked}">
            </div>
            <div v-else>
              <p>
                De registratie was succesvol. U ontvangt van ons bericht wanneer uw account is goedgekeurd. Parallel hieraan zult
                u zelf een e-mail ontvangen met daarin een link om alvast uw account te activeren.
              </p>
            </div>
          </form>
        </perfect-scrollbar>
      </div>
    </div>
  </transition>
</template>

<script>
import authService from '@/services/auth.service'
import listingService from '@/services/listing.service'
import Multiselect from '@vueform/multiselect'
import errorHandler from '@/common/ErrorHandler'

export default {
  emits: ['showMessage'],
  data() {
    return {
      user: {
        genderId: 0,
        name: '',
        surname: '',
        company: '',
        email: ''
      },
      genders: [],
      defaultData: {
        genderId: ['Geslacht', 'Wat is uw geslacht?'],
        name: ['Voornaam', 'Wat is uw voornaam?'],
        surname: ['Achternaam', 'Wat is uw achternaam?'],
        company: ['Bedrijfsnaam', 'Wat is uw bedrijfsnaam?'],
        email: ['Email-adres', 'Wat is uw e-mailadres?']
      },
      labelData: {},
      formErrors: {},
      title: "",
      submitText: "",
      success: false,
      init: false,
      pending: false
    };
  },
  computed: {
    store() {
      return this.$parent.store;
    },
    currentPopup() {
      return this.store.current.popup[this.store.current.popup.length-1];
    },
    isLocked() {
      if(this.pending || !this.init) return true;
      else return false;
    }
  },
  watch: {
    async currentPopup(value) {
      if(value == "register-user") {
        this.init = false;
        this.clear();
      
        this.title = "Registreren als gebruiker";
        this.submitText = "Registreren";

        await this.fetch();
        this.init = true;
        this.pending = false;
      }
    }
  },
  methods: {
    async register() {
      if(this.pending) return;
      this.pending = true;
      const response = await authService.register(this.user, this.defaultData);
      if(response) {
        if(response.status == 200) {
          this.success = true;
        }
        else {
          this.$emit('showMessage', response);
          this.formErrors = errorHandler.process(response.data, "");
        }
      }
      this.pending = false;
    },
    close() {
      this.$router.push('/');
      this.store.current.popup = [];
    },
    empty(e) {
      const val = this.user[e.target.name];
      if(this.defaultData[e.target.name] && (val == "" || val == this.defaultData[e.target.name][1])) {
        this.labelData[e.target.name] = {text: this.defaultData[e.target.name][0], active: true};
        this.user[e.target.name] = "";
      }
    },
    reset(e) {
      const val = this.user[e.target.name];
      if(val == "") {
        this.labelData[e.target.name] = {text: '', active: false};
        this.user[e.target.name] = this.defaultData[e.target.name][1];
      }
    },
    clear() {
      for(const[key, val] of Object.entries(this.defaultData)) {
        this.labelData[key] = {text: '', active: false};
        this.user[key] = val[1];
        this.formErrors = {};
      }
    },
    async fetch() {
      this.genders = await listingService.genders();
    }
  },
  components: {
    Multiselect
  }
}
</script>
