<template>
  <div class="hl" @mouseenter="pause" @mouseleave="resume">
    <div class="in">
      <div class="rd2 car">
        <!--<img src="img/web/3d_rotate-quan.png" :style="{transform: 'rotateY('+(360*this.rotation)+'deg)'}">-->
        <div v-if="slide == 1" :style="{opacity:this.opacity}">
          <div class="lf">
            <h3>Communiceren van hoeveelheden in PDF-documenten</h3>
            <p>
              Foundation biedt ondersteuning voor allerlei PDF-markeringen zoals
              lengtes en oppervlaktes. Bent u gewend aan de manier waarop Bluebeam Revu
              uw gegevens weergeeft? We garanderen u dat de weergave binnen Foundation 
              hier exact mee overeenkomt!
            </p>
            <p>
              De uittrekstaat is in Foundation direct gekoppeld aan de tekening. U kunt
              een markering op de tekening selecteren, maar ook vanuit de uittrekstaat.
              De interactiviteit is dus hetzelfde als binnen Bluebeam.
            </p>
            <div class="button" @click="$router.push('/over-het-pakket#hoeveelheden')">Meer informatie over dit onderwerp</div>
          </div>
          <div class="rg">
            <img src="img/web/bb-to-f.png">
          </div>
          <div class="clear"></div>
        </div>

        <div v-if="slide == 2" :style="{opacity:this.opacity}">
          <div class="lf">
            <h3>Deel documenten met iedereen binnen én buiten uw organisatie</h3>
            <p>
              U kunt vanuit Foundation documenten snel delen met verschillende
              partijen. Behoort de persoon tot uw eigen organisatie, dan zijn 
              documenten en daarin opgenomen informatie direct zichtbaar.
            </p>
            <p>
              Wilt u delen met een persoon buiten de organisatie? U kunt vanuit 
              Foundation een mailtje versturen met een link naar het document. 
              Natuurlijk wordt de persoon gevraagd om een verificatie, zodat u 
              altijd weet dat uw gegevens veilig zijn.
            </p>
            <p>
              Het maakt niet uit of u een document op uw computer, tablet of 
              telefoon bekijkt: onze viewer werkt op elk apparaat hetzelfde!
            </p>
            <div class="button" @click="$router.push('/over-het-pakket#informeren')">Meer informatie over dit onderwerp</div>
          </div>
          <div class="rg">
            <img src="img/web/sh-i-o.png">
          </div>
          <div class="clear"></div>
        </div>

        <div v-if="slide == 3" :style="{opacity:this.opacity}">
          <div class="lf">
            <h3>Samenwerken aan projecten</h3>
            <p>
              U kunt in Foundation probleemloos hoeveelheden bijmeten. Via
              formulieren kunt u daarnaast bijvoorbeeld kwaliteitsborging koppelen
              aan uw documenten. Desgewenst zijn uw gegevens zichtbaar voor iedere
              gebruiker met toegang tot het document.
            </p>
            <p>
              Iedereen heeft in Foundation beschikking tot zijn eigen "gereedschappen",
              waarmee vaak gemeten hoeveelheden kunnen worden vastgelegd om deze 
              snel te kunnen hergebruiken.
            </p>
            <div class="button" @click="$router.push('/over-het-pakket#samenwerken')">Meer informatie over dit onderwerp</div>
          </div>
          <div class="rg">
            <img src="img/web/quan.png">
          </div>
          <div class="clear"></div>
        </div>

        <span class="nxt" @click="next"></span>
        <span class="prv" @click="prev"></span>
      </div>
      <div class="rb">
        <div :style="{width: (this.progress/this.nextAfter*100)+'%'}"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from '@/store/pinia'

export default {
  data() {
    return {
      store: useStore(),
      progress: 0,
      nextAfter: 10000,
      slide: 1,
      opacity: 1,
      rotation: 0,
      paused: false,
      progressFunc: null
    };
  },
  computed: {

  },
  created() {
    this.create();
  },
  methods: {
    create() {
      if(this.progressFunc != null) {
        clearInterval(this.progressFunc);
      }
      this.progressFunc = setInterval(() => {
        if(!this.paused) {
          this.progress += 25;
        }
        if(this.progress > this.nextAfter) {
          this.next();
          this.progress = 0;
        }
      }, 25);
    },
    pause() {
      this.paused = true;
    },
    resume() {
      this.paused = false;
    },
    prev() {
      this.opacity = 0;
      this.rotation++;
      setTimeout(() => {
        if(this.slide > 1) this.slide--;
        else this.slide = 3;
        setTimeout(() => {
          this.opacity = 1;
          this.create();
        }, 100);
      }, 500);
    },
    next() {
      this.opacity = 0;
      this.rotation++;
      setTimeout(() => {
        if(this.slide < 3) this.slide++;
        else this.slide = 1;
        setTimeout(() => {
          this.opacity = 1;
          this.create();
        }, 100);
      }, 500);
    }
  }
};
</script>

<style scoped>
.hl {
  background: linear-gradient(40deg, rgb(240, 240, 240) 0%, rgb(202, 212, 230) 100%);
  position: relative;
}

.lf {
  width: 50%;
  display: table-cell;
  text-align: left;
  vertical-align: middle;
  margin: 0;
  padding-right: 50px;
}

.rg {
  width: 50%;
  display: table-cell;
  vertical-align: middle;
  margin: 0;
  padding-left: 50px;
}

.rg span {
  display: block;
  width: 100%;
}

.rg img {
  width: 100%;
  margin: 0;
}

.rd2.car {
  margin: 0 !important;
  padding: 0 !important;
  width: 100%;
  text-align: center;
  display: table;
  table-layout: fixed;
}

.rd2.car > div {
  width: calc(100% - 50px);
  margin: 0 auto;
  transition-duration: 0.5s;
}

.prv, .nxt {
  position: absolute;
  color: #474747;
  font-size: 150px;
  top: calc(50% - 75px);
  opacity: 0.5;
}

.prv {
  left: -50px;
}

.nxt {
  right: -50px;
}

.prv:before {
  position: relative;
  float: left;
  content: "‹";
}

.prv:hover {
  cursor: pointer;
  padding-left: 2px;
  transition-duration: 0.5s;
  opacity: 1;
}

.nxt:before {
  position: relative;
  float: right;
  content: "›";
}

.nxt:hover {
  cursor: pointer;
  padding-right: 2px;
  transition-duration: 0.5s;
  opacity: 1;
}

.button {
  float: left;
  margin: 0 auto;
}

.button:hover {
  background: #474747 !important;
  color: #fff !important;
}

img {
  width: 100px;
  margin: 0 auto;
  margin-bottom: 25px;
  transition-duration: 2s;
}

.rb {
  display: block;
  position: absolute;
  bottom: 0;
  left: 0; right: 0;
  background: #fff;
  height: 10px;
  overflow: hidden;
  opacity: 0.2;
}

.rb div {
  background-color: #474747;
  height: 100%;
}

h3 {
  margin-top: 0;
  font-weight: normal;
  font-size: 30px !important;
}
</style>