<template>
  <transition name="fade">
    <div class="popup" v-if="store.current.popup.includes('message-center')">
      <div>
        <perfect-scrollbar style="height:100%">
          <div class="closer" @click="close()">Scherm sluiten</div>
          <div class="adder" @click="readAll()">Markeer alles als gelezen</div>
          <h1>Berichtencentrum</h1>
          <div v-if="!init" class="loader" style="margin-bottom:2vh">
            <div>Even geduld, content wordt geladen...</div>
          </div>
          <template v-else>
            <template v-for="message in messages">
              <div class="quest-details msg" :class="{ unread: message.isUnread, noref: !message.documentUuid }">
                <p class="content">
                  <b>{{ message.date }}</b>: {{ message.message }}
                  <template v-if="message.contributionId">Ga direct <b><a href="#" @click="goToContribution(message)">naar de vraag</a></b>.</template>
                  <template v-else-if="message.documentUuid && message.documentVersion > 0">Bekijk het document <b><a href="#" @click="goToDocument(message)">hier</a></b>.</template>
                </p>
                <span>
                  <template v-if="message.isUnread"><span style="font-style:normal;display:inline">★</span> Dit is een nieuw bericht. </template>
                  <template v-if="message.documentUuid">Dit bericht hoort bij het document <b><a href="#" @click="goToDocument(message)">{{ message.documentName }}</a></b> (rev. {{ message.documentVersion }}) in het project <b><a href="#" @click="goToProject(message)">{{ message.projectName }}</a></b>.</template>
                  <template v-else>Het document of de vraag waarnaar dit bericht refereert bestaat niet meer.</template>
                  
                  <template v-if="message.isUnread"><br><span style="font-style:normal;display:inline">✔</span> <b><a href="#" @click="read(message)">Markeren als gelezen</a></b></template>
                  <template v-else><br><span style="font-style:normal;display:inline">✘</span> <b><a href="#" @click="unread(message)">Markeren als ongelezen</a></b></template>
                </span>
              </div>
            </template>
          </template>
          <input type="submit" value="Toon oudere berichten" @click="this.loadOlderMessages" :class="{locked:isLocked}">
        </perfect-scrollbar>
      </div>
    </div>
  </transition>
</template>

<script>
import userMessageService from '@/services/user.message.service'
import { useRenderStore } from '@/store/render'

export default {
  data() {
    return {
      messages: [],
      queryArgs: {
        orderType: "desc",
        orderBy: "date",
        query: "",
        offset: 0,
        pageSize: 5,
        count: 0
      },
      init: false,
      pending: false,
      renderStore: useRenderStore()
    };
  },
  computed: {
    store() {
      return this.$parent.store;
    },
    currentPopup() {
      return this.store.current.popup[this.store.current.popup.length-1];
    },
    isLocked() {
      if(this.pending || !this.init) return true;
      else return false;
    }
  },
  watch: {
    async currentPopup(value) {
      if(value == "message-center") {
        this.init = false;
        this.messages = [];
        this.queryArgs.offset = 0;
        await this.fetch();
        this.init = true;
      }
    }
  },
  methods: {
    async loadOlderMessages() {
      if(!this.pending) {
        this.queryArgs.offset++;
        await this.fetch();
      }
      this.pending = false;
    },
    close() {
      this.store.current.popup.splice(
        this.store.current.popup.indexOf('message-center'), 
        1
      );
    },
    async fetch() {
      const response = await userMessageService.listing(this.queryArgs);
      if(response) {
        if(response.status == 200) {
          this.messages = [...this.messages, ...response.data.results];
          this.queryArgs.count = response.data.count;
        }
        else {
          //this.$emit('showMessage', response);
        }
      }
    },
    async readAll() {
      const response = await userMessageService.readAll();
      if(response) {
        if(response.status == 200) {
          this.messages.forEach(message => {
            message.isUnread = false;
          });
          this.store.components.menuMain.updateMessageCount();
        }
      }
    },
    async read(message) {
      const response = await userMessageService.read(message.userMessageId);
      if(response) {
        if(response.status == 200) {
          message.isUnread = false;
          this.store.components.menuMain.updateMessageCount();
        }
      }
    },
    async unread(message) {
      const response = await userMessageService.unread(message.userMessageId);
      if(response) {
        if(response.status == 200) {
          message.isUnread = true;
          this.store.components.menuMain.updateMessageCount();
        }
      }
    },
    async goToProject(message) {
      this.store.current.data.pdf.project = { projectId: message.projectId };
      this.store.current.data.pdf.contribution = null;
      this.store.current.data.pdf.document = null;
      this.store.current.form = "view-pdf-project";
      this.store.current.popup = [];
      await this.read(message);
      this.store.components.menuMain.updateMessageCount();
    },
    async goToDocument(message) {
      if(this.store.current.form == "view-pdf-document") {
        this.store.current.form = "list-pdf-projects";
      }

      this.$nextTick(async function() {
        this.store.current.data.pdf.document = { uuid: message.documentUuid, version: message.documentVersion };
        this.store.current.data.pdf.contribution = null;
        this.store.current.form = "view-pdf-document";
        this.store.current.popup = [];
        await this.read(message);
        this.store.components.menuMain.updateMessageCount();
      });
    },
    async goToContribution(message) {
      if(this.store.current.data.pdf.document == null || this.store.current.data.pdf.document.uuid != message.documentUuid) {
        this.store.current.data.pdf.document = { uuid: message.documentUuid, version: message.documentVersion };
      }
      this.store.current.data.pdf.contribution = null;
      this.store.current.form = "view-pdf-document";
      this.store.current.popup = [];

      this.$nextTick(async function() {
        this.store.current.data.pdf.contribution = { contributionId: message.contributionId };
        this.renderStore.options.activeContribution = null;
        
        await this.read(message);
        this.store.components.menuMain.updateMessageCount();
      });
    }
  },
  components: {
    
  }
}
</script>
