
export default class Vector {

    constructor(x, y) {
        this.x = x;
        this.y = y;
    }

    normalize() {
        var len = Math.sqrt(Math.pow(this.x, 2) + Math.pow(this.y, 2));
        this.x /= len;
        this.y /= len;
    }

    angle() {
        var ang = Math.atan2(this.y, this.x);
        var deg = 180 * ang/Math.PI;
        return (360 + Math.round(deg)) % 360;
    }
  }