export default class PdfImage {

    constructor(easel) {
        this.easel = easel;
    }

    // Default attributes that define the "empty" state.
    defaults() {
        return {
            easel: null,
            loaded: false
        }
    }

    // Attribute mutations.
    mutations() {
        return {
            easel: (easel) => Object(easel) || null
        }
    }

    // Attribute validation
    validation() {
        return {
            
        }
    }

    // Route configuration
    /*
    routes() {
        return {
            fetch: '/gridline/{id}',
            save:  '/gridline',
        }
    }
    */
}