<template>
  <transition name="fade">
    <div v-if="init">
      <div v-if="store.current.popup.includes('reset-user')">
        <perfect-scrollbar style="height:100%">
          <div class="popup">
            <div>
              <perfect-scrollbar style="height:100%">
                <form @submit.prevent="resetRequest">
                  <div class="closer" @click="close()">Scherm sluiten</div>
                  <h1>{{ title }}</h1>
                  <div v-if="!requestSent">
                    <p>
                      Door het invullen van dit formulier kunt u een nieuw wachtwoord aanvragen. Wanneer er
                      een account geassocieerd is met het ingevulde e-mailadres, krijgt u binnen enkele minuten
                      een mailtje met daarin de instructies voor het herstellen van uw wachtwoord.
                    </p>
                    <div v-if="labelData.email" class="before-input" :class="{active:labelData.email.active}">{{ labelData.email.text }}</div>
                    <input type="text" 
                      name="email"
                      v-model="user.email" 
                      :class="{hasLabel:labelData.email.active}"
                      @focusin="empty($event)" 
                      @focusout="reset($event)">
                    <div v-if="formErrors.email" class="error-field">{{ defaultData.email[0] + " " + formErrors.email }}!</div>
                    <input type="submit" :value="submitText" :class="{locked:isLocked}">
                  </div>
                  <div v-else>
                    <h2>Controleer uw mailbox</h2>
                    <p>
                      De aanvraag voor het opnieuw aanmaken van een wachtwoord is succesvol in ons systeem
                      verwerkt. U zult binnen enkele minuten een e-mail van ons ontvangen met daarin een
                      link waarmee u uw gebruikersaccount kunt herstellen.
                    </p>
                    <p>
                      Ontvangt u geen e-mail? Probeer het dan eventueel over een tijdje opnieuw.
                    </p>
                  </div>
                  <div class="clear"></div>
                </form>
              </perfect-scrollbar>
            </div>
          </div>
        </perfect-scrollbar>
      </div>

      <div v-if="store.current.popup.includes('reset-user-change')">
        <perfect-scrollbar>
          <div class="popup">
            <div>
              <perfect-scrollbar style="height:100%">
                <form @submit.prevent="resetChange">
                  <div class="closer" @click="close()">Scherm sluiten</div>
                  <h1>{{ title }}</h1>
                  <div v-if="!changeSuccess">
                    <p>
                      Door het invullen van dit formulier kunt u een nieuw wachtwoord instellen. Het wachtwoord
                      dient tenminste aan de volgende eisen te voldoen:
                      <ul>
                        <li>Minimale lengte van 10 tekens</li>
                        <li>Tenminste 1 hoofdletter</li>
                        <li>Tenminste 1 kleine letter</li>
                        <li>Tenminste 1 cijfer</li>
                        <li>Tenminste 1 van de volgende karakters: !@#$%&*()</li>
                      </ul>
                    </p>

                    <div v-if="labelData.password1" class="before-input" :class="{active:labelData.password1.active}">{{ labelData.password1.text }}</div>
                    <input type="text" 
                      name="password1"
                      v-model="user.password1" 
                      :class="{hasLabel:labelData.password1.active}"
                      @focusin="empty($event)" 
                      @focusout="reset($event)"
                      @input="togglePassword">
                    <div v-if="formErrors.password1" class="error-field">{{ defaultData.password1[0] + " " + formErrors.password1 }}!</div>

                    <div v-if="labelData.password2" class="before-input" :class="{active:labelData.password2.active}">{{ labelData.password2.text }}</div>
                    <input type="text" 
                      name="password2"
                      v-model="user.password2" 
                      :class="{hasLabel:labelData.password2.active}"
                      @focusin="empty($event)" 
                      @focusout="reset($event)"
                      @input="togglePassword">
                    <div v-if="formErrors.password2" class="error-field">{{ defaultData.password2[0] + " " + formErrors.password2 }}!</div>
                    <input type="submit" :value="submitText" :class="{locked:isLocked}">
                  </div>
                  <div v-else>
                    <h2>Uw wachtwoord is succesvol gewijzigd</h2>
                    <p>
                      Het aanpassen van uw wachtwoord was succesvol en de wijzigingen zijn in ons systeem doorgevoerd.
                      U kunt vanaf nu inloggen met uw nieuwe wachtwoord.
                    </p>
                    <a class="submit" @click="store.current.popup = ['login-user']">Nu inloggen</a>
                  </div>
                  <div class="clear"></div>
                </form>
              </perfect-scrollbar>
            </div>
          </div>
        </perfect-scrollbar>
      </div>

      <div v-if="store.current.popup.includes('reset-user-invalid')">
        <perfect-scrollbar>
          <div class="popup small">
            <div>
              <div class="closer" @click="close()">Scherm sluiten</div>
              <h1>Wachtwoordherstel mislukt</h1>
              <p>
                De door u gebruikte link is al gebruikt of is niet geldig. Neem contact 
                met ons op als u uw account niet kunt gebruiken.
              </p>
            </div>
          </div>
        </perfect-scrollbar>
      </div>
    </div>
  </transition>
</template>

<script>
import authService from '@/services/auth.service'
import errorHandler from '@/common/ErrorHandler'

export default {
  emits: ['showMessage'],
  data() {
    return {
      user: {
        email: '',
        username: '',
        password1: '',
        password2: '',
        token: ''
      },
      defaultData: {
        email: ['Gebruikersnaam of e-mailadres', 'Wat is uw gebruikersnaam of e-mailadres?'],
        password1: ['Wachtwoord', 'Welk wachtwoord wilt u instellen?'],
        password2: ['Wachtwoord (herhaling)', 'Kunt u het wachtwoord nogmaals invullen?'],
      },
      labelData: {},
      formErrors: {},
      requestSent: false,
      changeSuccess: false,
      title: "",
      submitText: "",
      init: false,
      pending: false
    };
  },
  computed: {
    store() {
      return this.$parent.store;
    },
    currentPopup() {
      return this.store.current.popup[this.store.current.popup.length-1];
    },
    isLocked() {
      if(this.pending || !this.init) return true;
      else return false;
    }
  },
  watch: {
    async currentPopup(value) {
      if(value == "reset-user" || value == "reset-user-change") {
        this.init = false;
        this.clear();
        
        this.requestSent = false;
        this.changeSuccess = false;
      
        this.title = "Wachtwoord herstellen";
        this.submitText = "Herstel mijn wachtwoord";

        if(typeof this.$route.query.username !== 'undefined') {
          this.user.username = this.$route.query.username;
        }
        if(typeof this.$route.query.token !== 'undefined') {
          this.user.token = this.$route.query.token;
        }

        if(value == "reset-user-change") {
          const response = await authService.resetCheckValidity(this.user);
          if(!(response && response.status == 200)) {
            this.store.current.popup = ["reset-user-invalid"];
            this.$router.replace("/");
          }
          this.init = true;
          this.pending = false;
        }
        else {
          this.init = true;
          this.pending = false;
        }
      }
    }
  },
  methods: {
    async resetRequest() {
      if(this.pending) return;
      this.pending = true;
      const response = await authService.resetRequest(this.user, this.defaultData);
      if(response && response.status == 200) {
        this.requestSent = true;
        this.clear();
      }
      else {
        this.$emit('showMessage', response);
        this.formErrors = errorHandler.process(response.data, "");
      }
      this.pending = false;
    },
    async resetChange() {
      this.pending = true;
      const response = await authService.resetChange(this.user, this.defaultData);
      if(response && response.status == 200) {
        this.changeSuccess = true;
        this.clear();
      }
      else {
        this.$emit('showMessage', response);
        this.formErrors = errorHandler.process(response.data, "");
      }
      this.pending = false;
    },
    close() {
      this.$router.push('/');
      this.store.current.popup = [];
    },
    empty(e) {
      const val = this.user[e.target.name];
      if(this.defaultData[e.target.name] && (val == "" || val == this.defaultData[e.target.name][1])) {
        this.labelData[e.target.name] = {text: this.defaultData[e.target.name][0], active: true};
        this.user[e.target.name] = "";
      }
    },
    reset(e) {
      const val = this.user[e.target.name];
      if(val == "") {
        this.labelData[e.target.name] = {text: '', active: false};
        this.user[e.target.name] = this.defaultData[e.target.name][1];
      }
    },
    clear() {
      for(const[key, val] of Object.entries(this.defaultData)) {
        this.labelData[key] = {text: '', active: false};
        this.user[key] = val[1];
        this.formErrors = {};
      }
    },
    togglePassword(e) {
      if(e.target.value.length == 0 || e.target.value == this.defaultData[e.target.name]) {
        e.target.type = "text";
      }
      else {
        e.target.type = "password";
      }
    }
  }
}
</script>
