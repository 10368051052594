<template>
  <template v-if="typeof pivotData === 'object' && String(Object.keys(pivotData)[0]).startsWith(' ')">
    <template v-for="(pivotSubData, index) in pivotData" :key="index">
      <!--<template v-if="showPivotLevels && tlayer <= tlayerCount - activeLevel">
        <tr @click="filterAnnotations(pivotValues, index)" class="sub" :n="tlayerCount - tlayer - 1">
          <td v-for="(n,i) in tlayer" :i="tlayerCount - i - 1" :key="i"></td>
          <td class="exp"></td>
          <template v-for="(n,i) in tlayerCount" :key="i">
            <template v-if="n === 1">
              <td v-if="i != tlayer" :colspan="tlayerCount - tlayer"><span>{{ parsedText(pivotValues[i]) }}</span></td>
              <td v-else :colspan="tlayerCount - tlayer"><span>{{ parsedText(index) }}</span></td>
            </template>
            <td v-else-if="i != tlayer"><span>{{ parsedText(pivotValues[i]) }}</span></td>
            <td v-else><span>{{ parsedText(index) }}</span></td>
          </template>
          <template v-for="(n,i) in thead.length - tlayerCount" :key="i">
            <td><span>{{ findTotal(pivotValues, index, tlayerCount + i) }}</span></td>
          </template>
        </tr>
      </template>-->

      <template v-if="tlayer === 0">
        <tr class="div" :n="tlayerCount - tlayer"><td :colspan="tlayerCount + thead.length"></td></tr>
      </template>

      <template v-if="!showPivotLevels && tlayer === tlayerCount - activeLevel">
        <tr @click="filterAnnotations(pivotValues, index)" class="sub" :n=1>
          <template v-for="(n,i) in thead.length" :key="i">
            <template v-if="!thead[i].hidden">
              <template v-if="n === 1">
                <td v-if="i != tlayer"><span>{{ parsedText(pivotValues[i]) }} ({{ findMeasureCount(pivotSubData) }})</span></td>
                <td v-else><span>{{ parsedText(index) }} ({{ findMeasureCount(pivotSubData) }})</span></td>
              </template>
              <td v-else-if="i < tlayerCount - activeLevel"><span>{{ parsedText(pivotValues[i], i) }}</span></td>
              <td v-else-if="i == tlayerCount - activeLevel"><span>{{ parsedText(index, i) }}</span></td>
              <td v-else-if="i > tlayerCount - activeLevel && i < tlayerCount"></td>
              <td v-else><span>{{ findTotal(index, i) }}</span></td>
            </template>
          </template>
        </tr>
      </template>

      <AnnotationRow 
        v-if="isReady"
        :pivotData="pivotSubData"
        :pivotValues="updatePivotValues(index)" 
        :thead="thead" 
        :tlayer="tlayer+1" 
        :tlayerCount="tlayerCount"
        :activePage=activePage
        :activeIndex=activeIndex
        :activeLevel=activeLevel
        :columnWidths="columnWidths"
        :isReady=isReady
        :showPivotLevels=showPivotLevels
        :filterCount=filterCount
        @update-unit="updateUnit"
      />
    </template>
  </template>
</template>

<script>
import { resetSelection } from '@/components/app/viewer/PdfCanvas.js'

export default {
  emits: ['updateUnit'],
  data() {
    return {
      collapse: false
    }
  },
  props: {
    pivotData: {
      required: true
    },
    pivotValues: {
      type: Array,
      required: true
    },
    index: {
      type: Number,
      required: false
    },
    thead: {
      type: Array,
      required: true
    },
    tlayer: {
      type: Number,
      required: true
    },
    tlayerCount: {
      type: Number,
      required: true
    },
    activePage: {
      type: Number,
      required: true
    },
    activeIndex: {
      type: Number,
      required: true
    },
    activeLevel: {
      type: Number,
      required: true
    },
    columnWidths: {
      type: Object,
      required: true
    },
    isReady: {
      type: Boolean,
      required: true
    },
    showPivotLevels: {
      type: Boolean,
      required: true
    },
    filterCount: {
      type: Number,
      required: true
    }
  },
  computed: {
    renderStore() {
      return this.$parent.renderStore;
    },
    totals() {
      return this.calculateTotals(this.pivotData, null, null);
    }
  },
  methods: {
    getColumnWidth(colId) {
      if(this.columnWidths[colId]) {
        return "width:" + this.columnWidths[colId].width.toFixed(2)+"%";
      }
    },
    parsedText(text, colId) {
      if(text == null) return "";
      if(colId != null) {
        var col = this.thead[colId];
        if(col.type.match(/(Number|Formula)/)) {
          let floatVal = parseFloat(text);
          if(isNaN(floatVal)) return (0).toFixed(col.precision);
          if(col.unit != null) {
            if(floatVal != 0) return floatVal.toFixed(col.precision) + " " + col.unit;
            else return "";
          }
          return floatVal.toFixed(col.precision);
        }
      }

      return this.convertToText(text);
    },
    findMeasureCount(data) {
      var count = 0;

      if(this.renderStore.annotations.data.filter.length > 0) {
        return this.filterCount;
      }

      if(typeof data[0] === 'number') {
        return data[0];
      }

      Object.keys(data).forEach(key => {
        if(typeof data[key][0] !== 'number') {
          count += this.findMeasureCount(data[key]);
        }
        else count += data[key][0];
      }, count);
      return count;
    },
    updatePivotValues(name) {
      var pivotValues = [...this.pivotValues];

      try {
        pivotValues.push(name.length > 0 ? name : "");
        return pivotValues;
      }
      catch {
        return [];
      }
    },
    calculateTotals(data, totals, parentName) {
      if(data == null) return totals;

      var first = false;
      if(totals == null) {
        totals = {};
        first = true;
      }

      Object.entries(data).forEach(subData => {
        if(first) parentName = subData[0];
        if(typeof subData[1] === "object" && !(String(Object.keys(subData[1])[0]).startsWith(" "))) {
          Object.entries(subData[1][1]).forEach(entry => {
            if(totals[parentName] == null) totals[parentName] = {};
            if(totals[parentName][entry[0]] != null && this.isNumeric(totals[parentName][entry[0]])) totals[parentName][entry[0]] += entry[1];
            else totals[parentName][entry[0]] = entry[1];
          });
        }
        else {
          totals = this.calculateTotals(subData[1], totals, parentName);
        }
      });
      
      try {
        var sample = totals[Object.keys(totals)[0]];
        var sampleKeys = Object.keys(sample);
        if(sampleKeys.indexOf("totaal") >= 0 && sampleKeys.indexOf(this.thead[this.renderStore.annotations.data.unitAt].name) >= 0) {
          this.$emit("updateUnit", sample[this.thead[this.renderStore.annotations.data.unitAt].name]);
        }
      }
      catch { }

      return totals;
    },
    findTotal(index, colId) {
      try {
        if(this.totals && colId != null) {
          var col = this.thead[colId];
          var value = this.totals[index][col.name];
          if(col.type == 'Number' || col.type == 'Formula') {
            let floatVal = parseFloat(value);
            if(isNaN(floatVal)) return (0).toFixed(col.precision);

            var unit = this.parsedText(col.unit);
            if(unit.length == 0 && col.name == "totaal" && this.renderStore.annotations.data.unitAt != -1) {
              unit = this.totals[index][this.thead[this.renderStore.annotations.data.unitAt].name];
            }
            
            if(floatVal != 0) return floatVal.toFixed(col.precision) + " " + unit;
            else return "";
          }

          return this.convertToText(value);
        }
      }
      catch { }
      return null;
    },
    convertToText(value) {
      try { return value.replace(/&#(\d+);/g, function (m, n) { 
        return String.fromCharCode(n); }); 
      }
      catch(e) { return ""; }
    },
    async filterAnnotations(pivotValues, currName) {
      var storeText = "";
      for(let i = 0; i < this.tlayer; i++) {
        storeText += String(pivotValues[i]).substring(1) + "@!@"; 
      }
      storeText += String(currName).substring(1) + "@!@";

      this.renderStore.annotations.data.goto = true;
      this.renderStore.annotations.data.filter = storeText;
      await this.renderStore.loadAnnotations(true);
      resetSelection();

    },
    isNumeric(n) {
      return !isNaN(parseFloat(n)) && isFinite(n);
    },
    updateUnit(unit) {
      this.$emit("updateUnit", unit);
    }
  }
}
</script>