<template>
  <div class="content" v-if="this.renderStore.options.activeSideMenu === 'problem'">
    <div class="cm" @click="closeSideMenu()" title="Scherm sluiten">
      <img src="img/ico/close-1.png">
    </div>
    <h3>Een probleem melden</h3>
    <p>
      Foundation is momenteel nog in ontwikkeling. Daardoor kan het zijn dat u als gebruiker problemen
      ervaart bij bijvoorbeeld het uploaden van een nieuw document of de weergave van markeringen.
    </p>
    <p>
      Wilt u een probleem melden of een suggestie doen? U kunt ons bereiken door contact op te 
      nemen met Digitable op het telefoonnummer +31(0)402 5678 50. U kunt ook een email sturen naar 
      <a href="mailto:dennis@digitable.nl">dennis@digitable.nl</a>.
    </p>

    <h4>Problemen met een specifiek document?</h4>
    <p>
      Wij verzoeken u om uw <b>originele PDF-bestand mee te sturen</b> met uw vraag. Het origineel wordt 
      namelijk niet op onze server opgeslagen, waardoor wij géén directe inzage hebben in gegevens
      die u op de server heeft geüpload.
    </p>
  </div>
</template>

<script>
export default {
  computed: {
    renderStore() {
      return this.$parent.renderStore;
    }
  },
  methods: {
    closeSideMenu() {
      this.renderStore.options.activeSideMenu = null;
    }
  }
}
</script>