import api from '../api';
import tokenService from "../token.service";

class DocumentHistoryService {

  async select(documentHistory) {
    try {
      await tokenService.awaitSessionRefresh();
      const response = await api.post(
        "/app/document-history/select", {
          documentHistoryId: documentHistory.documentHistoryId
        }
      );
      return response.data;
    }
    catch (error) {
      return null;
    }
  }

  async update(formData, defaultData) {
    try {
      await tokenService.awaitSessionRefresh();
      const response = await api.post(
        "/app/document-history/update", this.toSubmitData(formData, defaultData)
      );
      return response;
    }
    catch (error) {
      return error.response;
    }
  }

  async unlink(id) {
    try {
      await tokenService.awaitSessionRefresh();
      const response = await api.post(
        "/app/document-history/unlink", {
          documentHistoryId: id
        }
      );
      return response;
    }
    catch (error) {
      return error.response;
    }
  }

  async listing(queryArgs) {
    try {
      await tokenService.awaitSessionRefresh();
      const response = await api.post(
        "/app/document-history/listing", queryArgs
      );
      return response;
    }
    catch (error) {
      return error.response;
    }
  }

  async fetchDataColumns(documentHistoryId) {
    try {
      await tokenService.awaitSessionRefresh();
      const response = await api.post(
        "/app/document-history/fetch-datacolumns", {
          documentHistoryId: documentHistoryId
        }
      );
      var dataColumns = JSON.parse(response.data.dataColumns).sort();
      return dataColumns;
    }
    catch (error) {
      return null;
    }
  }

  async downloadBackup(documentHistory) {
    try {
      let config = {
        responseType: 'arraybuffer',
        headers: {
          'content-type': 'application/json',
          'Accept': 'application/zip'
        }
      };

      await tokenService.awaitSessionRefresh();
      const response = await api.post(
        "/app/document-history/download-backup", {
          documentHistoryId: documentHistory.documentHistoryId
        }, config
      );

      var blob = new Blob([response.data], {type: "application/pdf"});
      return URL.createObjectURL(blob);
    }
    catch (error) {
      return null;
    }
  }

  toSubmitData(formData, defaultData) {
    const submitData = {...formData};
    for(const [key, val] of Object.entries(submitData)) {
      if(!isNaN(val)) continue;
      if(defaultData[key] && val == defaultData[key][1]) {
        submitData[key] = null;
      }
    }
    return submitData;
  }
}
export default new DocumentHistoryService();