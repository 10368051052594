<template>
  <transition name="fade">
    <div class="popup small" v-if="store.current.popup.includes('default-pdf-columns')">
      <div>
        <perfect-scrollbar style="height:100%">
          <form @submit.prevent="close">
            <div class="closer" @click="close()">Scherm sluiten</div>
            <h1>Gegevenskolommen niet aangetroffen</h1>
            <p>
              De kolommen binnen de weergave voor "Projecttotalen en -subtotalen" zijn hersteld 
              naar de basis omdat één of meerdere kolommen in uw persoonlijke (standaard)kolomindeling 
              niet in dit document werden aangetroffen.
            </p>
            <input type="submit" value="Ok, begrepen">
          </form>
        </perfect-scrollbar>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  emits: ['showMessage', 'updateParent'],
  props: {
    document: Object,
    getData: Object
  },
  data() {
    return {
      title: "",
      submitText: "",
      init: false,
      pending: false
    };
  },
  computed: {
    renderStore() {
      return this.$parent.renderStore;
    },
    store() {
      return this.$parent.store;
    },
    currentPopup() {
      return this.store.current.popup[this.store.current.popup.length-1];
    },
    isLocked() {
      if(this.pending || !this.init) return true;
      else return false;
    }
  },
  watch: {
    async currentPopup(value) {
      if(value == "default-pdf-columns") {
        this.pending = false;
      }
    }
  },
  methods: {
    close() {
      this.store.current.popup.splice(
        this.store.current.popup.indexOf('default-pdf-columns'), 
        1
      );
    }
  },
  components: {
    
  }
}
</script>
