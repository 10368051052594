<template>
  <transition name="fade">
    <div class="popup" v-if="store.current.popup.includes('add-company')">
      <div v-if="init">
        <perfect-scrollbar style="height:100%">
          <form @submit.prevent="add">
            <div class="closer" @click="close()">Scherm sluiten</div>
            <h1>{{ title }}</h1>
            <div v-if="init">
              <h2>Generieke gegevens</h2>
              
              <div v-if="labelData.name" class="before-input" :class="{active:labelData.name.active}">{{ labelData.name.text }}</div>
              <input type="text" 
                name="name"
                v-model="company.name" 
                :class="{hasLabel:labelData.name.active}"
                @focusin="empty($event)" 
                @focusout="reset($event)">
              <div v-if="formErrors.name" class="error-field">{{ defaultData.name[0] + " " + formErrors.name }}!</div>

              <div v-if="labelData.phoneNumber" class="before-input" :class="{active:labelData.phoneNumber.active}">{{ labelData.phoneNumber.text }}</div>
              <input type="text" 
                name="phoneNumber"
                v-model="company.phoneNumber" 
                :class="{hasLabel:labelData.phoneNumber.active}"
                @focusin="empty($event)" 
                @focusout="reset($event)">
              <div v-if="formErrors.phoneNumber" class="error-field">{{ defaultData.phoneNumber[0] + " " + formErrors.phoneNumber }}!</div>

              <div v-if="labelData.vatNumber" class="before-input" :class="{active:labelData.vatNumber.active}">{{ labelData.vatNumber.text }}</div>
              <input type="text" 
                name="vatNumber"
                v-model="company.vatNumber" 
                :class="{hasLabel:labelData.vatNumber.active}"
                @focusin="empty($event)" 
                @focusout="reset($event)"
                :disabled="companyId">
              <div v-if="formErrors.vatNumber" class="error-field">{{ defaultData.vatNumber[0] + " " + formErrors.vatNumber }}!</div>
              <div style="clear:both;height:50px"></div>

              <h2>Adressen</h2>
              <div class="filter-list">
                <template v-for="(thisAddress, index) in address" :key="index">
                  <label>
                    {{ defaultData.address.addressTypeId[0] }}
                    <Multiselect 
                      v-model="thisAddress.addressTypeId" 
                      :options="addressTypes" 
                      placeholder="Maak een keuze..."
                      noOptionsText="Geen opties gevonden.">
                    </Multiselect>
                  </label>
                  <div v-if="formErrors.address[index] && formErrors.address[index].addressTypeId" class="error-field">{{ defaultData.address.addressTypeId[0] + " " + formErrors.address[index].addressTypeId }}!</div>

                  <div class="before-input" :class="{active:labelData.address[index].name.active}">{{ labelData.address[index].name.text }}</div>
                  <input type="text" 
                    :name="'address['+index+'].name'"
                    v-model="thisAddress.name" 
                    :class="{hasLabel:labelData.address[index].name.active}"
                    @focusin="empty($event)" 
                    @focusout="reset($event)">
                  <div v-if="formErrors.address[index] && formErrors.address[index].name" class="error-field">{{ defaultData.address.name[0] + " " + formErrors.address[index].name }}!</div>

                  <div v-if="thisAddress.street1 != null" class="before-input" :class="{active:labelData.address[index].street1.active}">{{ labelData.address[index].street1.text }}</div>
                  <input type="text" 
                    :name="'address['+index+'].street1'"
                    v-model="thisAddress.street1" 
                    :class="{hasLabel:labelData.address[index].street1.active}"
                    @focusin="empty($event)" 
                    @focusout="reset($event)">
                  <div v-if="formErrors.address[index] && formErrors.address[index].street1" class="error-field">{{ defaultData.address.street1[0] + " " + formErrors.address[index].street1 }}!</div>

                  <div v-if="thisAddress.street2 != null" class="before-input" :class="{active:labelData.address[index].street2.active}">{{ labelData.address[index].street2.text }}</div>
                  <input type="text" 
                    :name="'address['+index+'].street2'"
                    v-model="thisAddress.street2" 
                    :class="{hasLabel:labelData.address[index].street2.active}"
                    @focusin="empty($event)" 
                    @focusout="reset($event)">
                  <div v-if="formErrors.address[index] && formErrors.address[index].street2" class="error-field">{{ defaultData.address.street2[0] + " " + formErrors.address[index].street2 }}!</div>

                  <div style="float:left;width:30%;margin-right:2%">
                    <div v-if="thisAddress.postalCode != null" class="before-input" :class="{active:labelData.address[index].postalCode.active}">{{ labelData.address[index].postalCode.text }}</div>
                    <input type="text" 
                      :name="'address['+index+'].postalCode'"
                      v-model="thisAddress.postalCode" 
                      :class="{hasLabel:labelData.address[index].postalCode.active}"
                      @focusin="empty($event)" 
                      @focusout="reset($event)">
                    <div v-if="formErrors.address[index] && formErrors.address[index].postalCode" class="error-field">{{ defaultData.address.postalCode[0] + " " + formErrors.address[index].postalCode }}!</div>
                  </div>

                  <div style="float:left;width:68%">
                    <div v-if="thisAddress.city != null" class="before-input" :class="{active:labelData.address[index].city.active}">{{ labelData.address[index].city.text }}</div>
                    <input type="text" 
                      :name="'address['+index+'].city'"
                      v-model="thisAddress.city" 
                      :class="{hasLabel:labelData.address[index].city.active}"
                      @focusin="empty($event)" 
                      @focusout="reset($event)">
                    <div v-if="formErrors.address[index] && formErrors.address[index].city" class="error-field">{{ defaultData.address.city[0] + " " + formErrors.address[index].city }}!</div>
                  </div>
                  <div style="clear:both"></div>

                  <label>
                    {{ defaultData.address.countryId[0] }}
                    <Multiselect 
                      v-model="thisAddress.countryId" 
                      :options="countries" 
                      placeholder="Maak een keuze..."
                      noOptionsText="Geen opties gevonden.">
                    </Multiselect>
                  </label>
                  <div v-if="formErrors.address[index] && formErrors.address[index].countryId" class="error-field">{{ defaultData.address.countryId[0] + " " + formErrors.address[index].countryId }}!</div>
                  
                  <div class="submit red" @click="removeAddress(index)">Dit adres verwijderen</div>

                  <div style="clear:both;height:50px"></div>
                </template>

                <div class="submit" @click="addAddress">Nieuw adres toevoegen</div>
                <div style="clear:both"></div>
              </div>

              <input type="submit" :value="submitText" :class="{locked:isLocked}">
            </div>
            <div v-else class="loader">
              <div>Even geduld, content wordt geladen...</div>
            </div>
          </form>
        </perfect-scrollbar>
      </div>
    </div>
  </transition>
</template>

<script>
import companyService from '@/services/company.service'
import listingService from '@/services/listing.service'

import errorHandler from '@/common/ErrorHandler'
import Multiselect from '@vueform/multiselect'

export default {
  emits: ['showMessage', 'fetchListing'],
  data() {
    return {
      companyId: null,
      company: {
        name: ""
      },
      address: [],
      addressTypes: null,
      countries: null,
      defaultData: {
        companyId: ['', null],
        name: ['Naam bedijf', 'Wat is de naam van het bedrijf?'],
        phoneNumber: ['Telefoonnummer', 'Wat is het telefoonnummer van het bedrijf?'],
        vatNumber: ['BTW-nummer', 'Wat is het BTW-nummer van het bedrijf?'],
        address: {
          name: ['Afwijkende naam (optioneel)', 'Vul hier eventueel een tenaamstelling in.'],
          street1: ['Adresregel 1', 'Vul hier adresregel 1 in.'],
          street2: ['Adresregel 2 (optioneel)', 'Vul hier eventueel adresregel 2 in.'],
          city: ['Woonplaats', 'Vul hier de woonplaats in.'],
          postalCode: ['Postcode', 'Vul hier de postcode in.'],
          region: ['Provincie / regio', 'Vul hier de provincie of regio in.'],
          countryId: ['Land', 'In welk land ligt dit adres?'],
          addressTypeId: ['Adres type', 'Welk type adres is dit?']
        }
      },
      labelData: { address: [] },
      formErrors: { address: [] },
      title: "",
      submitText: "",
      init: false,
      pending: false
    };
  },
  computed: {
    store() {
      return this.$parent.store;
    },
    currentPopup() {
      return this.store.current.popup[this.store.current.popup.length-1];
    },
    isLocked() {
      if(this.pending || !this.init) return true;
      else return false;
    }
  },
  watch: {
    async currentPopup(value) {
      if(value == "add-company") {
        this.init = false;
        this.clear();

        if(this.store.current.data.company) {
          this.title = "Account wijzigen";
          this.submitText = "Wijzigingen opslaan";
          this.companyId = this.store.current.data.company.companyId;
        }
        else {
          this.title = "Account toevoegen";
          this.submitText = "Record aanmaken";
          this.companyId = null;
        }
        
        await this.fetch();
        this.addressTypes = await listingService.addressTypes();
        this.countries = await listingService.countries();

        this.init = true;
        this.pending = false;
      }
    },
    company(listing) {
      for(const[key, value] of Object.entries(listing)) {
        if(!this.defaultData[key]) continue;
        if(value && value.toString().length > 0) {
          this.labelData[key] = {text: this.defaultData[key][0], active: true};
        }
        else {
          this.labelData[key] = {text: '', active: false};
          this.company[key] = this.defaultData[key][1];
        }
      }
    }
  },
  methods: {
    async add() {
      if(this.pending) return;
      this.pending = true;
      let response = null;
      if(!this.store.current.data.company) {
        response = await companyService.add(this.company, this.address, this.defaultData);
      }
      else {
        response = await companyService.update(this.company, this.address, this.defaultData);
      }

      if(response) {
        if(response.status == 200) {
          this.close();
        }
        else {
          this.$emit('showMessage', response);
          this.formErrors = errorHandler.process(response.data, "");
        }
      }
      this.pending = false;
    },
    empty(e) {
      if(e.target.name.indexOf("address") == 0) {
        const index = e.target.name.match(/\[(.*?)\]/)[1];
        const target = e.target.name.split('.')[1];
        const val = this.address[index][target];
        if(this.defaultData.address[target] && (val == "" || val == this.defaultData.address[target][1])) {
          this.labelData.address[index][target] = {text: this.defaultData.address[target][0], active: true};
          this.address[index][target] = "";
        }
      }
      else {
        const val = this.company[e.target.name];
        if(this.defaultData[e.target.name] && (val == "" || val == this.defaultData[e.target.name][1])) {
          this.labelData[e.target.name] = {text: this.defaultData[e.target.name][0], active: true};
          this.company[e.target.name] = "";
        }
      }
    },
    reset(e) {
      if(e.target.name.indexOf("address") == 0) {
        const index = e.target.name.match(/\[(.*?)\]/)[1];
        const target = e.target.name.split('.')[1];
        const val = this.address[index][target];
        if(val == "") {
          this.labelData.address[index][target] = {text: '', active: false};
          this.address[index][target] = this.defaultData.address[target][1];
        }
      }
      else {
        const val = this.company[e.target.name];
        if(val == "") {
          this.labelData[e.target.name] = {text: '', active: false};
          this.company[e.target.name] = this.defaultData[e.target.name][1];
        }
      }
    },
    clear() {
      for(const[key, val] of Object.entries(this.defaultData)) {
        if(key != "address") {
          this.labelData[key] = {text: '', active: false};
          this.company[key] = val[1];
        }
        else {
          for(const[key2, val2] of Object.entries(val)) {
            for(const i in this.address) {
              this.labelData.address[i][key2] = {text: '', active: false};
              this.address[i][key2] = val2[1];
            }
          }
        }
      }
    },
    close() {
      this.store.current.popup.splice(
        this.store.current.popup.indexOf('add-company'), 
        1
      );
      this.store.current.data.company = null;
      this.$emit('fetchListing');
    },
    async fetch() {
      if(this.companyId) {
        this.company = await companyService.select(this.companyId);
        this.address = this.company.addresses;

        this.labelData.address = [];
        this.formErrors.address = [];
        for(const i in this.address) {
          this.formErrors.address.push(new Object());
          this.labelData.address.push({...this.defaultData.address});

          for(const[key, val] of Object.entries(this.address[i])) {
            if(this.defaultData.address[key] != null) {
              if(val != null) {
                this.labelData.address[i][key] = {text: this.defaultData.address[key][0], active: true };
              }
              else {
                this.labelData.address[i][key] = {text: null, active: false };
                this.address[i][key] = this.defaultData.address[key][1];
              }
            }
          }
        }
      }
    },
    addAddress() {
      var address = {
        "name": "",
        "street1": "",
        "street2": "",
        "city": "",
        "postalCode": "",
        "region": "",
        "countryId": 0,
        "addressTypeId": 0,
      };
      this.formErrors.address.push(new Object());
      this.labelData.address.push({...this.defaultData.address});
      this.address.push(address);

      for(const[key, val] of Object.entries(this.defaultData.address)) {
        const i = this.address.length - 1;
        this.labelData.address[i][key] = {text: '', active: false};
        this.address[i][key] = val[1];
      }
    },
    removeAddress(index) {
      this.address.splice(index, 1);
      this.formErrors.address.splice(index, 1);
      this.labelData.address.splice(index, 1);
    }
  },
  components: {
    Multiselect
  }
}
</script>
