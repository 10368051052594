<template>
  <div class="file-upload">
    <div class="file-upload__area">
      <div v-if="!file || !file.isUploaded">
        <label for="file-upload" class="custom-file-upload">
          <input type="file" 
            name="" 
            id="file-upload" 
            @change="handleFileChange($event)" 
          />
          Klik hier om een bestand te kiezen...
        </label>
        <div v-if="errors.length > 0" class="file-upload__errors">
          <div class="file-upload__error"
            v-for="(error, index) in errors"
            :key="index"
          >
            <span>Er is een fout opgetreden: {{ error }}</span>
          </div>
        </div>
      </div>
      <div v-else class="upload-preview">
        <span>
          Klaar om te verwerken: {{ file.name }}.{{ file.extension }}
        </span>
      </div>
      <div v-if="removable && file.isUploaded" class="rem" @click.prevent="remove">×</div>
    </div>
  </div>
</template>
  
<script>
export default {
  emits: ['showMessage', 'fileChanged', 'fileRemoved'],
  name: "FileUpload",
  props: {
    maxSize: {
      type: Number,
      default: 1024,
      required: true,
    },
    accept: {
      type: String,
      default: "image/*",
    },
    removable: Boolean,
    file: Object
  },
  data () {
    return {
      errors: []
    };
  },
  methods: {
      reset() {
        this.createFile();
        this.file.isUploaded = false;
        this.$emit("fileChanged", this.file);
      },
      handleFileChange(e) {
        this.errors = [];
        if (e.target.files && e.target.files[0]) {
          if (this.isFileValid(e.target.files[0])) {
            const file = e.target.files[0];
            this.createFile();
            this.file.size = Math.round((file.size / 1024) * 100) / 100;
            this.file.extension = file.name.split(".").pop();
            this.file.name = file.name.replace("."+this.file.extension, "");
            this.file.data = file;
            this.file.isUploaded = true;
            this.$emit("fileChanged", this.file);
          } 
          else {
            this.$emit("showMessage", "Er is een fout opgetreden tijdens het uploaden van het bestand.");
          }
        }
      },
      remove() {
        this.$emit("fileRemoved");
      },
      isFileValid(file) {
        this.isFileSizeValid(Math.round((file.size / 1024) * 100) / 100);
        this.isFileTypeValid(file.name.split(".").pop());
        if (this.errors.length === 0) {
          return true;
        } else {
          return false;
        }
      },
      isFileSizeValid(fileSize) {
        if (fileSize <= this.maxSize) {
          
        } 
        else {
          this.$emit("showMessage", "Er is een fout opgetreden tijdens het uploaden van het bestand.");
          this.errors.push(`De bestandsgrootte mag niet groter zijn dan ${this.maxSize} kb.`);
        }
      },
      isFileTypeValid(fileExtension) {
        if (this.accept.split(",").includes(fileExtension)) {
            
        } 
        else {
          this.$emit("showMessage", "Er is een fout opgetreden tijdens het uploaden van het bestand.");
          this.errors.push(`Het bestandstype moet ${this.accept} zijn.`);
        }
      },
      createFile() {
        if(this.file.extension) {
          this.file.name = "";
          this.file.size = 0;
          this.file.extension = "";
          this.file.data = "";
          this.file.isUploaded = false;
        }
      }
  },
};
</script>

<style scoped>
.file-upload {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
}
.file-upload .file-upload__area {
    width: 100%;
    height: 40px;
    background: linear-gradient(40deg, rgba(81, 127, 157, 1) 0%, rgba(56, 83, 124, 1) 100%);
    border-radius: 20px;
    margin-top: 0;
    margin-bottom: 15px;
    position: relative;
    opacity: 0.7;
    transition-duration: 0.3s;
    font-weight: bold
}

.file-upload__errors {
    margin-top: 6vh;
    position: absolute;
}

.file-upload__error {
    color: #f00;
    font-style: italic;
    margin-left: 3vh;
}

.file-upload .file-upload__area:hover {
    opacity: 1;
    transition-duration: 0.3s;
}

.file-upload__area:has(.upload-preview) {
    opacity: 1;
    background: linear-gradient(40deg, rgb(143, 143, 143) 0%, rgb(94, 94, 94) 100%);
}

.file-upload input {
    display: none
}

.file-upload label, .upload-preview {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 100%; height: 100%;
    text-shadow: none;
    cursor: pointer;
    border-radius: 0;
    background: none;
    color: #fff
}

.rem {
  cursor: pointer;
  float: right;
  display: block;
  position: relative;
  font-weight : bold;
  color: #fff;
  padding: 2px 15px;
  font-size: 20px;
  z-index: 100
}
</style>