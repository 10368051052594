<template>
  <div id="holder-annots" ref="annotationViewer" :class="{collapsed: collapsed}">
    <div class="rs" v-on:mousedown="scaleHolder"></div>
    
    <div id="options" :style="(this.store.current.popup.length > 0) ? 'opacity:0.1;pointer-events:none' : ''">
      <div @click="showAnnotations" class="nav-icon" :style="(this.renderStore.options.showAnnotations)?'background:rgba(39,124,53,0.5)':''"><img src="img/ico/markup.png" title="Projecttotalen en -subtotalen" :style="(this.renderStore.options.showAnnotations)?'opacity:0.9;filter:none !important':''"></div>
      <div @click="showContributions" class="nav-icon" style="margin-left:40px"><img src="img/ico/nav-contribution.png" title="Markeringen van gebruikers"></div>
      <div v-if="!collapsed" class="ti">
        <div @click="toggleFullscreen" class="nav-icon" style="position:relative;float:right" title="Maximaliseren"><img :src="(this.store.pivotFullScreen)?'img/ico/expand.png':'img/ico/collapse.png'"></div>
        <div @click="download" class="nav-icon" style="position:relative;float:right" title="Download uittrekstaat"><img src="img/ico/download-b.png"></div>
        <div @click="setupPivot" class="nav-icon" style="position:relative;float:right" title="Instellingen draaitabel"><img src="img/ico/settings.png"></div>
        <h3>Projecttotalen en -subtotalen</h3>
      </div>
      <div class="tid">
        <label for="checkbox" :class="{ selected: filtered }">
          <SearchField ref="searcher" @search="search" />
          <img src="img/ico/close.png" @click="search('')">
        </label>
        <div v-on:click="toggleFilterOnPage" class="button" :class="{ selected: !isFilterOnPage }">Projecttotalen</div>
        <div v-if="this.renderStore.annotations.data.filter.length > 0" v-on:click="resetFilter" class="button red">Filter opheffen</div>

        <div class="numb">
          <b>Uitvouwen tot</b>
          <div v-for="(n, i) in this.renderStore.options.columnsPivot.length" 
            @click="expandToLevel(i+1)" 
            :class="{ selected: this.renderStore.options.expandToLevel == i+1 }"
            :key="i+1">{{ this.renderStore.options.columnsPivot[this.renderStore.options.columnsPivot.length - i - 1].name_nl }}
          </div>
        </div>
      </div>
    </div>

    <template v-if="!collapsed">
      <template v-if="this.renderStore.options.columns.length > 0">
        <AnnotationTable
          ref="annotationTable" 
          :pivotData="this.renderStore.annotations.data.pivotData" 
          :filteredData="this.renderStore.annotations.data.filteredData"
          :thead="this.renderStore.options.columns" 
          :tlayer=0
          :tlayerCount=this.renderStore.options.columnsPivot.length
          :isReady=this.renderStore.ready
          :showPivotLevels="this.showPivotLevels"
          :style="(this.store.current.popup.length > 0) ? 'opacity:0.1;pointer-events:none' : ''"
        />
      </template>
      <template v-else>
        <table ref="dataHolder" class="pivotRow" @click="setupPivot">
          <tr class="ph red">
            <td>
              De gegevens kunnen niet worden getoond omdat u nog geen opmaak geselecteerd heeft 
              voor de draaitabel. Dit kunt u doen door hier te klikken.
            </td>
          </tr>
        </table>
      </template>
    </template>
  </div>
</template>

<script>
import { nextTick } from 'vue'
import AnnotationTable from '@/components/app/viewer/AnnotationTable.vue'
import SearchField from '@/components/SearchField.vue'
import { rescaleCanvas, resetSelection } from '@/components/app/viewer/PdfCanvas.js'

export default {
  data() {
    return {
      showDetails: false,
      showSpinner: true,
      iconFolding: "unfold_more",
      textFolding: "Metingen uitvouwen",
      isFilterOnPage: false,
      showPivotLevels: false
    };
  },
  created() {
    this.renderStore.components.annotationViewer = this;
    this.renderStore.options.pageNumber = 1;
    this.renderStore.options.selectedAnnotationId = -1;
    this.renderStore.options.selectedContributionId = -1;
    this.renderStore.options.expandToLevel = 1;
  },
  mounted() {
    
  },
  computed: {
    store() {
      return this.$parent.store;
    },
    renderStore() {
      return this.$parent.renderStore;
    },
    collapsed() {
      if(this.renderStore.options.showAnnotations) {
        return false;
      }
      else return true;
    },
    filtered() {
      if(this.renderStore.options.queryAnnotations != null && this.renderStore.options.queryAnnotations.length > 0) return true;
      return false;
    }
  },
  watch: {
    stateFilterPage: function(newValue, oldValue) {
      this.renderStore.components.annotationViewer.showSpinner = true;
      this.renderStore.onePageData(newValue);
    }
  },
  methods: {
    scaleHolder(e) {
      this.renderStore.mouse.pos.x = e.screenX;
      this.renderStore.mouse.pos.y = e.screenY;
      this.renderStore.mouse.draggedElement = e.currentTarget;
      this.renderStore.options.showAnnotations = false;
      this.renderStore.options.showAnnotations = true;
    },
    togglePivotLevels(e) {
      this.showPivotLevels = !this.showPivotLevels;
    },
    toggleFullscreen(e) {
      var parentElement = e.currentTarget.parentElement.parentElement.parentElement;
      var style = window.getComputedStyle(parentElement);
      if(this.store.pivotFullScreen) {
        parentElement.style.height = style.minHeight;
        this.store.pivotFullScreen = false;
      }
      else {
        parentElement.style.height = style.maxHeight;
        this.store.pivotFullScreen = true;
      }
      rescaleCanvas(true);
    },
    showAnnotations() {
      resetSelection();
      if(this.renderStore.options.showAnnotations) {
        this.renderStore.options.showAnnotations = false;
      }
      else {
        this.renderStore.options.showAnnotations = true;
        this.renderStore.options.showContributions = false;
      }
      this.updateQuery();
    },
    showContributions() {
      resetSelection();
      if(this.renderStore.options.showContributions) {
        this.renderStore.options.showContributions = false;
      }
      else {
        this.renderStore.options.showAnnotations = false;
        this.renderStore.options.showContributions = true;
      }
      this.updateQuery();
    },
    updateQuery() {
      var self = this;
      nextTick(function() { 
        if(self.$refs.searcher != null) {
          if(self.renderStore.options.queryAnnotations.length > 0) {
            self.$refs.searcher.query = self.renderStore.options.queryAnnotations.query;
            self.$refs.searcher.isActive = true;
          }
        }
      });
    },
    async toggleFilterOnPage(e) {
      this.isFilterOnPage = !this.isFilterOnPage;
      this.renderStore.options.filterAnnotationsForPage = this.isFilterOnPage;
      await this.renderStore.loadAnnotations(true);
    },
    openDetails(pageId, measureId) {
      if(pageId != null && pageId > 0) {
        this.showDetails = true;
        this.renderStore.options.activeAnnotation = this.renderStore.data[pageId].annotations['ANNOTATION'][measureId];
      }
      else {
        if(this.renderStore.options.selectedAnnotationId == -1) {
          this.showDetails = false;
          this.activeAnnotation = null;
          this.renderStore.options.activeAnnotation = null;
        }
      }
    },
    selectPivotRule() {
      this.showDetails = false;
      try { return this.$refs.annotationTable.scrollToActiveRow(); }
      catch { return false; }
    },
    setupPivot() {
      this.store.current.popup = ["setup-pdf-pivot"];
    },
    download() {
      this.store.current.popup = ["download-pdf-pivot"];
    },
    async expandToLevel(level) {
      await this.resetFilter();
      this.renderStore.options.expandToLevel = level;
      this.$refs.annotationTable.expandToLevel(level);
    },
    search(query) {
      this.renderStore.searchAnnotations(query);
      this.$refs.searcher.query = query;
    },
    async resetFilter() {
      if(this.renderStore.annotations.data.filter.length > 0 || this.renderStore.options.queryAnnotations.length > 0) {
        this.renderStore.annotations.data.filter = "";
        await this.renderStore.loadAnnotations(true);
        resetSelection();
      }
    }
  },
  components: {
    AnnotationTable,
    SearchField
  }
}
</script>
