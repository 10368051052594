<template>
  <div class="lead">
    <h2 id="informeren">Uniform informeren van belanghebbende partijen</h2>
    <span class="sm">
      Intern én extern&nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp;
      Beschikbaar voor mobiele apparaten&nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp;
      Met oog voor databeveiliging
    </span>

    <div class="in">
      <div class="tab25">
        <img src="img/ico/application.png">
        <h3>Uniforme lay-out</h3>
        <p>
          Met Foundation kunt u alle partijen uniform informeren. Of het nu 
          om "kale" PDF-documenten gaat of met Bluebeam Revu ingemeten
          tekeningen. Onze viewer werkt supersnel en geeft iedereen hetzelfde 
          overzicht in dezelfde lay-out.
        </p>

        <img src="img/ico/iphone.png">
        <h3>Werkt op ieder apparaat</h3>
        <p>
          Onze tool functioneert binnen alle gangbare browsers.
          U hoeft géén software te installeren en u kunt de tool zelfs gebruiken 
          op uw tablet of mobiele telefoon. Met hetzelfde gemak
          als op uw computer!
        </p>
      </div>
      <div class="tab25">
        <img src="img/ico/cloud.png">
        <h3>Overal toegang tot data</h3>
        <p>
          Omdat de data in de cloud wordt opgeslagen, kunt u documenten overal 
          benaderen. Op kantoor, maar óók op de bouwplaats! Natuurlijk worden 
          gegevens via een beveiligde verbinding verstuurd.
        </p>

        <img src="img/ico/shield.png">
        <h3>Veilig delen</h3>
        <p>
          Wilt u iemand buiten uw organisatie informeren? Er wordt dan een mailtje 
          verstuurd waarin een link staat om het document te bekijken. Het inzien 
          van de gegevens is beveiligd met extra verificatie.
        </p>
      </div>
      <div class="tab50">
        <img src="img/web/sh-i-o.png">
      </div>
    </div>

    <div class="ln" style="margin-top:50px">
      <img src="img/logo-512.png">
    </div>
    <span>
      "Foundation stroomlijnt de communicatie tussen verschillende bedrijven en biedt 
      een handige tool die werkt op alle apparaten. Daardoor is het een oplossing die 
      ook op de bouwplaats perfect werkt!"
    </span>
    <span class="lt">
      Pietje Puk, Bouwgigant Miljonair B.V.
    </span>
    <div class="ln" style="margin-bottom:50px">
      <img src="img/logo-512.png">
    </div>

    <h2 id="hoeveelheden">PDF-hoeveelheden inzien en desgewenst bijmeten</h2>
    <span class="sm">
      Als overzichtelijke uittrekstaat&nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp;
      Met filters voor bepalen van subtotalen&nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp;
      Inclusief eigen gegevenskolommen
    </span>

    <div class="in">
      <div class="tab25">
        <img src="img/ico/add2.png">
        <h3>Hoeveelheden inzien</h3>
        <p>
          PDF-markeringen worden door Foundation ingelezen 
          en getoond in een overzichtelijke uittrekstaat. Natuurlijk kunt u gegevens naar 
          eigen wens filteren. Zo ziet u in de uittrekstaat én op het document alleen
          datgeen wat u belangrijk vindt.
        </p>

        <img src="img/ico/action-measuredist.png">
        <h3>Bijmeten op schaal</h3>
        <p>
          Lijnen in de PDF worden automatisch herkend waardoor u kunt snappen op inhoud. Wilt u
          vanaf een plattegrond wanden meten dan kan dit ook, onze tool 
          ondersteunt namelijk rekenvelden zoals helling en hoogte.
        </p>
      </div>
      <div class="tab25">
        <img src="img/ico/layout-columns.png">
        <h3>Aangepaste kolommen</h3>
        <p>
          Wanneer u Bluebeam Revu gebruikt, zult u wellicht kolommen gebruiken waaraan
          een formule gekoppeld is. Ook deze zijn in Foundation zichtbaar, waardoor u 
          hetzelfde overzicht ziet als in Bluebeam.
        </p>

        <img src="img/ico/filter2.png">
        <h3>Slimme overzichten</h3>
        <p>
          U kunt de uittrekstaat draaien over verschillende niveaus, om bijvoorbeeld
          te splitsen per verdieping. Ook filteren is mogelijk, waardoor u snel de 
          subtotalen voor een specifiek bouwdeel in beeld heeft.
        </p>
      </div>
      <div class="tab50">
        <img src="img/web/bb-to-f.png">
      </div>
    </div>

    <div class="ln" style="margin-top:50px">
      <img src="img/logo-512.png">
    </div>
    <span>
      "Het is altijd veel werk geweest om mijn calculatie uit Bluebeam Revu te delen met 
      de opdrachtgever. Met Foundation hoef ik géén legenda's meer aan te maken en kan iedereen,
      zonder installatie van software, mijn gegevens perfect uitlezen."
    </span>
    <span class="lt">
      Clara Bontekoe, De Onderste Steen Boven B.V.
    </span>
    <div class="ln" style="margin-bottom:50px">
      <img src="img/logo-512.png">
    </div>

    <h2 id="samenwerken">Samenwerken in documenten was nog nooit zo makkelijk</h2>
    <span class="sm">
      Intern én extern&nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp;
      Beschikbaar voor mobiele apparaten&nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp;
      Met oog voor databeveiliging
    </span>

    <div class="in">
      <div class="tab25">
        <img src="img/ico/choices.png">
        <h3>Specifieke formulieren</h3>
        <p>
          Foundation biedt de mogelijkheid om bedrijfsspecifieke 
          formulieren in te richten, waarmee u onder andere vragen, urenstaten
          of kwaliteitsborging aan een document koppelt.
          zijn. 
        </p>

        <img src="img/ico/robot.png">
        <h3>Gestandariseerd werken</h3>
        <p>
          Iedereen met toegang tot een document kan formulieren invullen. Door
          deze te standariseren worden gegevens altijd op dezelfde manier 
          ingevuld. Da's wel zo overzichtelijk!
        </p>
      </div>
      <div class="tab25">
        <img src="img/ico/update.png">
        <h3>Realtime updates</h3>
        <p>
          Heeft een gebruiker een formulier ingevuld, dan worden de wijzigingen
          automatisch zichtbaar bij iedereen die toegang heeft. Zo ziet 
          iedereen altijd de laatste gegevens.
        </p>

        <img src="img/ico/share.png">
        <h3>Bepaal wát u wilt delen</h3>
        <p>
          Deelt u een document met mensen buiten uw organisatie, dan kunt u kiezen 
          welke gegevens u wilt delen. Zo weet u zeker dat u 
          alleen datgene deelt wat voor de andere partij 
          van belang is.
        </p>
      </div>
      <div class="tab50">
        <img src="img/web/quan.png">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {

    };
  },
  computed: {
    
  },
  components: { 
    
  }
};
</script>

<style scoped>
h2 {
  width: 1000px;
  text-align: center;
  margin: 25px auto;
  margin-bottom: 0;
  font-size: 28px;
  line-height: 1em;
  font-style: normal;
}

h3 {
  font-style: normal;
  line-height: 1.5em;
  font-size: 16px;
}

p {
  font-size: 14px;
  line-height: 1.5em;
}

.lead {
  background: #fff;
  position: relative;
  text-align: left;
  padding-top: 50px;
  padding-bottom: 75px;
}

.lead > span {
  display: block;
  font-size: 24px;
  line-height: 1.3em;
  font-style: italic;
  font-weight: normal;
  width: 1000px;
  margin: 0 auto;
  margin-top: 75px;
  margin-bottom: 25px;
}

.tab span {
  display: block;
  font-size: 16px;
  line-height: 1.3em;
  font-weight: normal;
}

.tab25 {
  width: 30%;
  display: table-cell;
  padding: 0 25px;
  vertical-align: top;

  img {
    height: 32px;
    width: auto;
    margin-bottom: 0;
  }
}

.tab50 {
  width: 40%;
  display: table-cell;
  padding: 0 25px;
  vertical-align: middle;
}

.in {
  width: 1050px;
  margin: 0 auto;
  display: table;
  padding: 0;
  padding-top: 10px;
}

.tab {
  display: table-cell;
  vertical-align: top;
}

.button {
  float: none !important;
  display: inline-block;
  background-color: rgb(81, 111, 157) !important;
  color: #fff !important;
  opacity: 0.7;
  margin-top: -25px !important;
  margin-bottom: 75px;
}

.button:hover {
  opacity: 0.9;
}

span {
  text-align: center !important;
  margin-top: 25px !important;
}

span.lt {
  font-size: 14px;
}

span.sm {
  font-weight: bold;
  font-size: 12px;
  opacity: 0.3;
  margin: 0 auto;
  margin-top: 10px !important;
  margin-bottom: 20px !important;
}

img {
  margin: 15px 0;
  width: 100%;
}
</style>