import { useStore } from '@/store/pinia'

class TokenService {

  async sleep(milliseconds) {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  }

  getLocalRefreshToken() {
    const store = useStore();
    return store.refreshToken;
  }

  setLocalRefreshToken(token) {
    const store = useStore();
    store.refreshToken = token;
    store.persistSession();
  }

  getLocalAccessToken() {
    const store = useStore();
    return store.accessToken;
  }

  setLocalAccessToken(token) {
    const store = useStore();
    store.accessToken = token;
    store.persistSession();
  }

  getSessionRefreshingState() {
    const store = useStore();
    return store.sessionRefreshingState;
  }

  setSessionRefreshingState(state) {
    const store = useStore();
    store.sessionRefreshingState = state;
  }

  getSessionRefreshingTime() {
    const store = useStore();
    return store.sessionRefreshingTime;
  }

  setSessionRefreshingTime() {
    const store = useStore();
    store.sessionRefreshingTime = Date.now();
  }

  async awaitSessionRefresh() {
    const store = useStore();
    while(store.sessionRefreshingState) {
      await this.sleep(300);
    }
  }

  async awaitImageThreads() {
    const store = useStore();
    while(store.imageThreads >= 3 && store.data.length != 0) {
      await this.sleep(300);
    }
  }

  getUser() {
    const store = useStore();
    return store.user;
  }

  setUser(user) {
    const store = useStore();
    store.user = {...user};
    store.persistSession();
  }

  setAnonymous(uuid) {
    const store = useStore();
    store.user = { uuid: uuid };
    store.persistSession();
  }

  removeUser() {
    const store = useStore();
    store.user = null;
    store.accessToken = null;
    store.refreshToken = null;
    store.securityToken = null;
    store.persistSession();
    this.destroyRoles();
  }

  getSecurityToken() {
    const store = useStore();
    return store.securityToken;
  }

  setSecurityToken(token) {
    const store = useStore();
    store.securityToken = token;
  }

  setRoles(roles) {
    const store = useStore();

    if(roles.includes("USER")) store.isUser = true;
    else store.isUser = false;
    if(roles.includes("ADMIN")) store.isAdmin = true;
    else store.isAdmin = false;
    if(roles.includes("REGISTRAR")) store.isRegistrar = true;
    else store.isRegistrar = false;
    
    store.persistSession();
  }

  destroyRoles() {
    const store = useStore();
    store.isUser = false;
    store.isAdmin = false;
    store.isRegistrar = false;
    store.persistSession();
  }

  destroySession() {
    const store = useStore();
    store.isUser = false;
    store.isAdmin = false;
    store.isRegistrar = false;
    store.accessToken = null;
    store.refreshToken = null;
    store.persistSession();

    store.current.form = "list-pdf-projects";
    store.current.popup = ["logout-user"];
  }
}
  
export default new TokenService();