<template>
  <div class="content info" v-if="this.renderStore.options.activeSideMenu === 'toolbox' && this.store.current.data.pdf.contributionTemplate == null" @click="selectItem(-1)">
    <div class="cm" @click="closeSideMenu()" title="Scherm sluiten">
      <img src="img/ico/close-1.png">
    </div>
    <div class="adder small" @click="addChapter()">Nieuw</div>
    <h3>Toolbox</h3>
    <div v-if="chapters == null || chapters.length == 0" class="not-avail" style="margin-top:20px;line-height:1.3em">
      U heeft nog geen hoofdstukken in uw toolbox opgenomen. Voeg een eerste hoofdstuk toe via de knop hierboven.
    </div>
    <template v-else>
      <div v-for="chapter in chapters" :key="chapter.toolboxChapterId" class="tbx-chapter">
        <div class="tbx-head" @click="showChapter(chapter)">
          <div class="exp" title="Hoofdstuk in- of uitvouwen"></div>
          <div class="upd" @click.stop="editChapter(chapter.toolboxChapterId)" title="Hoofdstuk aanpassen"></div>
          <template v-if="chapter.code > 0">{{ String(chapter.code).padStart(2, '0') + " " + chapter.name }}</template>
          <template v-else>{{ chapter.name }}</template>
        </div>
        <div class="tbx-content" :chapterId="chapter.toolboxChapterId" :class="(this.renderStore.options.activeToolboxChapter && chapter.toolboxChapterId == this.renderStore.options.activeToolboxChapter.toolboxChapterId) ? 'show' : ''">
          <template v-if="chapter.items && chapter.items.length > 0">
            <div v-for="item in chapter.items" :key="item.toolboxItemId" :itemId="item.toolboxItemId" class="tbx-item" :class="(this.renderStore.options.activeToolboxItem && item.toolboxItemId == this.renderStore.options.activeToolboxItem.toolboxItemId) ? 'sel' : ''" @click.stop="selectItem(item)">
              <img v-if="item.values.icon" :src="'img/tbx/'+item.values.icon+'.png'">
              <img v-else :src="'img/tbx/'+findIconForTemplate(item.contributionTemplateId)+'.png'">
              <template v-if="item.code">{{ item.code + " " + item.name }}</template>
              <template v-else>{{ item.name }}</template>
              <div class="upd" @click.stop="editItem(chapter.toolboxChapterId, item.toolboxItemId, item.contributionTemplateId)" title="Item aanpassen"></div>
            </div>
          </template>
          <template v-else>
            <span>Aan dit hoofdstuk zijn nog géén items toegevoegd.</span>
          </template>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  data() {
    return {
      
    }
  },
  computed: {
    store() {
      return this.$parent.store;
    },
    renderStore() {
      return this.$parent.renderStore;
    },
    chapters() {
      return this.$parent.renderStore.toolboxChapters;
    }
  },
  methods: {
    addChapter() {
      this.store.current.data.pdf.toolboxChapter = null;
      this.store.current.data.pdf.toolboxItem = null;
      this.store.current.popup = ["add-pdf-toolbox-chapter"];
    },
    editChapter(id) {
      this.store.current.data.pdf.toolboxChapter = { toolboxChapterId: id };
      this.store.current.data.pdf.toolboxItem = null;
      this.store.current.popup = ["add-pdf-toolbox-chapter"];
    },
    addItem(id) {
      this.store.current.data.pdf.toolboxChapter = { toolboxChapterId: id };
      this.store.current.data.pdf.toolboxItem = null;
      this.store.current.popup = ["add-pdf-toolbox-item"];
    },
    editItem(chapterId, itemId, contributionTemplateId) {
      this.store.current.data.pdf.toolboxChapter = { toolboxChapterId: chapterId };
      this.store.current.data.pdf.toolboxItem = { 
        toolboxItemId: itemId,
        contributionTemplateId, contributionTemplateId 
      };
      this.store.current.popup = ["add-pdf-toolbox-item"];
    },
    async selectItem(toolboxItem) {
      this.renderStore.options.activeToolboxItem = {...toolboxItem};
      this.store.current.data.pdf.toolboxItem =  null;

      if(toolboxItem.toolboxItemId > 0) {
        this.store.current.data.pdf.contribution = {
          toolboxItemId: toolboxItem.toolboxItemId,
          code: toolboxItem.code
        };
        this.store.current.popup = ["add-pdf-contribution"];
      }
    },
    showChapter(toolboxChapter) {
      this.renderStore.options.activeToolboxChapter = {...toolboxChapter};
    },
    findIconForTemplate(templateId) {
      var icon = "";
      this.renderStore.contributionTemplates.forEach(template => {
        if(template.contributionTemplateId == templateId) {
          icon = template.icon;
        }
      });
      return icon;
    },
    closeSideMenu() {
      this.renderStore.options.activeSideMenu = null;
    }
  }
}
</script>