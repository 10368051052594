<template>
  <transition name="fade">
    <div class="popup small" v-if="store.current.popup.includes('add-pdf-toolbox-chapter')">
      <div>
        <perfect-scrollbar style="height:100%">
          <form @submit.prevent="add">
            <div class="closer" @click="close()">Scherm sluiten</div>
            <h1>{{ title }}</h1>

            <template v-if="init">
              <div v-if="labelData.name" class="before-input" :class="{active:labelData.name.active}">{{ labelData.name.text }}</div>
              <input type="text" 
                name="name" 
                v-model="toolboxChapter.name"
                :class="{hasLabel:labelData.name.active}" 
                @focusin="empty($event)" 
                @focusout="reset($event)"
                @keyup="checkName">
              <div v-if="formErrors.name" class="error-field">{{ defaultData.name[0] + " " + formErrors.name }}!</div>

              <div v-if="labelData.code" class="before-input" :class="{active:labelData.code.active}">{{ labelData.code.text }}</div>
              <input type="text" 
                name="code" 
                v-model="toolboxChapter.code"
                :class="{hasLabel:labelData.code.active}" 
                @focusin="empty($event)" 
                @focusout="reset($event)"
                style="width:30%">
              <div v-if="formErrors.code" class="error-field">{{ defaultData.code[0] + " " + formErrors.code }}!</div>
              <div style="clear:both"></div>

              <template v-if="toolboxChapter.toolboxChapterId">
                <input type="submit" :value="submitText" :class="{locked:isLocked}" style="width:48%">
                <div class="submit red" @click="unlinkChapter" style="width:48%;margin-left:4%">Hoofdstuk verwijderen</div>
              </template>
              <template v-else>
                <input type="submit" :value="submitText" :class="{locked:isLocked}">
              </template>
            </template>
            <div v-else class="loader">
              <div>Even geduld, content wordt geladen...</div>
            </div>
          </form>
        </perfect-scrollbar>
      </div>
    </div>
  </transition>
</template>

<script>
import toolboxService from '@/services/app/toolbox.service'
import errorHandler from '@/common/ErrorHandler'

export default {
  emits: ['showMessage', 'updateParent'],
  data() {
    return {
      toolboxChapter: {
        toolboxChapterId: 0,
        name: "",
        code: ""
      },
      defaultData: {
        name: ['Naam', "Welke naam wilt u instellen voor dit hoofdstuk?"],
        code: ['Code', "Welke code moet dit item krijgen?"]
      },
      labelData: {},
      formErrors: {},
      title: "",
      submitText: "",
      pending: false,
      init: false,
      exists: false
    };
  },
  computed: {
    store() {
      return this.$parent.store;
    },
    renderStore() {
      return this.$parent.renderStore;
    },
    currentPopup() {
      return this.store.current.popup[this.store.current.popup.length-1];
    },
    isLocked() {
      if(this.pending || !this.init) return true;
      else return false;
    }
  },
  watch: {
    async currentPopup(value) {
      if(value == "add-pdf-toolbox-chapter") {
        this.init = false;
        this.clear();

        if(this.store.current.data.pdf.toolboxChapter != null) {
          this.toolboxChapter = { toolboxChapterId: this.store.current.data.pdf.toolboxChapter.toolboxChapterId };
          await this.fetch();

          this.title = "Hoofdstuk aanpassen";
          this.submitText = "Opslaan";
        }
        else {
          this.toolboxChapter = {
            toolboxChapterId: 0,
            name: "",
            code: ""
          }
          this.title = "Hoofdstuk toevoegen";
          this.submitText = "Toevoegen";
        }
        this.init = true;
      }
    },
    toolboxChapter(listing) {
      for(const[key, value] of Object.entries(listing)) {
        if(!this.defaultData[key]) continue;
        if(value && value.toString().length > 0) {
          this.labelData[key] = {text: this.defaultData[key][0], active: true};
        }
        else {
          this.labelData[key] = {text: '', active: false};
          this.toolboxChapter[key] = this.defaultData[key][1];
        }
      }
    }
  },
  methods: {
    async add() {
      if(this.pending) return;
      this.pending = true;
      
      let response = await toolboxService.addChapter(this.toolboxChapter, this.defaultData);
      if(response) {
        if(response.status == 200) {
          this.$emit("updateParent");
          this.store.current.popup = [];
        }
        else {
          this.$emit('showMessage', response);
          this.formErrors = errorHandler.process(response.data, "");
        }
      }
      this.pending = false;
    },
    async fetch() {
      if(this.toolboxChapter.toolboxChapterId != null) {
        this.toolboxChapter = await toolboxService.selectChapter(this.toolboxChapter);
      }
    },
    empty(e) {
      const val = this.toolboxChapter[e.target.name];
      if(this.defaultData[e.target.name] && (val == "" || val == this.defaultData[e.target.name][1])) {
        this.labelData[e.target.name] = {text: this.defaultData[e.target.name][0], active: true};
        this.toolboxChapter[e.target.name] = "";
      }
    },
    reset(e) {
      const val = this.toolboxChapter[e.target.name];
      if(val == "") {
        this.labelData[e.target.name] = {text: '', active: false};
        this.toolboxChapter[e.target.name] = this.defaultData[e.target.name][1];
      }
    },
    clear() {
      for(const[key, val] of Object.entries(this.defaultData)) {
        this.labelData[key] = {text: '', active: false};
        this.toolboxChapter[key] = val[1];
        this.formErrors = {};
      }
    },
    unlinkChapter() {
      this.store.current.popup = ['unlink-pdf-toolbox-chapter'];
    },
    close() {
      this.store.current.popup = [];
      this.store.current.data.pdf.toolboxChapter = null;
    },
  },
  components: {
    
  }
}
</script>
