<template>
  <div class="lead">
    <div class="in">
      <div class="tab tab30">
        <span><img src="img/logo/digitable_eindhoven.png"></span>
        <strong>Digitable B.V.</strong><br/>
        Eindhoven
      </div>
      <div class="tab tab65">
        <p>
          Foundation heeft onze projectadminsitratie sterk verbeterd. Iedereen weet nu
          documenten op de juiste plek te vinden en heeft altijd de juiste versie beschikbaar.
          Daarnaast kunnen we simpel een document naar een klant opsturen ter controle.
        </p>
        <p>
          Zelfs als deze tekst een stuk langer wordt moet het goed blijven werken.
          Zelfs als deze tekst een stuk langer wordt moet het goed blijven werken.
          Zelfs als deze tekst een stuk langer wordt moet het goed blijven werken.
        </p>
        <span>Dennis Lansdaal, algemeen medewerker</span>
      </div>
      <img class="ov" src="img/logo-ov.png">
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {

    };
  },
  computed: {
    
  },
  components: { 
    
  }
};
</script>

<style scoped>
.lead {
  color: #494949;
  background: linear-gradient(135deg, rgba(255,255,255,1) 0%, rgba(230,230,230,1) 100%);
}

.in {
  width: 1000px;
  margin: 0 auto;
  padding: 0;
  position: relative;
  display: table;
}

.tab {
  display: table-cell;
  box-sizing: border-box;
  padding: 75px;
  width: 50%;
  border-radius: 50px;
  vertical-align: middle;
}

.tab30 {
  font-size: 16px;
  line-height: 1.5em;
  font-style: italic;
  padding-left: 100px;
}

.tab65 {
  font-size: 14px;
  font-style: italic;
  padding-left: 100px;
}

.tab30 span {
  display: block;
  width: 70%;
  margin-bottom: 15px;
}

.tab65 span {
  font-size: 14px;
  font-weight: bold;
}

span img {
  max-width: 100%;
  max-height: 200px;
}

h4 {
  display: block;
  font-size: 24px;
  line-height: 1.3em;
  text-align: center;
  font-style: italic;
  font-weight: normal;
  width: 900px;
  margin: 0 auto;
  margin-top: 75px;
}

.ov {
  position: absolute;
  left: -75px;
  top: -25px;
  width: calc(100% + 150px);
  height: calc(100% + 50px);
  opacity: 0.5;
}
</style>