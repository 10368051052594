<template>
  <div class="content info" v-if="this.renderStore.options.showChangelog">
    <div class="cm" @click="hideChangelog()" title="Scherm sluiten">
      <img src="img/ico/close-1.png">
    </div>
    <h3>Meldingen</h3>
    <template v-for="changes of changelog">
      <h4 v-if="Math.round(Date.now()/1000) - changes[0] < 60*60*24">Vandaag</h4>
      <h4 v-else-if="Math.round(Date.now()/1000) - changes[0] < 2*60*60*24">Gisteren</h4>
      <h4 v-else-if="Math.round(Date.now()/1000) - changes[0] < 7*60*60*24">{{ Math.floor((Math.round(Date.now()/1000) - changes[0]) / (60*60*24)) }} dagen geleden</h4>
      <h4 v-else>Langer geleden</h4>
      
      <template v-for="change of Object.entries(changes[1])">
        <div v-if="change[1].tp == 'cc'" @click="goToAnnotation(change[1].ct)" class="ch">
          <div class="tp">
            <img src="img/ico/add.png">
            Nieuwe bijdrage "{{ change[1].ct.title }}"
          </div>
          <span v-if="Math.round(Date.now()/1000) - changes[0] < 7*60*60*24">door {{ change[1].ct.askedBy }} om {{ change[1].ct.dateCreated.split(" om ")[1] }}</span>
          <span v-else>door {{ change[1].ct.askedBy }} om {{ change[1].ct.dateCreated }}</span>
        </div>
        <div v-if="change[1].tp == 'cm'" @click="goToAnnotation(change[1].ct)" class="ch">
          <div class="tp">
            <img src="img/ico/add.png">
            Bijdrage "{{ change[1].ct.title }}" aangepast
          </div>
          <span v-if="Math.round(Date.now()/1000) - changes[0] < 7*60*60*24">door {{ change[1].ct.askedBy }} om {{ change[1].ct.dateModified.split(" om ")[1] }}</span>
          <span v-else>door {{ change[1].ct.askedBy }} om {{ change[1].ct.dateModified }}</span>
        </div>
        <div v-if="change[1].tp == 'ac'" @click="goToAnnotation(change[1].ct)" class="ch">
          <div class="tp">
            <img src="img/ico/reply.png">
            Nieuw antwoord bij "{{ change[1].ct.title }}"
          </div>
          <span v-if="Math.round(Date.now()/1000) - changes[0] < 7*60*60*24">door {{ change[1].an.answeredBy }} om {{ change[1].an.dateCreated.split(" om ")[1] }}</span>
          <span v-else>door {{ change[1].an.answeredBy }} om {{ change[1].an.dateCreated }}</span>
        </div>
        <div v-if="change[1].tp == 'am'" @click="goToAnnotation(change[1].ct)" class="ch">
          <div class="tp">
            <img src="img/ico/reply.png">
            Antwoord aangepast bij "{{ change[1].ct.title }}"
          </div>
          <span v-if="Math.round(Date.now()/1000) - changes[0] < 7*60*60*24">door {{ change[1].an.answeredBy }} om {{ change[1].an.dateModified.split(" om ")[1] }}</span>
          <span v-else>door {{ change[1].an.answeredBy }} om {{ change[1].an.dateModified }}</span>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import { selectAnnotation } from '@/components/app/viewer/PdfCanvas.js'

export default {
  props: {
    document: Object,
    getData: Object
  },
  computed: {
    store() {
      return this.$parent.store;
    },
    renderStore() {
      return this.$parent.renderStore;
    },
    changelog() {
      return this.$parent.renderStore.changelog;
    }
  },
  methods: {
    goToAnnotation(ct) {
      selectAnnotation(ct.pageNumber, "Q"+ct.contributionId, true);
    },
    hideChangelog() {
      this.renderStore.options.showChangelog = false;
    }
  }
}
</script>

<style scoped>
.ch {
  line-height:1.3em;
  margin-bottom: 10px;
  padding: 5px;
  background: rgba(81, 111, 157, 0.1);
}

.ch:hover {
  margin-left: 3px;
  margin-right: -3px;
  transition-duration: 0.5s;
  cursor: pointer;
  opacity: 1;
}

span {
  font-size: 10px;
  display: block;
}

.tp {
  background: linear-gradient(-40deg, rgba(81, 127, 157, 0.3) 0%, rgba(0, 0, 0, 0) 100%);
  padding: 3px 0;
  margin-bottom: 3px;
  font-size: 12px;
  font-style: italic;
  font-weight: bold;

  img {
    width: 12px;
    margin-top: 2px;
    margin-right: 5px;
    float: right;
  }
}

h4 {
  font-size: 14px !important;
  background-color: none !important;
  border: none !important;
  padding-left: 5px !important;
}
</style>