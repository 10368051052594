<template>
  <transition name="fade">
    <div class="popup small" v-if="store.current.popup.includes('logout-user')">
      <div>
        <form @submit.prevent="close">
          <h1>U bent automatisch uitgelogd</h1>
          <p>
            Uw sessie is verlopen of u heeft recent ingelogd op een ander apparaat,
            waardoor u automatisch bent uitgelogd uit het systeem.
          </p>
          <input type="submit" value="Ok, begrepen" style="width:48%">
          <div class="submit red" @click="login" style="width:48%;margin-left:4%">Opnieuw inloggen</div>
        </form>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  emits: ['showMessage'],
  computed: {
    store() {
      return this.$parent.store;
    }
  },
  methods: {
    login() {
      this.$router.push('/');
      this.store.current.popup = ["login-user"];
    },
    close() {
      this.$router.push('/');
      this.store.current.popup = [];
    }
  }
}
</script>
