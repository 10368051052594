import api from './api';
import tokenService from "./token.service";

class LogService {

  async selectRequests(id) {
    try {
      await tokenService.awaitSessionRefresh();
      const response = await api.post(
        "/log/request/select", {
          logRequestId: id
        }
      );
      return response.data;
    }
    catch (error) {
      return null;
    }
  }

  async listingRequests(queryArgs) {
    try {
      await tokenService.awaitSessionRefresh();
      const response = await api.post(
        "/log/request/listing", queryArgs
      );
      return response;
    }
    catch (error) {
      return error.response;
    }
  }

  toSubmitData(formData, defaultData) {
    const submitData = {...formData};
    for(const [key, val] of Object.entries(submitData)) {
      if(!isNaN(val)) continue;
      if(defaultData[key] && val == defaultData[key][1]) {
        submitData[key] = null;
      }
    }
    return submitData;
  }
}
export default new LogService();