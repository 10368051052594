<template>
  <transition name="fade">
    <div class="popup" v-if="store.current.popup.includes('unlink-user')">
      <div>
        <perfect-scrollbar style="height:100%">
          <form @submit.prevent="unlink">
            <div class="closer" @click="close()">Scherm sluiten</div>
            <h1>{{ title }}</h1>
            <div v-if="init">
              <p>
                U staat op het punt om de gebruiker te verwijderen.
                <!--Met deze actie zullen de volgende gegevens worden verwijderd:
                <ul>
                  <li>{{ user.chapterCount }} hoofdstukken (inclusief onderliggende paragrafen)</li>
                  <li>{{ user.endpointCount }} eindpunten</li>
                </ul>-->
              </p>
              <input type="submit" :value="submitText" class="red" :class="{locked:isLocked}">
            </div>
            <div v-else class="loader">
              <div>Even geduld, content wordt geladen...</div>
            </div>
          </form>
        </perfect-scrollbar>
      </div>
    </div>
  </transition>
</template>

<script>
import userService from '@/services/user.service'

export default {
  emits: ['showMessage', 'updateParent'],
  data() {
    return {
      userId: null,
      user: {},
      title: "",
      submitText: "",
      init: false,
      pending: false
    };
  },
  computed: {
    store() {
      return this.$parent.store;
    },
    currentPopup() {
      return this.store.current.popup[this.store.current.popup.length-1];
    },
    isLocked() {
      if(this.pending || !this.init) return true;
      else return false;
    }
  },
  watch: {
    async currentPopup(value) {
      if(value == "unlink-user") {
        this.init = false;

        this.title = "Gebruiker verwijderen";
        this.submitText = "Ja, verwijder alle data!";
        
        this.userId = this.store.current.data.user.userId;
        await this.fetch();

        this.init = true;
        this.pending = false;
      }
    }
  },
  methods: {
    async unlink() {
      if(this.pending) return;
      this.pending = true;
      const response = await userService.unlink(this.userId);
      if(response) {
        if(response.status == 200) {
          this.close();
        }
        else {
          this.$emit('showMessage', response);
        }
      }
      this.pending = false;
    },
    close() {
      this.store.current.popup.splice(
        this.store.current.popup.indexOf('unlink-user'), 
        1
      );
      this.store.current.data.user = null;
      this.$emit('updateParent');
    },
    async fetch() {
      if(this.userId) {
        this.user = await userService.select(this.userId);
      }
    }
  }
}
</script>
