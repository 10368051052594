import api from '../api';
import tokenService from "../token.service";

class PivotConfigService {

  async select(pivotConfig, getData) {
    try {
      await tokenService.awaitSessionRefresh();
      const response = await api.post(
        "/app/pivot-config/select", {
          pivotConfigId: pivotConfig.pivotConfigId
        }
      );
      return response.data;
    }
    catch (error) {
      return null;
    }
  }

  async add(formData, defaultData, getData) {
    try {
      await tokenService.awaitSessionRefresh();
      const response = await api.post(
        "/app/pivot-config/add", Object.assign(this.toSubmitData(formData, defaultData), getData)
      );
      return response;
    }
    catch (error) {
      return error.response;
    }
  }

  async unlink(id, getData) {
    try {
      await tokenService.awaitSessionRefresh();
      const response = await api.post(
        "/app/pivot-config/unlink", {
          pivotConfigId: id
        }
      );
      return response;
    }
    catch (error) {
      return error.response;
    }
  }

  async listing(queryArgs, getData) {
    try {
      await tokenService.awaitSessionRefresh();
      const response = await api.post(
        "/app/pivot-config/listing", Object.assign(queryArgs, getData)
      );
      return response;
    }
    catch (error) {
      return error.response;
    }
  }

  toSubmitData(formData, defaultData) {
    const submitData = {...formData};
    for(const [key, val] of Object.entries(submitData)) {
      if(!isNaN(val)) continue;
      if(defaultData[key] && val == defaultData[key][1]) {
        submitData[key] = null;
      }
    }
    return submitData;
  }
}
export default new PivotConfigService();