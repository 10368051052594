<template>
  <transition name="fade">
    <div class="popup small" v-if="store.current.popup.includes('migrate-pdf-data')">
      <div>
        <perfect-scrollbar style="height:100%">
          <form @submit.prevent="migrate(true)">
            <div class="closer" @click="close()">Scherm sluiten</div>
            <h1>Uw bijdragen overzetten</h1>
            <p>
              Dit is een nieuwe versie van een document waarvoor u eerder toegang heeft verkregen. Heeft u 
              op de oudere versie bijdragen geplaatst, dan kunt u deze overzetten door hieronder
              te klikken. <b>U dient handmatig te controleren of uw bijdragen nog relevant zijn 
              binnen de nieuwe versie van het document</b>.
            </p>
            <input type="submit" value="Ja, migreer mijn gegevens" style="width:48%">
            <div class="submit red" @click="migrate(false)" style="width:48%;margin-left:4%">Nee, vraag mij dit niet opnieuw</div>
          </form>
        </perfect-scrollbar>
      </div>
    </div>
  </transition>
</template>

<script>
import documentService from '@/services/app/document.service'

export default {
  emits: ['showMessage', 'updateParent'],
  props: {
    document: Object,
    getData: Object
  },
  data() {
    return {
      title: "",
      submitText: "",
      init: false,
      pending: false
    };
  },
  computed: {
    renderStore() {
      return this.$parent.renderStore;
    },
    store() {
      return this.$parent.store;
    },
    currentPopup() {
      return this.store.current.popup[this.store.current.popup.length-1];
    },
    isLocked() {
      if(this.pending || !this.init) return true;
      else return false;
    }
  },
  watch: {
    async currentPopup(value) {
      if(value == "migrate-pdf-data") {
        this.pending = false;
      }
    }
  },
  methods: {
    async migrate(doMigrate) {
      if(this.pending) return;
      this.pending = true;
      
      let response = await documentService.migrate(
        this.document,
        doMigrate,
        this.getData.userEmail,
        this.getData.userToken,
        this.getData.accessKey,
      );
      if(response) {
        if(response.status == 200) {
          this.$emit('updateParent', response);
          this.renderStore.options.filterContributionsForUser = true;
          this.renderStore.options.activeSideMenu = "contributions";
          this.close();
        }
        else {
          this.$emit('showMessage', response);
        }
      }
      this.pending = false;
    },
    close() {
      this.store.current.popup.splice(
        this.store.current.popup.indexOf('migrate-pdf-data'), 
        1
      );
      this.store.current.data.pdf.document.isMigratable = false;
    }
  },
  components: {
    
  }
}
</script>
