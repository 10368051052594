import Vector from './models/Vector.js';
import * as easelGL from 'easel-gl';

import { useStore } from '@/store/pinia'
import { useRenderStore } from '@/store/render'
import { nextTick } from 'vue'

var store = null;
var render = null;
var dlQuality = 10;

function prepareAnnotations(update) {
  if(render.printToConsole) console.log("prepareAnnotations");

  render.stage.annotations.removeAllChildren();

  if(render.data[render.options.pageNumber] == null) return;
  //if(render.data[render.options.pageNumber].annotations == null) return;

  var bbox = render.data[render.options.pageNumber].annotations["PAGE"].bbox;
  var fade = false;
  render.canvas.annotations.scaling = store.current.data.pdf.document.scaleFactor / (store.current.data.pdf.document.gridVariants * 2);
  render.canvas.annotations.offset = {
    x: -bbox.x1 * render.canvas.annotations.scaling,
    y: bbox.y2 * render.canvas.annotations.scaling
  };  

  var ct = new easelGL.Container();
  drawBackground(0.01, "annotations");
  if(render.data[render.options.pageNumber].annotations != null) {
    if(render.data[render.options.pageNumber].annotations["ANNOTATION"] != null) {
      Object.entries(render.data[render.options.pageNumber].annotations["ANNOTATION"]).forEach(function(annot, measIndex) {

        // In some cases the annotations should not be rendered
        if(render.options.activeContribution != null && render.options.activeContribution.annotationId == annot[1].id) { }
        if(render.annotations.data.filteredPointers.indexOf(render.options.pageNumber + "." + measIndex) < 0) return;

        // Should this annotation be faded?
        if(render.options.activeAnnotation != null && render.options.activeAnnotation.groupMembers != null && render.options.activeAnnotation.groupMembers.indexOf(annot[1].id) >= 0) {
          fade = false;
        }
        else if(render.options.activeAnnotation != null && render.options.activeAnnotation.id == annot[1].id) fade = false;
        else if(store.current.data.pdf.contribution != null && store.current.data.pdf.contribution.contributionId != 0) {
          if(annot[1].id == store.current.data.pdf.contribution.annotationId) fade = false;
          else fade = true;
        }
        else if(store.current.popup.indexOf("add-pdf-contribution") >= 0) fade = true;
        else if(render.options.selectedAnnotationId != -1 && render.options.selectedAnnotationId != measIndex) fade = true;
        else if(!render.options.measureEnd && render.options.selectedAnnotationId != measIndex) fade = true;
        else if(render.options.showContributions) fade = true;
        else fade = false;

        switch(annot[1].type) {
          case "INK":
            drawInk(ct, annot[1], fade, render.options.pageNumber, measIndex);
            break;
          case "HIGHLIGHT":
            drawHighlight(ct, annot[1], fade, render.options.pageNumber, measIndex);
            break;
          case "TEXTBOX":
          case "CALLOUT":
            drawTextbox(ct, annot[1], fade, render.options.pageNumber, measIndex);
            break;
          case "COUNT": 
            drawCount(ct, annot[1], fade, render.options.pageNumber, measIndex);
            break;
          case "ANGLE": 
            drawAngle(ct, annot[1], fade, render.options.pageNumber, measIndex);
            break;
          case "LENGTH":
            drawLength(ct, annot[1], fade, render.options.pageNumber, measIndex);
            break;
          case "POLYLENGTH":
            drawPolylength(ct, annot[1], fade, render.options.pageNumber, measIndex);
            break;
          case "RECTANGLE":
          case "CIRCLE":
          case "ARC":
          case "AREA":
          case "VOLUME":
            drawShape(ct, annot[1], fade, render.options.pageNumber, measIndex);
            break;
          case "RADIUS":
            drawRadius(ct, annot[1], fade, render.options.pageNumber, measIndex);
            break;
          case "DIAMETER":
            drawDiameter(ct, annot[1], fade, render.options.pageNumber, measIndex);
            break;
          case "REMARK":
            drawRemark(ct, annot[1], fade, render.options.pageNumber, measIndex);
            break;
          default:
            drawOutline(ct, annot[1], fade, render.options.pageNumber, measIndex);
            break;
        }
      });
    }

    /*if(render.data[render.options.pageNumber].annotations["VIEWPORT"] != null) {
      for(const [ref, vp] of Object.entries(render.data[render.options.pageNumber].annotations["VIEWPORT"])) {
        if(vp.bbox.x2 != bbox.x2 || vp.bbox.y2 != bbox.y2) {
          let meas = render.data[render.options.pageNumber].annotations["MEASURE"][vp.refMeasure];
          drawViewport(ct, vp, meas, bbox);
        }
      }
    }*/

    if(render.data[render.options.pageNumber].annotations["LINK"] != null) {
      for(const [ref, lk] of Object.entries(render.data[render.options.pageNumber].annotations["LINK"])) {
        drawLink(ct, lk, fade);
      }
    }
  }

  if(render.filteredContributions != null) {
    Object.entries(render.filteredContributions).forEach(function(contribution, measIndex) {
      //if(!render.options.showContributions && render.options.showAnnotations) return;
      if(contribution[1].pageNumber != render.options.pageNumber) return;

      if(!render.options.measureEnd) fade = true;
      else if(render.options.activeContribution != null) {
        if(render.options.activeContribution.contributionId == contribution[1].contributionId) fade = false;
        else if(render.options.activeContribution.positions == null || render.options.activeContribution.positions.length == 0) fade = false;
        else fade = true;
      }
      else if(render.options.multiSelectedContributionIds.length > 0) {
        if(render.options.multiSelectedContributionIds.indexOf(contribution[1].contributionId) >= 0) fade = false;
        else fade = true;
      }
      else if(render.options.showAnnotations) fade = true;
      else fade = false;
      drawContribution(ct, contribution[1], fade, render.options.pageNumber, contribution[1].contributionId);
    });
  }

  var currentZoom = parseInt(render.canvas.zoom.factor.current);
  var page = render.pages[render.options.pageNumber-1];
  var imageWidth = page.imageWidth / Math.pow(2, store.current.data.pdf.document.gridVariants - 1);
  var imageHeight = page.imageHeight / Math.pow(2, store.current.data.pdf.document.gridVariants - 1);  
 
  render.stage.annotations.addChild(ct);
  if(update) {
    render.stage.annotations.uncache();
    render.stage.annotations.cache(
      0, 0,
      imageWidth, imageHeight,
      (currentZoom + 1 > 1) ? (currentZoom + 1) : 1
    );
    render.stage.annotations.update();
  }
}

function findAnnotationById(pageNumber, annotationId) {
  if(render.data[pageNumber].annotations["ANNOTATION"] != null) {
    render.data[pageNumber].annotations["ANNOTATION"].forEach(function(annot, measIndex) {
      if(annot.id == annotationId) {
        selectAnnotation(pageNumber, measIndex, true);
      }
    });
  }
}

function selectAnnotation(pageNumber, annotationId, zoomToBox) {
  if(render.printToConsole) console.log("selectAnnotation");

  //if(store.current.popup.indexOf("add-pdf-contribution") >= 0) return;
  if(render.mouse.dpos > 5 && !zoomToBox) return;
  if(!render.options.measureEnd) {
    if(store.current.data.pdf.contribution != null && store.current.data.pdf.contribution.positions != null && store.current.data.pdf.contribution.positions.length > 2) return;
    if(store.current.data.pdf.contributionTemplate != null && !store.current.data.pdf.contributionTemplate.allowLink) return;
  }

  annotationId = String(annotationId);
  if(annotationId.startsWith("Q")) {
    if(store.current.popup.indexOf("add-pdf-contribution") >= 0) return;
    var contributionId = parseInt(annotationId.substring(1));
    if(render.options.ctrlPressed) {

      if(render.options.selectedContributionId != -1) {
        render.options.multiSelectedContributionIds = [render.options.activeContribution.contributionId];
      }
      if(render.options.multiSelectedContributionIds.indexOf(contributionId) == -1) {
        render.options.multiSelectedContributionIds.push(contributionId);
      }
      else {
        var index = render.options.multiSelectedContributionIds.indexOf(contributionId);
        delete render.options.multiSelectedContributionIds[index];
      }

      render.options.activeContribution = null;
      render.options.selectedContributionId = -1;
      renderAnnotations(true, false);
      return;
    }
    else {
      render.options.multiSelectedContributionIds = [];
      //render.options.firstSelectedContributionKey = -1;
    }
  }

  var resetZoom = false;
  var rescaleView = false;

  if(!render.options.activeSideMenu) rescaleView = true;
  if(zoomToBox && (render.options.activeSideMenu != "contributions" && render.options.activeSideMenu != "annotation")) {
    resetZoom = true;
  }
  
  if(store.current.data.pdf.contributionTemplate == null) {
    render.options.activeSideMenu = "annotation";
  }

  if(String(annotationId).substring(0, 1) == "Q") {
    render.contributions.forEach((contrib, key) => {
      if(contrib.contributionId == String(annotationId).substring(1)) {
        render.options.activeContribution = {...contrib};

        if(render.options.selectedContributionId == contrib.contributionId) {
          render.options.selectedContributionId = -1;
          nextTick(function() {
            render.options.selectedContributionId = contrib.contributionId;
          });
        }
        else render.options.selectedContributionId = contrib.contributionId;
      }
    });    

    render.options.selectedAnnotationId = -1;
    render.options.activeAnnotation = null;
    render.options.activeToolboxItem = null;

    if(render.options.measureEnd) {
      if(!render.options.showContributions && !render.options.showAnnotations) {
        rescaleView = true;
      }
      render.options.showContributions = true;
      render.options.showAnnotations = false;
    }

    if(render.options.activeContribution != null && render.options.activeContribution.annotationId != null) {
      render.options.selectedAnnotationId = render.options.activeContribution.annotationId;
    }
    
    if(render.options.activeContribution != null && (render.options.activeContribution.positions == null || render.options.activeContribution.positions.length == 0)) {
      resetZoom = true;
    }
  }
  else {
    if(store.current.data.pdf.contributionTemplate != null) {
      if(!store.current.data.pdf.contributionTemplate.allowLink) {
        return;
      }
    }

    if(render.options.measureEnd) {
      if(!render.options.showContributions && !render.options.showAnnotations) {
        rescaleView = true;
      }
      render.options.showContributions = false;
      render.options.showAnnotations = true;
    }
    
    if(store.current.data.pdf.contributionTemplate == null) {
      render.options.activeContribution = null;
      render.options.selectedContributionId = -1;
      if(render.options.activeAnnotation == null) {
        showDetailsAnnotation(pageNumber, annotationId);
      }
    }

    if(render.options.selectedAnnotationId == -1 || annotationId != render.options.selectedAnnotationId) {
      render.options.selectedAnnotationId = annotationId;
    }
  }

  if(render.options.selectedAnnotationId >= 0) {
    if(render.data[pageNumber].annotations["ANNOTATION"] != null) {
      render.data[pageNumber].annotations["ANNOTATION"].forEach(function(annot, measIndex) {
        if(measIndex == render.options.selectedAnnotationId) {
          render.options.activeAnnotation = annot;
        }
      });
    }
  }

  if(rescaleView) {
    nextTick(function() {
      rescaleCanvas(true);
    });
  }

  if(render.options.pageNumber != pageNumber) {
    render.options.pageNumber = parseInt(pageNumber);
  }
  else {
    if(zoomToBox) {
      var objBounds;

      if(render.options.activeContribution != null) {
        if(render.options.activeContribution.positions != null) {
          var ap = render.options.activeContribution.positions;
          var as = render.canvas.annotations.scaling;
          var of = render.canvas.annotations.offset;
          objBounds = [new Vector(), new Vector()];
          for(var i = 0; i < ap.length; i = i + 2) {
            var x = (ap[i]-of.x)/as, y = (-ap[i+1]+of.y)/as;
            if(objBounds[0].x == null || objBounds[0].x > x) objBounds[0].x = x;
            if(objBounds[1].x == null || objBounds[1].x < x) objBounds[1].x = x;
            if(objBounds[0].y == null || objBounds[0].y > y) objBounds[0].y = y;
            if(objBounds[1].y == null || objBounds[1].y < y) objBounds[1].y = y;
          }
        }
        else if(resetZoom) {
          zoomReset();
        }
      }
      else {
        objBounds = render.data[render.options.pageNumber].annotations["ANNOTATION"][annotationId].bounds;
      }
      
      if(objBounds != null) {
        zoomBounds(objBounds);
      }
    }
  }
}

function drawBackground(alpha, layer) {
  if(render.pages[render.options.pageNumber-1] == null) return;
  var page = render.pages[render.options.pageNumber-1];
  var bg = new easelGL.Shape();
  bg.graphics.beginFill('white');
  bg.graphics.drawRect(
    0, 0, 
    page.imageWidth / Math.pow(2, store.current.data.pdf.document.gridVariants - 1), 
    page.imageHeight / Math.pow(2, store.current.data.pdf.document.gridVariants - 1)
  );
  bg.graphics.endFill();
  bg.alpha = alpha;
  if(layer == "annotations") render.stage.annotations.addChild(bg);
  else render.stage.images.addChild(bg);
}


function drawInk(ct, meas, fade, pageId, measureId) {
  var thisShape = new easelGL.Shape();
  var annotScaling = render.canvas.annotations.scaling;
  var of = render.canvas.annotations.offset;
  var lc = {...meas.line.color};
  var pId = 0;
  var objBounds = [new Vector(), new Vector()];

  if(fade) {
    lc.r = 100;
    lc.g = 100;
    lc.b = 100;
    lc.a = 0.2;
  }

  thisShape.graphics.setStrokeStyle(meas.line.width * annotScaling, "round");
  thisShape.graphics.beginStroke("rgba("+lc.r+","+lc.g+","+lc.b+","+lc.a+")");
  meas.inkPositions.forEach(function(inkGroup) {
    pId = 0;
    inkGroup.forEach(function(pos) {
      if(pId == 0) thisShape.graphics.moveTo(annotScaling * pos.x + of.x, -annotScaling * pos.y + of.y);
      else thisShape.graphics.lineTo(annotScaling * pos.x + of.x, -annotScaling * pos.y + of.y);
      objBounds = updateBounds(objBounds, pos, annotScaling, of);
      pId++;
    });
  });
  thisShape.graphics.endStroke();

  if(!meas.melted) {
    thisShape.addEventListener("click", function(event) {
      selectAnnotation(render.options.pageNumber, measureId, false);
    });
    thisShape.addEventListener("mouseover", function(event) { 
      showDetailsAnnotation(pageId, measureId);
      render.stage.annotations.cursor = "pointer";
    });
    thisShape.addEventListener("mouseout", function(event) { 
      showDetailsAnnotation(null, null); 
      render.stage.annotations.cursor = "default";
    });
  }

  thisShape.setBounds(
    objBounds[0].x, objBounds[0].y,
    objBounds[1].x - objBounds[0].x, objBounds[1].y - objBounds[0].y
  );
  ct.addChild(thisShape);
}

function drawTextbox(ctx, meas, fade, pageId, measureId) {

  var thisShape = new easelGL.Shape();
  var lineShape = new easelGL.Shape();
  var bb = new easelGL.Container();

  let annotScaling = render.canvas.annotations.scaling;
  let of = render.canvas.annotations.offset;
  let r = render.data[pageId].annotations["PAGE"].rotation;

  var lc = {...meas.line.color};
  var fc = {...meas.fill.color};
  var cc = {...meas.caption.font.color};

  if(meas.positions == null && meas.offsets == null) {
    meas.positions = {...meas.bounds};
  }

  if(!meas.rotate) {
    meas.rotate = 0;
  }

  var mbx0 = null, mbx1 = null, mby0 = null, mby1 = null;
  var mbx00 = null, mbx10 = null, mby00 = null, mby10 = null;
  for(const [key, position] of Object.entries(meas.positions)) {
    if(mbx0 == null || position.x < mbx0) mbx0 = position.x;
    if(mbx1 == null || position.x > mbx1) mbx1 = position.x;
    if(mby0 == null || position.y < mby0) mby0 = position.y;
    if(mby1 == null || position.y > mby1) mby1 = position.y;
  }

  mbx00 = mbx0;
  mbx10 = mbx1;
  mby00 = mby0;
  mby10 = mby1;

  if(meas.offsets != null) {
    mbx0 += meas.offsets[0].x;
    mby0 += meas.offsets[0].y;
    mbx1 -= meas.offsets[1].x;
    mby1 -= meas.offsets[1].y;
  }
  else {
    meas.offsets = [];
    meas.offsets[0] = { x:0, y:0 };
    meas.offsets[1] = { x:0, y:0 };
  }

  var w = Math.abs(mbx1 - mbx0);
  var h = Math.abs(mby1 - mby0);

  var tw = w;
  var th = h;
  if(r-meas.rotate == 90 || r-meas.rotate == 270) {
    tw = h;
    th = w;
  }

  if(fade) {
    lc = fadeColor(lc, .3);
    fc = fadeColor(fc, .3);
    cc = fadeColor(cc, render.options.opacity);

    lc = rgbToGrayscale(lc);
    fc = rgbToGrayscale(fc);
    cc = rgbToGrayscale(cc);
  }
  else if(meas.melted) {
    lc = fadeColor(lc, render.options.opacity);
    fc = fadeColor(fc, render.options.opacity);
    cc = fadeColor(cc, render.options.opacity);
    if(render.options.grayScale) {
      lc = rgbToGrayscale(lc);
      fc = rgbToGrayscale(fc);
      cc = rgbToGrayscale(cc);
    }
  }

  // Fill
  if(fc.r < 0) {
    fc.r = 255;
    fc.g = 255;
    fc.b = 255;
    fc.a = 0.02;
  }
  if(fc.r >= 0) {
    thisShape.graphics.beginFill("rgba("+fc.r+","+fc.g+","+fc.b+","+fc.a+")");
    thisShape.graphics.moveTo(annotScaling * -w/2, -annotScaling * -h/2);
    thisShape.graphics.lineTo(annotScaling * w/2, -annotScaling * -h/2);
    thisShape.graphics.lineTo(annotScaling * w/2, -annotScaling * h/2);
    thisShape.graphics.lineTo(annotScaling * -w/2, -annotScaling * h/2);
    thisShape.graphics.lineTo(annotScaling * -w/2, -annotScaling * -h/2);
    thisShape.graphics.endFill();
  }

  // Outline
  if(lc.r >= 0) {
    if(meas.line.width != 0) {
      thisShape.graphics.setStrokeStyle(meas.line.width * annotScaling);
      thisShape.graphics.beginStroke("rgba("+lc.r+","+lc.g+","+lc.b+","+lc.a+")");
      thisShape.graphics.moveTo(annotScaling * -w/2, -annotScaling * -h/2);
      thisShape.graphics.lineTo(annotScaling * w/2, -annotScaling * -h/2);
      thisShape.graphics.lineTo(annotScaling * w/2, -annotScaling * h/2);
      thisShape.graphics.lineTo(annotScaling * -w/2, -annotScaling * h/2);
      thisShape.graphics.closePath();
      thisShape.graphics.endStroke();
    }

    if(meas.linePositions != null) {
      let firstPos = meas.linePositions[0];
      let n = normalVector(meas.linePositions);
      let i = inverseVectorAtPoint(meas.linePositions, 0);
      
      var arrowPos;
      var lw = 1;
      if(meas.line.width != 0) lw = meas.line.width;

      var ls = meas.line.width;
      if(meas.line.startScale != -1) ls = meas.line.startScale;

      lineShape.graphics.setStrokeStyle(lw * annotScaling);
      lineShape.graphics.beginStroke("rgba("+lc.r+","+lc.g+","+lc.b+","+lc.a+")");

      if(meas.line.endStyle != null && meas.line.endStyle != "NONE") {
        arrowPos = new Vector(
          firstPos.x + 4 * ls * n.x + 8 * ls * i.x,
          firstPos.y + 4 * ls * n.y + 8 * ls * i.y
        );
        lineShape.graphics.moveTo(annotScaling * firstPos.x + of.x, -annotScaling * firstPos.y + of.y);
        lineShape.graphics.lineTo(annotScaling * arrowPos.x + of.x, -annotScaling * arrowPos.y + of.y);
        lineShape.graphics.moveTo(annotScaling * firstPos.x + of.x, -annotScaling * firstPos.y + of.y);
        
        arrowPos = new Vector(
          firstPos.x - 4 * ls * n.x + 8 * ls * i.x, 
          firstPos.y - 4 * ls * n.y + 8 * ls * i.y
        );
        lineShape.graphics.lineTo(annotScaling * arrowPos.x + of.x, -annotScaling * arrowPos.y + of.y);
      }

      for(const [key, position] of Object.entries(meas.linePositions)) {
        if(key == 0) lineShape.graphics.moveTo(annotScaling * position.x + of.x, -annotScaling * position.y + of.y);
        else lineShape.graphics.lineTo(annotScaling * position.x + of.x, -annotScaling * position.y + of.y);
      }

      lineShape.graphics.endStroke();

      lineShape.setTransform(
        annotScaling * (mbx00 + mbx10) / 2 + of.x, 
        -annotScaling * (mby00 + mby10) / 2 + of.y, 
        1, 1,  
        null,
        null, null,
        annotScaling * (mbx00 + mbx10) / 2 + of.x, 
        -annotScaling * (mby00 + mby10) / 2 + of.y
      );
    }
  }
  
  var fontStyle = (meas.caption.font.style != null) ? meas.caption.font.style : "";

  if(meas.caption.text == null) meas.caption.text = "";

  var text = new easelGL.Text(meas.caption.text.replaceAll("\\r", "\n"), fontStyle + " " + (annotScaling * meas.caption.font.size)+"px " + meas.caption.font.face, "rgba("+cc.r+","+cc.g+","+cc.b+","+lc.a+")");
  
  //cap.font.lineHeight = cap.font.size;
  if(meas.caption.font.align == null) meas.caption.font.align = "left";
  if(meas.caption.font.valign == null) meas.caption.font.valign = "top";

  if(meas.caption.font.lineHeight < meas.caption.font.size) meas.caption.font.lineHeight = meas.caption.font.size;
  text.lineHeight = annotScaling * meas.caption.font.lineHeight;
  text.textAlign = meas.caption.font.align;
  //text.rotation = rr;
  text.lineWidth = annotScaling * (tw - 2 * meas.caption.font.margin - 2);

  switch(text.textAlign) {
    case "left":
      text.x += annotScaling * (-tw/2 + meas.line.width + meas.caption.font.margin);
      break;
    case "center":
      
      break;
    case "right":
      text.x += annotScaling * (tw/2 - meas.line.width - meas.caption.font.margin);
      break;
  }

  switch(meas.caption.font.valign) {
    case "top":
    default:
      text.y += annotScaling * (-th/2 + meas.line.width + meas.caption.font.margin);
      break;
    case "middle":
      text.y += -text.getMeasuredHeight()/2;
      break;
    case "bottom":
      text.y += annotScaling * (th/2 - meas.line.width - meas.caption.font.margin) - text.getMeasuredHeight();
      break;
  }

  var ct = new easelGL.Container();
  ct.addChild(text);
  ct.setTransform(
    null, null,
    null, null,
    r-meas.rotate
  );

  var ctb = new easelGL.Container();
  ctb.addChild(thisShape, ct);
  ct.mouseEnabled = false;
  

  if(meas.linePositions != null) {

    ctb.setTransform(
      annotScaling * ((mbx0 + mbx1) / 2) + of.x, 
      -annotScaling * ((mby0 + mby1) / 2) + of.y,
      null, null,
      null
    );

    var c = new easelGL.Container();
    c.addChild(lineShape, ctb);
    c.setTransform(
      annotScaling * ((mbx00 + mbx10) / 2) + of.x, 
      -annotScaling * ((mby00 + mby10) / 2) + of.y,
      null, null,
      meas.rotation,
      null, null,
      annotScaling * ((mbx00 + mbx10) / 2) + of.x, 
      -annotScaling * ((mby00 + mby10) / 2) + of.y
    );

    if(!meas.melted) {
      bb.addEventListener("click", function(event) { 
        selectAnnotation(render.options.pageNumber, measureId, false);
      });
      bb.addEventListener("mouseover", function(event) { 
        showDetailsAnnotation(pageId, measureId);
        render.stage.annotations.cursor = "pointer";
      });
      bb.addEventListener("mouseout", function(event) { 
        showDetailsAnnotation(null, null); 
        render.stage.annotations.cursor = "default";
      });
    }

    bb.addChild(c);
  }
  else {

    ctb.setTransform(
      annotScaling * ((mbx0 + mbx1) / 2) + of.x, 
      -annotScaling * ((mby0 + mby1) / 2) + of.y,
      null, null,
      meas.rotation
    );

    if(!meas.melted) {
      bb.addEventListener("click", function(event) { 
        selectAnnotation(render.options.pageNumber, measureId, false);
      });
      bb.addEventListener("mouseover", function(event) { 
        showDetailsAnnotation(pageId, measureId);
        render.stage.annotations.cursor = "pointer";
      });
      bb.addEventListener("mouseout", function(event) { 
        showDetailsAnnotation(null, null); 
        render.stage.annotations.cursor = "default";
      });
    }
    bb.addChild(ctb);
  } 

  var objBounds = [new Vector(), new Vector()];
  meas.bounds.forEach(bound => {
    objBounds = updateBounds(objBounds, bound, annotScaling, of);
  });
  bb.setBounds(
    objBounds[0].x, objBounds[0].y,
    objBounds[1].x - objBounds[0].x, objBounds[1].y - objBounds[0].y
  );
  
  ctx.addChild(bb);
}

function drawRemark(ct, meas, fade, pageId, measureId) {

  var annotScaling = render.canvas.annotations.scaling;
  var of = render.canvas.annotations.offset;
  
  if(meas.positions == null || meas.positions.length == 0) {
    meas.positions = { ...meas.bounds };
  }

  var fc = { r: 255, g: 0, b: 0, a: 0.8 };
  if(fade) {
    fc = fadeColor(fc, .3);
    fc = rgbToGrayscale(fc);
  }
  else if(meas.melted) {
    fc = fadeColor(fc, render.options.opacity);
    if(render.options.grayScale) {
      fc = rgbToGrayscale(fc);
    }
  }

  var mbx0 = null, mbx1 = null, mby0 = null, mby1 = null;
  for(const [key, position] of Object.entries(meas.positions)) {
    if(mbx0 == null || position.x < mbx0) mbx0 = position.x;
    if(mbx1 == null || position.x > mbx1) mbx1 = position.x;
    if(mby0 == null || position.y < mby0) mby0 = position.y;
    if(mby1 == null || position.y > mby1) mby1 = position.y;
  }

  var thisShape = new easelGL.Shape();
  thisShape.graphics.beginFill("rgba("+fc.r+","+fc.g+","+fc.b+","+fc.a+")");
  thisShape.graphics.moveTo(annotScaling * mbx0 + of.x, -annotScaling * mby0 + of.y);
  thisShape.graphics.lineTo(annotScaling * mbx1 + of.x, -annotScaling * mby0 + of.y);
  thisShape.graphics.lineTo(annotScaling * mbx1 + of.x, -annotScaling * mby1 + of.y);
  thisShape.graphics.lineTo(annotScaling * mbx0 + of.x, -annotScaling * mby1 + of.y);
  thisShape.graphics.lineTo(annotScaling * mbx0 + of.x, -annotScaling * mby0 + of.y);
  thisShape.graphics.endFill();



  var thisShape2 = new easelGL.Shape();
  var m = new easelGL.Matrix2D();
  var s = annotScaling * (mbx1-mbx0) / render.remarkImg.width;
  m.scale(s, s);
  m.translate((annotScaling * mbx0 + of.x) / s, (-annotScaling * mby1 + of.y) / s);
  thisShape2.graphics.beginBitmapFill(render.remarkImg, "no-repeat", m);
  thisShape2.graphics.moveTo(annotScaling * mbx0 + of.x, -annotScaling * mby0 + of.y);
  thisShape2.graphics.lineTo(annotScaling * mbx0 + of.x, -annotScaling * mby0 + of.y);
  thisShape2.graphics.lineTo(annotScaling * (mbx0 + render.remarkImg.width) + of.x, -annotScaling * mby0 + of.y);
  thisShape2.graphics.lineTo(annotScaling * (mbx0 + render.remarkImg.width) + of.x, -annotScaling * (mby0 + render.remarkImg.width) + of.y);
  thisShape2.graphics.lineTo(annotScaling * mbx0 + of.x, -annotScaling * (mby0 + render.remarkImg.width) + of.y);
  thisShape2.graphics.endFill();

  var c = new easelGL.Container();
  c.addChild(thisShape, thisShape2);
  c.setTransform(
    annotScaling * (mbx1 + mbx0) / 2 + of.x, 
    -annotScaling * (mby1 + mby0) / 2 + of.y, 
    1, 
    1,  
    0,
    0, 0,
    annotScaling * (mbx1 + mbx0) / 2 + of.x, 
    -annotScaling * (mby1 + mby0) / 2 + of.y
  );

  var objBounds = [new Vector(), new Vector()];
  meas.bounds.forEach(bound => {
    objBounds = updateBounds(objBounds, bound, annotScaling, of);
  });
  c.setBounds(
    objBounds[0].x, objBounds[0].y,
    objBounds[1].x - objBounds[0].x, objBounds[1].y - objBounds[0].y
  );

  if(!meas.melted) {
    c.addEventListener("click", function(event) { 
      selectAnnotation(render.options.pageNumber, measureId, false);
    });
    c.addEventListener("mouseover", function(event) { 
      showDetailsAnnotation(pageId, measureId);
      render.stage.annotations.cursor = "pointer";
    });
    c.addEventListener("mouseout", function(event) { 
      showDetailsAnnotation(null, null); 
      render.stage.annotations.cursor = "default";
    });
  }

  ct.addChild(c);
}

function drawOutline(ct, meas, fade, pageId, measureId) {

  var thisShape = new easelGL.Shape();
  var annotScaling = render.canvas.annotations.scaling;
  var of = render.canvas.annotations.offset;

  var lc = { r: 255, g: 0, b: 0, a: 0.8 };
  var fc = { r: 255, g: 0, b: 0, a: 0.02 };
  
  if(meas.positions == null || meas.positions.length == 0) {
    meas.positions = { ...meas.bounds };
  }

  var mbx0 = null, mbx1 = null, mby0 = null, mby1 = null;
  for(const [key, position] of Object.entries(meas.positions)) {
    if(mbx0 == null || position.x < mbx0) mbx0 = position.x;
    if(mbx1 == null || position.x > mbx1) mbx1 = position.x;
    if(mby0 == null || position.y < mby0) mby0 = position.y;
    if(mby1 == null || position.y > mby1) mby1 = position.y;
  }

  meas.line.width = 0.5;

  if(fade) {
    lc = fadeColor(lc, .3);
    fc = fadeColor(fc, .3);

    lc = rgbToGrayscale(lc);
    fc = rgbToGrayscale(fc);
  }
  else if(meas.melted) {
    lc = fadeColor(lc, render.options.opacity);
    fc = fadeColor(fc, render.options.opacity);
    if(render.options.grayScale) {
      lc = rgbToGrayscale(lc);
      fc = rgbToGrayscale(fc);
    }
  }

  // Fill
  if(fc.r >= 0) {
    thisShape.graphics.beginFill("rgba("+fc.r+","+fc.g+","+fc.b+","+fc.a+")");
    thisShape.graphics.moveTo(annotScaling * mbx0 + of.x, -annotScaling * mby0 + of.y);
    thisShape.graphics.lineTo(annotScaling * mbx1 + of.x, -annotScaling * mby0 + of.y);
    thisShape.graphics.lineTo(annotScaling * mbx1 + of.x, -annotScaling * mby1 + of.y);
    thisShape.graphics.lineTo(annotScaling * mbx0 + of.x, -annotScaling * mby1 + of.y);
    thisShape.graphics.lineTo(annotScaling * mbx0 + of.x, -annotScaling * mby0 + of.y);
    thisShape.graphics.endFill();
  }

  // Outline
  if(lc.r >= 0 && meas.line.width > 0) {
    thisShape.graphics.setStrokeStyle(meas.line.width * annotScaling);
    thisShape.graphics.beginStroke("rgba("+lc.r+","+lc.g+","+lc.b+","+lc.a+")");
    thisShape.graphics.moveTo(annotScaling * mbx0 + of.x, -annotScaling * mby0 + of.y);
    thisShape.graphics.lineTo(annotScaling * mbx1 + of.x, -annotScaling * mby0 + of.y);
    thisShape.graphics.lineTo(annotScaling * mbx1 + of.x, -annotScaling * mby1 + of.y);
    thisShape.graphics.lineTo(annotScaling * mbx0 + of.x, -annotScaling * mby1 + of.y);
    thisShape.graphics.lineTo(annotScaling * mbx0 + of.x, -annotScaling * mby0 + of.y);
    thisShape.graphics.endStroke();
  }
  thisShape.setTransform(
    annotScaling * (mbx1 + mbx0) / 2 + of.x, 
    -annotScaling * (mby1 + mby0) / 2 + of.y, 
    1, 
    1,  
    0,
    0, 0,
    annotScaling * (mbx1 + mbx0) / 2 + of.x, 
    -annotScaling * (mby1 + mby0) / 2 + of.y
  );

  if(!meas.melted) {
    thisShape.addEventListener("click", function(event) { 
      selectAnnotation(render.options.pageNumber, measureId, false);
    });
    thisShape.addEventListener("mouseover", function(event) { 
      showDetailsAnnotation(pageId, measureId);
      render.stage.annotations.cursor = "pointer";
    });
    thisShape.addEventListener("mouseout", function(event) { 
      showDetailsAnnotation(null, null); 
      render.stage.annotations.cursor = "default";
    });
  }

  thisShape.setTransform(
    annotScaling * ((mbx0 + mbx1) / 2) + of.x, 
    -annotScaling * ((mby0 + mby1) / 2) + of.y, 
    1, 1,  
    meas.rotation,
    0, 0,
    annotScaling * (mbx0 + mbx1) / 2 + of.x, 
    -annotScaling * (mby0 + mby1) / 2 + of.y
  );

  var objBounds = [new Vector(), new Vector()];
  meas.bounds.forEach(bound => {
    objBounds = updateBounds(objBounds, bound, annotScaling, of);
  });
  thisShape.setBounds(
    objBounds[0].x, objBounds[0].y,
    objBounds[1].x - objBounds[0].x, objBounds[1].y - objBounds[0].y
  );

  ct.addChild(thisShape);
}

function drawHighlight(ct, meas, fade, pageId, measureId) {
  var thisShape = new easelGL.Shape();
  var annotScaling = render.canvas.annotations.scaling;
  var of = render.canvas.annotations.offset;
  var objBounds = [new Vector(), new Vector()];

  var fc = {...meas.line.color};
  fc.a /= 2;

  if(fade) {
    fc = fadeColor(fc, .3);
    fc = rgbToGrayscale(fc);
  }
  else if(meas.melted) {
    fc = fadeColor(fc, render.options.opacity);
    if(render.options.grayScale) {
      fc = rgbToGrayscale(fc);
    }
  }

  thisShape.graphics.beginFill("rgba("+fc.r+","+fc.g+","+fc.b+","+fc.a+")");
  thisShape.graphics.moveTo(annotScaling * meas.positions[0].x + of.x, -annotScaling * meas.positions[0].y + of.y);
  thisShape.graphics.lineTo(annotScaling * meas.positions[1].x + of.x, -annotScaling * meas.positions[1].y + of.y);
  thisShape.graphics.lineTo(annotScaling * meas.positions[3].x + of.x, -annotScaling * meas.positions[3].y + of.y);
  thisShape.graphics.lineTo(annotScaling * meas.positions[2].x + of.x, -annotScaling * meas.positions[2].y + of.y);
  thisShape.graphics.lineTo(annotScaling * meas.positions[0].x + of.x, -annotScaling * meas.positions[0].y + of.y);
  thisShape.graphics.endFill();

  meas.bounds.forEach(bound => {
    objBounds = updateBounds(objBounds, bound, annotScaling, of);
  });
  thisShape.setBounds(
    objBounds[0].x, objBounds[0].y,
    objBounds[1].x - objBounds[0].x, objBounds[1].y - objBounds[0].y
  );

  if(!meas.melted) {
    thisShape.addEventListener("click", function(event) { 
      selectAnnotation(render.options.pageNumber, measureId, false);
    });
    thisShape.addEventListener("mouseover", function(event) { 
      showDetailsAnnotation(pageId, measureId);
      render.stage.annotations.cursor = "pointer";
    });
    thisShape.addEventListener("mouseout", function(event) { 
      showDetailsAnnotation(null, null); 
      render.stage.annotations.cursor = "default";
    });
  }

  ct.addChild(thisShape);
}

function drawCount(ct, meas, fade, pageId, measureId) {
  var thisShape = new easelGL.Shape();
  var annotScaling = render.canvas.annotations.scaling;
  var of = render.canvas.annotations.offset;
  var lc = {...meas.line.color};
  var fc = {...meas.fill.color};
  var pId = 0;
  var objBounds = [new Vector(), new Vector()];

  if(fade) {
    lc = fadeColor(lc, .3);
    lc = rgbToGrayscale(lc);
  }
  else if(meas.melted) {
    lc = fadeColor(lc, render.options.opacity);
    if(render.options.grayScale) {
      lc = rgbToGrayscale(lc);
    }
  }
  fc = { ...lc };

  thisShape.graphics.setStrokeStyle(meas.line.width * annotScaling);
  thisShape.graphics.beginFill("rgba("+fc.r+","+fc.g+","+fc.b+","+fc.a+")");
  meas.positions.forEach(function(pos) {
    if(pId == 0) thisShape.graphics.moveTo(annotScaling * pos.x + of.x, -annotScaling * pos.y + of.y);
    else thisShape.graphics.lineTo(annotScaling * pos.x + of.x, -annotScaling * pos.y + of.y);
    objBounds = updateBounds(objBounds, pos, annotScaling, of);
    pId++;
  });
  thisShape.graphics.lineTo(annotScaling * meas.positions[0].x + of.x, -annotScaling * meas.positions[0].y + of.y);
  thisShape.graphics.endFill();

  if(meas.groupPositions != null) {
    meas.groupPositions.forEach(function(group) {
      thisShape.graphics.beginFill("rgba("+fc.r+","+fc.g+","+fc.b+","+fc.a+")");
      pId = 0;
      group.forEach(function(pos) {
        if(pId == 0) thisShape.graphics.moveTo(annotScaling * pos.x + of.x, -annotScaling * pos.y + of.y);
        else thisShape.graphics.lineTo(annotScaling * pos.x + of.x, -annotScaling * pos.y + of.y);
        objBounds = updateBounds(objBounds, pos, annotScaling, of);
        pId++;
      });
      thisShape.graphics.lineTo(annotScaling * meas.groupPositions[0].x + of.x, -annotScaling * meas.groupPositions[0].y + of.y);
      thisShape.graphics.endFill();
    });
  }

  if(!meas.melted) {
    thisShape.addEventListener("click", function(event) { 
      selectAnnotation(render.options.pageNumber, measureId, false);
    });
    thisShape.addEventListener("mouseover", function(event) { 
      showDetailsAnnotation(pageId, measureId);
      render.stage.annotations.cursor = "pointer";
    });
    thisShape.addEventListener("mouseout", function(event) { 
      showDetailsAnnotation(null, null); 
      render.stage.annotations.cursor = "default";
    });
  }
  
  thisShape.setBounds(
    objBounds[0].x, objBounds[0].y,
    objBounds[1].x - objBounds[0].x, objBounds[1].y - objBounds[0].y
  );

  ct.addChild(thisShape);
}

function drawAngle(ct, meas, fade, pageId, measureId) {
  var thisShape = new easelGL.Shape();
  var annotScaling = render.canvas.annotations.scaling;
  var of = render.canvas.annotations.offset;
  var objBounds = [new Vector(), new Vector()];

  var lc = {...meas.line.color};

  if(fade) {
    lc = fadeColor(lc, .3);
    lc = rgbToGrayscale(lc);
  }
  else if(meas.melted) {
    lc = fadeColor(lc, render.options.opacity);
    if(render.options.grayScale) {
      lc = rgbToGrayscale(lc);
    }
  }

  var angle1 = 0, angle2 = 0;
  var inverse = false;
  if(meas.positions[0].x >= meas.positions[1].x && meas.positions[0].y >= meas.positions[1].y) {
    angle1 = -Math.atan((meas.positions[0].y - meas.positions[1].y) / (meas.positions[0].x - meas.positions[1].x));
  }
  else if(meas.positions[0].x >= meas.positions[1].x && meas.positions[0].y < meas.positions[1].y) {
    angle1 = -Math.atan((meas.positions[0].y - meas.positions[1].y) / (meas.positions[0].x - meas.positions[1].x));
    
  }
  else if(meas.positions[0].x < meas.positions[1].x && meas.positions[0].y < meas.positions[1].y) {
    angle1 = Math.PI - Math.atan((meas.positions[0].y - meas.positions[1].y) / (meas.positions[0].x - meas.positions[1].x));
  }
  else {
    angle1 = Math.PI - Math.atan((meas.positions[0].y - meas.positions[1].y) / (meas.positions[0].x - meas.positions[1].x));
  }
  
  var a = Math.atan2((meas.positions[0].y - meas.positions[1].y), (meas.positions[0].x - meas.positions[1].x)) 
    - Math.atan2((meas.positions[2].y - meas.positions[1].y), (meas.positions[2].x - meas.positions[1].x));
  
  if(a >= 0) {
    if(a >= Math.PI) inverse = true;
    angle2 = angle1 + a;
  }
  else {
    if(Math.abs(a) < Math.PI) inverse = true;
    angle2 = angle1 + a;
  }

  var d1 = Math.sqrt(Math.pow(meas.positions[0].x - meas.positions[1].x, 2) + Math.pow(meas.positions[0].y - meas.positions[1].y, 2));
  var d2 = Math.sqrt(Math.pow(meas.positions[2].x - meas.positions[1].x, 2) + Math.pow(meas.positions[2].y - meas.positions[1].y, 2));

  thisShape.graphics.setStrokeStyle(1 * annotScaling);
  thisShape.graphics.beginStroke("rgba("+lc.r+","+lc.g+","+lc.b+","+lc.a+")");
  thisShape.graphics.moveTo(annotScaling * meas.positions[0].x + of.x, -annotScaling * meas.positions[0].y + of.y);
  thisShape.graphics.lineTo(annotScaling * meas.positions[1].x + of.x, -annotScaling * meas.positions[1].y + of.y);
  thisShape.graphics.lineTo(annotScaling * meas.positions[2].x + of.x, -annotScaling * meas.positions[2].y + of.y);
  thisShape.graphics.moveTo(annotScaling * meas.positions[1].x + of.x, -annotScaling * meas.positions[1].y + of.y);
  thisShape.graphics.arc(annotScaling * meas.positions[1].x + of.x, -annotScaling * meas.positions[1].y + of.y, 2 * Math.min(d1,d2) / 5 * annotScaling, angle1, angle2, inverse);
  thisShape.graphics.endStroke();

  if(meas.rotation != 0) {
    thisShape.setTransform(
      annotScaling * (meas.bounds[1].x + meas.bounds[0].x) / 2 + of.x, 
      -annotScaling * (meas.bounds[1].y + meas.bounds[0].y) / 2 + of.y,
      1, 1,  
      meas.rotation,
      0, 0,
      annotScaling * (meas.bounds[1].x + meas.bounds[0].x) / 2 + of.x, 
      -annotScaling * (meas.bounds[1].y + meas.bounds[0].y) / 2 + of.y
    );
  }

  if(!meas.melted) {
    thisShape.addEventListener("click", function(event) {
      selectAnnotation(render.options.pageNumber, measureId, false);
    });
    thisShape.addEventListener("mouseover", function(event) { 
      showDetailsAnnotation(pageId, measureId);
      render.stage.annotations.cursor = "pointer";
    });
    thisShape.addEventListener("mouseout", function(event) { 
      showDetailsAnnotation(null, null); 
      render.stage.annotations.cursor = "default";
    });
  }

  meas.bounds.forEach(bound => {
    objBounds = updateBounds(objBounds, bound, annotScaling, of);
  });
  thisShape.setBounds(
    objBounds[0].x, objBounds[0].y,
    objBounds[1].x - objBounds[0].x, objBounds[1].y - objBounds[0].y
  );

  ct.addChild(thisShape);
}

function drawLength(ct, meas, fade, pageId, measureId) {
  var thisShape = new easelGL.Shape();
  var bb = new easelGL.Container();
  var annotScaling = render.canvas.annotations.scaling;
  var of = render.canvas.annotations.offset;
  var pId = 0;
  var lc = {...meas.line.color};
  var cc = {...meas.caption.font.color};
  var newPos, arrowPos, offset;
  var objBounds = [new Vector(), new Vector()];

  // Set measure id
  //bb.measureId = measureId;

  // Line needs to be offset, so new points have to be calculated
  var n = normalVector(meas.positions, meas.lineOffset);

  if(fade) {
    lc = fadeColor(lc, .3);
    lc = rgbToGrayscale(lc);
    cc = fadeColor(cc, .3);
    cc = rgbToGrayscale(cc);
  }
  else if(meas.melted) {
    lc = fadeColor(lc, render.options.opacity);
    cc = fadeColor(cc, render.options.opacity);
    if(render.options.grayScale) {
      lc = rgbToGrayscale(lc);
      cc = rgbToGrayscale(cc);
    }
  }

  // Setup text object
  var text = null;

  // Find size of text, later we will also draw it
  newPos = new Vector(
    (meas.positions[0].x + meas.positions[1].x) / 2 + meas.line.offset * n.x, 
    (meas.positions[0].y + meas.positions[1].y) / 2 + meas.line.offset * n.y
  );

  if(meas.caption.text != null) {
    text = new easelGL.Text(meas.caption.text, (annotScaling * meas.caption.font.size)+"px Arial", "rgba("+cc.r+","+cc.g+","+cc.b+","+lc.a+")");
    text.textBaseline = "middle";
    text.textAlign = "center";
    text.setTransform(
      annotScaling * newPos.x + of.x, 
      -annotScaling * newPos.y + of.y, 
      1, 1, 
      //-n.angle() + 90
    );

    var textBounds = text.getBounds();
    text.setTransform(
      annotScaling * newPos.x + of.x, 
      -annotScaling * newPos.y + of.y, 
      1, 1, 
      -n.angle() + 90
    );
  }

  // Start drawing
  thisShape.graphics.setStrokeStyle(meas.line.width * annotScaling);
  thisShape.graphics.beginStroke("rgba("+lc.r+","+lc.g+","+lc.b+","+lc.a+")");

  meas.positions.forEach(function(pos) {
    var i = inverseVectorAtPoint(meas.positions, pId);
    var ls = meas.line.width;
    if(pId == 0) {

      // Draw line perpendicular to measurement direction, slightly longer than line offset
      offset = meas.line.offset;
      if(offset != 0) {
        newPos = new Vector(
          pos.x + (offset + Math.sign(offset) * 10 * meas.line.width * annotScaling) * n.x, 
          pos.y + (offset + Math.sign(offset) * 10 * meas.line.width * annotScaling) * n.y
        );
        thisShape.graphics.moveTo(annotScaling * pos.x + of.x, -annotScaling * pos.y + of.y);
        thisShape.graphics.lineTo(annotScaling * newPos.x + of.x, -annotScaling * newPos.y + of.y);

        objBounds = updateBounds(objBounds, pos, annotScaling, of);
        objBounds = updateBounds(objBounds, newPos, annotScaling, of);
      }

      // Then move to starting point for measurement line (offset)
      newPos = new Vector(pos.x + meas.line.offset * n.x, pos.y + meas.line.offset * n.y);
      thisShape.graphics.moveTo(annotScaling * newPos.x + of.x, -annotScaling * newPos.y + of.y);
      objBounds = updateBounds(objBounds, newPos, annotScaling, of);
    
      // Then draw the arrow
      if(meas.line.startStyle != null && meas.line.startStyle != "NONE") {

        if(meas.line.startScale != -1) ls = meas.line.startScale;
        arrowPos = new Vector(
          newPos.x + 4 * ls * n.x + 8 * ls * i.x,
          newPos.y + 4 * ls * n.y + 8 * ls * i.y
        );
        thisShape.graphics.lineTo(annotScaling * arrowPos.x + of.x, -annotScaling * arrowPos.y + of.y);
        thisShape.graphics.moveTo(annotScaling * newPos.x + of.x, -annotScaling * newPos.y + of.y);
        objBounds = updateBounds(objBounds, arrowPos, annotScaling, of);
        
        arrowPos = new Vector(
          newPos.x - 4 * ls * n.x + 8 * ls * i.x, 
          newPos.y - 4 * ls * n.y + 8 * ls * i.y
        );
        thisShape.graphics.lineTo(annotScaling * arrowPos.x + of.x, -annotScaling * arrowPos.y + of.y);
        thisShape.graphics.moveTo(annotScaling * newPos.x + of.x, -annotScaling * newPos.y + of.y);
        objBounds = updateBounds(objBounds, arrowPos, annotScaling, of);
      }
    }
    else {

      var textWidth = 0;
      if(textBounds != null) textWidth = textBounds.width + 1;
      var angRad = Math.atan((meas.positions[1].y - meas.positions[0].y) / (meas.positions[1].x - meas.positions[0].x));
      var midX = meas.positions[0].x + (meas.positions[1].x - meas.positions[0].x) / 2;
      var midY = meas.positions[0].y + (meas.positions[1].y - meas.positions[0].y) / 2;
      
      var toP1x = midX - 2 * Math.cos(angRad) * textWidth;
      var toP2x = midX + 2 * Math.cos(angRad) * textWidth;
      var toP1y = midY - 2 * Math.sin(angRad) * textWidth;
      var toP2y = midY + 2 * Math.sin(angRad) * textWidth;
      
      if(Math.sqrt(Math.pow(newPos.x - toP1x, 2) + Math.pow(newPos.y - toP1y, 2)) > Math.sqrt(Math.pow(newPos.x - toP2x, 2) + Math.pow(newPos.y - toP2y, 2))) {
        toP2x = midX - 2 * Math.cos(angRad) * textWidth;
        toP1x = midX + 2 * Math.cos(angRad) * textWidth;
        toP2y = midY - 2 * Math.sin(angRad) * textWidth;
        toP1y = midY + 2 * Math.sin(angRad) * textWidth;
      }

      // Draw measurement line (offset)
      newPos = new Vector(toP1x + meas.line.offset * n.x, toP1y + meas.line.offset * n.y);
      thisShape.graphics.lineTo(annotScaling * newPos.x + of.x, -annotScaling * newPos.y + of.y);
      objBounds = updateBounds(objBounds, newPos, annotScaling, of);

      newPos = new Vector(toP2x + meas.line.offset * n.x, toP2y + meas.line.offset * n.y);
      thisShape.graphics.moveTo(annotScaling * newPos.x + of.x, -annotScaling * newPos.y + of.y);
      objBounds = updateBounds(objBounds, newPos, annotScaling, of);

      newPos = new Vector(pos.x + meas.line.offset * n.x, pos.y + meas.line.offset * n.y);
      thisShape.graphics.lineTo(annotScaling * newPos.x + of.x, -annotScaling * newPos.y + of.y);
      objBounds = updateBounds(objBounds, newPos, annotScaling, of);
      
      if(meas.line.endScale != -1) ls = meas.line.endScale;

      offset = meas.line.offset;
      if(offset != 0) {
        newPos = new Vector(
          pos.x + (offset + Math.sign(offset) * 10 * meas.line.width * annotScaling) * n.x, 
          pos.y + (offset + Math.sign(offset) * 10 * meas.line.width * annotScaling) * n.y
        );
        thisShape.graphics.moveTo(annotScaling * newPos.x + of.x, -annotScaling * newPos.y + of.y);
        objBounds = updateBounds(objBounds, newPos, annotScaling, of);
      }

      // Then move to starting point for measurement line (offset)
      newPos = new Vector(
        pos.x + meas.line.offset * n.x, 
        pos.y + meas.line.offset * n.y
      );
      thisShape.graphics.lineTo(annotScaling * pos.x + of.x, -annotScaling * pos.y + of.y);
      thisShape.graphics.moveTo(annotScaling * newPos.x + of.x, -annotScaling * newPos.y + of.y);

      objBounds = updateBounds(objBounds, pos, annotScaling, of);
      objBounds = updateBounds(objBounds, newPos, annotScaling, of);

      // Then draw the arrow
      if(meas.line.endStyle != null && meas.line.endStyle != "NONE") {
        arrowPos = new Vector(
          newPos.x + 4 * ls * n.x + 8 * ls * i.x, 
          newPos.y + 4 * ls * n.y + 8 * ls * i.y
        );
        thisShape.graphics.lineTo(annotScaling * arrowPos.x + of.x, -annotScaling * arrowPos.y + of.y);
        thisShape.graphics.moveTo(annotScaling * newPos.x + of.x, -annotScaling * newPos.y + of.y);
        objBounds = updateBounds(objBounds, arrowPos, annotScaling, of);
        
        arrowPos = new Vector(
          newPos.x - 4 * ls * n.x + 8 * ls * i.x, 
          newPos.y - 4 * ls * n.y + 8 * ls * i.y
        );
        thisShape.graphics.lineTo(annotScaling * arrowPos.x + of.x, -annotScaling * arrowPos.y + of.y);
        thisShape.graphics.moveTo(annotScaling * newPos.x + of.x, -annotScaling * newPos.y + of.y);
        objBounds = updateBounds(objBounds, arrowPos, annotScaling, of);
      }

      // Finally also render the dimension as text
      if(meas.line.offset > 0) offset = meas.line.offset + meas.line.width * 7;
      else offset = meas.line.offset - meas.line.width * 9;
    }
    pId++;
  });

  bb.addChild(thisShape);
  if(text != null) bb.addChild(text);

  if(!meas.melted) {
    bb.addEventListener("click", function(event) { 
      selectAnnotation(render.options.pageNumber, measureId, false);
    });
    bb.addEventListener("mouseover", function(event) { 
      showDetailsAnnotation(pageId, measureId);
      render.stage.annotations.cursor = "pointer"; 
    });
    bb.addEventListener("mouseout", function(event) { 
      showDetailsAnnotation(null, null);
      render.stage.annotations.cursor = "default"; 
    });
  }

  bb.setBounds(
    objBounds[0].x, objBounds[0].y,
    objBounds[1].x - objBounds[0].x, objBounds[1].y - objBounds[0].y
  );

  ct.addChild(bb);
}

function drawPolylength(ct, meas, fade, pageId, measureId) {
  var thisShape = new easelGL.Shape();
  var annotScaling = render.canvas.annotations.scaling;
  var of = render.canvas.annotations.offset;
  var pId = 0;
  var objBounds = [new Vector(), new Vector()];

  var lc = {...meas.line.color};

  if(meas.positions == null) {
    return;
  }

  if(fade) {
    lc = fadeColor(lc, .3);
    lc = rgbToGrayscale(lc);
  }
  else if(meas.melted) {
    lc = fadeColor(lc, render.options.opacity);
    if(render.options.grayScale) {
      lc = rgbToGrayscale(lc);
    }
  }

  if(meas.line.width == 0) meas.line.width = 1;

  thisShape.graphics.setStrokeStyle(meas.line.width * annotScaling);
  thisShape.graphics.beginStroke("rgba("+lc.r+","+lc.g+","+lc.b+","+lc.a+")");
  meas.positions.forEach(function(pos) {
    if(pId == 0) thisShape.graphics.moveTo(annotScaling * pos.x + of.x, -annotScaling * pos.y + of.y);
    else {
      if(Object.keys(meas).includes("curves") && Object.keys(meas.curves).includes(""+(pId-1)) && Object.keys(meas.curves).includes(""+pId)) {
        //thisShape.graphics.lineTo(annotScaling * pos.x, -annotScaling * pos.y + offset);
        thisShape.graphics.bezierCurveTo(
          annotScaling * meas.curves[""+(pId-1)][1].x + of.x,
          -annotScaling * meas.curves[""+(pId-1)][1].y + of.y,
          annotScaling * meas.curves[""+pId][0].x + of.x,
          -annotScaling * meas.curves[""+pId][0].y + of.y,
          annotScaling * pos.x + of.x, 
          -annotScaling * pos.y + of.y
        );
      }
      else if(Object.keys(meas).includes("curves") && Object.keys(meas.curves).includes(""+pId)) {
        thisShape.graphics.bezierCurveTo(
          annotScaling * meas.positions[""+(pId-1)].x + of.x,
          -annotScaling * meas.positions[""+(pId-1)].y + of.y,
          annotScaling * meas.curves[""+pId][0].x + of.x,
          -annotScaling * meas.curves[""+pId][0].y + of.y,
          annotScaling * pos.x + of.x, 
          -annotScaling * pos.y + of.y
        );
      }
      else if(Object.keys(meas).includes("curves") && Object.keys(meas.curves).includes(""+(pId-1))) {
        thisShape.graphics.bezierCurveTo(
          annotScaling * meas.positions[""+(pId-1)].x + of.x,
          -annotScaling * meas.positions[""+(pId-1)].y + of.y,
          annotScaling * meas.curves[""+(pId-1)][1].x + of.x,
          -annotScaling * meas.curves[""+(pId-1)][1].y + of.y,
          annotScaling * pos.x + of.x + of.x, 
          -annotScaling * pos.y + of.y
        );
      }
      else  {
        thisShape.graphics.lineTo(annotScaling * pos.x + of.x, -annotScaling * pos.y + of.y);
      }
    }
    pId++;
  }, meas.curves);

  if(meas.rotation != 0) {
    thisShape.setTransform(
      annotScaling * (meas.bounds[1].x + meas.bounds[0].x) / 2 + of.x, 
      -annotScaling * (meas.bounds[1].y + meas.bounds[0].y) / 2 + of.y,
      1, 1,  
      meas.rotation,
      0, 0,
      annotScaling * (meas.bounds[1].x + meas.bounds[0].x) / 2 + of.x, 
      -annotScaling * (meas.bounds[1].y + meas.bounds[0].y) / 2 + of.y
    );
  }



  if(!meas.melted) {
    thisShape.addEventListener("click", function(event) { 
      selectAnnotation(render.options.pageNumber, measureId, false);
    });
    thisShape.addEventListener("mouseover", function(event) { 
      showDetailsAnnotation(pageId, measureId);
      render.stage.annotations.cursor = "pointer"; 
    });
    thisShape.addEventListener("mouseout", function(event) { 
      showDetailsAnnotation(null, null); 
      render.stage.annotations.cursor = "default";
    });
  }

  meas.bounds.forEach(bound => {
    objBounds = updateBounds(objBounds, bound, annotScaling, of);
  });
  thisShape.setBounds(
    objBounds[0].x, objBounds[0].y,
    objBounds[1].x - objBounds[0].x, objBounds[1].y - objBounds[0].y
  );

  ct.addChild(thisShape);
}

function drawShape(ct, meas, fade, pageId, measureId) {
  var thisShape = new easelGL.Shape();
  var thisShape2 = new easelGL.Shape();
  var annotScaling = render.canvas.annotations.scaling;
  var of = render.canvas.annotations.offset;
  var objBounds = [new Vector(), new Vector()];
  
  var cap = {...meas.caption};
  var lc = {...meas.line.color};
  var fc = {...meas.fill.color};
  var cc = {...meas.caption.font.color};
  var pId = 0, cId = 0;

  if(fade) {
    if(lc) lc = fadeColor(lc, .3);
    if(fc) fc = fadeColor(fc, .3);
    if(cc) cc = fadeColor(cc, render.options.opacity);

    if(lc) lc = rgbToGrayscale(lc);
    if(fc) fc = rgbToGrayscale(fc);
    if(cc) cc = rgbToGrayscale(cc);
  }
  else if(meas.melted) {
    lc = fadeColor(lc, render.options.opacity);
    fc = fadeColor(fc, render.options.opacity);
    cc = fadeColor(cc, render.options.opacity);
    if(render.options.grayScale) {
      lc = rgbToGrayscale(lc);
      fc = rgbToGrayscale(fc);
      cc = rgbToGrayscale(cc);
    }
  }

  if(meas.positions == null || meas.positions.length == 0) return; 

  var mbx0 = null, mbx1 = null, mby0 = null, mby1 = null;
  if(meas.caption != null && meas.caption.show) {
    for(const [key, position] of Object.entries(meas.positions)) {
      if(mbx0 == null || position.x < mbx0) mbx0 = position.x;
      if(mbx1 == null || position.x > mbx1) mbx1 = position.x;
      if(mby0 == null || position.y < mby0) mby0 = position.y;
      if(mby1 == null || position.y > mby1) mby1 = position.y;
    }
  }
  else {
    mbx0 = 0; mby0 = 0;
    mbx1 = 0; mby1 = 0;
  }

  //if(meas.line.width == 0) meas.line.width = 1;

  // Fill for outer positions
  if(fc.r >= 0 && meas.positions != null) {
    thisShape.graphics.beginFill("rgba("+fc.r+","+fc.g+","+fc.b+","+fc.a+")");
    meas.positions.forEach(function(pos) {
      if(pId == 0) thisShape.graphics.moveTo(annotScaling * pos.x + of.x, -annotScaling * pos.y + of.y);
      else {
        if(Object.keys(meas).includes("curves") && Object.keys(meas.curves).includes(""+(pId-1)) && Object.keys(meas.curves).includes(""+pId)) {
          thisShape.graphics.bezierCurveTo(
            annotScaling * meas.curves[""+(pId-1)][1].x + of.x,
            -annotScaling * meas.curves[""+(pId-1)][1].y + of.y,
            annotScaling * meas.curves[""+pId][0].x + of.x,
            -annotScaling * meas.curves[""+pId][0].y + of.y,
            annotScaling * pos.x + of.x, 
            -annotScaling * pos.y + of.y
          );
        }
        else thisShape.graphics.lineTo(annotScaling * pos.x + of.x, -annotScaling * pos.y + of.y);
      }
      pId++;
    });

    if(Object.keys(meas).includes("curves") && Object.keys(meas.curves).includes(""+(pId-1)) && Object.keys(meas.curves).includes("0")) {
      thisShape.graphics.bezierCurveTo(
        annotScaling * meas.curves[""+(pId-1)][1].x + of.x,
        -annotScaling * meas.curves[""+(pId-1)][1].y + of.y,
        annotScaling * meas.curves[0][0].x + of.x,
        -annotScaling * meas.curves[0][0].y + of.y,
        annotScaling * meas.positions[0].x + of.x, 
        -annotScaling * meas.positions[0].y + of.y
      );
    }
    else thisShape.graphics.closePath();

    // Create cutouts if available
    if(meas.cutouts != null) {
      cId = 0;
      meas.cutouts.forEach(function(cutout) {
        pId = 0; cutout.forEach(function(pos) {
          if(pId == 0) thisShape.graphics.moveTo(annotScaling * pos.x + of.x, -annotScaling * pos.y + of.y);
          else {
            if(Object.keys(meas).includes("cutoutCurves") && Object.keys(meas.cutoutCurves).includes(""+cId)) {
              var cutoutCurves = meas.cutoutCurves[""+cId];
              if(Object.keys(cutoutCurves).includes(""+(pId-1)) && Object.keys(cutoutCurves).includes(""+pId)) {
                thisShape.graphics.bezierCurveTo(
                  annotScaling * cutoutCurves[""+(pId-1)][1].x + of.x,
                  -annotScaling * cutoutCurves[""+(pId-1)][1].y + of.y,
                  annotScaling * cutoutCurves[""+pId][0].x + of.x,
                  -annotScaling * cutoutCurves[""+pId][0].y + of.y,
                  annotScaling * pos.x + of.x, 
                  -annotScaling * pos.y + of.y
                );
              }
              else thisShape.graphics.lineTo(annotScaling * pos.x + of.x, -annotScaling * pos.y + of.y);
            }
            else thisShape.graphics.lineTo(annotScaling * pos.x + of.x, -annotScaling * pos.y + of.y);
          }
          pId++;
        });

        if(Object.keys(meas).includes("cutoutCurves") && Object.keys(meas.cutoutCurves).includes(""+cId)) {
          var cutoutCurves = meas.cutoutCurves[""+cId];
          if(Object.keys(cutoutCurves).includes(""+(pId-1)) && Object.keys(cutoutCurves).includes("0")) {
            thisShape.graphics.bezierCurveTo(
              annotScaling * cutoutCurves[""+(pId-1)][1].x + of.x,
              -annotScaling * cutoutCurves[""+(pId-1)][1].y + of.y,
              annotScaling * cutoutCurves["0"][0].x + of.x,
              -annotScaling * cutoutCurves["0"][0].y + of.y,
              annotScaling * cutout[0].x + of.x, 
              -annotScaling * cutout[0].y + of.y
            );
          }
          else thisShape.graphics.closePath();
        }
        else thisShape.graphics.closePath();

        cId++;
      });
    }  
    thisShape.graphics.endFill();

    if(meas.rotation != 0) {
      thisShape.setTransform(
        annotScaling * (meas.bounds[1].x + meas.bounds[0].x) / 2 + of.x, 
        -annotScaling * (meas.bounds[1].y + meas.bounds[0].y) / 2 + of.y,
        1, 1,  
        meas.rotation,
        0, 0,
        annotScaling * (meas.bounds[1].x + meas.bounds[0].x) / 2 + of.x, 
        -annotScaling * (meas.bounds[1].y + meas.bounds[0].y) / 2 + of.y
      );
    }
  }

  if(meas.pImg) {
    var m = new easelGL.Matrix2D();
    m.scale(-0.0241, 0.0241);
    if(!fade) thisShape2.graphics.beginBitmapFill(meas.pImg, "repeat", m);
    else thisShape2.graphics.beginBitmapFill(meas.pgImg, "repeat", m);
  }

  // Outline for outer positions
  if(lc.r >= 0 && meas.oPattern != null && meas.oPattern.length > 0 && meas.cutouts == null) {

    if(meas.line.width > 0) {
      thisShape2.graphics.setStrokeStyle(meas.line.width * annotScaling);
      thisShape2.graphics.beginStroke("rgba("+lc.r+","+lc.g+","+lc.b+","+lc.a+")");
    }

    meas.oPattern.forEach(function(step) {
      if(step.stepType == "MOVE") {
        thisShape2.graphics.moveTo(annotScaling * step.x + of.x, -annotScaling * step.y + of.y);
        
      }
      if(step.stepType == "LINE") {
        thisShape2.graphics.lineTo(annotScaling * step.x + of.x, -annotScaling * step.y + of.y);
      }
      if(step.stepType == "CURVE") {
        thisShape2.graphics.bezierCurveTo(
          annotScaling * step.x1 + of.x, 
          -annotScaling * step.y1 + of.y,
          annotScaling * step.x2 + of.x, 
          -annotScaling * step.y2 + of.y,
          annotScaling * step.x + of.x, 
          -annotScaling * step.y + of.y
        );
      }
    });
    if(meas.type != "ARC") {
      thisShape2.graphics.closePath();
    }
    thisShape2.graphics.endStroke();
  }
  else if(lc.r >= 0 && meas.positions != null) {
    if(meas.line.width > 0) {
      thisShape2.graphics.setStrokeStyle(meas.line.width * annotScaling);
      thisShape2.graphics.beginStroke("rgba("+lc.r+","+lc.g+","+lc.b+","+lc.a+")");
    }

    pId = 0; meas.positions.forEach(function(pos) {
      if(pId == 0) thisShape2.graphics.moveTo(annotScaling * pos.x + of.x, -annotScaling * pos.y + of.y);
      else {
        if(Object.keys(meas).includes("curves") && Object.keys(meas.curves).includes(""+(pId-1)) && Object.keys(meas.curves).includes(""+pId)) {
          thisShape2.graphics.bezierCurveTo(
            annotScaling * meas.curves[""+(pId-1)][1].x + of.x,
            -annotScaling * meas.curves[""+(pId-1)][1].y + of.y,
            annotScaling * meas.curves[""+pId][0].x + of.x,
            -annotScaling * meas.curves[""+pId][0].y + of.y,
            annotScaling * pos.x + of.x, 
            -annotScaling * pos.y + of.y
          );
        }
        else thisShape2.graphics.lineTo(annotScaling * pos.x + of.x, -annotScaling * pos.y + of.y);
      }
      pId++;
    });
    thisShape2.graphics.closePath();
    thisShape2.graphics.endStroke();

    // Outline for cutouts
    if(meas.cutouts != null) {
      cId = 0;
      meas.cutouts.forEach(function(cutout) {
        thisShape2.graphics.beginStroke("rgba("+lc.r+","+lc.g+","+lc.b+","+lc.a+")");
        pId = 0; cutout.forEach(function(pos) {
          if(pId == 0) thisShape2.graphics.moveTo(annotScaling * pos.x + of.x, -annotScaling * pos.y + of.y);
          else {
            if(Object.keys(meas).includes("cutoutCurves") && Object.keys(meas.cutoutCurves).includes(""+cId)) {
              var cutoutCurves = meas.cutoutCurves[""+cId];
              if(Object.keys(cutoutCurves).includes(""+(pId-1)) && Object.keys(cutoutCurves).includes(""+pId)) {
                thisShape2.graphics.bezierCurveTo(
                  annotScaling * cutoutCurves[""+(pId-1)][1].x + of.x,
                  -annotScaling * cutoutCurves[""+(pId-1)][1].y + of.y,
                  annotScaling * cutoutCurves[""+pId][0].x + of.x,
                  -annotScaling * cutoutCurves[""+pId][0].y + of.y,
                  annotScaling * pos.x + of.x, 
                  -annotScaling * pos.y + of.y
                );
              }
              else thisShape2.graphics.lineTo(annotScaling * pos.x + of.x, -annotScaling * pos.y + of.y);
            }
            else thisShape2.graphics.lineTo(annotScaling * pos.x + of.x, -annotScaling * pos.y + of.y);
          }
          pId++;
        });
        
        if(Object.keys(meas).includes("cutoutCurves") && Object.keys(meas.cutoutCurves).includes(""+cId)) {
          var cutoutCurves = meas.cutoutCurves[""+cId];
          if(Object.keys(cutoutCurves).includes(""+(pId-1)) && Object.keys(cutoutCurves).includes("0")) {
            thisShape2.graphics.bezierCurveTo(
              annotScaling * cutoutCurves[""+(pId-1)][1].x + of.x,
              -annotScaling * cutoutCurves[""+(pId-1)][1].y + of.y,
              annotScaling * cutoutCurves["0"][0].x + of.x,
              -annotScaling * cutoutCurves["0"][0].y + of.y,
              annotScaling * cutout[0].x + of.x, 
              -annotScaling * cutout[0].y + of.y
            );
          }
          else thisShape2.graphics.closePath();
        }
        else thisShape2.graphics.closePath();
        thisShape2.graphics.endStroke();
      });
    }
  }

  if(meas.pImg || lc.r >= 0) {
    if(meas.rotation != 0) {
      thisShape2.setTransform(
        annotScaling * (meas.bounds[1].x + meas.bounds[0].x) / 2 + of.x, 
        -annotScaling * (meas.bounds[1].y + meas.bounds[0].y) / 2 + of.y,
        1, 1,  
        meas.rotation,
        0, 0,
        annotScaling * (meas.bounds[1].x + meas.bounds[0].x) / 2 + of.x, 
        -annotScaling * (meas.bounds[1].y + meas.bounds[0].y) / 2 + of.y
      );
    }
  }

  var text = null;
  if(meas.caption != null && meas.caption.show) {
    if(meas.caption.text == null) meas.caption.text = "";
    var fontStyle = (meas.caption.font.style != null) ? meas.caption.font.style : "";
    text = new easelGL.Text(meas.caption.text.replaceAll("\\r", "\n"), fontStyle + " " + (annotScaling * meas.caption.font.size)+"px " + meas.caption.font.face, "rgba("+cc.r+","+cc.g+","+cc.b+","+lc.a+")");
    
    text.lineHeight = annotScaling * meas.caption.font.size;
    text.textAlign = "center";

    var xt = annotScaling * (mbx1 + mbx0) / 2 + of.x;
    var yt = -annotScaling * (mby1 + mby0) / 2 + of.y;
    switch(render.data[pageId].annotations["PAGE"].rotation) {
      case 0:
        yt -= text.getMeasuredHeight() / 2;
        break;
      case 90:
        xt += text.getMeasuredHeight() / 2;
        break;
      case 180:
        yt += text.getMeasuredHeight() / 2;
        break;
      case 270:
        xt -= text.getMeasuredHeight() / 2;
        break;
    }

    text.setTransform(
      xt, yt, 
      1, 1, 
      0
    );

    text.mouseEnabled = false;
  }

  var c = new easelGL.Container();
  c.addChild(thisShape, thisShape2);
  if(text != null) c.addChild(text);

  if(!meas.melted) {
    c.addEventListener("click", function(event) { 
      selectAnnotation(render.options.pageNumber, measureId, false);
    });
    c.addEventListener("mouseover", function(event) { 
      showDetailsAnnotation(pageId, measureId);
      render.stage.annotations.cursor = "pointer";
    });
    c.addEventListener("mouseout", function(event) { 
      showDetailsAnnotation(null, null); 
      render.stage.annotations.cursor = "default";
    });
  }

  meas.bounds.forEach(bound => {
    objBounds = updateBounds(objBounds, bound, annotScaling, of);
  });
  c.setBounds(
    objBounds[0].x, objBounds[0].y,
    objBounds[1].x - objBounds[0].x, objBounds[1].y - objBounds[0].y
  );

  ct.addChild(c);
}

function drawRadius(ct, meas, fade, pageId, measureId) {
  var thisShape = new easelGL.Shape();
  var annotScaling = render.canvas.annotations.scaling;
  var of = render.canvas.annotations.offset;
  var objBounds = [new Vector(), new Vector()];

  var lc = {...meas.line.color};

  if(fade) {
    lc = fadeColor(lc, .3);
    lc = rgbToGrayscale(lc);
  }
  else if(meas.melted) {
    lc = fadeColor(lc, render.options.opacity);
    if(render.options.grayScale) {
      lc = rgbToGrayscale(lc);
    }
  }

  if(meas.line.width == 0) meas.line.width = 1;

  //var inverse = true;
  var angle1 = 0, angle2 = 0;
  var r = Math.sqrt(Math.pow(meas.positions[0].x - meas.positions[1].x, 2) + Math.pow(meas.positions[0].y - meas.positions[1].y, 2));
  if(meas.positions[0].x >= meas.positions[1].x && meas.positions[0].y >= meas.positions[1].y) {
    angle1 = -Math.atan((meas.positions[0].y - meas.positions[1].y) / (meas.positions[0].x - meas.positions[1].x));
  }
  else if(meas.positions[0].x >= meas.positions[1].x && meas.positions[0].y < meas.positions[1].y) {
    angle1 = -Math.atan((meas.positions[0].y - meas.positions[1].y) / (meas.positions[0].x - meas.positions[1].x));
    
  }
  else if(meas.positions[0].x < meas.positions[1].x && meas.positions[0].y < meas.positions[1].y) {
    angle1 = Math.PI - Math.atan((meas.positions[0].y - meas.positions[1].y) / (meas.positions[0].x - meas.positions[1].x));
  }
  else {
    angle1 = Math.PI - Math.atan((meas.positions[0].y - meas.positions[1].y) / (meas.positions[0].x - meas.positions[1].x));
  }
  
  var a = Math.atan2((meas.positions[0].y - meas.positions[1].y), (meas.positions[0].x - meas.positions[1].x)) 
    - Math.atan2((meas.positions[2].y - meas.positions[1].y), (meas.positions[2].x - meas.positions[1].x));
  
  if(a >= 0) {
    //if(a >= Math.PI) inverse = false;
    angle2 = angle1 + a;
  }
  else {
    //if(Math.abs(a) < Math.PI) inverse = false;
    angle2 = angle1 + a;
  }

  thisShape.graphics.setStrokeStyle(meas.line.width * annotScaling);
  thisShape.graphics.beginStroke("rgba("+lc.r+","+lc.g+","+lc.b+","+lc.a+")");
  thisShape.graphics.moveTo(annotScaling * meas.positions[2].x + of.x, -annotScaling * meas.positions[2].y + of.y);
  thisShape.graphics.lineTo(annotScaling * meas.positions[1].x + of.x, -annotScaling * meas.positions[1].y + of.y);
  thisShape.graphics.lineTo(annotScaling * meas.positions[0].x + of.x, -annotScaling * meas.positions[0].y + of.y);
  thisShape.graphics.arc(
    annotScaling * meas.positions[1].x + of.x, 
    -annotScaling * meas.positions[1].y + of.y, 
    annotScaling * r, 
    angle1, 
    angle2, 
    true
  );

  if(meas.rotation != 0) {
    thisShape.setTransform(
      annotScaling * (meas.bounds[1].x + meas.bounds[0].x) / 2 + of.x, 
      -annotScaling * (meas.bounds[1].y + meas.bounds[0].y) / 2 + of.y,
      1, 1,  
      meas.rotation,
      0, 0,
      annotScaling * (meas.bounds[1].x + meas.bounds[0].x) / 2 + of.x, 
      -annotScaling * (meas.bounds[1].y + meas.bounds[0].y) / 2 + of.y
    );
  }

  if(!meas.melted) {
    thisShape.addEventListener("click", function(event) { 
      selectAnnotation(render.options.pageNumber, measureId, false);
    });
    thisShape.addEventListener("mouseover", function(event) { 
      showDetailsAnnotation(pageId, measureId);
      render.stage.annotations.cursor = "pointer"; 
    });
    thisShape.addEventListener("mouseout", function(event) { 
      showDetailsAnnotation(null, null); 
      render.stage.annotations.cursor = "default";
    });
  }

  meas.bounds.forEach(bound => {
    objBounds = updateBounds(objBounds, bound, annotScaling, of);
  });
  thisShape.setBounds(
    objBounds[0].x, objBounds[0].y,
    objBounds[1].x - objBounds[0].x, objBounds[1].y - objBounds[0].y
  );

  ct.addChild(thisShape);
}

function drawDiameter(ct, meas, fade, pageId, measureId) {
  var thisShape = new easelGL.Shape();
  var annotScaling = render.canvas.annotations.scaling;
  var of = render.canvas.annotations.offset;
  var objBounds = [new Vector(), new Vector()];

  var lc = {...meas.line.color};

  if(fade) {
    lc = fadeColor(lc, .3);
    lc = rgbToGrayscale(lc);
  }
  else if(meas.melted) {
    lc = fadeColor(lc, render.options.opacity);
    if(render.options.grayScale) {
      lc = rgbToGrayscale(lc);
    }
  }

  if(meas.line.width == 0) meas.line.width = 1;

  thisShape.graphics.setStrokeStyle(meas.line.width * annotScaling);
  thisShape.graphics.beginStroke("rgba("+lc.r+","+lc.g+","+lc.b+","+lc.a+")");

  var mbx = (meas.positions[0].x + meas.positions[1].x) / 2;
  var mby = (meas.positions[0].y + meas.positions[1].y) / 2;
  if(meas.positions[0].x == meas.positions[1].x) {
    var r = Math.abs(meas.positions[1].y - meas.positions[0].y) / 2;
    thisShape.graphics.moveTo(annotScaling * (mbx - r) + of.x, -annotScaling * mby + of.y);
    thisShape.graphics.lineTo(annotScaling * (mbx + r) + of.x, -annotScaling * mby + of.y);
    thisShape.graphics.drawCircle(
      annotScaling * mbx + of.x, 
      -annotScaling * mby + of.y, 
      annotScaling * r
    );
  }
  else {
    var r = Math.abs(meas.positions[1].x - meas.positions[0].x) / 2;
    thisShape.graphics.moveTo(annotScaling * (mbx - r) + of.x, -annotScaling * mby + of.y);
    thisShape.graphics.lineTo(annotScaling * (mbx + r) + of.x, -annotScaling * mby + of.y);
    thisShape.graphics.drawCircle(
      annotScaling * mbx + of.x, 
      -annotScaling * mby + of.y, 
      annotScaling * r
    );
  }

  thisShape.setTransform(
    annotScaling * (meas.positions[1].x + meas.positions[0].x) / 2 + of.x, 
    -annotScaling * (meas.positions[1].y + meas.positions[0].y) / 2 + of.y,
    1, 1,  
    meas.rotation,
    0, 0,
    annotScaling * (meas.positions[1].x + meas.positions[0].x) / 2 + of.x, 
    -annotScaling * (meas.positions[1].y + meas.positions[0].y) / 2 + of.y
  );

  if(!meas.melted) {
    thisShape.addEventListener("click", function(event) { 
      selectAnnotation(render.options.pageNumber, measureId, false);
    });
    thisShape.addEventListener("mouseover", function(event) { 
      showDetailsAnnotation(pageId, measureId);
      render.stage.annotations.cursor = "pointer"; 
    });
    thisShape.addEventListener("mouseout", function(event) { 
      showDetailsAnnotation(null, null); 
      render.stage.annotations.cursor = "default";
    });
  }

  meas.bounds.forEach(bound => {
    objBounds = updateBounds(objBounds, bound, annotScaling, of);
  });
  thisShape.setBounds(
    objBounds[0].x, objBounds[0].y,
    objBounds[1].x - objBounds[0].x, objBounds[1].y - objBounds[0].y
  );

  ct.addChild(thisShape);
}

function drawViewport(ct, vp, meas, pBbox) {
  var thisShape = new easelGL.Shape();
  var thisShape2 = new easelGL.Shape();
  var annotScaling = render.canvas.annotations.scaling;
  var of = render.canvas.annotations.offset;
  var fc = { r: 0, g: 0, b: 0, a: 0.03 };

  // Fill for outer positions
  if(fc.r >= 0) {
    thisShape.graphics.beginFill("rgba("+fc.r+","+fc.g+","+fc.b+","+fc.a+")");
    thisShape.graphics.moveTo(
      annotScaling * vp.bbox.x1 + of.x, 
      -annotScaling * vp.bbox.y1 + of.y
    );
    thisShape.graphics.lineTo(
      annotScaling * vp.bbox.x2 + of.x, 
      -annotScaling * vp.bbox.y1 + of.y
    );
    thisShape.graphics.lineTo(
      annotScaling * vp.bbox.x2 + of.x, 
      -annotScaling * vp.bbox.y2 + of.y
    );
    thisShape.graphics.lineTo(
      annotScaling * vp.bbox.x1 + of.x, 
      -annotScaling * vp.bbox.y2 + of.y
    );
    thisShape.graphics.lineTo(
      annotScaling * vp.bbox.x1 + of.x, 
      -annotScaling * vp.bbox.y1 + of.y
    );
    thisShape.graphics.endFill();  
    thisShape.mouseEnabled = false;
    ct.addChild(thisShape);

    thisShape2.graphics.setStrokeStyle(1 * annotScaling);
    thisShape2.graphics.beginStroke("rgba("+fc.r+","+fc.g+","+fc.b+",0.5)");
    thisShape2.graphics.moveTo(
      annotScaling * vp.bbox.x1 + of.x, 
      -annotScaling * vp.bbox.y1 + of.y
    );
    thisShape2.graphics.lineTo(
      annotScaling * vp.bbox.x2 + of.x, 
      -annotScaling * vp.bbox.y1 + of.y
    );
    thisShape2.graphics.lineTo(
      annotScaling * vp.bbox.x2 + of.x, 
      -annotScaling * vp.bbox.y2 + of.y
    );
    thisShape2.graphics.lineTo(
      annotScaling * vp.bbox.x1 + of.x, 
      -annotScaling * vp.bbox.y2 + of.y
    );
    thisShape2.graphics.lineTo(
      annotScaling * vp.bbox.x1 + of.x, 
      -annotScaling * vp.bbox.y1 + of.y
    );
    thisShape2.graphics.endFill();
    thisShape2.mouseEnabled = false;
    ct.addChild(thisShape2);
  }

  let scaleText = "VP ";
  switch(meas.scale) {
    case 0.001:
      scaleText += "1 : 1000";
      break;
    case 0.002:
      scaleText += "1 : 500";
      break;
    case 0.005:
      scaleText += "1 : 200";
      break;
    case 0.01:
      scaleText += "1 : 100";
      break;
    case 0.02:
      scaleText += "1 : 50";
      break;
    case 0.05:
      scaleText += "1 : 20";
      break;
    case 0.1:
      scaleText += "1 : 10";
      break;
    case 1:
      scaleText += "1 : 1";
      break;
    default:
      scaleText += "1 cm = " + Math.round(1/meas.scale)/100 + " m";
      break;
  }

  let text = new easelGL.Text(scaleText, (annotScaling * pBbox.y2 / 100) + "px Arial", "rgba("+fc.r+","+fc.g+","+fc.b+",0.5)");
  text.lineHeight = annotScaling * 15;
  text.textAlign = "left";
  text.textBaseline = "top";

  var xt = annotScaling * (vp.bbox.x1 + 5) + of.x;
  var yt = -(annotScaling * (vp.bbox.y2 - 5)) + of.y;

  text.setTransform(
    xt, 
    yt, 
    1, 1, 
    0
  );

  text.mouseEnabled = false;
  ct.addChild(text);
}

function drawLink(ct, lk, fade) {
  var thisShape = new easelGL.Shape();
  var annotScaling = render.canvas.annotations.scaling;
  var of = render.canvas.annotations.offset;
  var fc = { r: 81, g: 127, b: 157, a: 0.2 };

  fc = fadeColor(fc, render.options.opacity);
  if(render.options.grayScale) {
    fc = rgbToGrayscale(fc);
  }

  // Fill for outer positions
  if(fc.r >= 0) {
    thisShape.graphics.beginFill("rgba("+fc.r+","+fc.g+","+fc.b+","+fc.a+")");
    thisShape.graphics.moveTo(
      annotScaling * lk.bbox[0].x + of.x, 
      -annotScaling * lk.bbox[0].y + of.y
    );
    thisShape.graphics.lineTo(
      annotScaling * lk.bbox[1].x + of.x, 
      -annotScaling * lk.bbox[0].y + of.y
    );
    thisShape.graphics.lineTo(
      annotScaling * lk.bbox[1].x + of.x, 
      -annotScaling * lk.bbox[1].y + of.y
    );
    thisShape.graphics.lineTo(
      annotScaling * lk.bbox[0].x + of.x, 
      -annotScaling * lk.bbox[1].y + of.y
    );
    thisShape.graphics.lineTo(
      annotScaling * lk.bbox[0].x + of.x, 
      -annotScaling * lk.bbox[0].y + of.y
    );
    thisShape.graphics.endFill();

    if(!lk.melted) {
      thisShape.addEventListener("click", function(event) { 
        for(const [pId, pData] of Object.entries(render.data)) {
          if(pData.annotations["PAGE"].ref == lk.refDest) {
            render.options.pageNumber = parseInt(pId);
          }
        }
      });
      thisShape.addEventListener("mouseover", function(event) { 
        render.stage.annotations.cursor = "pointer"; 
      });
      thisShape.addEventListener("mouseout", function(event) { 
        render.stage.annotations.cursor = "default";
      });
    }
    ct.addChild(thisShape);
  }
}

function drawSnappingPoint(sp) {

  var s = new easelGL.Shape();
  var as = render.canvas.annotations.scaling;
  var of = render.canvas.annotations.offset;
  var ro = 30 / render.canvas.zoom.factor.current;

  s.graphics.beginFill("rgba(81, 127, 157, 0.2)");
  //s.graphics.beginFill("rgba(0, 0, 0, 1)");
  s.graphics.moveTo((sp[0] - ro) * as + of.x, -(sp[1] - ro) * as + of.y);
  s.graphics.lineTo((sp[0] + ro) * as + of.x, -(sp[1] - ro) * as + of.y);
  s.graphics.lineTo((sp[0] + ro) * as + of.x, -(sp[1] + ro) * as + of.y);
  s.graphics.lineTo((sp[0] - ro) * as + of.x, -(sp[1] + ro) * as + of.y);
  s.graphics.closePath();
  s.graphics.endFill();

  s.graphics.setStrokeStyle(1 / render.canvas.zoom.factor.current * as);
  s.graphics.beginStroke("rgb(0, 0, 0)");
  s.graphics.moveTo((sp[0] - ro) * as + of.x, -(sp[1] - ro) * as + of.y);
  s.graphics.lineTo((sp[0] + ro) * as + of.x, -(sp[1] - ro) * as + of.y);
  s.graphics.lineTo((sp[0] + ro) * as + of.x, -(sp[1] + ro) * as + of.y);
  s.graphics.lineTo((sp[0] - ro) * as + of.x, -(sp[1] + ro) * as + of.y);
  s.graphics.closePath();
  s.graphics.endStroke();
  render.stage.annotations.addChild(s);
}

function drawCurrentContribution() {
  if(store.current.data.pdf.contributionTemplate == null) return;
  var ct = store.current.data.pdf.contributionTemplate;
  var s = ct.viewerOutput.shape;

  if(s.type == "icon") {
    if(store.current.data.pdf.contribution != null) {
      if(store.current.data.pdf.contribution.positions != null && store.current.data.pdf.contribution.positions.length > 0) {
        drawContribution_Icon(render.stage.annotations, store.current.data.pdf.contribution, ct.viewerOutput, false, -1);
      }
    }
  }

  if(s.type == "textbox") {
    if(store.current.data.pdf.contribution != null) {
      if(store.current.data.pdf.contribution.positions != null && store.current.data.pdf.contribution.positions.length > 0) {
        drawContribution_Textbox(render.stage.annotations, store.current.data.pdf.contribution, ct.viewerOutput, false, -1);
      }
    }
  }

  if(s.type == "count") {
    if(store.current.data.pdf.contribution != null) {
      if(store.current.data.pdf.contribution.positions != null && store.current.data.pdf.contribution.positions.length > 0) {
        drawContribution_Count(render.stage.annotations, store.current.data.pdf.contribution, false, -1);
      }
    }
  }

  if(s.type == "length") {
    if(store.current.data.pdf.contribution != null) {
      if(store.current.data.pdf.contribution.positions != null && store.current.data.pdf.contribution.positions.length > 0) {
        drawContribution_Length(render.stage.annotations, store.current.data.pdf.contribution, false, -1);
      }
    }
  }

  if(s.type == "poly" || s.type == "area") {
    if(store.current.data.pdf.contribution != null) {
      if(store.current.data.pdf.contribution.positions != null && store.current.data.pdf.contribution.positions.length > 0) {
        drawContribution_Area(render.stage.annotations, store.current.data.pdf.contribution, s.type, false, -1);
      }
    }
  }

  if(store.current.popup.indexOf("add-pdf-contribution") >= 0) {
    if(store.current.data.pdf.contribution != null) {
      if(store.current.data.pdf.contribution.positions != null && store.current.data.pdf.contribution.positions.length > 0) {
        drawEditPoints(render.stage.annotations, store.current.data.pdf.contribution.positions);
      }
    }
  }
}

function drawEditPoints(ct, pinnedLocation) {

  if(pinnedLocation == null) return;

  const a = render.canvas.annotations.scaling;
  const of = render.canvas.annotations.offset;
  const cz = render.canvas.zoom.factor.current;
  const s = 6;

  var points = [];
  for(var i = 0; i < pinnedLocation.length; i = i+2) {
    points.push({
      x: pinnedLocation[i]/a,
      y: -(pinnedLocation[i+1]) / a
    });
  }

  var c = new easelGL.Container();
  points.forEach((point, index) => {
    var cp = [{
      x: point.x*a - s/cz,
      y: -point.y*a - s/cz
    }, {
      x: point.x*a + s/cz,
      y: -point.y*a - s/cz
    }, {
      x: point.x*a + s/cz,
      y: -point.y*a + s/cz
    }, {
      x: point.x*a - s/cz,
      y: -point.y*a + s/cz
    }];

    var si = new easelGL.Shape();
    si.graphics.beginFill("rgba(255, 195, 11, 0.7)");
    for(var i = 0; i < cp.length; i++) {
      if(i == 0) si.graphics.moveTo(cp[i].x, cp[i].y);
      else si.graphics.lineTo(cp[i].x, cp[i].y);
    }
    si.graphics.closePath();

    var so = new easelGL.Shape();
    so.graphics.setStrokeStyle(2 * a / cz);
    so.graphics.beginStroke("rgba(0, 0, 0, 1)");
    for(var i = 0; i < cp.length; i++) {
      if(i == 0) so.graphics.moveTo(cp[i].x, cp[i].y);
      else so.graphics.lineTo(cp[i].x, cp[i].y);
    }
    so.graphics.closePath();

    si.addEventListener("mousedown", function(event) { 
      render.stage.annotations.cursor = "move";
      selectEditPoint(index);
    });

    c.addChild(si, so);
  });
  ct.addChild(c);
}

function drawContribution(ct, contribution, fade, pageId, measureId) {
  if(contribution.positions == null || contribution.positions.length == 0) return;

  const markup = render.contributionTemplates[contribution.contributionTemplateId].viewerOutput;
  if(markup.shape.type == "count") {
    drawContribution_Count(ct, contribution, fade, measureId);
  }

  if(markup.shape.type == "length") {
    drawContribution_Length(ct, contribution, fade, measureId);
  }

  if(markup.shape.type == "poly" || markup.shape.type == "area") {
    drawContribution_Area(ct, contribution, markup.shape.type, fade, measureId);
  }
  
  if(markup.shape.type == "textbox") {
    drawContribution_Textbox(ct, contribution, markup, fade, measureId);
  }

  if(markup.shape.type == "icon") {
    drawContribution_Icon(ct, contribution, markup, fade, measureId);
  }
}

function drawContribution_Count(ct, contribution, fade, measureId) {
  if(!(contribution.cache || contribution.tempCache) && render.options.measureEnd) return;

  var positions = [];
  for(var i = 0; i < contribution.positions.length; i = i+2) {
    positions.push({
      x: contribution.positions[i], 
      y: contribution.positions[i+1]
    });
  }

  var c = new easelGL.Container();
  var objBounds = [{x:null,y:null},{x:null,y:null}];
  var is = (contribution.values.is) ? contribution.values.is/3 : 1.5;

  // Set measure id
  c.measureId = "Q"+measureId;

  for(var i = 0; i < positions.length; i++) {
    var ic;
    if(contribution.tempCache != null) {
      ic = new easelGL.Bitmap(contribution.tempCache);
      ic.scaleX = 0.018*is;
      ic.scaleY = 0.018*is;
      ic.x = positions[i].x - 3.45*is;
      ic.y = positions[i].y - 3.45*is;
    }
    else if(fade && contribution.gsCache != null) {
      ic = new easelGL.Bitmap(contribution.gsCache);
      ic.scaleX = 0.018*is;
      ic.scaleY = 0.018*is;
      ic.x = positions[i].x - 3.45*is;
      ic.y = positions[i].y - 3.45*is;
    }
    else if(!fade && contribution.cache != null) {
      ic = new easelGL.Bitmap(contribution.cache);
      ic.scaleX = 0.018*is;
      ic.scaleY = 0.018*is;
      ic.x = positions[i].x - 3.45*is;
      ic.y = positions[i].y - 3.45*is;
    }
    else {
      ic = new easelGL.Bitmap('img/tbx/ch4.png');
      is = 2;
      ic.scaleX = 0.018*is;
      ic.scaleY = 0.018*is;
      ic.x = positions[i].x - 0.018*is*256/4;
      ic.y = positions[i].y - 0.018*is*256/4;
    }

    if(fade) ic.alpha = 0.2;
    objBounds = updateBounds(objBounds, {x: positions[i].x, y: positions[i].y}, 1, 0);
    c.addChild(ic);
  }
  
  c.addEventListener("click", function(event) {
    selectAnnotation(render.options.pageNumber, "Q"+measureId, false);
  });
  c.addEventListener("mouseover", function(event) {
    showDetailsContribution(measureId);
    render.stage.annotations.cursor = "pointer"; 
  });
  c.addEventListener("mouseout", function(event) { 
    showDetailsContribution(null);
    render.stage.annotations.cursor = "default";
  });
  c.setBounds(
    objBounds[0].x, 
    objBounds[0].y, 
    objBounds[1].x - objBounds[0].x, objBounds[1].y - objBounds[0].y
  );
  ct.addChild(c);
}

function drawContribution_Length(ct, contribution, fade, measureId) {
  var as = render.canvas.annotations.scaling;
  var of = render.canvas.annotations.offset;
  var settings = contribution.values;
  var lineRgb = settings.lc ? hexToRgb(settings.lc): {r:81, g:111, b:157};
  var positions = [];
  for(var i = 0; i < contribution.positions.length; i = i+2) {
    positions.push({
      x: (contribution.positions[i] - of.x)/as, 
      y: (-contribution.positions[i+1] + of.y)/as
    });
  }

  if(positions.length == 1) {
    var currentPoint = (render.options.snapToContent && render.options.snappingPoint != null) 
      ? {x:as*parseFloat(render.options.snappingPoint[0])+of.x, y:-as*parseFloat(render.options.snappingPoint[1])+of.y} 
      : render.stage.annotations.globalToLocal(render.stage.annotations.mouseX, render.stage.annotations.mouseY);

    positions.push({
      x: (parseFloat(currentPoint.x) - of.x)/as, 
      y: -(parseFloat(currentPoint.y) - of.y) / as 
    });
  }

  var measure = {
    line: {
      color: {
        r: lineRgb.r,
        g: lineRgb.g,
        b: lineRgb.b,
        a: settings.lo ? settings.lo/100 : 0.8
      },
      offset: 10,
      width: settings.lw ? settings.lw/5 : 1,
      startStyle: "arrow",
      startScale: settings.as ? settings.as/5 : 1,
      endStyle: "arrow",
      endScale: settings.as ? settings.as/5 : 1
    },
    lineOffset: 10,
    caption: {
      text: settings.mr,
      font: {
        color: {
          r: lineRgb.r,
          g: lineRgb.g,
          b: lineRgb.b,
          a: settings.lo
        },
        size: settings.fs ? settings.fs : 8
      }
    },
    positions: positions,
    melted: false
  };
  drawLength(ct, measure, fade, render.options.pageNumber, "Q"+measureId);
}

function drawContribution_Area(ct, contribution, type, fade, measureId) {
  var as = render.canvas.annotations.scaling;
  var of = render.canvas.annotations.offset;
  var c = new easelGL.Container();
  var positions = [];
  for(var i = 0; i < contribution.positions.length; i = i+2) {
    positions.push({
      x: contribution.positions[i], 
      y: contribution.positions[i+1]
    });
  }
  
  if(!render.options.measureEnd && !contribution.contributionId) {
    if(render.options.snapToContent && render.options.snappingPoint != null) {
      positions.push({
        x: parseFloat(render.options.snappingPoint[0])*as + of.x, 
        y: -parseFloat(render.options.snappingPoint[1])*as + of.y
      });
    }
    else {
      var pos = render.stage.annotations.globalToLocal(render.stage.annotations.mouseX, render.stage.annotations.mouseY);
      positions.push({
        x: pos.x,
        y: pos.y
      });
    }
  }
  
  // Set measure id
  c.measureId = "Q"+measureId;

  if(type && contribution.values.fo > 0 && !render.options.measureIntersects) {
    var si = new easelGL.Shape();
    var fc = hexToRgb(contribution.values.fc);
    fc.a = contribution.values.fo/100;
    if(fade) {
      fc = fadeColor(fc, .3);
      fc = rgbToGrayscale(fc);
    }
    si.graphics.beginFill("rgba("+fc.r+","+fc.g+","+fc.b+","+fc.a+")");
    positions.forEach(pos => {
      if(i == 0) si.graphics.moveTo(pos.x, pos.y);
      else si.graphics.lineTo(pos.x, pos.y);
    });
    si.graphics.closePath();
    c.addChild(si);
  }

  if(contribution.values.lw > 0 && contribution.values.lo > 0) {
    var so = new easelGL.Shape();
    var lc = hexToRgb(contribution.values.lc);
    lc.a = contribution.values.lo/100;
    if(fade) {
      lc = fadeColor(lc, .3);
      lc = rgbToGrayscale(lc);
    }
    so.graphics.setStrokeStyle(contribution.values.lw / 5 * as);
    so.graphics.beginStroke("rgba("+lc.r+","+lc.g+","+lc.b+","+lc.a+")");
    positions.forEach(pos => {
      if(i == 0) so.graphics.moveTo(pos.x, pos.y);
      else so.graphics.lineTo(pos.x, pos.y);
    });
    if(type == "area") so.graphics.closePath();
    c.addChild(so);
  }

  var xmin, xmax, ymin, ymax;
  positions.forEach(pos => {
    if(xmin == null || xmin > pos.x) xmin = pos.x;
    if(xmax == null || xmax < pos.x) xmax = pos.x;
    if(ymin == null || ymin > pos.y) ymin = pos.y;
    if(ymax == null || ymax < pos.y) ymax = pos.y;
  });

  c.setBounds(
    xmin, ymin, 
    xmax - xmin, ymax - ymin
  );

  c.addEventListener("click", function(event) {
    selectAnnotation(render.options.pageNumber, "Q"+measureId, false);
  });
  c.addEventListener("mouseover", function(event) { 
    showDetailsContribution(measureId);
    render.stage.annotations.cursor = "pointer"; 
  });
  c.addEventListener("mouseout", function(event) { 
    showDetailsContribution(null);
    render.stage.annotations.cursor = "default";
  });

  ct.addChild(c);
}

function drawContribution_Textbox(canvas, contribution, markup, fade, measureId) {
  var ct = store.current.data.pdf.contributionTemplate;
  var as = render.canvas.annotations.scaling;
  var of = render.canvas.annotations.offset;

  var positions = [];
  for(var i = 0; i < contribution.positions.length; i = i+2) {
    positions.push({
      x: contribution.positions[i], 
      y: contribution.positions[i+1]
    });
  }

  if(positions.length == 1) {
    var currentPoint = (render.options.snapToContent && render.options.snappingPoint != null) 
      ? {x:as*parseFloat(render.options.snappingPoint[0])+of.x, y:-as*parseFloat(render.options.snappingPoint[1])+of.y} 
      : render.stage.annotations.globalToLocal(render.stage.annotations.mouseX, render.stage.annotations.mouseY);

    positions.push({
      x: currentPoint.x,
      y: currentPoint.y
    });
  }

  var objBounds = [new Vector(positions[0].x, positions[0].y), new Vector(positions[1].x, positions[1].y)];

  var values = contribution.values;
  var inputs = (ct != null) ? ct.inputs : null;
  var fc = {...markup.shape.fillColor};
  var lc = {...markup.shape.lineColor};
  var cc = {...markup.shape.textColor};
  if(markup.shape.forStatus != null) {
    var status = values.status;
    if(inputs != null && typeof status === "string") {
      inputs.forEach(input => {
        if(input.name == "status") {
          status = input.options.indexOf(status);
        }
      });
    }
    if(values != null && markup.shape.forStatus[status] != null) {
      if(markup.shape.forStatus[status].fillColor != null) {
        fc = {...markup.shape.forStatus[status].fillColor};
      }
      if(markup.shape.forStatus[status].lineColor != null) {
        lc = {...markup.shape.forStatus[status].lineColor};
      }
      if(markup.shape.textColor != null) {
        cc = {...markup.shape.textColor};
      }
    }
  }

  if(fade) {
    lc.r = 100;
    lc.g = 100;
    lc.b = 100;
    lc.a = 0.2;

    fc = {...lc};
    cc = {...lc};
  }

  var text = markup.text;
  for(const field of Object.keys(values)) {
    if(ct != null && (!contribution.contributionId || contribution.contributionId == render.options.selectedContributionId)) {
      ct.inputs.forEach(input => {
        if(input.name == field) {
          if(values[field] == input.label) {
            text = text.replaceAll("{{"+field+"}}", "**");
          }
          else {
            text = text.replaceAll("{{"+field+"}}", values[field]);
          }
        }
      });
    }
    else text = text.replaceAll("{{"+field+"}}", values[field]);
    if(text.startsWith(": ")) text = text.substring(2);
  }

  var textObj = new easelGL.Text(text.replaceAll("\\r", "\n"), markup.shape.fontStyle + " " + (as * markup.shape.textSize)+"px " + markup.shape.fontFace, "rgba("+cc.r+","+cc.g+","+cc.b+","+cc.a+")");
  textObj.lineHeight = as * markup.shape.lineHeight;
  textObj.textAlign = "left";
  textObj.textBaseline = "top";
  textObj.x = parseFloat(positions[1].x) + as * markup.shape.padding;
  textObj.y = parseFloat(positions[1].y) + as * markup.shape.padding;
  textObj.text = text;
  textObj.mouseEnabled = false;
  
  var fillObj = new easelGL.Shape();
  fillObj.graphics.beginFill("rgba("+fc.r+","+fc.g+","+fc.b+","+fc.a+")");
  fillObj.graphics.setStrokeStyle(markup.shape.lineWidth);
  fillObj.graphics.drawRect(
    positions[1].x, positions[1].y, 
    textObj.getMeasuredWidth() + 2 * as * markup.shape.padding, 
    textObj.getMeasuredHeight() + 2 * as * markup.shape.padding
  );
  fillObj.graphics.endFill();

  var lineObj = new easelGL.Shape();
  lineObj.graphics.beginStroke("rgba("+lc.r+","+lc.g+","+lc.b+","+lc.a+")");
  lineObj.graphics.setStrokeStyle(markup.shape.lineWidth);
  lineObj.graphics.drawRect(
    positions[1].x, positions[1].y, 
    textObj.getMeasuredWidth() + 2 * as * markup.shape.padding, 
    textObj.getMeasuredHeight() + 2 * as * markup.shape.padding
  );
  lineObj.graphics.endStroke();
  lineObj.mouseEnabled = false;

  var c = new easelGL.Container();
  var positions;

  // Set measure id
  c.measureId = "Q"+measureId;

  if(contribution.positions != null) {
    var a = new easelGL.Shape();
    var s = markup.shape;
    var offset = null;
    if(Math.abs(positions[0].x - positions[1].x) > Math.abs(positions[0].y - positions[1].y)) {
      if(positions[0].x - positions[1].x > 0) {
        offset = { 
          "x": (textObj.getMeasuredWidth() + 2 * as * markup.shape.padding),
          "y": (textObj.getMeasuredHeight() + 2 * as * markup.shape.padding) / 2
        }
      }
      else {
        offset = { 
          "x": 0,
          "y": (textObj.getMeasuredHeight() + 2 * as * markup.shape.padding) / 2
        }
      }
    }
    else {
      if(positions[0].y - positions[1].y > 0) {
        offset = { 
          "x": (textObj.getMeasuredWidth() + 2 * as * markup.shape.padding) / 2,
          "y": (textObj.getMeasuredHeight() + 2 * as * markup.shape.padding)
        }
      }
      else {
        offset = { 
          "x": (textObj.getMeasuredWidth() + 2 * as * markup.shape.padding) / 2,
          "y": 0
        }
      }
    }

    var arrowPositions = [
      new Vector(
        parseFloat(positions[0].x) + (textObj.getMeasuredWidth() + 2 * as * markup.shape.padding) / 2, 
        parseFloat(positions[0].y) + (textObj.getMeasuredHeight() + 2 * as * markup.shape.padding) / 2
      ),
      new Vector(
        parseFloat(positions[1].x) + offset.x, 
        parseFloat(positions[1].y) + offset.y
      )
    ];
    var n = normalVector(arrowPositions, null);
    var i = inverseVectorAtPoint(arrowPositions, 0);
    var arrowPos = new Vector(
      arrowPositions[0].x + 4 * s.lineWidth * n.x + 8 * s.lineWidth * i.x,
      arrowPositions[0].y + 4 * s.lineWidth * n.y + 8 * s.lineWidth * i.y
    );

    a.graphics.moveTo(arrowPositions[0].x, arrowPositions[0].y);
    a.graphics.beginFill("rgba("+lc.r+", "+lc.g+", "+lc.b+", "+lc.a+")");
    a.graphics.lineTo(arrowPos.x, arrowPos.y);
    
    arrowPos = new Vector(
      arrowPositions[0].x - 4 * s.lineWidth * n.x + 8 * s.lineWidth * i.x, 
      arrowPositions[0].y - 4 * s.lineWidth * n.y + 8 * s.lineWidth * i.y
    );
    a.graphics.lineTo(arrowPos.x, arrowPos.y);
    a.graphics.lineTo(arrowPositions[0].x, arrowPositions[0].y);
    a.graphics.endFill();
    
    a.graphics.setStrokeStyle(s.lineWidth);
    a.graphics.beginStroke("rgba("+lc.r+", "+lc.g+", "+lc.b+", "+lc.a+")");
    a.graphics.moveTo(arrowPositions[0].x + 8 * s.lineWidth * i.x, arrowPositions[0].y + 8 * s.lineWidth * i.y);
    a.graphics.lineTo(arrowPositions[1].x, arrowPositions[1].y);
    a.graphics.endStroke();

    c.addChild(a);
  }

  c.addChild(fillObj, lineObj, textObj);
  c.x = -(textObj.getMeasuredWidth() + 2 * as * markup.shape.padding) / 2;
  c.y = -(textObj.getMeasuredHeight() + 2 * as * markup.shape.padding) / 2;

  fillObj.addEventListener("click", function(event) {
    selectAnnotation(render.options.pageNumber, "Q"+measureId, false);
  });
  fillObj.addEventListener("mouseover", function(event) { 
    showDetailsContribution(measureId);
    render.stage.annotations.cursor = "pointer"; 
  });
  fillObj.addEventListener("mouseout", function(event) { 
    showDetailsContribution(null);
    render.stage.annotations.cursor = "default";
  });

  c.setBounds(
    objBounds[0].x, 
    objBounds[0].y, 
    objBounds[1].x - objBounds[0].x, objBounds[1].y - objBounds[0].y
  );

  canvas.addChild(c);
}

function drawContribution_Icon(canvas, contribution, markup, fade, measureId) {
  var ct = store.current.data.pdf.contributionTemplate;
  var as = render.canvas.annotations.scaling;
  var values = contribution.values;
  var text = markup.text;
  for(const field of Object.keys(values)) {
    if(ct != null && (!contribution.contributionId || contribution.contributionId == render.options.selectedContributionId)) {
      ct.inputs.forEach(input => {
        if(input.name == field) {
          if(values[field] == input.label) {
            text = text.replaceAll("{{"+field+"}}", "******");
          }
          else {
            text = text.replaceAll("{{"+field+"}}", values[field]);
          }
        }
      });
    }
    else text = text.replaceAll("{{"+field+"}}", values[field]);
    if(text.startsWith(": ")) text = text.substring(2);
  }

  var positions = [];
  for(var i = 0; i < contribution.positions.length; i = i+2) {
    positions.push({
      x: contribution.positions[i], 
      y: contribution.positions[i+1]
    });
  }

  var textObj = new easelGL.Text(text.replaceAll("\\r", "\n"), "italic bold "+(as * 5)+"px Arial", "rgb(0,0,0)");
  textObj.lineHeight = as * 6;
  textObj.textAlign = "center";
  textObj.textBaseline = "top";
  textObj.x = positions[0].x;
  textObj.y = positions[0].y;
  textObj.text = text;
  textObj.mouseEnabled = false;
  if(fade) textObj.alpha = 0.2;

  var iconObj = new easelGL.Bitmap((values.ico) ? "img/tbx/"+values.ico+".png" : "img/tbx/ch1.png");
  iconObj.scaleX = 0.018;
  iconObj.scaleY = 0.018;
  iconObj.x = positions[0].x - 1.2;
  iconObj.y = positions[0].y - textObj.getMeasuredHeight() / 2 - 1.4;
  iconObj.mouseEnabled = false;
  if(fade) iconObj.alpha = 0.2;

  var objBounds = [
    new Vector(positions[0].x - textObj.getMeasuredWidth() / 2, positions[0].y - 3.3), 
    new Vector(positions[0].x - textObj.getMeasuredWidth() / 2 + textObj.getMeasuredWidth(), positions[0].y - 3.3 + textObj.getMeasuredHeight() + 3.6)
  ];

  var fillObj = new easelGL.Shape();
  if(!fade) fillObj.graphics.beginFill("rgba(81,127,157,0.5)");
  else fillObj.graphics.beginFill("rgba(100,100,100,0.1)");
  fillObj.graphics.drawRect(
    positions[0].x - textObj.getMeasuredWidth() / 2, 
    positions[0].y - 3.3, 
    textObj.getMeasuredWidth(), 
    textObj.getMeasuredHeight() + 3.6
  );
  fillObj.graphics.endFill();

  var lineObj = new easelGL.Shape();
  if(!fade) lineObj.graphics.beginStroke("rgba(81,127,157,1)");
  else lineObj.graphics.beginStroke("rgba(100,100,100,0.2)");
  lineObj.graphics.setStrokeStyle(0.1);
  lineObj.graphics.drawRect(
    positions[0].x - textObj.getMeasuredWidth() / 2, 
    positions[0].y - 3.5, 
    textObj.getMeasuredWidth(), 
    textObj.getMeasuredHeight() + 4
  );
  lineObj.graphics.endStroke();
  lineObj.mouseEnabled = false;

  var c = new easelGL.Container();

  // Set measure id
  c.measureId = "Q"+measureId;

  c.addChild(fillObj, iconObj, textObj);
  //c.x = -(textObj.getMeasuredWidth()) / 2;
  c.y = -(textObj.getMeasuredHeight()) / 2;

  fillObj.addEventListener("click", function(event) {
    selectAnnotation(render.options.pageNumber, "Q"+measureId, false);
  });
  fillObj.addEventListener("mouseover", function(event) { 
    showDetailsContribution(measureId);
    render.stage.annotations.cursor = "pointer"; 
  });
  fillObj.addEventListener("mouseout", function(event) { 
    showDetailsContribution(null);
    render.stage.annotations.cursor = "default";
  });

  c.setBounds(
    objBounds[0].x, 
    objBounds[0].y, 
    objBounds[1].x - objBounds[0].x, objBounds[1].y - objBounds[0].y
  );

  canvas.addChild(c);
}

function normalVector(points) {
    
    var dx = points[1].x - points[0].x;
    var dy = points[1].y - points[0].y
    var v = new Vector(-dy, dx);

    v.normalize();

    return v;
}

function inverseVectorAtPoint(points, id) {
  var dx, dy;
  if(id == 0) {
    dx = points[1].x - points[0].x;
    dy = points[1].y - points[0].y;
  }
  if(id == 1) {
    dx = points[0].x - points[1].x;
    dy = points[0].y - points[1].y;
  }

  var v = new Vector(dx, dy);
  v.normalize();
  return v;
}

function renderStage(exportFullImage) {
  if(render.printToConsole) console.log("");
  if(render.images.data == null || render.images.data[render.options.pageNumber] == null || render.images.data[render.options.pageNumber][0] == null) {
    return;
  }

  var step = render.canvas.images.step;
  var imageZoom = render.canvas.images.zoomFactor;
  while(step >= 0 && (render.images.data[render.options.pageNumber][step] == null || render.images.data[render.options.pageNumber][step].length == 0)) {
    step--;
    imageZoom /= 2;
  }
  if(step < 0) return;

  var images = render.images.data[render.options.pageNumber][step];
  if(images == null) return; 
  for(var i = 0; i < images.length; i++) {
    if(images[i] == null || images[i].length == 0) {
      step--;
      if(step < 0) return;
      images = render.images.data[render.options.pageNumber][step];
      i = 0; j = 0;
    }
    for(var j = 0; j < images[i].length; j++) {
      if(images[i][j] == null || !images[i][j].loaded) {
        step--;
        if(step < 0) return;
        images = render.images.data[render.options.pageNumber][step];
        i = 0; j = 0;
      }
    }
  }
  if(images == null) return; 

  //let annotations = render.stage.annotations.children;
  //if(imagesChanged() || override) {
    var imageSize = render.images.size;
    var dpr = window.devicePixelRatio;

    // Draw all PDF images
    render.canvas.images.current = [];
    render.canvas.gridLines.current = [];
    render.canvas.gridLines.count = 0;

    render.canvas.images.count = 0;
    render.stage.images.removeAllChildren();
    render.stage.images.addChild(drawBackground(1, "images"));
    
    if(images != null) {
      for(var i = 0; i < images.length; i++) {
        for(var j = 0; j < images[i].length; j++) {
          if(images[i][j].easel == null) return;
          if(render.canvas.images.current.indexOf(images[i][j]) != -1) return;
          render.stage.images.addChild(images[i][j].easel);

          images[i][j].easel.x = j * imageSize / imageZoom;
          images[i][j].easel.y = i * imageSize / imageZoom;
          images[i][j].easel.scaleX = 1.0 / imageZoom;
          images[i][j].easel.scaleY = 1.0 / imageZoom;
          images[i][j].easel.alpha = render.options.opacity;

          if(exportFullImage || inViewport(images[i][j].easel, imageZoom)) {
            render.canvas.images.current.push(images[i][j]);
            render.canvas.images.count++;
          }
          else {
            render.stage.images.removeChild(images[i][j].easel);
          }
        }
      }
    }

    render.stage.images.update();
    //render.stage.annotations.update();
}

function renderAnnotations(prepareFirst, exportFullImage) {
  if(!render) return;
  if(render.printToConsole) console.log("renderAnnotations");
  if(prepareFirst) {
    prepareAnnotations(false);
  }

  var currentZoom = parseInt(render.canvas.zoom.factor.current);
  var page = render.pages[render.options.pageNumber-1];
  var imageWidth = page.imageWidth / Math.pow(2, store.current.data.pdf.document.gridVariants - 1);
  var imageHeight = page.imageHeight / Math.pow(2, store.current.data.pdf.document.gridVariants - 1);
  var offsetX = render.stage.annotations.regX - render.stage.annotations.x / render.canvas.zoom.factor.current;
  var offsetY = render.stage.annotations.regY - render.stage.annotations.y / render.canvas.zoom.factor.current;
  var maxX = offsetX + render.canvas.annotationObj.width / render.canvas.zoom.factor.current;
  var maxY = offsetY + render.canvas.annotationObj.height / render.canvas.zoom.factor.current;
  offsetX = (offsetX >= 0) ? offsetX : 0;
  offsetY = (offsetY >= 0) ? offsetY : 0;

  // Do not render annotations that are outside the view
  var measureId = "";
  if(store.current.popup.indexOf("add-pdf-contribution") >= 0) {
    if(store.current.data.pdf.contribution != null && !store.current.data.pdf.contribution.copy) {
      measureId = "Q"+store.current.data.pdf.contribution.contributionId;
    }
  }

  Object.values(render.stage.annotations.children[1].children).forEach(child => {

    child.visible = true;

    var b = child.getBounds();
    if(b == null) return;
    
    if(child.measureId == measureId) {
      child.visible = false;
    }
    if(!inViewportBounds(b)) {
      child.visible = false;
    }
  });

  while(render.stage.annotations.children.length > 2) {
    let child = render.stage.annotations.children[render.stage.annotations.children.length-1];
    render.stage.annotations.removeChild(child);
  }

  if(!render.options.measureEnd || store.current.popup.indexOf("add-pdf-contribution") >= 0) {
    if(render.mouse.hpos.x != null && render.mouse.hpos.y != null) {
      var sp = findClosestDataPoint(render.mouse.hpos);
      if(sp != null) {
        render.options.snappingPoint = {...sp};
        drawSnappingPoint(sp);
      }
      else render.options.snappingPoint = null;
    }
  }

  // Draw current contribution
  drawCurrentContribution();

  // Update stage contents
  render.stage.annotations.uncache();
  //render.stage.annotations.children[1].visible = true;
  if(!exportFullImage) {
    render.stage.annotations.cache(
      offsetX, offsetY,
      maxX - offsetX, maxY - offsetY,
      (currentZoom + 1 > 1) ? (currentZoom + 1) : 1
    );
  }
  else {
    render.stage.annotations.cache(
      0, 0,
      imageWidth, imageHeight,
      dlQuality
    );
  }

  render.stage.annotations.update();
}

function imagesChanged() {
  var dpr = window.devicePixelRatio;
  if(render.pages[render.options.pageNumber-1] == null) return;
  for(var i = 0; i < store.current.data.pdf.document.gridVariants; i++) {
    var factor = Math.pow(2, store.current.data.pdf.document.gridVariants - 1) / Math.pow(2, i);
    if(
      render.canvas.annotationObj.width/dpr * render.canvas.zoom.factor.current / render.canvas.zoom.factor.min <= render.pages[render.options.pageNumber-1].imageWidth / factor &&
      render.canvas.annotationObj.height/dpr * render.canvas.zoom.factor.current / render.canvas.zoom.factor.min <= render.pages[render.options.pageNumber-1].imageHeight / factor
    ) {
      if(i != render.canvas.images.step) {
        render.canvas.images.zoomFactor = Math.pow(2, i);
        render.canvas.images.step = i;
        render.canvas.images.id = i;
        return true;
      }
      else return false;
    }
  }

  var lastStep = store.current.data.pdf.document.gridVariants - 1;
  if(lastStep != render.canvas.images.step) {
    render.canvas.images.zoomFactor = Math.pow(2, lastStep);
    render.canvas.images.step = lastStep;
    render.canvas.images.id = lastStep;
    return true;
  }
  else return false;
}

function inViewport(element, zoomFactor) {
    var offsetX = render.stage.annotations.regX - render.stage.annotations.x / render.canvas.zoom.factor.current;
    var offsetY = render.stage.annotations.regY - render.stage.annotations.y / render.canvas.zoom.factor.current;
    if(element.x - offsetX + render.images.size / zoomFactor < 0) {
        return false;
    }
    if(element.y - offsetY + render.images.size / zoomFactor < 0) {
        return false;
    }
    if(element.x - offsetX > render.canvas.annotationObj.width / render.canvas.zoom.factor.current) {
        return false;
    }
    if(element.y - offsetY > render.canvas.annotationObj.height / render.canvas.zoom.factor.current) {
        return false;
    }
    
    return true;
}

function inViewportBounds(bounds) {
  var offsetX = render.stage.annotations.regX - render.stage.annotations.x / render.canvas.zoom.factor.current;
  var offsetY = render.stage.annotations.regY - render.stage.annotations.y / render.canvas.zoom.factor.current;
  if(bounds.x + bounds.width - offsetX < 0) {
    return false;
  }
  if(bounds.y + bounds.height - offsetY < 0) {
    return false;
  }
  
  if(bounds.x - offsetX > render.canvas.annotationObj.width / render.canvas.zoom.factor.current) {
    return false;
  }
  if(bounds.y - offsetY > render.canvas.annotationObj.height / render.canvas.zoom.factor.current) {
    return false;
  }
  return true;
}

function initCanvasRenderer(resetZoom) {
  store = useStore();
  render = useRenderStore();

  if(render.renderTimer != null) clearInterval(render.renderTimer);
  renderAnnotations(true, false);
  
  if(!render.canvas.zoom.factor.current || resetZoom) {
    rescaleCanvas(true);
  }
  else rescaleCanvas(false);

  if(render.options.activeAnnotation != null) {
    if(render.data[render.options.pageNumber].annotations["ANNOTATION"][render.options.selectedAnnotationId]) {
      var objBounds = render.data[render.options.pageNumber].annotations["ANNOTATION"][render.options.selectedAnnotationId].bounds;
      zoomBounds(objBounds);
    }
  }
  if(render.options.activeContribution != null) {
    if(render.options.activeContribution.positions != null) {
      var ap = render.options.activeContribution.positions;
      var as = render.canvas.annotations.scaling;
      var of = render.canvas.annotations.offset;
      objBounds = [new Vector(), new Vector()];
      for(var i = 0; i < ap.length; i = i + 2) {
        var x = (ap[i]-of.x)/as, y = (-ap[i+1]+of.y)/as;
        if(objBounds[0].x == null || objBounds[0].x > x) objBounds[0].x = x;
        if(objBounds[1].x == null || objBounds[1].x < x) objBounds[1].x = x;
        if(objBounds[0].y == null || objBounds[0].y > y) objBounds[0].y = y;
        if(objBounds[1].y == null || objBounds[1].y < y) objBounds[1].y = y;
      }
      zoomBounds(objBounds);
    }
    else zoomReset();
  }
  
  render.renderTimer = setInterval(() => {
    if(render.drawTimer == null) {
      renderStage();
    }
  }, 500);
}

function rescaleCanvas(zr) {
  if(render.printToConsole) console.log("rescaleCanvas");
  if(render.components.annotationViewer == null && render.components.contributionViewer == null) return;
  if(render.components.pdfNavigation.$refs.navbarForms == null) return;
  
  var leftOffset = render.components.pdfNavigation.$refs.changeLog.clientWidth;
  if(render.components.pdfNavigation.$refs.navbarForms.clientWidth > 40) {
    leftOffset += render.components.pdfNavigation.$refs.navbarForms.clientWidth + 40;
  }
  else leftOffset += 40;
  render.canvas.leftOffset = leftOffset;

  if(render.options.showAnnotations) {
    var width = window.innerWidth - leftOffset;
    var height = window.innerHeight - render.components.annotationViewer.$refs.annotationViewer.clientHeight - 40;
    render.setCanvasSize(width, height);
    render.canvas.imageObj.style.left = leftOffset + "px";
    render.canvas.annotationObj.style.left = leftOffset + "px";
    render.components.navbarBottom.style.left = leftOffset + "px";
    render.components.navbarBottom.style.bottom = (render.components.annotationViewer.$refs.annotationViewer.clientHeight - 0) + "px";
    render.components.pdfNavigation.$refs.navbarForms.style.height = (window.innerHeight - render.components.annotationViewer.$refs.annotationViewer.clientHeight) + "px";
    render.components.pdfNavigation.$refs.navbarButtons.$refs.buttons.style.height = (window.innerHeight - render.components.annotationViewer.$refs.annotationViewer.clientHeight) + "px";
    render.components.pdfNavigation.$refs.changeLog.style.height = (window.innerHeight - render.components.annotationViewer.$refs.annotationViewer.clientHeight) + "px";
  }
  if(render.options.showContributions) {
    var width = window.innerWidth - leftOffset;
    var height = window.innerHeight - render.components.contributionViewer.$refs.contributionViewer.clientHeight - 40;
    render.setCanvasSize(width, height);
    render.canvas.imageObj.style.left = leftOffset + "px";
    render.canvas.annotationObj.style.left = leftOffset + "px";
    render.components.navbarBottom.style.left = leftOffset + "px";
    render.components.navbarBottom.style.bottom = (render.components.contributionViewer.$refs.contributionViewer.clientHeight - 0) + "px";
    render.components.pdfNavigation.$refs.navbarForms.style.height = (window.innerHeight - render.components.contributionViewer.$refs.contributionViewer.clientHeight) + "px";
    render.components.pdfNavigation.$refs.navbarButtons.$refs.buttons.style.height = (window.innerHeight - render.components.contributionViewer.$refs.contributionViewer.clientHeight) + "px";
    render.components.pdfNavigation.$refs.changeLog.style.height = (window.innerHeight - render.components.contributionViewer.$refs.contributionViewer.clientHeight) + "px";
  }
  if(!render.options.showAnnotations && !render.options.showContributions) {
    var width = window.innerWidth - leftOffset;
    var height = window.innerHeight - render.components.annotationViewer.$refs.annotationViewer.clientHeight - 0;
    render.setCanvasSize(width, height);
    render.canvas.imageObj.style.left = leftOffset + "px";
    render.canvas.annotationObj.style.left = leftOffset + "px";
    render.components.navbarBottom.style.left = "80px";
    render.components.navbarBottom.style.bottom = (render.components.annotationViewer.$refs.annotationViewer.clientHeight - 40) + "px";
    render.components.pdfNavigation.$refs.navbarForms.style.height = (window.innerHeight - render.components.annotationViewer.$refs.annotationViewer.clientHeight) + "px";
    render.components.pdfNavigation.$refs.navbarButtons.$refs.buttons.style.height = (window.innerHeight - render.components.contributionViewer.$refs.contributionViewer.clientHeight - 40) + "px";
    render.components.pdfNavigation.$refs.changeLog.style.height = (window.innerHeight - render.components.annotationViewer.$refs.annotationViewer.clientHeight) + "px";
  }

  let margin = 30;
  var z = 0;
  var dpr = window.devicePixelRatio;
  var page = render.pages[render.options.pageNumber-1];
  var fitZoomX = (render.canvas.annotationObj.width - margin*dpr - 40*dpr) / (page.imageWidth / Math.pow(2, store.current.data.pdf.document.gridVariants - 1));
  var fitZoomY = (render.canvas.annotationObj.height- margin*dpr) / (page.imageHeight / Math.pow(2, store.current.data.pdf.document.gridVariants - 1));  
  var minZoom = Math.pow(2, z) * Math.round(Math.min(fitZoomX, fitZoomY) * 10) / 10;
  render.canvas.zoom.factor.min = minZoom;
  if(render.canvas.zoom.factor.current < minZoom) {
    render.canvas.zoom.factor.current = minZoom;
  }
  
  if(zr) zoomReset();
  else renderStage();
}

function zoomReset(exportFullImage) {
  if(render.printToConsole) console.log("zoomReset");
  let fitZoomX = false;
  let fitZoomY = false;
  let margin = 30;
  var z = 0;
  var dpr = window.devicePixelRatio;

  var page = render.pages[render.options.pageNumber-1];
  fitZoomX = (render.canvas.annotationObj.width - margin*dpr - 40*dpr) / (page.imageWidth / Math.pow(2, store.current.data.pdf.document.gridVariants - 1));
  fitZoomY = (render.canvas.annotationObj.height- margin*dpr) / (page.imageHeight / Math.pow(2, store.current.data.pdf.document.gridVariants - 1));  
  while(fitZoomX/2 >= 1 || fitZoomY/2 >= 1) {
    fitZoomX /= 2;
    fitZoomY /= 2;
    z++;
  }

  render.canvas.zoom.factor.current = Math.pow(2, z) * Math.round(Math.min(fitZoomX, fitZoomY) * 10) / 10;
  //render.canvas.zoom.factor.min = render.canvas.zoom.factor.current;

  if(exportFullImage) {
    render.canvas.zoom.factor.current = 8;
  }

  var page = render.pages[render.options.pageNumber-1];
  zoomStage(
    (render.canvas.annotationObj.width - render.canvas.zoom.factor.current * page.imageWidth / Math.pow(2, store.current.data.pdf.document.gridVariants - 1)) / 2, 
    (render.canvas.annotationObj.height - render.canvas.zoom.factor.current * page.imageHeight / Math.pow(2, store.current.data.pdf.document.gridVariants - 1)) / 2
  );
  renderStage(exportFullImage);
}

function zoomStage(x, y, fromTouch) {
  if(render.printToConsole) console.log("zoomStage");
  if(x == null && y == null) {
    var local = render.stage.annotations.globalToLocal(render.stage.annotations.mouseX, render.stage.annotations.mouseY);
    render.stage.annotations.regX = local.x;
    render.stage.annotations.regY = local.y;
    render.stage.annotations.x = render.stage.annotations.mouseX;
    render.stage.annotations.y = render.stage.annotations.mouseY;
    render.canvas.offset.x = render.stage.annotations.mouseX;
    render.canvas.offset.y = render.stage.annotations.mouseY;
  }
  else if(x != null && y != null && fromTouch) {
    var local = render.stage.annotations.globalToLocal(x, y);
    render.stage.annotations.regX = local.x;
    render.stage.annotations.regY = local.y;
    render.stage.annotations.x = x;
    render.stage.annotations.y = y;
    render.canvas.offset.x = x;
    render.canvas.offset.y = y;
  }
  else {
    render.stage.annotations.regX = 0;
    render.stage.annotations.regY = 0;
    render.stage.annotations.x = x;
    render.stage.annotations.y = y;
    render.canvas.offset.x = x;
    render.canvas.offset.y = y;
  }

  render.stage.annotations.scaleX = render.canvas.zoom.factor.current;
  render.stage.annotations.scaleY = render.canvas.zoom.factor.current;

  // copy positioning to image canvas
  render.stage.images.regX = render.stage.annotations.regX;
  render.stage.images.regY = render.stage.annotations.regY;
  render.stage.images.x = render.stage.annotations.x;
  render.stage.images.y = render.stage.annotations.y;
  render.stage.images.scaleX = render.stage.annotations.scaleX;
  render.stage.images.scaleY = render.stage.annotations.scaleY;

  render.stage.annotations.update();
  if(render.zoomTimer != null) {
    clearTimeout(render.zoomTimer);
    render.zoomTimer = null;
  }

  //render.canvas.images.step = 0;
  imagesChanged();
  renderStage();
  render.zoomTimer = setTimeout(renderAnnotations, 300);
}

function resetSelection() {
  if(render.printToConsole) console.log("resetSelection");
  if(render.options.ctrlPressed) return;
  render.options.selectedAnnotationId = -1;
  render.options.selectedContributionId = -1;
  render.options.activeAnnotation = null;
  render.options.activeContribution = null;
  render.options.firstSelectedContributionKey = -1;
  store.current.data.pdf.contribution = null;
  store.current.data.pdf.contributionTemplate = null;

  if(render.options.multiSelectedContributionIds.length > 0) {
    render.options.multiSelectedContributionIds = [];
    renderAnnotations(true, false);
  }
}

function zoomBounds(bounds) {
  if(render.printToConsole) console.log("zoomBounds");
  
  var forContribution = false;
  var x, y;

  if(bounds == null) return;

  var bbox = render.data[render.options.pageNumber].annotations["PAGE"].bbox;
  var as = render.canvas.annotations.scaling;

  render.canvas.annotations.offset = {
    x: -bbox.x1 * as,
    y: bbox.y2 * as
  };

  var fitZoomX = (render.canvas.annotationObj.width-200) / (Math.abs(bounds[0].x - bounds[1].x) * as);
  var fitZoomY = (render.canvas.annotationObj.height-200) / (Math.abs(bounds[0].y - bounds[1].y) * as);
  var fitZoom = Math.min(fitZoomX, fitZoomY);

  if(fitZoom < render.canvas.zoom.factor.current) {
    render.canvas.zoom.factor.current = fitZoom;
  }
  else if(render.canvas.zoom.factor.current < 3) {
    render.canvas.zoom.factor.current = 3;
  }

  render.stage.annotations.scaleX = render.canvas.zoom.factor.current;
  render.stage.annotations.scaleY = render.canvas.zoom.factor.current;
  render.stage.images.scaleX = render.stage.annotations.scaleX;
  render.stage.images.scaleY = render.stage.annotations.scaleY;
  imagesChanged();

  var cz = render.canvas.zoom.factor.current;
  var of = {...render.canvas.annotations.offset};
  if(!forContribution) {
    x = ((bounds[1].x + bounds[0].x) / 2) * as * cz;
    y = ((bounds[1].y + bounds[0].y) / 2) * as * cz;

    render.stage.annotations.regX = 0;
    render.stage.annotations.regY = 0;
    render.stage.annotations.x = render.canvas.annotationObj.width / 2 - x + of.x * cz;
    render.stage.annotations.y = render.canvas.annotationObj.height / 2 + y - of.y * cz;
    render.canvas.offset.x = render.canvas.annotationObj.width / 2 - x + of.x * cz;
    render.canvas.offset.y = render.canvas.annotationObj.height / 2 + y - of.y * cz;
  }
  /*else {
    x = (bounds[1].x + bounds[0].x) / 2 * render.canvas.zoom.factor.current;
    y = (bounds[1].y + bounds[0].y) / 2 * render.canvas.zoom.factor.current;
    render.stage.annotations.regX = 0;
    render.stage.annotations.regY = 0;
    render.stage.annotations.x = render.canvas.annotationObj.width / 2 - x
    render.stage.annotations.y = render.canvas.annotationObj.height / 2 - y// + y;
    render.canvas.offset.x = render.canvas.annotationObj.width / 2 - x// + x;
    render.canvas.offset.y = render.canvas.annotationObj.height / 2 - y// + y;
  }*/

  // copy positioning to image canvas
  render.stage.images.regX = 0;
  render.stage.images.regY = 0;
  render.stage.images.x = render.stage.annotations.x;
  render.stage.images.y = render.stage.annotations.y;

  render.options.grayScale = true;
  render.options.opacity = 0.3;

  nextTick(function() {
    renderStage();
  });
}

function downloadImage() {
  var page = render.pages[render.options.pageNumber-1];
  var imageWidth = page.imageWidth / Math.pow(2, store.current.data.pdf.document.gridVariants - 1);
  var imageHeight = page.imageHeight / Math.pow(2, store.current.data.pdf.document.gridVariants - 1);

  renderAnnotations(true, true);
  zoomReset(true);
  var annotCache = new easelGL.Bitmap(render.stage.annotations.cacheCanvas);

  render.stage.images.cache(
    0, 0,
    imageWidth, imageHeight,
    dlQuality
  );
  var imageCache = new easelGL.Bitmap(render.stage.images.cacheCanvas);
  if(render.options.grayScale) {
    var gs = new easelGL.ColorMatrix().adjustSaturation(-100);
    imageCache.filters = [new easelGL.ColorMatrixFilter(gs)];
    imageCache.cache(
      0, 0,
      imageWidth * dlQuality, imageHeight * dlQuality,
      1
    );
  }

  render.stage.temp.clear();
  render.stage.temp.addChild(imageCache, annotCache);
  render.stage.temp.cache(
    0, 0,
    imageWidth * dlQuality, imageHeight * dlQuality,
    1
  );

  var current = store.current.data.pdf.document;
  var a = document.createElement("a");
  a.href = render.stage.temp.cacheCanvas.toDataURL('image/jpeg', 0.9);
  a.download = current.name+" ("+current.currentUuid+") - pagina "+render.options.pageNumber+".jpg";
  a.click();

  render.stage.images.uncache();
  zoomReset();
}

function dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
  while(n--){
      u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, {type:mime});
}

function showDetailsAnnotation(pageId, measureId) {
  if(render.options.selectedAnnotationId == -1) {
    render.components.annotationViewer.openDetails(pageId, measureId);
  }
}

function showDetailsContribution(contributionId) {
  if(store.current.popup.indexOf("add-pdf-contribution") != -1) return;
  if(render.options.selectedContributionId == -1) {
    render.components.contributionViewer.openDetails(contributionId);
  }
}

function moveStage(dx, dy) {
    render.canvas.offset.x += dx;
    render.canvas.offset.y += dy;

    render.stage.images.x = render.canvas.offset.x;
    render.stage.images.y = render.canvas.offset.y;
    render.stage.annotations.x = render.canvas.offset.x;
    render.stage.annotations.y = render.canvas.offset.y;
    
    renderStage();
    render.stage.annotations.update();
}

function rgbToGrayscale(rgb) {
  if(rgb.r >= 0) {
    const r = rgb.r * .3;
    const g = rgb.g * .59;
    const b = rgb.b * .11;
    const gray = r + g + b;
    
    rgb.r = gray;
    rgb.g = gray;
    rgb.b = gray;
    return rgb;
  }
  else {
    return rgb;
  }
}

function fadeColor(rgb, a) {
  if(rgb.a) {
    rgb.a = a * rgb.a;
  }
  else rgb.a = a;
  return rgb;
}

function updateBounds(bounds, pos, scale, offset) {
  if(bounds[0].x == -1 || bounds[0].x > scale * pos.x + offset.x) bounds[0].x = scale * pos.x + offset.x;
  if(bounds[0].y == -1 || bounds[0].y > -scale * pos.y + offset.y) bounds[0].y = -scale * pos.y + offset.y;
  if(bounds[1].x == -1 || bounds[1].x < scale * pos.x + offset.x) bounds[1].x = scale * pos.x + offset.x;
  if(bounds[1].y == -1 || bounds[1].y < -scale * pos.y + offset.y) bounds[1].y = -scale * pos.y + offset.y;
  return bounds;
}

function findClosestDataPoint(mp) {
  if(mp == null || typeof(mp.x) != 'string') return;

  var pageObj = render.data[render.options.pageNumber].annotations["PAGE"];
  var snapmap = render.snapMap[render.options.pageNumber];
  if(snapmap == null) return;
  
  var pc = findPointCloud(mp, snapmap);
  if(pc !== undefined) {
    var sp = selectClosest(mp, pc);
    if(sp == null) return null;

    switch(pageObj.rotation) {
      case 90:
        sp = [
          parseFloat(sp[1]),
          -parseFloat(sp[0]) + (pageObj.bbox.y2 - pageObj.bbox.y1)
        ];
        break;
      case 180:
        sp = [
          -parseFloat(sp[0]) + (pageObj.bbox.x2 - pageObj.bbox.x1),
          -parseFloat(sp[1]) + (pageObj.bbox.y2 - pageObj.bbox.y1)
        ];
        break;
      case 270:
        sp = [
          -parseFloat(sp[1]) + (pageObj.bbox.x2 - pageObj.bbox.x1),
          parseFloat(sp[0])
        ];
        break;
      default:
        sp = [
          parseFloat(sp[0]),
          parseFloat(sp[1])
        ];
        break;
    }

    //sp = [pageObj.bbox.x1 + sp[0], (pageObj.bbox.y1 - pageObj.bbox.y2) - sp[1]];
    //console.log(sp);
    return sp;
  }
}

function findPointCloud(mp, data) {
  var results = [];
  var mpx = (mp.x[0] == "-")?mp.x.substring(0,4):mp.x.substring(0,3);
  var mpy = (mp.y[0] == "-")?mp.y.substring(0,4):mp.y.substring(0,3);
  for(var i = 0; i < 10; i++) {
    for(var j = 0; j < 10; j++) {
      var sub = data[mpx+i+mpy+j];
      if(sub !== undefined) {
        for(var p of sub) {
          results.push([
            mpx + i + "." + p.substring(0,3),
            mpy + j + "." + p.substring(3,6)
          ]);
        }
      }
    }
  }

  return results;
}

function retrieveFromCloud(b, pc, res) {
  Object.entries(pc).forEach(pc1 => {
    if(pc1[0].length == 1) {
      res = retrieveFromCloud(b, pc1[1], res);
    }
    else if(!Array.isArray(pc1[1])) {
      var val = String(pc1[0]);
      val = val.replaceAll("10", "0");
      var bx = (b[0].length == 4) ? (b[0] + "." + val[0]) : (b[0] + "" + val[0]);
      var by = (b[1].length == 4) ? (b[1] + "." + val[1]) : (b[1] + "" + val[1]);
      res = retrieveFromCloud([bx,by], pc1[1], res);
    }
    else {
      var val = String(pc1[0]);
      val = val.replaceAll("10", "0");
      var bx = (b[0].length == 4) ? (b[0] + "." + val[0]) : (b[0] + "" + val[0]);
      var by = (b[1].length == 4) ? (b[1] + "." + val[1]) : (b[1] + "" + val[1]);
      for(var i in pc1[1]) {
        val = String(pc1[1][i]);
        val = val.replaceAll("10", "0");
        res.push([bx + "" + val[0], by + "" + val[1]]);
      }
    }
  });
  return res;
}

function selectClosest(mp, pc) {
  var d = 0;
  var s = null;
  pc.forEach(p => {
    var dd = Math.sqrt(Math.pow(parseFloat(mp.x) - parseFloat(p[0]), 2) + Math.pow(parseFloat(mp.y) - parseFloat(p[1]), 2));
    if(d == 0 || dd < d) {
      s = p;
      d = dd;
    }
  });
  
  if(d > 0 && d < 1.5) return s;
  else return null;
}

function hexToRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null;
}

function selectEditPoint(index) {
  if(render.options.measureEnd) {
    render.options.activeEditPointIndex = index;
  }
}



export { zoomStage, zoomReset, renderStage, renderAnnotations, zoomBounds, rescaleCanvas, moveStage, initCanvasRenderer, selectAnnotation, resetSelection, downloadImage, findAnnotationById }