<template>
  <transition name="fade">
    <div class="popup" v-if="store.current.popup.includes('add-pdf-pivot-config')">
      <div>
        <perfect-scrollbar style="height:100%">
          <form @submit.prevent="add">
            <div class="closer" @click="close()">Scherm sluiten</div>
            <h1>{{ title }}</h1>

            <template v-if="init">
              <div v-if="labelData.name" class="before-input" :class="{active:labelData.name.active}">{{ labelData.name.text }}</div>
              <input type="text" 
                name="name" 
                v-model="pivotConfig.name"
                :class="{hasLabel:labelData.name.active}" 
                @focusin="empty($event)" 
                @focusout="reset($event)"
                @keyup="checkName">
              <div v-if="formErrors.name" class="error-field">{{ defaultData.name[0] + " " + formErrors.name }}!</div>
              <div v-if="exists" class="error-field">
                Er bestaat al een configuratie met deze naam. Wanneer u deze wilt overschrijven,
                kunt u de invoer zo laten.
              </div>

              <label>
                {{ defaultData.isDefault[0] }}
                <input type="checkbox" 
                  v-model="pivotConfig.isDefault" 
                  @click="toggle($event)">
                <span :class="{active:pivotConfig.isDefault}"></span>
              </label>
              <div v-if="formErrors.isDefault" class="error-field">{{ defaultData.isDefault[0] + " " + formErrors.isDefault }}!</div>

              <input type="submit" :value="submitText" :class="{locked:isLocked}">
            </template>
            <div v-else class="loader">
              <div>Even geduld, content wordt geladen...</div>
            </div>
          </form>
        </perfect-scrollbar>
      </div>
    </div>
  </transition>
</template>

<script>
import listingService from '@/services/listing.service'
import pivotConfigService from '@/services/app/pivot.config.service'
import errorHandler from '@/common/ErrorHandler'

import Multiselect from '@vueform/multiselect'

export default {
  emits: ['showMessage'],
  data() {
    return {
      pivotConfig: {
        pivotConfigId: 0,
        name: "",
        config: ""
      },
      pivotConfigs: [],
      defaultData: {
        name: ['Naam', "Welke naam wilt u instellen voor deze configuratie?"],
        isDefault: ['Als standaard instellen?', false]
      },
      labelData: {},
      formErrors: {},
      title: "",
      submitText: "",
      pending: false,
      init: false,
      exists: false
    };
  },
  computed: {
    store() {
      return this.$parent.store;
    },
    renderStore() {
      return this.$parent.renderStore;
    },
    currentPopup() {
      return this.store.current.popup[this.store.current.popup.length-1];
    },
    isLocked() {
      if(this.pending || !this.init) return true;
      else return false;
    }
  },
  watch: {
    async currentPopup(value) {
      if(value == "add-pdf-pivot-config") {
        this.init = false;
        this.exists = false;
        this.clear();

        this.renderStore.options.columns = [...this.renderStore.options.columnsPivot, ...this.renderStore.options.columnsOverview];
        if(this.store.current.data.pdf.pivotConfig.pivotConfigId) {
          this.pivotConfig = { ...this.store.current.data.pdf.pivotConfig };
          this.title = "Configuratie wijzigen";
          this.submitText = "Wijzigingen opslaan";
        }
        else {
          this.pivotConfig = { name: "", isDefault: false };
          this.title = "Configuratie toevoegen";
          this.submitText = "Toevoegen";
        }

        await this.fetch();
        this.init = true;
      }
    },
    pivotConfig(listing) {
      for(const[key, value] of Object.entries(listing)) {
        if(!this.defaultData[key]) continue;
        if(value && value.toString().length > 0) {
          this.labelData[key] = {text: this.defaultData[key][0], active: true};
        }
        else {
          this.labelData[key] = {text: '', active: false};
          this.pivotConfig[key] = this.defaultData[key][1];
        }
      }
    }
  },
  methods: {
    async add() {
      if(this.pending) return;
      this.pending = true;
      let response = null;

      var pivotColumns = JSON.parse(JSON.stringify(this.renderStore.options.columns));
      for(const key in pivotColumns) {
        delete(pivotColumns[key].width);
        delete(pivotColumns[key].name_nl);
        delete(pivotColumns[key].charCount);
        delete(pivotColumns[key].deleted);
      }

      this.pivotConfig.config = JSON.stringify(pivotColumns);
      response = await pivotConfigService.add(this.pivotConfig, this.defaultData);

      if(response) {
        if(response.status == 200) {
          this.store.current.popup = ["setup-pdf-pivot"];
        }
        else {
          this.$emit('showMessage', response);
          this.formErrors = errorHandler.process(response.data, "");
        }
      }
      this.pending = false;
    },
    empty(e) {
      const val = this.pivotConfig[e.target.name];
      if(this.defaultData[e.target.name] && (val == "" || val == this.defaultData[e.target.name][1])) {
        this.labelData[e.target.name] = {text: this.defaultData[e.target.name][0], active: true};
        this.pivotConfig[e.target.name] = "";
      }
    },
    reset(e) {
      const val = this.pivotConfig[e.target.name];
      if(val == "") {
        this.labelData[e.target.name] = {text: '', active: false};
        this.pivotConfig[e.target.name] = this.defaultData[e.target.name][1];
      }
    },
    clear() {
      for(const[key, val] of Object.entries(this.defaultData)) {
        this.labelData[key] = {text: '', active: false};
        this.pivotConfig[key] = val[1];
        this.formErrors = {};
      }
    },
    toggle(e) {
      this.pivotConfig[e.target.name] = !this.pivotConfig[e.target.name];
    },
    close() {
      this.store.current.popup = ["setup-pdf-pivot"];
    },
    async fetch() {
      this.pivotConfigs = await listingService.pivotConfigs();
    },
    checkName() {
      this.exists = false;
      this.pivotConfig.pivotConfigId = 0;
      try {
        for(const [key,value] of Object.entries(this.pivotConfigs)) {
          if(value.toLowerCase() == this.pivotConfig.name.toLowerCase()) {
            this.exists = true;
            this.pivotConfig.pivotConfigId = key;
          }
        }
      }
      catch {}
    }
  },
  components: {
    Multiselect
  }
}
</script>
