<template>
  <div class="lead">
    <h4>
      Ons continu groeiende platform heeft de afgelopen 30 dagen
      veel betekend voor de workflow van bedrijven binnen de bouw en dienstverlening
    </h4>

    <div class="in">
      <div class="tab tab5">
        <span>12K</span>
      </div>
      <div class="tab tab30">
        professionals gebruikten onze software
      </div>

      <div class="tab tab5">
        <span>800</span>
      </div>
      <div class="tab tab30">
        documenten werden verwerkt door ons systeem
      </div>

      <div class="tab tab5">
        <span>3.3K</span>
      </div>
      <div class="tab tab30">
        aantekeningen werden toegevoegd aan documenten
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {

    };
  },
  computed: {
    
  },
  components: { 
    
  }
};
</script>

<style scoped>
.lead {
  background: rgba(255,255,255,0.3);
  color: #fff;
}

.in {
  width: 1000px;
  margin: 0 auto;
  display: table;
  padding: 0;
  padding-top: 50px;
  padding-bottom: 75px;
}

.tab {
  display: table-cell;
  vertical-align: middle;
}

.tab5 {
  width: 10%;
}

.tab30 {
  width: 23.33%;
  font-size: 16px;
  padding-left: 15px;
  padding-right: 50px;
  font-style: italic;
}

span {
  display: block;
  font-size: 20px;
  font-weight: bold;
  background-color: rgba(0,0,0,0.3);
  text-align: center;
  line-height: 100px;
  border-radius: 100px;
}

h4 {
  display: block;
  font-size: 24px;
  line-height: 1.3em;
  text-align: center;
  font-style: italic;
  font-weight: normal;
  width: 900px;
  margin: 0 auto;
  margin-top: 75px;
}
</style>