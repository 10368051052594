<template>
  <div class="form" v-if="store.current.form === 'list-companies'">
    <div class="adder" @click="add()">Toevoegen</div>
    <SearchField @search="search" />
    <h1>Overzicht accounts</h1>
    <div class="loader" v-if="!init">
      <div>Even geduld, content wordt geladen...</div>
    </div>
    <div class="nodata" v-else-if="companies.length === 0">
      Er zijn geen resultaten die hier kunnen worden getoond.
      Wilt u misschien iets <a href="#" @click="add()">toevoegen</a>?
    </div>
    <div v-else>
      <table class="listing">
        <tbody>
          <tr>
            <th title="Bedrijfsnaam" style="width:30%"><span>Bedrijfsnaam</span></th>
            <th title="Adres" style="width:35%"><span>Locatie</span></th>
            <th title="Telefoonnummer"><span>Telefoonnummer</span></th>
            <th class="opth" style="width:0%"></th>
          </tr>
          <tr v-for="(item, index) in companies" v-bind:key="index" @click="goto(item)">
            <td>{{ item.name }}</td>
            <td>{{ item.location }}</td>
            <td>{{ item.phoneNumber }}</td>
            <td class="opt">
              <div>
                <div>
                  Opties bij regel
                  <a href="#" @click="edit(item)">Pas aan</a>
                  <div class="clear"></div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <Pagination @change-offset="changeOffset" :query-args="queryArgs"></Pagination>
    </div>
  </div>
  <PopupAddCompany 
    @fetch-listing="fetch"
    @show-message="showMessage" 
  />
</template>

<script>
import companyService from '@/services/company.service'
import PopupAddCompany from '@/popups/AddCompany.vue'
import Pagination from '@/components/Pagination.vue'
import SearchField from '@/components/SearchField.vue'

export default {
  emits: ['showMessage'],
  data() {
    return {
      companies: [],
      queryArgs: {
        orderType: "desc",
        orderBy: "dateRegistered",
        query: "",
        offset: 0,
        pageSize: 5,
        count: 0
      },
      init: false,
      eventHandlers: {
        'fetch-user-listing': this.fetch
      }
    };
  },
  computed: {
    store() {
      return this.$parent.store;
    },
    currentForm() {
      return this.store.current.form;
    }
  },
  watch: {
    currentForm(value) {
      if(value == "list-companies") {
        document.title = "Overzicht bedrijven / gebruikers | " + this.store.appName;
        this.queryArgs.offset = 0;
        this.queryArgs.query = "";
        this.fetch();
      }
    }
  },
  methods: {
    add() {
      this.store.current.data.company = null;
      this.store.current.popup = ["add-company"];
    },
    async fetch() {
      this.init = false;
      this.companies = [];
      const response = await companyService.listing(this.queryArgs);
      if(response && response.status == 200) {
        this.companies = response.data.results;
        this.queryArgs.count = response.data.count;
      }
      else {
        this.$emit('showMessage', response);
      }
      this.init = true;
    },
    changeOffset(offset) {
      this.queryArgs.offset = offset;
      this.fetch();
    },
    search(query) {
      if(this.queryArgs.query != query) {
        this.queryArgs.query = query;
        this.queryArgs.offset = 0;
        this.fetch();
      }
    },
    showMessage(e) {
      this.$emit("showMessage", e);
    },
    goto(item) {
      this.store.current.data.company = {...item};
      this.store.current.form = "view-company";
    }
  },
  components: {
    PopupAddCompany,
    Pagination,
    SearchField
  }
}
</script>
