<template>
  <div class="lead">
    <div class="in">
      <div class="lt lt50">
        <h2>
          Databeveiliging zoals u mag verwachten van een
          gespecialiseerd bedrijf
        </h2>
        <p>
          Natuurlijk kunt u erop vertrouwen dat uw gegevens 
          veilig zijn op onze servers. Wij geven u graag 
          inzicht in hoe we hiervoor zorgen.
        </p>
      </div>
      <div class="clear"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {

    };
  },
  computed: {
    
  },
  components: { 
    
  }
};
</script>

<style scoped>
h2 {
  font-size: 24px;
  font-style: normal;
  font-weight: bold;
  color: #fff;
}

h3 {
  font-size: 20px;
  color: #fff;
  margin: 0; padding: 0;
  padding-bottom: 10px;
}

p {
  font-size: 14px;
}

span {
  font-size: 14px;
  line-height: 1.5em;
  border-left: 5px solid #fff;
  background-color: rgba(255,255,255,0.1);
  padding: 5px 15px;
  display: block;
  border-radius: 10px;
  margin-top: 20px;
}

.lead {
  color: #fff;

  .in  {
    width: 1050px;
    margin: 0 auto;
    padding: 75px 0;
  }
}

.lt50 {
  width: 50%;
  float: left;
  box-sizing: border-box;
  padding: 0 25px;
}
</style>