<script setup>

</script>

<template>
  <div class="container">
    <router-view />
  </div>
</template>

<style>
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');
</style>

<script>
import EventBus from '@/common/EventBus'

export default {
  methods: {
    
  },
  mounted() {
    EventBus.on("logout", () => {
      this.logOut();
    });
    setTimeout(() => this.scrollFix(this.$route.hash), 1);
  },
  beforeDestroy() {
    EventBus.remove("logout");
  },
  computed: {
    routeHash() {
      return this.$route.hash;
    }
  },
  watch: {
    routeHash(hash) {
      var self = this;
      setTimeout(() => self.scrollFix(hash), 300);
    }
  },
  methods: {
    scrollFix: function(hash) {
      const el = document.getElementById(hash.slice(1));
      if(el) {
        const ps = document.getElementsByClassName('ps')[0];
        ps.scrollTo(0, el.offsetTop + el.offsetParent.offsetTop - 125);
      }
    }
  }
}
</script>