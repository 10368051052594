<template>
  <div class="lead">
    <div class="in">
      <span>
        <strong>Ons adres</strong><br/>
        Sprookjesbosch 44<br/>
        5629JZ EINDHOVEN<br/>
        Nederland<br/><br/>
        KvK: ???<br/>
        Btw: ???
      </span>
    </div>
    <div id="gmap"></div>
    <div class="clear"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {

    };
  },
  computed: {
    
  },
  mounted() {
    this.renderMap('51.487144,5.483998');
  },
  methods: {
    async renderMap(latLng) {
      const position = { lat: parseFloat(latLng.split(',')[0]), lng: parseFloat(latLng.split(',')[1]) };
      const { Map } = await google.maps.importLibrary("maps");
      const { AdvancedMarkerElement } = await google.maps.importLibrary("marker");

      const map = new Map(document.getElementById("gmap"), {
        zoom: 9,
        center: position,
        mapId: "proj-loc",
        options: {
          scrollwheel: false,
          navigationControl: false,
          mapTypeControl: false,
          scaleControl: false,
          streetViewControl: false,
          mapTypeId: google.maps.MapTypeId.ROADMAP
        }
      });

      const marker = new AdvancedMarkerElement({
        map: map,
        position: position,
        title: "Foundation",
      });
    }
  },
  components: { 
    
  }
};
</script>

<style scoped>
#gmap {
  position: absolute;
  width: 600px;
  left: 50%;
  margin-left: -100px;
  height: 300px;

  border-radius: 50px;
  border-left: 10px solid rgba(255,255,255,0.8);
  border-right: 10px solid rgba(255,255,255,0.8);
  margin-top: -10px;
  margin-bottom: -10px;
  box-sizing: border-box;
  overflow: hidden;
}

.lead {
  background: rgba(255,255,255,0.9);
  position: relative;
}

.in {
  width: 50%;
  height: 280px;
  float: left;
  padding: 50px 0;
  padding-bottom: 0;
  box-sizing: border-box;
}

span {
  display: block;
  float: right;
  width: 500px;
  font-size: 16px;
  line-height: 1.5em;
  font-weight: normal;
}
</style>