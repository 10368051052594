<template>
  <transition name="fade">
    <div class="popup" v-if="store.current.popup.includes('revise-pdf-document')">
      <div>
        <perfect-scrollbar style="height:100%">
          <form @submit.prevent="revise">
            <div class="closer" @click="close()">Scherm sluiten</div>
            <h1>{{ title }}</h1>

            <div v-if="init && store.uploadProgress == 0">
              <p>
                Kies een bestand vanaf uw computer om als revisie voor dit document te uploaden. Deze actie heeft de volgende gevolgen:
                <ul>
                  <li>
                    Gebruikers die toegang hebben tot de huidige versie van het document, zullen ook
                    toestemming krijgen voor het bekijken van de nieuwe versie. Zij ontvangen automatisch een bericht
                    wanneer de nieuwe versie verwerkt is in het systeem;
                  </li>
                  <li>
                    Indien de nieuwe versie in grote lijnen overeenkomt met de vorige versie (paginanamen, -afmetingen, -labels, etc.), zal
                    voor gebruikers de optie worden gegeven om bijdragen, opmerkingen en eventuele eigen metingen over te zetten naar de
                    nieuwe versie van het document;
                  </li>
                  <li>
                    Wanneer aan het voorgaande criterium wordt voldaan, kunnen ook uw eigen bijdragen opnieuw worden ingelezen in de 
                    nieuwe versie van het document. Dit kunt u doen vanuit de viewer in het menu "Bijdragen van gebruikers"
                  </li>
                </ul>
              </p>

              <FileUpload 
                :maxSize="500000" 
                :file="new Object()"
                accept="pdf"
                @show-message="$emit('showMessage')"
                @file-changed="validateUpload"
              />
              <div v-if="fileError" class="error-field">Kies eerst een bestand om te uploaden.</div>

              <input type="submit" :value="submitText" :class="{locked:isLocked}">
            </div>
            <div v-else-if="store.uploadProgress > 0">
              <div class="loader" style="margin-bottom:2vh">
                <div>Even geduld, uw bestand wordt geüpload ({{ store.uploadProgress }}% voltooid)...</div>
              </div>
              <div v-if="error.length > 0" class="error-field">{{ error }}</div>
              <input class="submit red" @click="cancel" value="Upload annuleren">
            </div>
            <div v-else class="loader">
              <div>Even geduld, content wordt geladen...</div>
            </div>
          </form>
        </perfect-scrollbar>
      </div>
    </div>
  </transition>
</template>

<script>
import documentService from '@/services/app/document.service'
import errorHandler from '@/common/ErrorHandler'

import Multiselect from '@vueform/multiselect'
import FileUpload from '@/components/FileUpload'

export default {
  emits: ['showMessage', 'updateParent'],
  data() {
    return {
      document: {
        projectId: 0,
        documentId: 0,
        integrationId: 0
      },
      fileError: false,
      defaultData: {
        //name: ['Naam document', 'Welke naam moet worden gebruikt voor dit document?'],
        integrationId: ['Bronlocatie document', 0]
      },
      labelData: {},
      formErrors: {},
      title: "",
      submitText: "",
      error: "",
      init: false,
      pending: false
    };
  },
  computed: {
    store() {
      return this.$parent.store;
    },
    currentPopup() {
      return this.store.current.popup[this.store.current.popup.length-1];
    },
    isLocked() {
      if(this.pending || !this.init) return true;
      else return false;
    }
  },
  watch: {
    async currentPopup(value) {
      if(value == "revise-pdf-document") {
        this.init = false;
        this.store.fileToUpload = null;
        this.store.uploadProgress = 0;
        this.clear();

        this.title = "Nieuwe versie uploaden";
        this.submitText = "Bestand uploaden";
        this.document.projectId = this.store.current.data.pdf.project.projectId;
        this.document.documentId = this.store.current.data.pdf.document.documentId;

        this.init = true;
        this.pending = false;
      }
    },
    document(listing) {
      for(const[key, value] of Object.entries(listing)) {
        if(!this.defaultData[key]) continue;
        if(value && value.toString().length > 0) {
          this.labelData[key] = {text: this.defaultData[key][0], active: true};
        }
        else {
          this.labelData[key] = {text: '', active: false};
          this.document[key] = this.defaultData[key][1];
        }
      }
    }
  },
  methods: {
    async revise() {
      if(this.pending) return;
      this.pending = true;
      this.fileError = false;

      if(this.store.fileToUpload == null) {
        this.fileError = true;
        this.pending = false;
        return;
      }
      const response = await documentService.revise(this.document, this.defaultData, this.store.fileToUpload);
      if(response) {
        if(response.status == 200) {
          this.store.current.data.pdf.transaction = { ...response.data };
          this.close();
          this.store.current.popup = ["approve-pdf-transaction"];
        }
        else {
          this.$emit('showMessage', response);
          if(response.data != null && response.data.message != null) {
            this.error = response.data.message;
          }
          this.formErrors = errorHandler.process(response.data, "");
        }
      }
      this.pending = false;
    },
    cancel() {
      this.store.fileToUpload = null;
      this.store.uploadProgress = 0;
    },
    validateUpload(file) {
      this.store.fileToUpload = file.data;
      this.isInvalid = !file.isUploaded;
    },
    empty(e) {
      const val = this.document[e.target.name];
      if(this.defaultData[e.target.name] && (val == "" || val == this.defaultData[e.target.name][1])) {
        this.labelData[e.target.name] = {text: this.defaultData[e.target.name][0], active: true};
        this.document[e.target.name] = "";
      }
    },
    reset(e) {
      const val = this.document[e.target.name];
      if(val == "") {
        this.labelData[e.target.name] = {text: '', active: false};
        this.document[e.target.name] = this.defaultData[e.target.name][1];
      }
    },
    clear() {
      for(const[key, val] of Object.entries(this.defaultData)) {
        this.labelData[key] = {text: '', active: false};
        this.document[key] = val[1];
        this.formErrors = {};
      }
    },
    close() {
      this.store.current.popup.splice(
        this.store.current.popup.indexOf('revise-pdf-document'), 
        1
      );
      this.store.current.data.pdf.document = null;
      this.$emit('updateParent');
    }
  },
  components: {
    Multiselect,
    FileUpload
  }
}
</script>
