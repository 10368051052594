<template>
  <transition name="fade">
    <div class="popup" v-if="store.current.popup.includes('add-pdf-toolbox-item')">
      <div>
        <perfect-scrollbar style="height:100%">
          <form @submit.prevent="add">
            <div class="closer" @click="close()">Scherm sluiten</div>
            <h1>{{ title }}</h1>

            <template v-if="init">
              <div style="float:right;width:40%">
                <h2>Locatie voor tool</h2>
                <label>
                  Hoofdstuk
                  <Multiselect 
                    name="toolboxChapterId"
                    v-model="toolboxItem.toolboxChapterId"
                    :options="toolboxChapters"
                    label="name"
                    placeholder="Maak een keuze..."
                    noOptionsText="Geen opties gevonden." />
                </label>
                <div v-if="formErrors.toolboxChapterId" class="error-field">{{ defaultData.toolboxChapterId[0] + " " + formErrors.toolboxChapterId }}!</div>
              
                <div v-if="labelData.name" class="before-input" :class="{active:labelData.name.active}">{{ labelData.name.text }}</div>
                <input type="text" 
                  name="name" 
                  v-model="toolboxItem.name"
                  :class="{hasLabel:labelData.name.active}" 
                  @focusin="empty($event)" 
                  @focusout="reset($event)">
                <div v-if="formErrors.name" class="error-field">{{ defaultData.name[0] + " " + formErrors.name }}!</div>

                <div v-if="labelData.code" class="before-input" :class="{active:labelData.code.active}">{{ labelData.code.text }}</div>
                <input type="number"
                  name="code"
                  min="0"
                  max="999"
                  step="1"
                  v-model="toolboxItem.code"
                  :class="{hasLabel:labelData.code.active}" 
                  @focusin="empty($event)" 
                  @focusout="reset($event)">
                <div v-if="formErrors.code" class="error-field">{{ defaultData.code[0] + " " + formErrors.code }}!</div>
              </div>
              
              <div style="float:left;width:55%">
                <h2>Specifieke instellingen</h2>
                <template v-for="(input, key) in contributionTemplate.inputs">
                  <template v-if="!input['internal'] && input.name != 'sj' && input.name != 'mc'">

                    <template v-if="input['type'] === 'head'">
                      <h3 style="clear:both">{{ input.title }}</h3>
                    </template>

                    <template v-if="input['type'] === 'text' || input['type'] === 'number' || input['type'] === 'float'">
                      <div :key="key">
                        <div v-if="input.labelShort" class="before-input" :class="{active:labelData[input.name].active}">{{ input.labelShort }}</div>
                        <input type="text"
                          :name="input.name"
                          v-model="toolboxItem.values[input.name]"
                          :class="{hasLabel:labelData[input.name].active}" 
                          @focusin="empty($event)" 
                          @focusout="reset($event)">
                        <div v-if="formErrors[input.name]" class="error-field">{{ input.labelShort + " " + formErrors[input.name] }}!</div>
                      </div>
                    </template>

                    <template v-if="input['type'] === 'textarea'">
                      <div :key="key">
                        <div v-if="input.labelShort" class="before-input" :class="{active:labelData[input.name].active}">{{ input.labelShort }}</div>
                        <textarea
                          :name="input.name"
                          v-model="toolboxItem.values[input.name]"
                          :class="{hasLabel:labelData[input.name].active}" 
                          @focusin="empty($event)" 
                          @focusout="reset($event)" />
                        <div v-if="formErrors[input.name]" class="error-field">{{ input.labelShort + " " + formErrors[input.name] }}!</div>
                      </div>
                    </template>
                    
                    <template v-if="input['type'] === 'select'">
                      <div :key="key">
                        <label>
                          {{ input.label }}
                          <Multiselect 
                            :name="input.name"
                            v-model="toolboxItem.values[input.name]"
                            :options="input.options"
                            placeholder="Maak een keuze..."
                            noOptionsText="Geen opties gevonden." />
                        </label>
                        <div v-if="formErrors[input.name]" class="error-field">{{ input.labelShort + " " + formErrors[input.name] }}!</div>
                      </div>
                    </template>

                    <template v-if="input['type'] === 'rgb'">
                      <div class="color-choice" style="float:left;width:65%">
                        <span>{{ input.labelShort }}</span>
                        <template v-for="(rgb, name) in rgbColors">
                          <div :title="name" :style="{backgroundColor: '#'+rgb}" :class="{sel:(toolboxItem.values[input.name] == rgb)}" @click="setColor(input.name, rgb)"></div>
                        </template>
                        <div class="cl"></div>
                      </div>
                    </template>

                    <template v-if="input['type'] === 'range'">
                      <div :key="key" :style="(input.float)?'float:right;width:30%':''">
                        <div v-if="input.labelShort" class="before-input" :class="{active:labelData[input.name].active}">{{ input.labelShort }}</div>
                        <input type="number"
                          :name="input.name"
                          :min="input.min"
                          :max="input.max"
                          :step="input.step"
                          v-model="toolboxItem.values[input.name]"
                          :class="{hasLabel:labelData[input.name].active}" 
                          @focusin="empty($event)" 
                          @focusout="reset($event)">
                        <div v-if="formErrors[input.name]" class="error-field">{{ input.labelShort + " " + formErrors[input.name] }}!</div>
                      </div>
                    </template>

                    <template v-if="input['type'] === 'checkbox'">
                      <div :key="key">
                        <label>
                          {{ input.label }}
                          <input type="checkbox" 
                            v-model="toolboxItem.values[input.name]" 
                            @click="toggle($event)">
                          <span :class="{active:toolboxItem[input.name]}"></span>
                        </label>
                        <div v-if="formErrors[input.name]" class="error-field">{{ input.labelShort + " " + formErrors[input.name] }}!</div>
                      </div>
                    </template>

                    <template v-if="input['type'] === 'icon'">
                      <div class="ico-choice" ref="icoChoice">
                        <span>Kies een icoon voor het item</span>
                        <template v-for="icon in icons">
                          <img :class="(toolboxItem.values.ico == icon) ? 'sel' : ''" :src="'img/tbx/'+icon+'.png'" :val="icon" @click="setIcon(icon)">
                        </template>
                      </div>
                    </template>
                  </template>
                </template>
              </div>

              <template v-if="toolboxItem.toolboxItemId">  
                <input type="submit" :value="submitText" :class="{locked:isLocked}" style="width:48%">
                <div class="submit red" @click="unlinkItem" style="width:48%;margin-left:4%">Item verwijderen</div>
              </template>
              <template v-else>
                <input type="submit" :value="submitText" :class="{locked:isLocked}">
              </template>
            </template>
            <div v-else class="loader">
              <div>Even geduld, content wordt geladen...</div>
            </div>
          </form>
        </perfect-scrollbar>
      </div>
    </div>
  </transition>
</template>

<script>
import toolboxService from '@/services/app/toolbox.service'
import errorHandler from '@/common/ErrorHandler'
import Multiselect from '@vueform/multiselect/src/Multiselect'

export default {
  emits: ['showMessage', 'updateParent'],
  data() {
    return {
      toolboxItem: {
        toolboxChapterId: 0,
        code: ""
      },
      contribution: null,
      contributionTemplate: null,
      defaultDataDef: {
        toolboxChapterId: ['Hoofdstuk', "In welk hoofdstuk moet de tool worden opgenomen?"],
        code: ['Code', "Welke code moet dit item krijgen?"],
        name: ['Onderwerp', "Welk onderwerp moet dit item krijgen?"]
      },
      icons: [
        'bao', 'bar', 'bri', 'cne', 'dem', 'exc', 'flo', 'ham', 'pai', 'pip', 'sho', 'spa', 'ste',
        'mea', 'lnd', 'mix', 'rei', 'wlp', 'flr', 'wal', 'win', 'doo', 'sta',
        'rof', 'lam', 'fla', 'wat', 'ven', 'wod', 'til', 'the', 'smh', 'mir',
        'ch1', 'ch2', 'ch3', 'ch4', 'ch5'
      ],
      rgbColors: {
        "Indian Red": "cd5c5c",
        "Salmon": "fa8072",
        "Crimson": "dc143c",
        "Red": "ff0000",
        "Fire Brick": "b22222",
        "Dark Red": "8b0000",
        "Pink": "ffc0cb",
        "Hot Pink": "ff69b4",
        "Medium Violet Red": "c71585",
        "Coral": "ff7f50",
        "Orange Red": "ff4500",
        "Dark Orange": "ff8c00",
        "Orange": "ffa500",
        "Gold": "ffd700",
        "Yellow": "ffff00",
        "Lemon Chiffon": "fffacd",
        "Moccasin": "ffe4b5",
        "Khaki": "f0e68c",
        "Dark Khaki": "bdb76b",
        "Lavender": "e6e6fa",
        "Plum": "dda0dd",
        "Violet": "ee82ee",
        "Magenta": "ff00ff",
        "Medium Purple": "9370db",
        "Rebecca Purple": "663399",
        "Dark Magenta": "8b008b",
        "Indigo": "4b0082",
        "Slate Blue": "6a5acd",
        "Dark Slate Blue": "483d8b",
        "Green Yellow": "adff2f",
        "Lawn Green": "7cfc00",
        "Lime Green": "32cd32",
        "Light Green": "90ee90",
        "Medium Sea Green": "3cb371",
        "Green": "008000",
        "Dark Green": "006400",
        "Yellow Green": "9acd32",
        "Olive": "808000",
        "Medium Aquamarine": "66cdaa",
        "Dark Cyan": "008b8b",
        "Teal": "008080",
        "Aqua": "00ffff",
        "Light Cyan": "e0ffff",
        "Pale Turquoise": "afeeee",
        "Aquamarine": "7fffd4",
        "Turquoise": "40e0d0",
        "Cadet Blue": "5f9ea0",
        "Steel Blue": "4682b4",
        "Light Steel Blue": "b0c4de",
        "Powder Blue": "b0e0e6",
        "Sky Blue": "87ceeb",
        "Deep Sky Blue": "00bfff",
        "Dodger Blue": "1e90ff",
        "Royal Blue": "4169e1",
        "Foundation Blue": "516f9d",
        "Blue": "0000ff",
        "Midnight Blue": "191970",
        "Blanched Almond": "ffebcd",
        "Navajo White": "ffdead",
        "Burly Wood": "deb887",
        "Rosy Brown": "bc8f8f",
        "Sandy Brown": "f4a460",
        "Goldenrod": "daa520",
        "Chocolate": "d2691e",
        "Saddle Brown": "8b4513",
        "Brown": "a52a2a",
        "Gainsboro": "dcdcdc",
        "Silver": "c0c0c0",
        "Gray": "808080",
        "Light Slate Gray": "778899",
        "Black": "000000"
      },
      labelData: {},
      formErrors: {},
      title: "",
      submitText: "",
      pending: false,
      init: false,
      exists: false
    };
  },
  computed: {
    store() {
      return this.$parent.store;
    },
    renderStore() {
      return this.$parent.renderStore;
    },
    currentPopup() {
      return this.store.current.popup[this.store.current.popup.length-1];
    },
    isLocked() {
      if(this.pending || !this.init) return true;
      else return false;
    },
    toolboxChapters() {
      var chapters = [];
      this.$parent.renderStore.toolboxChapters.forEach(chapter => {
        chapters.push({
          name: chapter.code + " " + chapter.name,
          value: chapter.toolboxChapterId
        });
      });
      return chapters;
    }
  },
  watch: {
    async currentPopup(value) {
      if(value == "add-pdf-toolbox-item") {
        this.init = false;
        this.contribution = null;
        this.contributionTemplate = null;
        this.clear();
        
        if(this.store.current.data.pdf.toolboxItem != null) {
          this.toolboxItem = { 
            toolboxChapterId: this.store.current.data.pdf.toolboxChapter.toolboxChapterId,
            toolboxItemId: this.store.current.data.pdf.toolboxItem.toolboxItemId,
            contributionTemplateId: this.store.current.data.pdf.toolboxItem.contributionTemplateId,
            init: true
          };
          await this.fetch();
          if(this.toolboxItem.code) this.toolboxItem.code = parseInt(this.toolboxItem.code.split("-")[1]);
          else this.toolboxItem.code = 0;
          
          var contributionTemplateId = this.toolboxItem.contributionTemplateId;
          this.setContributionTemplate(this.renderStore.contributionTemplates[contributionTemplateId], false);

          this.title = "Item aanpassen";
          this.submitText = "Opslaan";
        }
        else if(this.store.current.data.pdf.contribution != null) {
          this.contribution = {...this.store.current.data.pdf.contribution};
          var contributionTemplateId = this.contribution.contributionTemplateId;

          this.toolboxItem = {
            toolboxChapterId: 0,
            contributionTemplateId: contributionTemplateId,
            values: {...this.contribution.values},
            name: this.contribution.values.sj,
            code: (this.contribution.values.mc && this.contribution.values.mc.length > 0) ? this.contribution.values.mc.split('-')[1] : ''
          };
          if(this.contribution.values.mc && this.contribution.values.mc.length > 0) {
            var chapterId = parseInt(this.contribution.values.mc.split('-')[0]);
            Object.entries(this.renderStore.toolboxChapters).forEach(chapter => {
              if(chapter[1].code == chapterId) {
                this.toolboxItem.toolboxChapterId = parseInt(chapter[0])+1;
              }
            })
          }
          delete this.toolboxItem.values.sj;
          delete this.toolboxItem.values.mc;
          this.setContributionTemplate(this.renderStore.contributionTemplates[contributionTemplateId], false);

          this.title = "Item toevoegen";
          this.submitText = "Toevoegen";
        }
        else {
          this.toolboxItem = { 
            toolboxChapterId: this.store.current.data.pdf.toolboxChapter.toolboxChapterId,
            name: "",
            code: ""
          };

          this.title = "Item toevoegen";
          this.submitText = "Toevoegen";
        }
        this.init = true;
      }
    },
    toolboxItem(listing) {
      if(listing.init) return;
      if(!this.defaultData) return;
      for(const[key, value] of Object.entries(listing)) {
        if(!this.defaultData[key]) continue;
        if(value && value.toString().length > 0) {
          this.labelData[key] = {text: this.defaultData[key][0], active: true};
        }
        else {
          this.labelData[key] = {text: '', active: false};
          this.toolboxItem[key] = this.defaultData[key][1];
        }
      }
    }
  },
  methods: {
    async add() {
      if(this.pending) return;
      this.pending = true;
      
      let response = await toolboxService.addItem(this.toolboxItem, this.defaultData);
      if(response) {
        if(response.status == 200) {
          this.$emit("updateParent");
          this.close();
        }
        else {
          this.$emit('showMessage', response);
          this.formErrors = errorHandler.process(response.data, "");
        }
      }
      this.pending = false;
    },
    async fetch() {
      if(this.toolboxItem.toolboxItemId != null) {
        this.toolboxItem = await toolboxService.selectItem(this.toolboxItem);
        this.toolboxItem.values = JSON.parse(this.toolboxItem.values);
      }
    },
    empty(e) {
      const val = this.toolboxItem[e.target.name];
      if(this.defaultData[e.target.name] && (val == "" || val == this.defaultData[e.target.name][1])) {
        this.labelData[e.target.name] = {text: this.defaultData[e.target.name][0], active: true};
        this.toolboxItem[e.target.name] = "";
      }
    },
    reset(e) {
      const val = (this.toolboxItem[e.target.name]) ? this.toolboxItem[e.target.name] : this.toolboxItem.values[e.target.name];
      if((!val || val.length == 0) && this.defaultData[e.target.name][1]) {
        this.labelData[e.target.name] = {text: '', active: false};
        this.toolboxItem[e.target.name] = this.defaultData[e.target.name][1];
      }
    },
    clear() {
      for(const[key, val] of Object.entries(this.defaultDataDef)) {
        this.labelData[key] = {text: '', active: false};
        this.toolboxItem[key] = val[1];
        this.formErrors = {};
      }
    },
    async setIcon(icon) {
      this.toolboxItem.values.ico = icon;
    },
    setContributionTemplate(template, resetValues) {
      this.contributionTemplate = {...template};
      //this.contributionTemplate.inputs = JSON.parse(this.contributionTemplate.inputs);
      try { this.setData(this.contributionTemplate.inputs, this.toolboxItem.values); }
      catch { this.setData(this.contributionTemplate.inputs, this.contribution.values); }
      if(resetValues) {
        this.contribution.isPublic = this.contributionTemplate.public;
        this.setContributionValues(this.contributionTemplate.inputs);
        this.clear();
      }
      this.store.current.data.pdf.contributionTemplate = {...this.contributionTemplate};
    },
    setContributionValues(inputs) {
      this.contribution.values = {};
      inputs.forEach(input => {
        this.contribution.values[input.name] = "";
      });
    },
    setData(inputs, values) {
      this.labelData = {};
      this.defaultData = {...this.defaultDataDef};
      Object.entries(this.defaultData).forEach(def => {
        this.labelData[def[0]] = {
          active: false,
          text: def[1][0]
        };

        if(this.toolboxItem[def[0]] != def[1][1]) {
          this.labelData[def[0]].active = true;
        }
        if(def[0] == "code") {
          this.labelData[def[0]].active = true;
        }
      });
      inputs.forEach(input => {
        if(input.name == null) return;
        this.defaultData[input.name] = [
          input.labelShort,
          input.label
        ];
        this.labelData[input.name] = {
          active: false,
          text: input.labelShort
        }; 

        if(values != null) {
          if(values[input.name] == input.label && (input.type == "text" || input.type == "textarea")) {
            this.labelData[input.name].active = false;
          }
          else if(values[input.name] != null && String(values[input.name]).length > 0) {
            this.labelData[input.name].active = true;
          }
          else {
            values[input.name] = this.defaultData[input.name][1];
            this.labelData[input.name].active = false;
          }
        }
      });
    },
    setColor(field, rgb) {
      this.toolboxItem.values[field] = rgb;
    },
    unlinkItem() {
      this.store.current.popup = ['unlink-pdf-toolbox-item'];
    },
    close() {
      this.store.current.popup = [];
      this.store.current.data.pdf.toolboxItem = null;
      this.store.current.data.pdf.contributionTemplate = null;
    },
  },
  components: {
    Multiselect
  }
}
</script>
