<template>
  <transition name="fade">
    <div class="popup" v-if="store.current.popup.includes('add-pdf-project')">
      <div>
        <perfect-scrollbar style="height:100%">
          <form @submit.prevent="add">
            <div class="closer" @click="close()">Scherm sluiten</div>
            <h1>{{ title }}</h1>
            <h2>Algemene gegevens</h2>

            <div v-if="init">
              <div v-if="labelData.name" class="before-input" :class="{active:labelData.name.active}">{{ labelData.name.text }}</div>
              <input type="text" 
                name="name" 
                v-model="project.name"
                :class="{hasLabel:labelData.name.active}" 
                @focusin="empty($event)" 
                @focusout="reset($event)">
              <div v-if="formErrors.name" class="error-field">{{ defaultData.name[0] + " " + formErrors.name }}!</div>

              <label>
                {{ defaultData.projectTypeId[0] }}
                <Multiselect 
                  v-model="project.projectTypeId"
                  :options="projectTypes" 
                  placeholder="Maak een keuze..."
                  noOptionsText="Geen opties gevonden.">
                </Multiselect>
              </label>
              <div v-if="formErrors.projectTypeId" class="error-field">{{ defaultData.projectTypeId[0] + " " + formErrors.projectTypeId }}!</div>

              <!--<label>
                {{ defaultData.integrationId[0] }}
                <Multiselect 
                  v-model="project.integrationId"
                  :options="integrations" 
                  placeholder="Maak een keuze..."
                  noOptionsText="Geen opties gevonden.">
                </Multiselect>
              </label>
              <div v-if="formErrors.integration" class="error-field">{{ defaultData.integrationId[0] + " " + formErrors.integration }}!</div>-->

              <h2 style="margin-top:5vh">Projectlocatie</h2>
              <p>
                Leg de projectlocatie vast door hieronder in de kaart de markering te verplaatsen naar de juiste locatie. De locatie zal
                niet aan een straatnaam en/of huisnummer worden gekoppeld, maar in de verdere verwerking ook worden getoond volgens de nu
                gekozen coördinaten.
              </p>
              <input type="text" 
                id="gmp-adi"
                name="address"
                placeholder="Zoeken naar adres..."
                :class="{hasLabel:labelData.name.active}" 
                @focusin="empty($event)" 
                @focusout="reset($event)"
                @keydown.enter.prevent="">
              <div class="gmap" id="gmap-add-project" style="width:100%;height:300px"></div>

              <input type="submit" :value="submitText" :class="{locked:isLocked}">
            </div>
            <div v-else class="loader">
              <div>Even geduld, content wordt geladen...</div>
            </div>
          </form>
        </perfect-scrollbar>
      </div>
    </div>
  </transition>
</template>

<script>
import projectService from '@/services/app/project.service'
import listingService from '@/services/listing.service'
import errorHandler from '@/common/ErrorHandler'

import Multiselect from '@vueform/multiselect'

export default {
  emits: ['showMessage', 'fetchListing'],
  data() {
    return {
      project: {
        projectId: 0,
        name: '',
        projectTypeId: 0,
        integrationId: 0
      },
      gmapMarker: null,
      projectTypes: [],
      defaultData: {
        name: ['Naam project', 'Welke naam moet worden gebruikt voor dit project?'],
        projectTypeId: ['Type', 0],
        integrationId: ['Integratie', 0]
      },
      labelData: {},
      formErrors: {},
      title: "",
      submitText: "",
      init: false,
      pending: false
    };
  },
  computed: {
    store() {
      return this.$parent.store;
    },
    currentPopup() {
      return this.store.current.popup[this.store.current.popup.length-1];
    },
    isLocked() {
      if(this.pending || !this.init) return true;
      else return false;
    }
  },
  watch: {
    async currentPopup(value) {
      if(value == "add-pdf-project") {
        this.init = false;
        this.clear();

        if(this.store.current.data.pdf.project) {
          this.title = "Project wijzigen";
          this.submitText = "Wijzigingen opslaan";
          this.project.projectId = this.store.current.data.pdf.project.projectId;
        }
        else {
          this.title = "Project toevoegen";
          this.submitText = "Record aanmaken";
          this.project.projectId = null;
        }
        
        await this.fetch();
        this.init = true;
        this.pending = false;
        this.renderMap(this.project.latLng);
      }
    },
    project(listing) {
      for(const[key, value] of Object.entries(listing)) {
        if(!this.defaultData[key]) continue;
        if(value || value.toString().length > 0) {
          this.labelData[key] = {text: this.defaultData[key][0], active: true};
        }
        else {
          this.labelData[key] = {text: '', active: false};
          this.project[key] = this.defaultData[key][1];
        }
      }
    }
  },
  methods: {
    async add() {
      if(this.pending) return;
      this.pending = true;
      let response = null;

      var latLng = this.gmapMarker.getPosition();
      this.project.latLng = latLng.lat() + "," + latLng.lng();

      if(!this.project.projectId) {
        response = await projectService.add(this.project, this.defaultData);
      }
      else {
        response = await projectService.update(this.project, this.defaultData);
      }

      if(response) {
        if(response.status == 200) {
          this.close();
        }
        else {
          this.$emit('showMessage', response);
          this.formErrors = errorHandler.process(response.data, "");
        }
      }
      this.pending = false;
    },
    empty(e) {
      const val = this.project[e.target.name];
      if(this.defaultData[e.target.name] && (val == "" || val == this.defaultData[e.target.name][1])) {
        this.labelData[e.target.name] = {text: this.defaultData[e.target.name][0], active: true};
        this.project[e.target.name] = "";
      }
    },
    reset(e) {
      const val = this.project[e.target.name];
      if(val == "") {
        this.labelData[e.target.name] = {text: '', active: false};
        this.project[e.target.name] = this.defaultData[e.target.name][1];
      }
    },
    clear() {
      for(const[key, val] of Object.entries(this.defaultData)) {
        this.labelData[key] = {text: '', active: false};
        this.project[key] = val[1];
        this.formErrors = {};
      }
    },
    close() {
      this.store.current.popup.splice(
        this.store.current.popup.indexOf('add-pdf-project'), 
        1
      );
      this.store.current.data.pdf.project = null;
      this.$emit('fetchListing');
    },
    async fetch() {
      if(this.project.projectId) {
        this.project = await projectService.select(this.project);
      }
      this.fetchListings();
    },
    async fetchListings() {
      this.projectTypes = await listingService.projectTypes();
      this.integrations = await listingService.integrations();
    },
    async renderMap(latLng) {
      if(latLng == null) latLng = "52.129490,5.205140";
      const position = { lat: parseFloat(latLng.split(',')[0]), lng: parseFloat(latLng.split(',')[1]) };
      const { Map } = await google.maps.importLibrary("maps");

      const map = new Map(document.getElementById("gmap-add-project"), {
        zoom: 7,
        center: position,
        mapId: "DEMO_MAP_ID",
      });

      document.getElementById("gmap-add-project").addEventListener('DOMMouseScroll',function(event){
        event.stopPropagation();
      },false);
      document.getElementById("gmap-add-project").addEventListener('mousewheel',function(event){
        event.stopPropagation();
      },false);

      this.gmapMarker = new google.maps.Marker({
        map: map,
        draggable: true,
        animation: google.maps.Animation.DROP,
        position: position,
      });
      this.gmapMarker.addListener("gmp-click", this.toggleBounce);

      const defaultBounds = {
        north: position.lat + 0.1,
        south: position.lat - 0.1,
        east: position.lng + 0.1,
        west: position.lng - 0.1,
      };

      const input = document.getElementById("gmp-adi");
      const options = {
        bounds: defaultBounds,
        componentRestrictions: { country: "nl" },
        fields: ["address_components", "geometry", "icon", "name"],
        strictBounds: false,
      };
      const autocomplete = new google.maps.places.Autocomplete(input, options);
      autocomplete.addListener("place_changed", () => {
        this.gmapMarker.setVisible(false);

        const place = autocomplete.getPlace();

        if (!place.geometry || !place.geometry.location) {
          // User entered the name of a Place that was not suggested and
          // pressed the Enter key, or the Place Details request failed.
          window.alert("No details available for input: '" + place.name + "'");
          return;
        }

        // If the place has a geometry, then present it on a map.
        if (place.geometry.viewport) {
          map.fitBounds(place.geometry.viewport);
        } else {
          map.setCenter(place.geometry.location);
          map.setZoom(17);
        }

        this.gmapMarker.setPosition(place.geometry.location);
        this.gmapMarker.setVisible(true);
      });
    },
    toggleBounce() {
      if (this.gmapMarker.getAnimation() !== null) {
        this.gmapMarker.setAnimation(null);
      } else {
        this.gmapMarker.setAnimation(google.maps.Animation.BOUNCE);
      }
    }
  },
  components: {
    Multiselect
  }
}
</script>
