<template>
  <div class="lead">
    <span>
      Foundation is een initiatief van Digitable, uw vertrouwde partner voor 
      het digitaal werken op bouwtekeningen in Bluebeam Revu
    </span>

    <div class="ln">
      <img src="img/logo-512.png">
    </div>
    <div class="clear"></div>

    <div class="in">
      <div class="tab tab100">
        <p>
          Digitable is al ruim 10 jaar actief als dienstverlener binnen de
          bouwsector. We begeleiden (bouw)bedrijven bij het digitaliseren
          van hun bedrijf en implementeren een totaalconcept waarin 
          passende hardware, software en educatie uw bedrijf stap voor stap
          verder helpen. 
        </p>
        <p>
          Bij Digitable begint digitaal werken bij het softwarepakket Bluebeam
          Revu. Met dit krachtige pakket verwerkt u bouwtekeningen binnen een
          handomdraai tot een sluitende kostencalculatie. U kunt aantekeningen 
          aanmaken waar nodig, waardoor altijd duidelijk blijft waarmee u 
          heeft gerekend. Foundation kan deze gegevens perfect inlezen, waardoor
          ze kunnen worden gedeeld met elke geïnteresseerde partij.
        </p>
      </div>
      <div class="button">Meer informatie over Digitable</div>
      <div class="clear"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {

    };
  },
  computed: {
    
  },
  components: { 
    
  }
};
</script>

<style scoped>
p {
  font-size: 16px;
  line-height: 1.5em;
}

.lead {
  background: #fff;
  position: relative;
  text-align: center;
}

.lead > span {
  display: block;
  font-size: 24px;
  line-height: 1.3em;
  font-style: italic;
  font-weight: normal;
  width: 900px;
  margin: 0 auto;
  margin-top: 75px;
  margin-bottom: 25px;
}

.tab span {
  display: block;
  font-size: 16px;
  line-height: 1.3em;
  font-weight: normal;
}

.in {
  width: 1050px;
  margin: 0 auto;
  padding: 0;
  padding-top: 25px;
  padding-bottom: 75px;
}

.tab100 {
  width: 100%;
  padding: 0 25px;
  position: relative;
  box-sizing: border-box;
}

.button {
  float: none !important;
  background-color: rgb(81, 111, 157) !important;
  color: #fff !important;
  opacity: 0.7;
  width: 300px;
  margin: 0 auto;
}

.button:hover {
  opacity: 0.9;
}
</style>