<template>
  <transition name="fade">
    <div class="popup small" v-if="store.current.popup.includes('unlink-pdf-answer')">
      <div>
        <perfect-scrollbar style="height:100%">
          <form @submit.prevent="unlink">
            <div class="closer" @click="close()">Terug naar vorig scherm</div>
            <h1>{{ title }}</h1>
            <div v-if="init">
              <p>
                U staat op het punt om uw opmerking te verwijderen.
                Deze actie kan niet ongedaan worden gemaakt. Wilt u doorgaan?
              </p>
              <input type="submit" :value="submitText" class="red" :class="{locked:isLocked}">
            </div>
            <div v-else class="loader">
              <div>Even geduld, content wordt geladen...</div>
            </div>
          </form>
        </perfect-scrollbar>
      </div>
    </div>
  </transition>
</template>

<script>
import answerService from '@/services/app/answer.service'
import { formatContribution } from '@/functions/FormatContribution.js'

export default {
  emits: ['showMessage'],
  props: {
    getData: Object
  },
  data() {
    return {
      answer: {},
      title: "",
      submitText: "",
      init: false,
      pending: false
    };
  },
  computed: {
    renderStore() {
      return this.$parent.renderStore;
    },
    store() {
      return this.$parent.store;
    },
    currentPopup() {
      return this.store.current.popup[this.store.current.popup.length-1];
    },
    isLocked() {
      if(this.pending || !this.init) return true;
      else return false;
    }
  },
  watch: {
    async currentPopup(value) {
      if(value == "unlink-pdf-answer") {
        this.init = false;
        this.title = "Weet u dit zeker?";
        this.submitText = "Ja, ik weet het zeker!";

        this.answer = { 
          answerId: this.store.current.data.pdf.answer.answerId,
          contributionId: this.store.current.data.pdf.contribution.contributionId
        };
        //await this.fetch();

        this.init = true;
        this.pending = false;
      }
    }
  },
  methods: {
    async unlink() {
      if(this.pending) return;
      this.pending = true;

      const response = await answerService.unlink(this.answer, this.getData);
      if(response) {
        if(response.status == 200) {
          for(var i = 0; i < this.renderStore.contributions.length; i++) {
            if(this.renderStore.contributions[i].contributionId == this.answer.contributionId) {
              const contributionTemplate = this.renderStore.contributionTemplates[response.data.contributionTemplateId];
              var contribution = formatContribution(response.data, contributionTemplate);
              this.renderStore.contributions[i] = {...contribution};
              this.renderStore.options.activeContribution = {...contribution};
            }
          }
          this.close();
        }
        else {
          this.$emit('showMessage', response);
        }
      }
      this.pending = false;
    },
    parseValues(format, values) {
      for(const [key,value] of Object.entries(JSON.parse(values))) {
        format = format.replaceAll("{{"+key+"}}", value);
      }
      if(format.startsWith(": ")) format = format.substring(2);
      return format;
    },
    close() {
      this.store.current.popup.splice(
        this.store.current.popup.indexOf('unlink-pdf-answer'), 
        1
      );
      this.store.current.data.pdf.answer = null;
    },
    /*async fetch() {
      if(this.answer.answerId) {
        this.answer = await answerService.select(this.answer, this.getData);
      }
    }*/
  }
}
</script>
