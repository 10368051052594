<template>
  <div class="navbar-side" ref="changeLog" :class="{ collapsed: changelogCollapsed }" style="left:40px" :style="{width:(changelogCollapsed)?'0px':'350px'}">
    <DocChangelog />
  </div>

  <div class="navbar-side" ref="navbarForms" :class="{ collapsed: sidebarCollapsed }"  :style="{left:(changelogCollapsed)?'40px':'390px'}">
    <DocInfo 
      :document="document" 
      :get-data="getData" 
    />
    <DocContribution 
      :document="document" 
      :get-data="getData"
    />
    <DocAnnotation
      :annotation="renderStore.options.activeAnnotation" 
      :contributionRaw="renderStore.options.activeContribution"
      :contributionIds="renderStore.options.multiSelectedContributionIds"
      :getData="getData"
    />
    <DocMiniatures />
    <DocBookmarks />
    <DocToolbox />
    <DocProblem />
  </div>

  <NavBottom :document="document" />
  <NavSide ref="navbarButtons" :document="document" />
</template>

<script>
import { rescaleCanvas } from '@/components/app/viewer/PdfCanvas.js'
import { nextTick } from 'vue'

import NavBottom from '@/components/app/viewer/navigation/NavBottom.vue'
import NavSide from '@/components/app/viewer/navigation/NavSide.vue'
import DocInfo from '@/components/app/viewer/navigation/DocInfo.vue'
import DocContribution from '@/components/app/viewer/navigation/DocContribution.vue'
import DocAnnotation from '@/components/app/viewer/navigation/DocAnnotation.vue'
import DocMiniatures from '@/components/app/viewer/navigation/DocMiniatures.vue'
import DocBookmarks from '@/components/app/viewer/navigation/DocBookmarks.vue'
import DocToolbox from '@/components/app/viewer/navigation/DocToolbox.vue'
import DocChangelog from '@/components/app/viewer/navigation/DocChangelog.vue'
import DocProblem from '@/components/app/viewer/navigation/DocProblem.vue'

import { resetSelection } from './PdfCanvas'

export default {
  emits: ['showMessage', 'renderCanvas'],
  props: {
    getData: Object
  },
  computed: {
    document() {
      return this.$parent.document;
    },
    store() {
      return this.$parent.store;
    },
    renderStore() {
      return this.$parent.renderStore;
    },
    activeSideMenu() {
      return this.$parent.renderStore.options.activeSideMenu;
    },
    showChangelog() {
      return this.$parent.renderStore.options.showChangelog;
    },
    opacity() {
      return this.renderStore.options.opacity;
    },
    sidebarCollapsed() {
      if(this.renderStore.options.activeSideMenu != null) {
        return false;
      }
      else return true;
    },
    changelogCollapsed() {
      return !this.renderStore.options.showChangelog;
    }
  },
  watch: {
    activeSideMenu(val, prev) {
      if(!val) resetSelection();
      nextTick(function() { 
        if((prev == null && val != null) || (prev != null && val == null)) {
          rescaleCanvas(true);
        }
      });
    },
    showChangelog(val, prev) {
      nextTick(function() { 
        if(val != prev) {
          rescaleCanvas(true);
        }
      });
    }
  },
  created() {
    this.renderStore.components.menuNavigation = this;
  },
  mounted() {
    
  },
  beforeDestroy() {
    
  },
  methods: {
    showMessage(e) {
      this.$emit("showMessage", e);
    }
  },
  components: {
    NavBottom,
    NavSide,

    DocInfo,
    DocContribution,
    DocAnnotation,
    DocMiniatures,
    DocBookmarks,
    DocToolbox,
    DocChangelog,
    DocProblem
  }
}
</script>