<template>
  <transition name="fade">
    <div class="popup" v-if="store.current.popup.includes('verify-pdf-document')">
      <div>
        <perfect-scrollbar style="height:100%">
          <div class="closer" @click="close()">Scherm sluiten</div>
          <h1>Document verifiëren</h1>
          <template v-if="this.error.length > 0">
            <h2>{{ this.error }}</h2>
            <p>
              Controleer de opmaak van uw XML-bestand. Het bestand dient voor elk totaal naast een onderwerp ook de kolommen
              aantal, lengte, oppervlakte en inhoud te bevatten.
            </p>
          </template>
          <template v-else-if="Object.values(this.verifyData).length > 0">
            <h2>Er zijn verschillen aangetroffen</h2>
            <p>
              De gegevens uit beide bronnen komen niet geheel met elkaar overeen. Het kan soms voorkomen
              dat door afronding van getallen een klein verschil ontstaat; in de meeste gevallen worden deze
              situaties al door het systeem goedgekeurd. Hieronder vind u een overzicht van de verschillen 
              tussen de hoeveelheden in het XML-bestand (XML) en de hoeveelheden zoals ingelezen door de 
              applicatie (DOC):
            </p>

            <table class="diff-table">
              <tr class="tt">
                <th></th>
                <th colspan="2">Aantal regels</th>
                <th colspan="2">Totaal aantal</th>
                <th colspan="2">Totale lengte</th>
                <th colspan="2">Totale oppervlakte</th>
                <th colspan="2">Totaal volume</th>
              </tr>
              <tr class="t">
                <td><b>Onderwerp</b></td>
                <td>XML</td>
                <td>DOC</td>
                <td>XML</td>
                <td>DOC</td>
                <td>XML</td>
                <td>DOC</td>
                <td>XML</td>
                <td>DOC</td>
                <td>XML</td>
                <td>DOC</td>
              </tr>
              <template v-for="(fields, subject) in this.verifyData">
                <tr class="d">
                  <td class="e"><span>{{ subject }}</span></td>

                  <template v-if="fields != 'N/A'">
                    <template v-if="fields['aCount'] != null">
                      <td>✘ {{ fields['aCount'][1] }}</td>
                      <td>✘ {{ fields['aCount'][0] }}</td>
                    </template>
                    <template v-else>
                      <td class="c">✔ OK</td>
                      <td class="c">✔ OK</td>
                    </template>
                    
                    <template v-if="fields['count'] != null">
                      <td>✘ {{ fields['count'][1] }}</td>
                      <td>✘ {{ fields['count'][0] }}</td>
                    </template>
                    <template v-else>
                      <td class="c">✔ OK</td>
                      <td class="c">✔ OK</td>
                    </template>

                    <template v-if="fields['len'] != null">
                      <td>✘ {{ fields['len'][1] }}</td>
                      <td>✘ {{ Math.round(1000*fields['len'][0])/1000 }}</td>
                    </template>
                    <template v-else>
                      <td class="c">✔ OK</td>
                      <td class="c">✔ OK</td>
                    </template>

                    <template v-if="fields['area'] != null">
                      <td>✘ {{ fields['area'][1] }}</td>
                      <td>✘ {{ Math.round(1000*fields['area'][0])/1000 }}</td>
                    </template>
                    <template v-else>
                      <td class="c">✔ OK</td>
                      <td class="c">✔ OK</td>
                    </template>

                    <template v-if="fields['volume'] != null">
                      <td>✘ {{ fields['volume'][1] }}</td>
                      <td>✘ {{ Math.round(1000*fields['volume'][0])/1000 }}</td>
                    </template>
                    <template v-else>
                      <td class="c">✔ OK</td>
                      <td class="c">✔ OK</td>
                    </template>
                  </template>
                  <template v-else>
                    <td colspan="10">✘ Dit onderwerp werd niet aangetroffen in het document</td>
                  </template>
                </tr>
              </template>
            </table>

            <div class="filter-list">
              <h2>Toch goedkeuren</h2>
              <p>
                Als u bovenstaande verschillen kunt verklaren en de hoeveelheden die binnen de
                applicatie worden getoond juist zijn, kunt u door op onderstaande knop te drukken
                de gegevens instellen als geverifieerd.
              </p>
            
              <form @submit.prevent="submit">
                <label class="check-front">
                  <span :class="{active:this.accept}"></span>
                  Ik begrijp de risico's en dat de verantwoording in dit geval bij mij ligt
                  <input type="checkbox"
                    v-model="this.accept" 
                    @click="toggleAccept">
                </label>
                <input type="submit" value="Ja, voltooi de verificatie" :class="{locked:isLocked}" style="margin-top:20px">
              </form>
              <div style="clear:both"></div>
            </div>
          </template>
          <template v-else-if="fileToUpload != null && fileToUpload.content != null">
            <h2>De gegevens komen overeen</h2>
            <p>
              Er zijn geen verschillen aangetroffen tussen het aangeboden XML-bestand en de hoeveelheden
              binnen het document. Het document is daarom automatisch gemarkeerd als geverifieerd. U kunt
              dit venster nu sluiten.
            </p>
          </template>
          <template v-else>
            <h2>Geautomatiseerde verificatie</h2>
            <p>
              U kunt dit document verifiëren door een XML-bestand te uploaden waarin de 
              projecttotalen voor dit document zijn opgeslagen. Hoe u dit bestand kunt aanmaken,
              kunt u nalezen <a href="" target="_blank">via deze link</a>. Na het uploaden van
              uw bestand zal het systeem een automatische cross-check uitvoeren.
            </p>
            <FileUpload
              :maxSize="500000" 
              :file="new Object()"
              accept="xml"
              @show-message="$emit('showMessage')"
              @file-changed="validateUpload"
            />
          </template>
        </perfect-scrollbar>
      </div>
    </div>
  </transition>
</template>

<script>
import documentService from '@/services/app/document.service'
import errorHandler from '@/common/ErrorHandler'

import FileUpload from '@/components/FileUpload'

export default {
  emits: ['showMessage', 'updateParent'],
  props: {
    document: Object
  },
  data() {
    return {
      verifyData: {},
      fileToUpload: null,
      accept: false,
      formErrors: {},
      title: "",
      submitText: "",
      error: "",
      init: false,
      pending: false,
    };
  },
  computed: {
    store() {
      return this.$parent.store;
    },
    renderStore() {
      return this.$parent.renderStore;
    },
    currentPopup() {
      return this.store.current.popup[this.store.current.popup.length-1];
    },
    isLocked() {
      if(this.pending || !this.init) return true;
      else return false;
    }
  },
  watch: {
    async currentPopup(value) {
      if(value == "verify-pdf-document") {
        this.init = false;
        this.fileToUpload = null;
        this.verifyData = {};
        this.accept = false;
        this.error = "";

        this.title = "Document verifiëren";
        this.submitText = "Wijzigingen opslaan";
        
        this.init = true;
        this.pending = false;
      }
      else {
        this.fileToUpload = null;
      }
    }
  },
  methods: {
    verify() {
      if(this.pending) return;
      this.pending = true;
      this.error = "";

      const mar = 0.0075;
      const originalColumns = JSON.parse(JSON.stringify(this.renderStore.options.columns));
      this.renderStore.loadDefaultColumns();
      this.renderStore.loadAnnotations(false);

      try {
        var p = new DOMParser();
        var xmlData = p.parseFromString(this.fileToUpload.content, "text/xml");
        
        this.verifyData = {};
        for(const xmlChild of Object.values(xmlData.children.item(0).children)) {
          var e = xmlChild.getElementsByTagName("Onderwerp")[0];
          if(!e) e = xmlChild.getElementsByTagName("Subject")[0];

          var spl = e.textContent.split(" ");
          var subject = "";
          for(let i = 0; i < spl.length - 1; i++) {
            if(i > 0 && spl[i].length > 0) subject += " ";
            subject += spl[i];
          }

          subject = subject.replace(" / ", "/");
          subject = subject.replace(" /", "/");
          subject = subject.replace("/ ", "/");
          subject = subject.replaceAll("  ", " ");
          if(subject[0] == " ") subject = subject.substring(1);
          
          if(subject.length == 0) subject = "undefined";
          var count = spl[spl.length - 1].replace("(", "").replace(")", "");
          
          var data = this.renderStore.annotations.data.pivotData[" "+subject];
          if(data == null) {
            this.verifyData[subject] = "N/A";
            continue;
          }
          if(data[0] != count) {
            if(this.verifyData[subject] == null) this.verifyData[subject] = {};
            this.verifyData[subject]["aCount"] = [data[0], count];
          }

          for(const entry of Object.entries(data[1])) {
            var field = entry[0];
            var value = parseFloat(entry[1]);
            
            switch(field) {
              case "count":
                var xmlElement = xmlChild.getElementsByTagName("Aantal")[0];
                if(xmlElement == null) xmlChild.getElementsByTagName("Count")[0];
                if(xmlElement != null) {
                  var xmlValue = parseFloat(xmlElement.textContent.replace(",", "."));
                  if(!(xmlValue > (1-mar) * value && xmlValue < (1+mar) * value)) {
                    if(this.verifyData[subject] == null) this.verifyData[subject] = {};
                    this.verifyData[subject]["count"] = [value, xmlValue];
                  }
                }
                break;
              case "length":
                var xmlElement = xmlChild.getElementsByTagName("Lengte")[0];
                if(xmlElement == null) xmlChild.getElementsByTagName("Length")[0];
                if(xmlElement != null) {
                  var xmlValue = parseFloat(xmlElement.textContent.replace(",", "."));
                  if(!(xmlValue > (1-mar) * value && xmlValue < (1+mar) * value)) {
                    if(this.verifyData[subject] == null) this.verifyData[subject] = {};
                    this.verifyData[subject]["len"] = [value, xmlValue];
                  }
                }
                break;
              case "area":
                var xmlElement = xmlChild.getElementsByTagName("Oppervlakte")[0];
                if(xmlElement == null) xmlChild.getElementsByTagName("Area")[0];
                if(xmlElement != null) {
                  var xmlValue = parseFloat(xmlElement.textContent.replace(",", "."));
                  if(!(xmlValue > (1-mar) * value && xmlValue < (1+mar) * value)) {
                    if(this.verifyData[subject] == null) this.verifyData[subject] = {};
                    this.verifyData[subject]["area"] = [value, xmlValue];
                  }
                }
                break;
              case "volume":
                var xmlElement = xmlChild.getElementsByTagName("Volume")[0];
                //if(xmlElement == null) xmlChild.getElementsByTagName("Volume")[0];
                if(xmlElement != null) {
                  var xmlValue = parseFloat(xmlElement.textContent.replace(",", "."));
                  if(!(xmlValue > (1-mar) * value && xmlValue < (1+mar) * value)) {
                    if(this.verifyData[subject] == null) this.verifyData[subject] = {};
                    this.verifyData[subject]["volume"] = [value, xmlValue];
                  }
                }
                break;
            }
          }
        }

        this.renderStore.options.columns = originalColumns;
        this.renderStore.loadAnnotations(true);
        this.pending = false;
        this.error = "";

        if(Object.values(this.verifyData).length == 0) {
          this.accept = true;
          this.submit();
        }
      }
      catch {
        this.renderStore.options.columns = originalColumns;
        this.renderStore.loadAnnotations(true);
        this.pending = false;
        this.error = "Uw XML-bestand heeft niet de juiste opmaak";
      }
    },
    toggleAccept() {
      this.accept = !this.accept;
    },
    async submit() {
      if(this.pending) return;
      if(!this.accept) return;
      this.pending = true;

      let response = await documentService.verify(this.document);
      if(response) {
        if(response.status == 200) {
          this.document.isVerified = true;
          if(Object.values(this.verifyData).length > 0) {
            this.close();
          }
        }
        else {
          this.$emit('showMessage', response);
          if(response.data != null && response.data.message != null) {
            this.error = response.data.message;
          }
        }
      }
      this.pending = false;
    },
    validateUpload(file) {
      this.fileToUpload = file.data;
      this.isInvalid = !file.isUploaded;
      
      const fr = new FileReader();
      const ctx = this;
      fr.onload = function(res) {
        ctx.fileToUpload.content = res.target.result;
        ctx.verify();
      };
      fr.onerror = (err) => this.$emit('showMessage', err);
      fr.readAsText(this.fileToUpload);
    },
    close() {
      this.store.current.popup.splice(
        this.store.current.popup.indexOf('verify-pdf-document'), 
        1
      );
      this.$emit('updateParent');
    },
    convertToText(value) {
      try { return value.replace(/&#(\d+);/g, function (m, n) { 
        return String.fromCharCode(n); }); 
      }
      catch(e) { return ""; }
    },
  },
  components: {
    FileUpload
  }
}
</script>
