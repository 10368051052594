<template>
  <transition name="fade">
    <div class="popup" v-if="store.current.popup.includes('unlink-pdf-document-history')">
      <div>
        <perfect-scrollbar style="height:100%">
          <form @submit.prevent="unlink">
            <div class="closer" @click="close()">Scherm sluiten</div>
            <h1>{{ title }}</h1>
            <div v-if="init">
              <p>
                U staat op het punt om deze versie met alle daaraan gekoppelde gegevens te verwijderen.
                Weet u zeker dat deze versie verwijderd kan worden?
              </p>
              <input type="submit" :value="submitText" class="red" :class="{locked:isLocked}">
            </div>
            <div v-else class="loader">
              <div>Even geduld, content wordt geladen...</div>
            </div>
          </form>
        </perfect-scrollbar>
      </div>
    </div>
  </transition>
</template>

<script>
import documentHistoryService from '@/services/app/document.history.service'

export default {
  emits: ['showMessage', 'updateParent'],
  data() {
    return {
      documentHistory: {},
      title: "",
      submitText: "",
      init: false,
      pending: false
    };
  },
  computed: {
    store() {
      return this.$parent.store;
    },
    currentPopup() {
      return this.store.current.popup[this.store.current.popup.length-1];
    },
    isLocked() {
      if(this.pending || !this.init) return true;
      else return false;
    }
  },
  watch: {
    async currentPopup(value) {
      if(value == "unlink-pdf-document-history") {
        this.init = false;
        this.title = "Versie van document verwijderen";
        this.submitText = "Ja, verwijder alle data!";
        this.documentHistory.documentHistoryId = this.store.current.data.pdf.documentHistory.documentHistoryId;
        await this.fetch();

        this.init = true;
        this.pending = false;
      }
    }
  },
  methods: {
    async unlink() {
      if(this.pending) return;
      this.pending = true;
      const response = await documentHistoryService.unlink(this.documentHistory.documentHistoryId);
      if(response) {
        if(response.status == 200) {
          this.close();
        }
        else {
          this.$emit('showMessage', response);
        }
      }
      this.pending = false;
    },
    close() {
      this.store.current.popup = ["manage-pdf-document-history"];
      this.store.current.data.pdf.document.history = null;
      this.$emit('updateParent');
    },
    async fetch() {
      if(this.documentHistory.documentHistoryId) {
        this.documentHistory = await documentHistoryService.select(this.documentHistory);
      }
    }
  }
}
</script>
