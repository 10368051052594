import { defineStore } from 'pinia'

export const useRenderStore = defineStore('render', {
  state: () => {
    return { 
      data: [],
      changelog: {},
      document: null,
      documentHistory: null,
      pages: null,
      contributions: null,
      outlines: null,
      usedStorage: 0,
      printToConsole: false,
      columnsContribDef: [
        { "name": "title", "name_nl": "Onderwerp", "type": "text", "width": "30%", "sortable": true },
        { "name": "templateName", "name_nl": "Type", "type": "text", "width": "8%", "sortable": true },
        { "name": "askedBy", "name_nl": "Auteur", "type": "text", "width": "8%", "sortable": true },
        { "name": "dateCreated", "name_nl": "Datum", "type": "date", "width": "8%", "sortable": true },
        { "name": "values.mm1", "name_nl": "MP1", "type": "number", "width": "3%" },
        { "name": "values.mm2", "name_nl": "MP2", "type": "number", "width": "3%" },
        { "name": "values.mh", "name_nl": "Hgt", "type": "number", "width": "3%", "suf": " m" },
        { "name": "values.ma", "name_nl": "Hoek", "type": "number", "width": "3%", "suf": "º" },
        { "name": "values.mr", "name_nl": "Maat", "type": "number", "width": "6%" },
        { "name": "values.mn", "name_nl": "Aantal", "type": "number", "width": "6%", "suf": " st", "totals": true },
        { "name": "values.mle", "name_nl": "Lengte", "type": "number", "width": "6%", "suf": " m", "totals": true },
        { "name": "values.mar", "name_nl": "Oppervlakte", "type": "number", "width": "6%", "suf": " m²", "totals": true },
        { "name": "values.mwa", "name_nl": "Wandoppervlakte", "type": "number", "width": "6%", "suf": " m²", "totals": true },
        { "name": "values.mvo", "name_nl": "Volume", "type": "number", "width": "6%", "suf": " m³", "totals": true }
      ],
      options: {
        queryAnnotations: "",
        filterAnnotations: "",
        queryContributions: "",
        filterContributions: "",
        pageNumber: 1,
        selectedAnnotationId: -1,
        activeAnnotation: null,
        selectedContributionId: -1,
        multiSelectedContributionIds: [],
        firstSelectedContributionKey: -1,
        activeContribution: null,
        activeAttachment: -1,
        activeToolboxItem: null,
        activeEditPointIndex: -1,
        measureScale: null,
        measureIntersects: false,
        measureEnd: true,
        opacity: 1,
        grayScale: false,
        columns: [],
        columnsPivot: [],
        columnsOverview: [],
        columnsContrib: [],
        activeSideMenu: null,
        filterAnnotationsForPage: false,
        filterContributionsForPage: false,
        filterContributionsForUser: false,
        showContributionsAsTable: false,
        pivotFullScreen: false,
        expandToLevel: 0,
        showAnnotations: false,
        showContributions: false,
        showChangelog: false,
        needsRescale: false,
        snapToContent: true,
        orthogonal: false,
        sortContributionsBy: {
          column: "title",
          order: "asc"
        },
        ctrlPressed: false,
        shiftPressed: false
      },
      ready: false,
      imagesReady: false,
      fetchImagesRetryCount: 0,
      renderTimer: null,
      snapTimer: null,
      zoomTimer: null,
      drawTimer: null,
      contribRefreshTimer: null,
      listedItems: [],
      listedTotal: 0,
      current: "",
      components: {},
      message: "",
      remarkImg: null,
      auth: {

      },
      resize: {
        delay: 500,
        timer: null
      },
      touch: {
        start: [],
        current: []
      },
      mouse: {
        dragging: false,
        draggedElement: null,
        pos: {
          x: 0,
          y: 0
        },
        hpos: {
          x: 0,
          y: 0
        },
        dpos: 0
      },
      stage: {
        images: {},
        annotations: {},
        measure: {}
      },
      painting: false,
      firstRender: false,
      images: {
        size: 1024,
        urls: [],
        blobData: [],
        data: []
      },
      annotations: {
        scaling: 0,
        cachedZoom: null,
        markup: [],
        data: {
          charCountAvailable: false,
          subjectAt: -1,
          totalAt: -1,
          unitAt: -1,
          rawData: [],
          filteredData: [],
          filteredPointers: [],
          pivotData: [],
          filter: "",
          query: "",
          goto: false,
          unitFields: ["unit", "eenh", "eh", "eenheid"]
        }
      },
      texts: {
        data: []
      },
      canvasCssWidth: 0,
      canvasCssHeight: 0,
      canvas: {
        leftOffset: 0,
        imageObj: null,
        annotationObj: null,
        objCount: 0,
        refPage: "",
        update: {
          unix: 0,
          type: ''
        },
        zoom: {
          scroll: 1.1,
          factor: {
            current: null,
            min: null, 
            max: 50
          },
          step: null,
        },
        offset: {
          x: 0,
          y: 0
        },
        images: {
          id: null,
          count: 0,
          zoomFactor: 1,
          totalWidth: 0,
          totalHeight: 0,
          container: null,
          current: []
        },
        annotations: {
          count: 0,
          graphics: null,
          shapes: null
        },
        texts: {
          count: 0,
          container: null
        },
        gridLines: {
          count: 0,
          current: []
        }
      }
    }
  },
  actions: {
    async loadAnnotations(deep) {
      if(this.printToConsole) console.log("loadAnnotations");

      var pageNumber = 0;
      var presentOnPage = [];
      this.ready = false;
      this.annotations.data.filteredData = [];
      this.annotations.data.filteredPointers = [];
      this.sortTableHeaders();

      // Check which columns should be displaying a total value (which needs calculation)
      // Also check where to find the unit associated with the rule's total value
      this.options.columns.forEach((displayField, i) => {
        if(displayField.name == "subject") this.annotations.data.subjectAt = i;
        if(!displayField.pivot && (displayField.type.match(/(Number|Formula)/))) {
          displayField.totals = true;
        }

        if(displayField.name.toLowerCase() == "totaal") {
          this.annotations.data.totalAt = i;
        }
        if(this.annotations.data.unitFields.includes(displayField.name.toLowerCase())) {
          this.annotations.data.unitAt = i;
        }
      });

      // If we found a unit, we automatically want to include it inside the 
      // column "totaal" for Digitcalc customers
      if(this.annotations.data.totalAt >= 0 && this.annotations.data.unitAt >= 0) {
        this.options.columns[this.annotations.data.unitAt].hidden = true;
      }
      else if(this.annotations.data.unitAt >= 0) {
        this.options.columns[this.annotations.data.unitAt].hidden = false;
      }

      if(!this.annotations.data.charCountAvailable) {
        Object.entries(this.data).forEach(pageData => {
          if(pageData[1].annotations["ANNOTATION"] == null) return;
          Object.entries(pageData[1].annotations["ANNOTATION"]).forEach(meas => {

            if(meas[1].melted) return;
            if(this.options.columns == null) return;

            let record = [];
            var columnData = meas[1].columnData;

            this.options.columns.forEach(column => {
              var insertAt = this.findColumn(column.name);
              var value = columnData[column.name];
              var thisCharCount = 0;

              if(insertAt >= 0) {
                if(column.type.match(/(Number|Formula)/)) {
                  value = parseFloat(value).toFixed(column.precision);
                  thisCharCount = 5;
                }
                else {
                  let str = String(value) || "";
                  thisCharCount = 2 + 0.5 * str.length;
                }

                if(!column.charCount || thisCharCount > column.charCount) {
                  column.charCount = thisCharCount;
                }
              }
            }, columnData, record, this);

            // Add this record to the full listing of data
            this.annotations.data.charCountAvailable = true;
          }, this);
        }, this);

        var charWidth = 0;
        this.options.columns.forEach(column => {
          if(column.hidden == null) {
            charWidth += column.charCount;
          }
        }, charWidth);

        this.options.columns.forEach(column => {
          column.width = (column.charCount) / charWidth * 100;
        }, charWidth);
      }

      //console.log(this.options.columns);
      //console.log(this.annotations.data.subjectAt);

      // Filter data if necessary
      var filterText = String(this.annotations.data.filter).toLowerCase();
      var filterMultiple = [];
      if(this.annotations.data.filter.indexOf("@!@") >= 0) {
        filterMultiple = this.annotations.data.filter.split("@!@");
        filterMultiple.pop();
      }

      var queryText = String(this.options.queryAnnotations).toLowerCase();

      pageNumber = 0;
      presentOnPage = [];
      Object.entries(this.data).forEach(pageData => {
        pageNumber++;
        if(pageData[1].annotations == null || pageData[1].annotations["ANNOTATION"] == null) {
          return;
        }
        if(this.options.filterAnnotationsForPage && this.options.pageNumber != pageNumber) {
          return;
        }

        Object.entries(pageData[1].annotations["ANNOTATION"]).forEach(meas => {

          let record = [];
          var columnData = meas[1].columnData;
          var includeInFilter = false;
          var includeInQuery = false;

          if(filterMultiple.length > 0) {
            includeInFilter = true;
          }

          if(queryText.length == 0) {
            includeInQuery = true;
          }

          this.options.columns.forEach(column => {
            var insertAt = this.findColumn(column.name);
            var value = "" + columnData[column.name];

            if(String(value).toLowerCase().indexOf(queryText) >= 0 && column.pivot) {
              includeInQuery = true;
            }

            if(insertAt >= 0) {
              if(filterMultiple.length == 0) {
                if(filterText.length == 0) {
                  includeInFilter = true;
                }
                else if(String(value).toLowerCase().indexOf(filterText) >= 0) {
                  includeInFilter = true;
                }
              }
              else {
                if(filterMultiple.length > insertAt && filterMultiple[insertAt] != this.convertToText(value)) {
                  includeInFilter = false;
                }
              }
              
              record.push(value);
            }
          }, columnData, record, includeInFilter, this);
          
          if(this.annotations.data.goto && (this.annotations.data.filter.length > 0 || this.options.filterAnnotations.length > 0 || this.options.queryAnnotations.length > 0)) {
            if(includeInQuery && includeInFilter) {
              if(presentOnPage.indexOf(pageNumber) == -1) {
                presentOnPage.push(pageNumber);
              }
            }
          }

          if(includeInQuery && includeInFilter) {
            switch(meas[1].type) {
              case "LENGTH":
              case "POLYLENGTH":
              case "RADIUS":
              case "DIAMETER":
                record.push("L");
                break;
              case "AREA":
                record.push("A");
                break;
              case "VOLUME":
                record.push("V");
                break;
              case "COUNT":
                record.push("C");
                break;
              case "ANGLE":
                record.push("D");
                break;
              default:
                record.push("");
                break;
            }
            record.push(pageData[0]);
            record.push(meas[0]);
            
            if(!meas[1].melted && meas[1].active) {
              this.annotations.data.filteredData.push(record);
            }
            this.annotations.data.filteredPointers.push(pageData[0] + "." + meas[0]);
          }
        }, this);
      }, this);

      // Sort the filtered data according to the sort setting (asc, desc) for
      // all of the pivot columns
      for(let i = this.options.columnsPivot.length - 1; i >= 0; i--) {
        if(this.options.columnsPivot[i].sort == "desc") {
          try { this.annotations.data.filteredData.sort((a,b) => b[i].localeCompare(a[i])); }
          catch(e) {}
        }
        else {
          try { this.annotations.data.filteredData.sort((a,b) => a[i].localeCompare(b[i])); }
          catch(e) {}
        }
      }

      if(this.annotations.data.goto && presentOnPage.length > 0 && presentOnPage.indexOf(this.options.pageNumber) == -1) {
        this.options.pageNumber = presentOnPage[0];
        this.annotations.data.goto = false;
      }
      this.annotations.data.pivotData = this.pivotData(null, 0, deep);
      this.ready = true;
    },
    async setColumnWidths() {
      this.options.columnsContrib.forEach(column => {
        column.charCount = 0;
      });
      
      Object.entries(this.contributions).forEach(contribution => {
        this.options.columnsContrib.forEach(column => {
          var chars = 0;
          var value = null;
          if(contribution[1][column.name] != null) value = contribution[1][column.name];
          else if(contribution[1].values[column.name] != null) value = contribution[1].values[column.name];

          if(this.isNumeric(value)) chars = 10;
          else chars = String(value).length;
          
          if(column.charCount < chars) {
            column.charCount = chars;
          }
          
        }, this);
      }, this);

      var charWidth = 0;
      this.options.columnsContrib.forEach(column => {
        if(column.hidden == null) {
          charWidth += column.charCount;
        }
      }, charWidth);

      this.options.columnsContrib.forEach(column => {
        column.width = (column.charCount) / charWidth * 100;
      }, charWidth);
    },
    pivotData(data, index, deep) { 
      var sorted = {};

      if(data == null) {
        data = this.annotations.data.filteredData;
      }

      data.forEach(rule => {
        var sortText;
        if(deep) sortText = " "+this.convertToText(rule[0]);
        else sortText = " "+this.convertToText(rule[this.annotations.data.subjectAt]);
        if(sortText.indexOf("  ") > 0) sortText = sortText.replace("  ", " ");
        else if(sortText.indexOf("  ") == 0) sortText = " "+sortText.replace("  ", " ");

        if(Object.keys(sorted).indexOf(sortText) == -1) {
          sorted[sortText] = [];
        }
        if(deep && index + 1 < this.options.columnsPivot.length) {
          sorted[sortText].push(rule.slice(1, rule.length));
        }
        else {
          if(Object.keys(sorted[sortText]).length == 0) {
            sorted[sortText] = [1, this.pivotTotals(rule, null, index)];
          }
          else {
            sorted[sortText][0]++;
            sorted[sortText][1] = this.pivotTotals(rule, sorted[sortText][1], index);
          }
        }
      });
      
      var result = {};
      if(deep && index + 1 < this.options.columnsPivot.length) {
        Object.entries(sorted).forEach(entry => {
          const [key, value] = entry;
          result[key] = this.pivotData(value, index + 1, deep);
        }, sorted);
        return result;
      }
      else {
        return sorted;
      }
    },
    pivotTotals(data, appendTo, offset) {
      
      if(appendTo == null) {
        appendTo = {};
      }
      
      this.options.columns.forEach((displayField, f) => {
        if(displayField.totals && f > this.annotations.data.subjectAt) {
          if(Object.keys(appendTo).indexOf(displayField.name) == -1) {
            appendTo[displayField.name] = 0;
          }
          appendTo[displayField.name] += parseFloat(data[f-offset]);
        }
        if(f >= this.options.columnsPivot.length) {
          var text = this.convertToText(data[f-offset]);
          if(Object.keys(appendTo).indexOf(displayField.name) == -1) {
            appendTo[displayField.name] = text;
          }
          else if(appendTo[displayField.name].length > 0 && appendTo[displayField.name] != text) {
            appendTo[displayField.name] = "";
          }
        }
      });
      
      return appendTo;
    },
    async searchAnnotations(input) {
      this.options.queryAnnotations = input.toLowerCase();
      await this.loadAnnotations(true);
    },
    async searchContributions(input) {
      this.options.queryContributions = input.toLowerCase();
      //await this.loadAnnotations(true);
    },
    async sortAnnotationsBy(colId) {
      if(this.options.columns[colId].sort == "asc") {
        this.options.columns[colId].sort = "desc";
      }
      else {
        this.options.columns[colId].sort = "asc";
      }
      await this.loadAnnotations(true);
    },
    async onePageData(state) {
      this.annotations.data.onePage = state;
      await this.loadAnnotations(true);
    },
    multiplyZoomFactor(factor) {
      this.canvas.zoom.factor.current *= factor;
      if(this.canvas.zoom.factor.current < this.canvas.zoom.factor.min) {
        this.canvas.zoom.factor.current = this.canvas.zoom.factor.min;
      }
      if(this.canvas.zoom.factor.current > this.canvas.zoom.factor.max) {
        this.canvas.zoom.factor.current = this.canvas.zoom.factor.max;
      }
    },
    setCanvasSize(width, height) {
      var dpr = window.devicePixelRatio;
      this.canvas.imageObj.width = dpr * width;
      this.canvas.imageObj.height = dpr * height;
      this.canvas.annotationObj.width = dpr * width;
      this.canvas.annotationObj.height = dpr * height;
      this.canvasCssWidth = width;
      this.canvasCssHeight = height;
    },
    sortTableHeaders() {
      this.options.columns.forEach((userDef, index) => {
        if(this.options.columns[index].sort == null) {
          this.options.columns[index].sort = "asc";
        }
      });
    },
    isNumeric(n) {
      return !isNaN(parseFloat(n)) && isFinite(n);
    },
    findColumn(findMe) {
      var hit = -1;
      this.options.columns.forEach((column, index) => {
        if(column.name == findMe) hit = index;
        if(column.name == findMe.name) hit = index;
      }, hit);
      return hit;
    },
    convertToText(value) {
      try { return value.replace(/&#(\d+);/g, function (m, n) { 
        return String.fromCharCode(n); }); 
      }
      catch(e) { return ""; }
    },
    loadDefaultColumns() {
      this.options.columnsPivot = [
        { "name": "subject", "name_nl": "onderwerp", type: "Text", "precision": 0, "pivot": true, "totals": false, "sort": "asc" }
      ];
      this.options.columnsOverview = [
        { "name": "width", "name_nl": "breedte", type: "Number", "precision": 3, unit: "m", "pivot": false, "totals": true },
        { "name": "height", "name_nl": "hoogte", type: "Number", "precision": 3, unit: "m", "pivot": false, "totals": true },
        { "name": "depth", "name_nl": "diepte", type: "Number", "precision": 3, unit: "m", "pivot": false, "totals": true },
        { "name": "risedrop", "name_nl": "stijgpijp", type: "Number", "precision": 3, unit: "m", "pivot": false, "totals": true },
        { "name": "angle", "name_nl": "hoek", type: "Number", "precision": 3, unit: "deg", "pivot": false, "totals": false },
        { "name": "slope", "name_nl": "helling", type: "Number", "precision": 3, unit: "deg", "pivot": false, "totals": false },
        { "name": "count", "name_nl": "aantal", type: "Number", "precision": 0, unit: "st", "pivot": false, "totals": true },
        { "name": "length", "name_nl": "lengte", type: "Number", "precision": 3, unit: "m", "pivot": false, "totals": true },
        { "name": "wallArea", "name_nl": "wandoppervlakte", type: "Number", "precision": 3, unit: "m&#178;", "pivot": false, "totals": true },
        { "name": "area", "name_nl": "oppervlakte", type: "Number", "precision": 3, unit: "m&#178;", "pivot": false, "totals": true },
        { "name": "volume", "name_nl": "volume", type: "Number", "precision": 3, unit: "m&#179;", "pivot": false, "totals": true }
      ];
      this.options.columns = [...this.options.columnsPivot, ...this.options.columnsOverview];
    }
  },
})