<template>
  <span class="search-field" :class="{active:isActive}"
    @click="activate(true)"
  >
    <input type="text" class="searcher" 
      ref="query"
      v-model="query"
      @focusout="activate(false)"
      @input="updateQuery"
      v-on:keyup.enter="search"
    >
  </span>
</template>
  
<script>
export default {
  emits: ["search"],
  name: "SearchField",
  data() {
    return {
      isActive: false,
      query: ""
    }
  },
  created() {
    
  },
  methods: {
    search() {
      this.$emit('search', this.query);
    },
    updateQuery(e) {
      
    },
    activate(state) {
      if(state) {
        this.isActive = state;
        this.$refs.query.focus();
      }
      else {
        if(this.query.length == 0) {
          this.isActive = state;
        }
        this.$emit('search', this.query);
      }
    }
  },
};
</script>

<style scoped>
span {
  display: block;
  width: 40px;
  height: 35px;
  float: right;
  background: linear-gradient(40deg, rgb(200, 200, 200) 0%, rgb(240, 240, 240) 100%);
  transition-duration: 0.5s;
  position: relative;
  border-radius: 20px;
  box-sizing: border-box;
  box-shadow: 1px 1px 3px rgba(0,0,0,0.2);
  overflow: hidden;
}

span::before {
  content: '';
  display: block;
  position: absolute;
  top: 10px; bottom: 10px;
  width: 40px;
  opacity: 0.7;
  background-image: url('/public/img/ico/search.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  cursor: text;
  z-index: 100;
}

.small {
  width: 24.5px;
  height: 24.5px;
}

.small::before {
  top: 8px; bottom: 8px;
  left: -7px;
}

input {
  position: absolute;
  padding: 5px 18px !important;
  top: 0; left: 20px; right: 0;
  width: calc(100% - 35px);
  height: 35px !important;
  opacity: 0.2;
  border: none;
  background: none;
  font-style: normal !important;
  color: #000;
}

.small input {
  padding: 5px !important;
  height: 25px !important;
  font-size: 10px;
}

.active {
  cursor: text;
  width: 150px;
  color: rgba(0,0,0,1);
  transition-duration: 0.5s
}


.active input {
  transition-duration: 0.5s;
  opacity: 0.8
}

.small.active {
  width: 110px;
}
</style>
