<template>
  <transition name="fade">
    <div class="popup" v-if="store.current.popup.includes('add-user')">
      <div>
        <perfect-scrollbar style="height:100%">
          <form @submit.prevent="add">
            <div class="closer" @click="close()">Scherm sluiten</div>
            <h1>{{ title }}</h1>
            <div v-if="init">
              <h2>Generieke gegevens</h2>
              <label>
                {{ defaultData.genderId[0] }}
                <Multiselect 
                  v-model="user.genderId" 
                  :options="genders" 
                  label="name"
                  placeholder="Maak een keuze..."
                  noOptionsText="Geen opties gevonden.">
                </Multiselect>
              </label>
              <div v-if="formErrors.user_genderId" class="error-field">{{ defaultData.genderId[0] + " " + formErrors.user_genderId }}!</div>


              <div v-if="labelData.name" class="before-input" :class="{active:labelData.name.active}">{{ labelData.name.text }}</div>
              <input type="text" 
                name="name"
                v-model="user.name" 
                :class="{hasLabel:labelData.name.active}"
                @focusin="empty($event)" 
                @focusout="reset($event)">
              <div v-if="formErrors.user_name" class="error-field">{{ defaultData.name[0] + " " + formErrors.user_name }}!</div>

              <div v-if="labelData.surname" class="before-input" :class="{active:labelData.surname.active}">{{ labelData.surname.text }}</div>
              <input type="text" 
                name="surname"
                v-model="user.surname" 
                :class="{hasLabel:labelData.surname.active}"
                @focusin="empty($event)" 
                @focusout="reset($event)">
              <div v-if="formErrors.user_surname" class="error-field">{{ defaultData.surname[0] + " " + formErrors.user_surname }}!</div>

              <div v-if="labelData.email" class="before-input" :class="{active:labelData.email.active}">{{ labelData.email.text }}</div>
              <input type="text" 
                name="email"
                v-model="user.email" 
                :class="{hasLabel:labelData.email.active}"
                @focusin="empty($event)" 
                @focusout="reset($event)"
                :disabled="this.userId != null">
              <div v-if="formErrors.user_email" class="error-field">{{ defaultData.email[0] + " " + formErrors.user_email }}!</div>
              <div style="clear:both;height:50px"></div>


              <h2>Rechten</h2>
              <label>
                {{ defaultData.isAdmin[0] }}
                <input type="checkbox" 
                  v-model="user.isAdmin" 
                  @click="toggle($event)">
                <span :class="{active:user.isAdmin}"></span>
              </label>

              <input type="submit" :value="submitText" :class="{locked:isLocked}">
            </div>
            <div v-else class="loader">
              <div>Even geduld, content wordt geladen...</div>
            </div>
          </form>
        </perfect-scrollbar>
      </div>
    </div>
  </transition>
</template>

<script>
import userService from '@/services/user.service'
import listingService from '@/services/listing.service'
import errorHandler from '@/common/ErrorHandler'
import Multiselect from '@vueform/multiselect'

export default {
  emits: ['showMessage', 'updateParent'],
  data() {
    return {
      userId: null,
      user: {
        userId: null,
        genderId: 0,
        name: '',
        surname: '',
        email: '',
        isAdmin: false
      },
      genders: [],
      defaultData: {
        userId: ['', null],
        genderId: ['Geslacht', 'Wat is het geslacht van de gebruiker?'],
        name: ['Voornaam', 'Wat is de voornaam van de gebruiker?'],
        surname: ['Achternaam', 'Wat is de achternaam van de gebruiker?'],
        email: ['Email-adres', 'Wat is het email-adres van de gebruiker?'],
        isAdmin: ['Administrator', 'Is deze gebruiker een administrator?'],
      },
      labelData: {},
      formErrors: {},
      title: "",
      submitText: "",
      init: false,
      pending: false
    };
  },
  computed: {
    store() {
      return this.$parent.store;
    },
    currentPopup() {
      return this.store.current.popup[this.store.current.popup.length-1];
    },
    isLocked() {
      if(this.pending || !this.init) return true;
      else return false;
    }
  },
  watch: {
    async currentPopup(value) {
      if(value == "add-user") {
        this.init = false;
        this.clear();

        if(this.store.current.data.user.userId != null) {
          this.title = "Gebruiker wijzigen";
          this.submitText = "Wijzigingen opslaan";
          this.userId = this.store.current.data.user.userId;
        }
        else {
          this.title = "Gebruiker toevoegen";
          this.submitText = "Record aanmaken";
          this.userId = null;
          this.user.companyId = this.store.current.data.user.companyId;
          this.user.isAdmin = false;
        }
        
        await this.fetch();
        this.init = true;
        this.pending = false;
      }
    },
    user(listing) {
      for(const[key, value] of Object.entries(listing)) {
        if(!this.defaultData[key]) continue;
        if(value && value.toString().length > 0) {
          this.labelData[key] = {text: this.defaultData[key][0], active: true};
        }
        else {
          this.labelData[key] = {text: '', active: false};
          this.user[key] = this.defaultData[key][1];
        }
      }
    }
  },
  methods: {
    async add() {
      if(this.pending) return;
      this.pending = true;
      let response = null;
      if(this.userId == null) {
        response = await userService.add(this.user, this.defaultData);
      }
      else {
        response = await userService.update(this.user, this.defaultData);
      }

      if(response) {
        if(response.status == 200) {
          this.close();
        }
        else if(response.data.code == "EEX_EXC") {
          this.$emit('showMessage', "Er is al een gebruiker geregistreerd op dit email-adres.");
        }
        else {
          this.$emit('showMessage', response);
          this.formErrors = errorHandler.process(response.data, "");
        }
      }
      this.pending = false;
    },
    empty(e) {
      const val = this.user[e.target.name];
      if(this.defaultData[e.target.name] && (val == "" || val == this.defaultData[e.target.name][1])) {
        this.labelData[e.target.name] = {text: this.defaultData[e.target.name][0], active: true};
        this.user[e.target.name] = "";
      }
    },
    reset(e) {
      const val = this.user[e.target.name];
      if(val == "") {
        this.labelData[e.target.name] = {text: '', active: false};
        this.user[e.target.name] = this.defaultData[e.target.name][1];
      }
    },
    clear() {
      for(const[key, val] of Object.entries(this.defaultData)) {
        this.labelData[key] = {text: '', active: false};
        this.user[key] = val[1];
      }
      this.formErrors = {};
    },
    toggle(e) {
      this.user[e.target.name] = !this.user[e.target.name];
    },
    close() {
      this.store.current.popup.splice(
        this.store.current.popup.indexOf('add-user'), 
        1
      );
      this.store.current.data.user = null;
      this.$emit('updateParent');
    },
    async fetch() {
      if(this.userId) {
        this.user = await userService.select(this.userId);
      }
      this.fetchListings();
    },
    async fetchListings() {
      this.genders = await listingService.genders();
    }
  },
  components: {
    Multiselect
  }
}
</script>
