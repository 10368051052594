import api from './api';
import tokenService from "./token.service";

class UserMessageService {

  async read(messageId) {
    try {
      await tokenService.awaitSessionRefresh();
      const response = await api.post(
        "/user/message/"+messageId+"/read", 
      );
      return response;
    }
    catch (error) {
      return error.response;
    }
  }

  async unread(messageId) {
    try {
      await tokenService.awaitSessionRefresh();
      const response = await api.post(
        "/user/message/"+messageId+"/unread", 
      );
      return response;
    }
    catch (error) {
      return error.response;
    }
  }

  async readAll() {
    try {
      await tokenService.awaitSessionRefresh();
      const response = await api.post(
        "/user/message/read", 
      );
      return response;
    }
    catch (error) {
      return error.response;
    }
  }

  async count() {
    try {
      await tokenService.awaitSessionRefresh();
      const response = await api.post(
        "/user/message/count"
      );
      return response.data;
    }
    catch (error) {
      return error.response;
    }
  }
  

  async listing(queryArgs) {
    try {
      await tokenService.awaitSessionRefresh();
      const response = await api.post(
        "/user/message/listing", queryArgs
      );
      return response;
    }
    catch (error) {
      return error.response;
    }
  }
}
export default new UserMessageService();