<template>
  <transition name="fade">
    <div class="popup" v-if="store.current.popup.includes('add-pdf-document')">
      <div>
        <perfect-scrollbar style="height:100%">
          <form v-if="!this.store.current.data.pdf.document" @submit.prevent="add">
            <div class="closer" @click="close()">Scherm sluiten</div>
            <h1>{{ title }}</h1>

            <div v-if="init && store.uploadProgress == 0">
              <!--<label>
                {{ defaultData.integrationId[0] }}
                <Multiselect 
                  v-model="document.integrationId"
                  :options="integrations" 
                  placeholder="Maak een keuze..."
                  noOptionsText="Geen opties gevonden.">
                </Multiselect>
              </label>
              <div v-if="formErrors.integration" class="error-field">{{ defaultData.integrationId[0] + " " + formErrors.integration }}!</div>-->

              <template v-if="this.store.fileToUpload">
                <h3>Het bestand staat klaar om te uploaden</h3>
                <p>U kunt hieronder de eigenschappen voor het document aanpassen.</p>
                <div v-if="labelData.name" class="before-input" :class="{active:labelData.name.active}">{{ labelData.name.text }}</div>
                <input type="text" 
                  name="name" 
                  v-model="document.name"
                  :class="{hasLabel:labelData.name.active}" 
                  @focusin="empty($event)" 
                  @focusout="reset($event)">
                <div v-if="formErrors.name" class="error-field">{{ defaultData.name[0] + " " + formErrors.name }}!</div>
              </template>
              <template v-else>
                <FileUpload 
                  :maxSize="500000"
                  :file="new Object()"
                  accept="pdf,xls,xlsx"
                  @show-message="$emit('showMessage')"
                  @file-changed="validateUpload"
                />
              </template>

              <input type="submit" :value="submitText" :class="{locked:isLocked}">
            </div>
            <div v-else-if="store.uploadProgress > 0">
              <div class="loader" style="margin-bottom:2vh">
                <div>Even geduld, uw bestand wordt geüpload ({{ store.uploadProgress }}% voltooid)...</div>
              </div>
              <div v-if="error.length > 0" class="error-field">{{ error }}</div>
              <input class="submit red" @click="cancel" value="Upload annuleren">
            </div>
            <div v-else class="loader">
              <div>Even geduld, content wordt geladen...</div>
            </div>
          </form>
          <form v-else @submit.prevent="update">
            <div class="closer" @click="close()">Scherm sluiten</div>
            <h1>{{ title }}</h1>

            <div v-if="labelData.name" class="before-input" :class="{active:labelData.name.active}">{{ labelData.name.text }}</div>
            <input type="text" 
              name="name" 
              v-model="document.name"
              :class="{hasLabel:labelData.name.active}" 
              @focusin="empty($event)" 
              @focusout="reset($event)">
            <div v-if="formErrors.name" class="error-field">{{ defaultData.name[0] + " " + formErrors.name }}!</div>

            <input type="submit" :value="submitText" :class="{locked:isLocked}">
          </form>
        </perfect-scrollbar>
      </div>
    </div>
  </transition>
</template>

<script>
import documentService from '@/services/app/document.service'
import errorHandler from '@/common/ErrorHandler'

import Multiselect from '@vueform/multiselect'
import FileUpload from '@/components/FileUpload'
import DropboxBrowser from '@/components/app/integration/DropboxBrowser.vue'

export default {
  emits: ['showMessage', 'updateParent'],
  data() {
    return {
      document: {
        projectId: 0,
        documentId: 0,
        name: ''
      },
      integrations: [],
      defaultData: {
        name: ['Documentnaam', 'Welke naam moet worden gebruikt voor dit document?']
      },
      labelData: {},
      formErrors: {},
      title: "",
      submitText: "",
      error: "",
      init: false,
      pending: false,
    };
  },
  computed: {
    store() {
      return this.$parent.store;
    },
    currentPopup() {
      return this.store.current.popup[this.store.current.popup.length-1];
    },
    isLocked() {
      if(this.pending || !this.init) return true;
      else return false;
    }
  },
  watch: {
    async currentPopup(value) {
      if(value == "add-pdf-document") {
        this.init = false;
        this.store.fileToUpload = null;
        this.store.uploadProgress = 0;
        this.clear();

        this.document.projectId = this.store.current.data.pdf.project.projectId;
        if(this.store.current.data.pdf.document) {
          this.document = { ...this.store.current.data.pdf.document };
          await this.fetch();

          this.title = "Document wijzigen";
          this.submitText = "Wijzigingen opslaan";
          this.document.documentId = this.store.current.data.pdf.document.documentId;
        }
        else {
          this.title = "Document toevoegen";
          this.submitText = "Record aanmaken";
          this.document.documentId = null;
        }
        
        await this.fetch();
        this.init = true;
        this.pending = false;
      }
      else {
        this.store.fileToUpload = null;
        this.store.uploadProgress = 0;
      }
    },
    document(listing) {
      for(const[key, value] of Object.entries(listing)) {
        if(!this.defaultData[key]) continue;
        if(value && value.toString().length > 0) {
          this.labelData[key] = {text: this.defaultData[key][0], active: true};
        }
        else {
          this.labelData[key] = {text: '', active: false};
          this.document[key] = this.defaultData[key][1];
        }
      }
    }
  },
  methods: {
    async add() {
      if(this.pending) return;
      this.pending = true;
      this.error = "";
      let response = await documentService.upload(this.document, this.defaultData, this.store.fileToUpload);

      /*if(!this.document.documentId) {
        switch(this.document.integrationId) {
          case 'Uploaden vanaf computer':
            response = await documentService.upload(this.document, this.defaultData, this.store.fileToUpload);
            break;
          default:
            response = await documentService.add(this.document, this.defaultData);
            break;
        }
      }
      else {
        response = await documentService.update(this.document, this.defaultData);
      }*/

      if(response) {
        if(response.status == 200) {
          this.store.current.data.pdf.transaction = { ...response.data };
          this.close();
          this.store.current.popup = ["approve-pdf-transaction"];
        }
        else {
          this.$emit('showMessage', response);
          if(response.data != null && response.data.message != null) {
            this.error = response.data.message;
          }
          this.formErrors = errorHandler.process(response.data, "");
        }
      }
      this.pending = false;
    },
    async update() {
      if(this.pending) return;
      this.pending = true;
      this.error = "";

      let response = await documentService.update(this.document, this.defaultData);
      if(response) {
        if(response.status == 200) {
          this.store.current.data.pdf.transaction = { ...response.data };
          this.$emit("updateParent");
          this.close();
        }
        else {
          this.$emit('showMessage', response);
          if(response.data != null && response.data.message != null) {
            this.error = response.data.message;
          }
          this.formErrors = errorHandler.process(response.data, "");
        }
      }
      this.pending = false;
    },
    cancel() {
      this.store.fileToUpload = null;
      this.store.uploadProgress = 0;
    },
    validateUpload(file) {
      this.store.fileToUpload = file.data;
      this.document.name = file.name;
      this.labelData['name'] = {text: 'Documentnaam', active: true};
      this.isInvalid = !file.isUploaded;
    },
    empty(e) {
      const val = this.document[e.target.name];
      if(this.defaultData[e.target.name] && (val == "" || val == this.defaultData[e.target.name][1])) {
        this.labelData[e.target.name] = {text: this.defaultData[e.target.name][0], active: true};
        this.document[e.target.name] = "";
      }
    },
    reset(e) {
      const val = this.document[e.target.name];
      if(val == "") {
        this.labelData[e.target.name] = {text: '', active: false};
        this.document[e.target.name] = this.defaultData[e.target.name][1];
      }
    },
    clear() {
      for(const[key, val] of Object.entries(this.defaultData)) {
        this.labelData[key] = {text: '', active: false};
        this.document[key] = val[1];
        this.formErrors = {};
      }
    },
    close() {
      this.store.current.popup.splice(
        this.store.current.popup.indexOf('add-pdf-document'), 
        1
      );
      this.store.current.data.pdf.document = null;
      //this.$emit('updateParent');
    },
    async fetch() {
      if(this.document.documentId) {
        this.document = await documentService.select(this.document);
      }
      //this.documentTypes = await listingService.documentTypes();
      //this.integrations = await listingService.integrations();
      //this.integrations[0] = "Uploaden vanaf computer";
    }
  },
  components: {
    Multiselect,
    FileUpload,
    DropboxBrowser
  }
}
</script>
