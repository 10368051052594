<template>
  <div class="pag">
    <div :class="{hide:!isVisible}">
      <div @click="prev" class="goto" :class="{hide:!prevVisible}">❮❮</div>
      <div v-for="offset in pages" :key="offset" @click="goTo(offset-1)" class="goto" :class="activeClass(offset)">{{offset}}</div>
      <div @click="next" class="goto" :class="{hide:!nextVisible}">❯❯</div>
    </div>
    <div>
      {{ this.queryArgs.count }} resultaten gevonden.
    </div>
  </div>
  <div style="clear:both"></div>
</template>
  
<script>
export default {
  emits: ["changeOffset"],
  name: "Pagination",
  props: {
    queryArgs: Object
  },
  data() {
    return {
      pages: [],
      isVisible: true,
      prevVisible: true,
      nextVisible: true
    }
  },
  created() {
    this.pagesToShow();
  },
  methods: {
    pagesToShow() {
      var pages = [];
      if(this.queryArgs.count <= this.queryArgs.pageSize) {
        this.isVisible = false;
      }
      else {   
        this.isVisible = true;   
        if(this.queryArgs.offset <= 2) {
          pages.push(1);
          pages.push(2);
          pages.push(3);
          pages.push(Math.ceil(this.queryArgs.count / this.queryArgs.pageSize));
        }
        else {
          pages.push(1);
        }
        
        pages.push(this.queryArgs.offset);
        pages.push(this.queryArgs.offset + 1);
        pages.push(this.queryArgs.offset + 2);

        if(this.queryArgs.offset > Math.ceil(this.queryArgs.count / this.queryArgs.pageSize) - 3) {
          pages.push(Math.ceil(this.queryArgs.count / this.queryArgs.pageSize));
          pages.push(Math.ceil(this.queryArgs.count / this.queryArgs.pageSize) - 1);
          pages.push(Math.ceil(this.queryArgs.count / this.queryArgs.pageSize) - 2);
        }
        else {
          pages.push(Math.ceil(this.queryArgs.count / this.queryArgs.pageSize));
        }

        for(var i = pages.length - 1; i > 0; i--) {
          if(pages[i] <= 0) pages.splice(i, 1);
          if(pages[i] > Math.ceil(this.queryArgs.count / this.queryArgs.pageSize)) pages.splice(i, 1);
        }
        pages = [...new Set(pages)];
        pages.sort(function(a, b) {
          return a - b;
        });

        for(var i = 0; i < pages.length - 1; i++) {
          if(pages[i] == "...") continue;
          if(pages[i+1] == "...") continue;
          if(pages[i+1] != pages[i]+1) {
            pages.splice(i+1, 0, "...");
            i = 0;
          }
        }
      }

      if(this.queryArgs.offset == 0) this.prevVisible = false;
      else this.prevVisible = true;

      if(this.queryArgs.offset + 1 == pages[pages.length-1]) this.nextVisible = false;
      else this.nextVisible = true;

      this.pages = pages;
    },
    prev() {
      var pageId = this.queryArgs.offset - 1;
      if(pageId < 0) 
        pageId = 0;
      this.$emit('changeOffset', pageId);
    },
    next() {
      var pageId = this.queryArgs.offset + 1;
      if(pageId > Math.ceil(this.queryArgs.count / this.queryArgs.pageSize) - 1) 
        pageId = Math.ceil(this.queryArgs.count / this.queryArgs.pageSize) - 1;
      this.$emit('changeOffset', pageId);
    },
    goTo(offset) {
      this.$emit('changeOffset', offset);
    },
    activeClass(i) {
      return {
        'active': this.queryArgs.offset + 1 === i
      }
    }
  },
};
</script>

<style scoped>
.hide {
  display: none !important
}
.pag {
  padding: 10px;
  padding-left: 2vh;
  padding-top: 3vh;
  width: 100%;
  box-sizing: border-box;
  border-bottom-left-radius: 2vh;
  border-bottom-right-radius: 2vh;
  position: relative;
  background: rgba(255,255,255,0.8);
  height: calc(22px + 4vh);
  margin-top: -2vh;
  font-size: 12px;
  line-height: 20px;
  font-weight: bold;
  font-style: italic;
  color: rgba(0,0,0,0.7)
}

.pag > div {
  float: left;
  padding-right: 2vh
}

.goto {
  display: block;
  cursor: pointer;
  padding: 5px 8px;
  margin-right: 5px;
  float: left;
  background: rgb(81,111,157);
  background: linear-gradient(40deg, rgb(81, 127, 157) 0%, rgb(56, 83, 124) 100%);
  color: #fff;
  font-weight: bold;
  border-radius: 1vh;
  opacity: 0.5;
  transition-duration: 0.3s;
  line-height: 1em
}

.goto.active, .goto:hover {
  opacity: 1;
  transition-duration: 0.3s;
}

.prev {
  cursor: pointer;
  float: left;
  margin-right: 2vh
}
</style>
