import api from './api'
import tokenService from './token.service'

class AuthService {
  async login(formData, defaultData) {
    try {
      const submitData = this.toSubmitData(formData, defaultData);

      //await tokenService.awaitSessionRefresh();
      const response = await api.post(
        "/auth/login", {
          username: submitData.username,
          password: submitData.password
        }
      );
      
      if(response.data.isValid) {
        tokenService.setSecurityToken(response.data.securityToken);
      }
      return response;
    }
    catch (error) {
      return error.response;
    }
  }

  async login2FA(formData, defaultData) {
    try {
      const submitData = this.toSubmitData(formData, defaultData);

      //await tokenService.awaitSessionRefresh();
      const response = await api.post(
        "/auth/login-2fa", {
          username: formData.username,
          password: formData.password,
          securityToken: tokenService.getSecurityToken(),
          multiToken: submitData.multiToken
        }
      );

      if(response.data.accessToken) {
        tokenService.setLocalAccessToken(response.data.accessToken);
        tokenService.setLocalRefreshToken(response.data.refreshToken);
        tokenService.setRoles(response.data.roles);
        tokenService.setSecurityToken(null);

        delete response.data.accessToken;
        delete response.data.refreshToken;
        delete response.data.roles;

        tokenService.setUser(response.data);
      }
      return response;
    }
    catch (error) {
      return error.response;
    }
  }

  async sessionRequest() {
    try {
      //await tokenService.awaitSessionRefresh();
      const response = await api.get(
        "/auth/session-request"
      );

      if(response.data.accessToken) {
        tokenService.setAnonymous(response.data.uuid);
        tokenService.setLocalAccessToken(response.data.accessToken);
        return response;
      }
      else return null;
    }
    catch (error) {
      return error.response;
    }
  }

  async resetRequest(formData, defaultData) {
    try {
      const submitData = this.toSubmitData(formData, defaultData);

      //await tokenService.awaitSessionRefresh();
      const response = await api.post(
        "/auth/reset-request", {
          email: submitData.email
        }
      );
      return response;
    }
    catch (error) {
      return error.response;
    }
  }

  async resetCheckValidity(formData) {
    try {
      //await tokenService.awaitSessionRefresh();
      const response = await api.post(
        "/auth/reset-check-validity", {
          username: formData.username,
          token: formData.token
        }
      );
      return response;
    }
    catch (error) {
      return error.response;
    }
  }

  async resetChange(formData, defaultData) {
    try {
      const submitData = this.toSubmitData(formData, defaultData);

      //await tokenService.awaitSessionRefresh();
      const response = await api.post(
        "/auth/reset-change", {
          username: formData.username,
          token: formData.token,
          password1: submitData.password1,
          password2: submitData.password2
        }
      );
      return response;
    }
    catch (error) {
      return error.response;
    }
  }

  async register(formData, defaultData) {
    try {
      //await tokenService.awaitSessionRefresh();
      const response = await api.post(
        "/auth/register", this.toSubmitData(formData, defaultData)
      );
      return response;
    }
    catch (error) {
      return error.response;
    }
  }

  async activateUser(getData) {
    try {
      //await tokenService.awaitSessionRefresh();
      const response = await api.post(
        "/auth/activate", getData
      );
      return response;
    }
    catch (error) {
      return error.response;
    }
  }

  async refresh() {
    try {
      var headers = {};
      headers.isRefreshToken = true;
      const rs = await api.post(
        "/auth/refreshtoken", null, {
          headers: headers
        }
      );

      if(rs.status == 200 && rs.data != null && rs.data.accessToken != null) {
        tokenService.setLocalAccessToken(rs.data.accessToken);
        tokenService.setLocalRefreshToken(rs.data.refreshToken);
        tokenService.setRoles(rs.data.roles);
        return true;
      }
      else return false;
    } 
    catch (e) {
      return false;
    }
  }

  async logout() {
    try {
      var headers = {};
      headers.isLogoutUser = true;

      //await tokenService.awaitSessionRefresh();
      const rs = await api.post(
        "/auth/logout", null, {
          headers: headers
        }
      );

      tokenService.removeUser();
      return true;
    }
    catch (error) {
      return false;
    }
  }

  getUser() {
    return tokenService.getUser();
  }

  toSubmitData(formData, defaultData) {
    const submitData = {...formData};
    for(const [key, val] of Object.entries(submitData)) {
      if(!isNaN(val)) continue;
      if(defaultData[key] && val == defaultData[key][1]) {
        submitData[key] = null;
      }
    }
    return submitData;
  }
}
export default new AuthService();