<template>
  <div class="content" v-if="this.renderStore.options.activeSideMenu === 'miniatures'">
    <div class="cm" @click="closeSideMenu()" title="Scherm sluiten">
      <img src="img/ico/close-1.png">
    </div>
    <h3>Navigatie</h3>
    <template v-if="this.renderStore.data != null">
      <template v-for="[pageNumber, data] in Object.entries(this.renderStore.data)" :key="pageNumber">
        <div class="min-nav" :class="{active: pageNumber == this.renderStore.options.pageNumber}" @click="goToPage(pageNumber)">
          <span class="nav-icon">{{ pageNumber }}</span>
          <img v-if="this.thumbnailLoaded(pageNumber)" :src="thumbnail(pageNumber)">
          <img v-else src="img/spinner.gif">
          <div>
            <span class="nav-new" v-if="data['annotations']['PAGE'].isNew">nieuw</span>
            {{ parsedText((this.renderStore.pageLabels && this.renderStore.pageLabels[pageNumber-1]) ? parsedText(this.renderStore.pageLabels[pageNumber-1]) : "Paginalabel onbekend") }}
          </div>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import { resetSelection } from '@/components/app/viewer/PdfCanvas.js'

export default {
  computed: {
    store() {
      return this.$parent.store;
    },
    renderStore() {
      return this.$parent.renderStore;
    },
    thumbnail() {
      try { return pageNumber => this.renderStore.images.data[pageNumber][0][0][0].easel.image.currentSrc; }
      catch { return null; }
    }  
  },
  methods: {
    goToPage(pageNumber) {
      if(pageNumber > 0) {
        resetSelection();
        this.renderStore.options.pageNumber = parseInt(pageNumber);
      }
    },
    thumbnailLoaded(pageNumber) {
      try { 
        if(this.renderStore.images.data[pageNumber][0][0][0].easel.image.width > 0) {
          return true;
        }
        return false;
      }
      catch { 
        return false;
      }
    },
    parsedText(text) {
      try { return text.replace(/&#(\d+);/g, function (m, n) { 
        return String.fromCharCode(n); }); 
      }
      catch(e) { return ""; }
    },
    closeSideMenu() {
      this.renderStore.options.activeSideMenu = null;
    }
  }
}
</script>
