<template>
  <div v-if="!document.isVerified" class="top-icon" style="right:70px">
    <img src="img/ico/not-verified.png">
    <div @click="verify">
      <p>
        Dit document is niet geverifieerd, waardoor hoeveelheden incompleet kunnen worden 
        weergegeven in de lijst "Projecttotalen en -subtotalen".
        <template v-if="document.isOwner"><b>Klik op dit bericht om de hoeveelheden te verifiëren.</b></template>
      </p>
    </div>
  </div>
  <div v-else class="top-icon" style="right:70px">
    <img src="img/ico/verified.png">
    <div>
      <p>
        Dit document is geverifieerd. U kunt er vanuit gaan dat hoeveelheden in de lijst
        "Projecttotalen en -subtotalen" compleet zijn.
      </p>
    </div>
  </div>
</template>

<script>
import { resetSelection } from '@/components/app/viewer/PdfCanvas.js'

export default {
  props: {
    document: Object
  },
  computed: {
    store() {
      return this.$parent.store;
    },
    renderStore() {
      return this.$parent.renderStore;
    }
  },
  methods: {
    async verify() {
      this.store.current.popup = ["verify-pdf-document"];
      if(this.renderStore.options.filterAnnotations.length > 0 || this.renderStore.options.queryAnnotations.length > 0) {
        this.renderStore.options.filterAnnotations = "";
        this.renderStore.options.queryAnnotations = "";
        await this.renderStore.loadAnnotations(true);
        resetSelection();
      }
    }
  }
}
</script>