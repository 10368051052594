<template>
  <div class="top-icon">
    <img src="img/spinner.gif">
    <div>
      <p>
        Enkele pagina's binnen het document worden momenteel op een hogere resolutie voor u ingeladen.
        Totdat dit is voltooid, zijn sommige details wellicht minder goed leesbaar.
      </p>
    </div>
  </div>
</template>