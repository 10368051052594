function formatContribution(contribution, contributionTemplate) {
  contribution.title = parseValues(contributionTemplate.listingOutput.title, contribution.values)
  contribution.templateName = contributionTemplate.name;
  contribution.templateVersion = contributionTemplate.version;
  contribution.values = JSON.parse(contribution.values);
  
  let dateCreated = new Date(contribution.dateCreated * 1000);
  contribution.dateCreatedUnix = contribution.dateCreated;
  contribution.dateCreated = dateCreated.toLocaleDateString("nl-nl") + " om " + dateCreated.toLocaleTimeString("nl-nl");

  let dateModified = new Date(contribution.dateModified * 1000);
  contribution.dateModifiedUnix = contribution.dateModified;
  contribution.dateModified = dateModified.toLocaleDateString("nl-nl") + " om " + dateModified.toLocaleTimeString("nl-nl");

  if(contribution.answers != null) {
    contribution.answers.forEach(answer => {
      let dateCreated = new Date(answer.dateCreated * 1000);
      answer.dateCreatedUnix = answer.dateCreated;
      answer.dateCreated = dateCreated.toLocaleDateString("nl-nl") + " om " + dateCreated.toLocaleTimeString("nl-nl");

      let dateModified = new Date(answer.dateModified * 1000);
      answer.dateModifiedUnix = answer.dateModified;
      answer.dateModified = dateModified.toLocaleDateString("nl-nl") + " om " + dateModified.toLocaleTimeString("nl-nl");
    });
  }
  return contribution;
}

function parseValues(format, values) {
  for(const [key,value] of Object.entries(JSON.parse(values))) {
    format = format.replaceAll("{{"+key+"}}", value);
  }
  if(format.startsWith(": ")) format = format.substring(2);
  return format;
}

export { formatContribution };