<template>

  <MenuApp 
    @logout-user="logout"
    @reset-data="resetData"
  />

  <div id="content-box">
    <MenuRegistrar v-if="store.isRegistrar" />

    <!-- FORMS -->
    <ListProjects @show-message="showMessage" />
    <ViewProject @show-message="showMessage" />
    <ViewDocument @show-message="showMessage" />

    <ListCompanies v-if="store.isRegistrar" @show-message="showMessage" />
    <ListUsers v-if="store.isRegistrar" @show-message="showMessage" />
    <ListLogRequests v-if="store.isRegistrar" @show-message="showMessage" />
    <ViewCompany v-if="store.isRegistrar" @show-message="showMessage" />

    <!-- POPUPS -->
    <PopupLoginUser @show-message="showMessage" />
    <PopupLogoutUser @show-message="showMessage" />
    <PopupRegisterUser @show-message="showMessage" />
    <PopupActivationUser @show-message="showMessage" />
    <PopupResetUser @show-message="showMessage" />
    <PopupMessageCenter />
    <About />
  </div>

  <transition name="fade">
    <div class="message error" v-if="error !== ''">{{ error }}</div>
  </transition>
</template>

<script>
import MenuApp from '@/components/MenuApp.vue'
import MenuRegistrar from '@/components/MenuRegistrar.vue'

import About from '@/components/About.vue'

import ListProjects from '@/components/app/ListProjects.vue'
import ViewProject from '@/components/app/ViewProject.vue'
import ViewDocument from '@/components/app/ViewDocument.vue'

import ListCompanies from '@/components/ListCompanies.vue'
import ListUsers from '@/components/ListUsers.vue'
import ViewCompany from '@/components/ViewCompany.vue'
import PopupLoginUser from '@/popups/LoginUser.vue'
import PopupLogoutUser from '@/popups/LogoutUser.vue'
import PopupRegisterUser from '@/popups/RegisterUser.vue'
import PopupActivationUser from '@/popups/ActivationUser.vue'
import PopupResetUser from '@/popups/ResetUser.vue'
import PopupMessageCenter from '@/popups/MessageCenter.vue'
import ListLogRequests from '@/components/ListLogRequests.vue'

import { useStore } from '@/store/pinia'
import authService from '@/services/auth.service'
import tokenService from '@/services/token.service'

export default {
  name: "Home",
  data() {
    return {
      pageName: "Home",
      store: useStore(),
      error: ""
    };
  },
  computed: {
    currentForm() {
      return this.store.current.form;
    }
  },
  watch: {
    currentForm: {
      handler(val) {
        if(val == "") {
          //this.store.current.form = "list-pdf-projects";
        }
      },
      immediate: true
    }
  },
  methods: {
    async activateUser() {
      if((typeof this.$route.query.u !== 'undefined' || typeof this.$route.query.e !== 'undefined') && typeof this.$route.query.t !== 'undefined') {
        this.getData = {
          "username": this.$route.query.u,
          "email": this.$route.query.e,
          "token": this.$route.query.t
        };
        const response = await authService.activateUser(this.getData);
        if(response) {
          if(response.status == 200) {
            this.store.current.popup = ["activate-user-success"];
          }
          else {
            this.store.current.popup = ["activate-user-fail"];
          }
        }
      }
      this.$router.replace("/");
    },
    async viewDocument() {
      this.store.current.form = "view-pdf-document";
    },
    async resetUser() {
      this.store.current.popup = ["reset-user-change"];
    },
    async logout() {
      if(await authService.logout()) {
        this.store.current.popup = [];
        this.store.current.form = "list-pdf-projects";
        this.$router.go();
      }
    },
    showMessage(response) {
      if(!response || response.message == "Network Error") {
        this.error = "Er is een generieke fout opgetreden tijdens de communicatie met ons systeem. Controleer uw internetverbinding en probeer het opnieuw.";
      }
      else {
        switch(response.status) {
          case 401:
            this.error = "Het lijkt erop dat u geen toegang heeft tot de opgevraagde pagina.";
            break;
          default:
            if(response.data && response.data.message)
              this.error = response.data.message;
            else if(typeof response == "string") {
              this.error = response;
            }
            else
              this.error = "Er is een generieke fout opgetreden tijdens de communicatie met ons systeem. Kunt u het opnieuw proberen? Als dit het probleem niet oplost, neem dan alstublieft contact met ons op voor ondersteuning.";
            break;
        }
      }
      setTimeout(() => this.error = '', 5000);
    },
    resetData() {
      for(const [key, value] of Object.entries(this.store.current.data)) {
        this.store.current.data[key] = {};
      }
    }
  },
  created() {
    this.store.current.form = "list-pdf-projects";
    this.store.current.popup = [];
    tokenService.setLocalAccessToken(this.store.accessToken);
    tokenService.setLocalRefreshToken(this.store.refreshToken);
  },
  mounted() {
    if(typeof this.$route.query.activate !== 'undefined') {
      this.activateUser();
    }
    if(typeof this.$route.query.document !== 'undefined') {
      this.viewDocument();
    }
    if(typeof this.$route.query.reset !== 'undefined') {
      this.resetUser();
    }
    if(typeof this.$route.query.p !== 'undefined') {
      this.store.current.data.pdf.project = { uuid: this.$route.query.p };
      this.store.current.form = 'view-project';
    }
  },
  components: { 
    MenuApp,
    MenuRegistrar,
    
    About,

    ListProjects,
    ViewProject,
    ViewDocument,

    ListCompanies,
    ListUsers,
    ViewCompany,

    PopupLoginUser,
    PopupLogoutUser,
    PopupRegisterUser,
    PopupActivationUser,
    PopupResetUser,
    PopupMessageCenter,
    ListLogRequests
  }
};
</script>

<style src="@vueform/multiselect/themes/default.css"></style>