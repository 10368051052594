<template>
  <div id="holder-contrib" ref="contributionViewer" :class="{collapsed: collapsed, hidden: hidden}">
    <div class="rs" v-on:mousedown="scaleHolder"></div>
    
    <div id="options" :style="(this.store.current.popup.length > 0) ? 'opacity:0.1;pointer-events:none' : ''">
      <div @click="showAnnotations" class="nav-icon"><img src="img/ico/markup.png" title="Projecttotalen en -subtotalen"></div>
      <div @click="showContributions" class="nav-icon" style="margin-left:40px" :style="(this.renderStore.options.showContributions)?'background:rgba(39,124,53,0.5)':''"><img src="img/ico/nav-contribution.png" title="Markeringen van gebruikers" :style="(this.renderStore.options.showContributions)?'opacity:0.9;filter:none !important':''"></div>
      <div v-if="!collapsed" class="ti">
        <div @click="toggleFullscreen" class="nav-icon" style="position:relative;float:right" title="Maximaliseren"><img :src="(this.store.pivotFullScreen)?'img/ico/expand.png':'img/ico/collapse.png'"></div>
        <div @click="download" class="nav-icon" style="position:relative;float:right" title="Download gegevens"><img src="img/ico/download-b.png"></div>
        <div @click="setupContrib" class="nav-icon" style="position:relative;float:right" title="Instellingen"><img src="img/ico/settings.png"></div>
        <h3>Markeringen van gebruikers</h3>
      </div>
      <div class="tid">
        <label for="checkbox" :class="{ selected: filtered }" style="height:40px">
          <SearchField ref="searcher" @search="filterAndSearch" />
          <img src="img/ico/close.png" @click="filterAndSearch('')">
        </label>
        <div v-on:click="toggleFilterForUser" class="button" :class="{ selected: !this.renderStore.options.filterContributionsForUser }">Eigen bijdragen</div>
        <div v-on:click="toggleFilterForPage" class="button" :class="{ selected: !this.renderStore.options.filterContributionsForPage }">Projecttotalen</div>
        
        <div v-if="this.renderStore.options.filterContributions.length > 0" v-on:click="resetFilter" class="button red">Filter opheffen</div>
      </div>
    </div>

    <template v-if="!collapsed">
      <a :href=blobUrl class="href-hidden" ref="file-download"></a>
      <ContributionTable
        ref="contributionTable" 
        :pivotData="this.renderStore.filteredContributions"
        :totals="this.totals"
        :thead="this.renderStore.options.columnsContrib"
        :getData="this.getData"
        :style="(this.store.current.popup.length > 0) ? 'opacity:0.1;pointer-events:none' : ''"
        @update-parent="updateParent"
      />
    </template>
  </div>
</template>

<script>
import { nextTick } from 'vue'
import ContributionTable from '@/components/app/viewer/ContributionTable.vue'
import SearchField from '@/components/SearchField.vue'
import {  rescaleCanvas, resetSelection, selectAnnotation } from '@/components/app/viewer/PdfCanvas.js'

import documentService from '@/services/app/document.service'

export default {
  data() {
    return {
      totals: {},
      showDetails: false,
      showSpinner: true,
      iconFolding: "unfold_more",
      textFolding: "Metingen uitvouwen",
      isFilterOnPage: false,
      showPivotLevels: false,
      blobUrl: null,
      init: false
    };
  },
  emits: ['showMessage', 'updateParent'],
  props: {
    document: {
      type: Object,
      required: true
    },
    getData: {
      type: Object,
      required: true
    }
  },
  created() {
    this.renderStore.components.contributionViewer = this;
    this.renderStore.options.pageNumber = 1;
    this.renderStore.options.selectedAnnotationId = -1;
  },
  mounted() {
    
  },
  computed: {
    store() {
      return this.$parent.store;
    },
    renderStore() {
      return this.$parent.renderStore;
    },
    collapsed() {
      if(this.renderStore.options.showContributions) {
        return false;
      }
      else return true;
    },
    hidden() {
      if(this.renderStore.options.showAnnotations) {
        return true;
      }
      else return false;
    },
    filtered() {
      if(this.renderStore.options.queryContributions != null && this.renderStore.options.queryContributions.length > 0) return true;
      return false;
    },
    activeContribution() {
      return this.$parent.renderStore.options.activeContribution;
    },
    contributions() {
      return this.$parent.renderStore.contributions;
    },
    contributionTemplates() {
      return this.$parent.renderStore.contributionTemplates;
    },
    currentPageNumber() {
      return this.$parent.renderStore.options.pageNumber;
    },
    sortContributionsBy() {
      return this.$parent.renderStore.options.sortContributionsBy;
    }
  },
  watch: {
    stateFilterPage: function(newValue, oldValue) {
      this.renderStore.components.contributionViewer.showSpinner = true;
      this.renderStore.onePageData(newValue);
    },
    contributions: {
      handler() {
        this.filterContributions(true);
      },
      immediate: false,
      deep: true
    },
    currentPageNumber: {
      handler() {
        this.filterContributions(true);
      },
      deep: true
    },
    sortContributionsBy: {
      handler() {
        this.filterContributions(false);
      },
      deep: true
    }
  },
  methods: {
    scaleHolder(e) {
      this.renderStore.mouse.pos.x = e.screenX;
      this.renderStore.mouse.pos.y = e.screenY;
      this.renderStore.mouse.draggedElement = e.currentTarget;
      this.renderStore.options.showContributions = false;
      this.renderStore.options.showContributions = true;
    },
    togglePivotLevels(e) {
      this.showPivotLevels = !this.showPivotLevels;
    },
    toggleFullscreen(e) {
      var parentElement = e.currentTarget.parentElement.parentElement.parentElement;
      var style = window.getComputedStyle(parentElement);
      if(this.store.pivotFullScreen) {
        parentElement.style.height = style.minHeight;
        this.store.pivotFullScreen = false;
      }
      else {
        parentElement.style.height = style.maxHeight;
        this.store.pivotFullScreen = true;
      }
      rescaleCanvas(true);
    },
    showAnnotations() {
      resetSelection();
      if(this.renderStore.options.showAnnotations) {
        this.renderStore.options.showAnnotations = false;
      }
      else {
        this.renderStore.options.showAnnotations = true;
        this.renderStore.options.showContributions = false;
      }
      this.updateQuery();
    },
    showContributions() {
      resetSelection();
      if(this.renderStore.options.showContributions) {
        this.renderStore.options.showContributions = false;
      }
      else {
        this.renderStore.options.showAnnotations = false;
        this.renderStore.options.showContributions = true;
      }
      this.updateQuery();
    },
    updateQuery() {
      var self = this;
      nextTick(function() { 
        if(self.$refs.searcher != null) {
          if(self.renderStore.options.queryAnnotations.length > 0) {
            self.$refs.searcher.query = self.renderStore.options.queryAnnotations.query;
            self.$refs.searcher.isActive = true;
          }
        }
      });
    },
    async toggleFilterOnPage(e) {
      this.isFilterOnPage = !this.isFilterOnPage;
      this.renderStore.options.filterOnPage = this.isFilterOnPage;
    },
    openDetails(contributionId) {
      if(contributionId != null) {
        this.showDetails = true;
        this.renderStore.contributions.forEach(contribution => {
          if(contribution.contributionId == contributionId) {
            this.renderStore.options.activeContribution = {...contribution};
          }
        });
      }
      else {
        if(this.renderStore.options.selectedContributionId == -1) {
          this.showDetails = false;
          this.renderStore.options.activeContribution = null;
        }
      }
    },
    selectPivotRule() {
      this.showDetails = false;
      try { return this.$refs.annotationTable.scrollToActiveRow(); }
      catch { return false; }
    },
    hide() {
      this.renderStore.options.showContributions = false;
    },
    async expandToLevel(level) {
      await this.resetFilter();
      this.renderStore.options.expandToLevel = level;
      this.$refs.annotationTable.expandToLevel(level);
    },
    async resetFilter() {
      if(this.renderStore.options.filterContributions.length > 0 || this.renderStore.options.queryContributions.length > 0) {
        this.renderStore.options.filterContributions = "";
        resetSelection();
      }
    },
    selectContribution(contribution, key) {
      this.store.current.data.pdf.contribution = {...contribution};
      selectAnnotation(contribution.pageNumber, "Q"+contribution.contributionId, true);
    },
    toggleFilterForPage() {
      this.renderStore.options.filterContributionsForPage = !this.renderStore.options.filterContributionsForPage;
      this.filterContributions();
    },
    toggleFilterForUser() {
      this.renderStore.options.filterContributionsForUser = !this.renderStore.options.filterContributionsForUser;
      this.filterContributions();
    },
    filterContributions(render) {
      if(this.contributions != null) {
        var filteredContributions = [...this.contributions];
        for(var i = filteredContributions.length-1; i >= 0; i--) {
          var contribution = filteredContributions[i];

          if(this.renderStore.options.filterContributionsForPage) {
            if(contribution.pageNumber != this.renderStore.options.pageNumber) {
              delete filteredContributions[i];
              continue;
            }
          }
          if(this.renderStore.options.filterContributionsForUser) {
            if(contribution.askedBy != "uzelf") {
              delete filteredContributions[i];
              continue;
            }
          }
        }

        this.init = false;
        filteredContributions.sort(this.compare);
        this.renderStore.filteredContributions = {...filteredContributions};
        
        this.search(this.renderStore.options.queryContributions, render);
        this.findTotals();
        this.init = true;
      }
    },
    findTotals() {
      if(this.renderStore.filteredContributions == null) return;

      let totals = {};
      let column = this.renderStore.options.sortContributionsBy.column;
      Object.entries(this.renderStore.filteredContributions).forEach(contrib => {
        var sortName = contrib[1][column];
        if(!totals.hasOwnProperty(sortName)) {
          totals[sortName] = {};
          for(var head of this.renderStore.options.columnsContrib) {
            if(head.totals) {
              totals[sortName][head.name] = 0;
            }
          }
        }

        for(var head of this.renderStore.options.columnsContrib) {
          if(head.totals) {
            if(head.name.startsWith("values.")) {
              if(contrib[1].values[head.name.replace("values.", "")] != null) {
                totals[sortName][head.name] += contrib[1].values[head.name.replace("values.", "")];
              }
            }
            else {
              if(contrib[1][head.name] != null) {
                totals[sortName][head.name] += contrib[1][head.name];
              }
            }
          }
        }
      });

      Object.values(totals).forEach(total => {
        for(var head of this.renderStore.options.columnsContrib) {
          if(head.totals) {
            total[head.name] = Math.round(100 * total[head.name]) / 100;
          }
        }
      });
      this.totals = {...totals};
    },
    compare(a, b) {
      var column = this.renderStore.options.sortContributionsBy.column;
      var order = this.renderStore.options.sortContributionsBy.order;

      if(column.startsWith("values.")) {
        if(order == "asc") return (""+a.values[column].replace("values.", "")).localeCompare(""+b.values[column].replace("values.", ""));
        else return (""+b.values[column].replace("values.", "")).localeCompare(""+a.values[column].replace("values.", ""));
      }
      else {
        if(order == "asc") return (""+a[column]).localeCompare(""+b[column]);
        else return (""+b[column]).localeCompare(""+a[column]);
      }
    },
    filterAndSearch(query) {
      this.renderStore.options.queryContributions = query;
      this.filterContributions(true);
    },
    search(query) {
      this.renderStore.options.queryContributions = query;
      if(this.$refs.searcher != null) {
        this.$refs.searcher.query = query;
      }

      if(query != null && query.length > 0 && this.renderStore.filteredContributions != null) {
        var keys = Object.keys(this.renderStore.filteredContributions);
        for(const key of keys) {
          var contribution = this.renderStore.filteredContributions[key];
          var include = false;

          if(JSON.stringify(contribution.values).toLowerCase().indexOf(query.toLowerCase()) >= 0) {
            include = true;
          }

          if(!include) {
            delete this.renderStore.filteredContributions[key];
          }
        }
      }
    },
    async download() {
      this.pending = true;
      const url = await documentService.downloadContributions(
        this.document,
        this.renderStore.filteredContributions,
        this.getData.userEmail,
        this.getData.userToken,
        this.getData.accessKey
      );

      var a = this.$refs["file-download"];
      if(a != null) {
        a.href = url;
        a.download = this.document.currentUuid + ".xlsx";
        a.click();
        window.URL.revokeObjectURL(url);
      }

      this.pending = false;
    },
    setupContrib() {
      this.store.current.popup = ["setup-pdf-contrib"];
    },
    updateParent(data) {
      this.$emit('updateParent', data);
    }
  },
  components: {
    ContributionTable,
    SearchField
  }
}
</script>
