import api from "./api";
import authService from "./auth.service";
import tokenService from "./token.service";

var isRefreshing = false;
var awaitParallel = false;

async function sleep(milliseconds) {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
}

const setup = (store) => {
  api.interceptors.request.use(
    (config) => {
      if(store.user && store.user.uuid) {
        config.headers["isanonymous"] = true;
        config.headers["session-uuid"] = store.user.uuid;
        config.headers["session-access-token"] = store.accessToken;
        //config.headers["Content-Type"] = "application/json";
      }
      else if(store.accessToken != null) {
        config.headers["x-access-token"] = "Bearer " + store.accessToken;
        config.headers["x-refresh-token"] = "Bearer " + store.refreshToken;
        //config.headers["Content-Type"] = "application/json";
      }
      //console.log(config.headers);
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  api.interceptors.response.use(
    (res) => {
      //console.log(res);
      if(typeof res.data === 'string') {
        res.data = res.data.replaceAll("###", "");
        try { res.data = JSON.parse(res.data); }
        catch {}
      }
      return res;
    },
    async (err) => {
      const originalConfig = err.config;
      const { config, message } = err;

      if (originalConfig.url !== "/auth/login" && err.response) {
        if(typeof err.response.data === 'string') {
          err.response.data = err.response.data.replace("###", "");
          try { err.response.data = JSON.parse(err.response.data); }
          catch {}
        }

        // Access Token was expired
        if (err.response.status === 401 && !originalConfig._retry) {
          //console.log("retry?");
          // only refresh when it is a user session (not anonymous)
          if(store.user && store.user.uuid) {
            tokenService.removeUser();
            tokenService.setLocalAccessToken(null);
            return;
          }

          //console.log("refresh token...");
          originalConfig._retry = true;
          //console.log("Old access-key: " + tokenService.getLocalAccessToken());

          while(tokenService.getSessionRefreshingState()) {
            awaitParallel = true;
            await sleep(300);
            //console.log("zzzz (for parallel)...");
          }

          if(awaitParallel) {
            originalConfig.headers["x-access-token"] = tokenService.getLocalAccessToken();
            originalConfig.headers["x-refresh-token"] = tokenService.getLocalRefreshToken();
            awaitParallel = false;
            return api(originalConfig);
          }

          if(isRefreshing) {
            //console.log("waiting required!");
            while(tokenService.getSessionRefreshingState()) {
              await sleep(300);
              //console.log("zzzz...");
            }
            //console.log("free to go!");
            originalConfig.headers["x-access-token"] = tokenService.getLocalAccessToken();
            originalConfig.headers["x-refresh-token"] = tokenService.getLocalRefreshToken();
            return api(originalConfig);
          }
          else {
            if(tokenService.getSessionRefreshingState()) {
              return api(originalConfig);
            }

            

            var needsRefresh = false;
            if(tokenService.getLocalAccessToken() != null && Math.abs(tokenService.getSessionRefreshingTime() - Date.now()) > 3000) {
              needsRefresh = true;
              isRefreshing = true;
              tokenService.setSessionRefreshingState(true);
              tokenService.setSessionRefreshingTime();
            }
            if(needsRefresh) {
              if(await authService.refresh()) {
                isRefreshing = false;
                tokenService.setSessionRefreshingState(false);
                //console.log("token refreshed!");
                //console.log("New access-key: " + tokenService.getLocalAccessToken());
                originalConfig.headers["x-access-token"] = tokenService.getLocalAccessToken();
                originalConfig.headers["x-refresh-token"] = tokenService.getLocalRefreshToken();
                //console.log(originalConfig);
                return api(originalConfig);
              }
              else {
                isRefreshing = false;
                tokenService.setSessionRefreshingState(false);
                tokenService.destroySession();
              }
            }
          }
        }

        if(err.response.status == 403) {
          tokenService.destroySession();
        }
      }

      let data;
      try { data = JSON.parse(config.data); }
      catch { data = config.data; }
      if (!data || data.retry == 0) {
        return Promise.reject(err);
      }
      
      if (!(message.includes("timeout") || message.includes("Network Error"))) {
        return Promise.reject(err);
      }

      if(!data.retry) data.retry = 2;
      else data.retry -= 1;
      const delayRetryRequest = new Promise((resolve) => {
        setTimeout(() => {
          resolve();
        }, data.retryDelay || 1000);
      });
      originalConfig.data = {...data};
      return delayRetryRequest.then(() => api(originalConfig));
    }
  );
};

export default setup;




/*
import api from "./api";
import authService from "./auth.service";
import tokenService from "./token.service";

var isRefreshing = false
const MAX_REQUESTS_COUNT = 2
const INTERVAL_MS = 10
let PENDING_REQUESTS = 0

async function sleep(milliseconds) {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
}

const setup = (store) => {
  api.interceptors.request.use(function(config) {
    return new Promise((resolve, reject) => {
      let interval = setInterval(() => {
        if (PENDING_REQUESTS < MAX_REQUESTS_COUNT) {
          console.log("test");
          PENDING_REQUESTS++;
          clearInterval(interval);
          if(store.user && store.user.uuid) {
            config.headers["isanonymous"] = true;
            config.headers["session-uuid"] = store.user.uuid;
            config.headers["session-access-token"] = store.accessToken;
            //config.headers["Content-Type"] = "application/json";
          }
          else if(store.accessToken != null) {
            config.headers["x-access-token"] = "Bearer " + store.accessToken;
            config.headers["x-refresh-token"] = "Bearer " + store.refreshToken;
            //config.headers["Content-Type"] = "application/json";
          }
          config.headers["Access-Control-Allow-Origin"] = "*";
          //console.log(config.headers);
          resolve(config);
        }
      }, INTERVAL_MS)
    });
  },
  function(error) {
    return Promise.reject(error);
  });

  api.interceptors.response.use(function(res) {
    PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1);

    //console.log(res);
    if(typeof res.data === 'string') {
      res.data = res.data.replaceAll("###", "");
      try { res.data = JSON.parse(res.data); }
      catch {}
    }
    return Promise.resolve(response);
  },
  async function (err) {
    const originalConfig = err.config;
    const { config, message } = err;

    if (originalConfig.url !== "/auth/login" && err.response) {
      if(typeof err.response.data === 'string') {
        err.response.data = err.response.data.replace("###", "");
        try { err.response.data = JSON.parse(err.response.data); }
        catch {}
      }

      // Access Token was expired
      if (err.response.status === 401 && !originalConfig._retry) {
        //console.log("retry?");
        // only refresh when it is a user session (not anonymous)
        if(store.user && store.user.uuid) {
          tokenService.removeUser();
          tokenService.setLocalAccessToken(null);
          return;
        }

        //console.log("refresh token...");
        originalConfig._retry = true;
        //console.log("Old access-key: " + tokenService.getLocalAccessToken());
        if(isRefreshing) {
          //console.log("waiting required!");
          while(isRefreshing) {
            await sleep(200);
            //console.log("zzzz...");
          }
          //console.log("free to go!");
          originalConfig.headers["x-access-token"] = tokenService.getLocalAccessToken();
          originalConfig.headers["x-refresh-token"] = tokenService.getLocalRefreshToken();
          return api(originalConfig);
        }
        else {
          isRefreshing = true;
          //console.log("token refresh pending...");
          if(await authService.refresh()) {
            isRefreshing = false;
            //console.log("token refreshed!");
            //console.log("New access-key: " + tokenService.getLocalAccessToken());
            originalConfig.headers["x-access-token"] = tokenService.getLocalAccessToken();
            originalConfig.headers["x-refresh-token"] = tokenService.getLocalRefreshToken();
            //console.log(originalConfig);
            return api(originalConfig);
          }
          else {
            isRefreshing = false;
            //console.log("logout...");
            authService.logout();
          }
        }
      }

      if(err.response.status == 403) {
        //console.log("logout...");
        authService.logout();
      }
    }

    let data;
    try { data = JSON.parse(config.data); }
    catch { data = config.data; }
    if (!data || data.retry == 0) {
      return Promise.reject(err);
    }
    
    if (!(message.includes("timeout") || message.includes("Network Error"))) {
      return Promise.reject(err);
    }

    if(!data.retry) data.retry = 2;
    else data.retry -= 1;
    const delayRetryRequest = new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, data.retryDelay || 1000);
    });
    originalConfig.data = {...data};
    return delayRetryRequest.then(() => api(originalConfig));
  });
};

export default setup;
*/