<template>
  <transition name="fade">
    <div class="popup" v-if="store.current.popup.includes('setup-pdf-pivot')">
      <div>
        <perfect-scrollbar style="height:100%">
          <form @submit.prevent="add">
            <div class="closer" @click="close()">Scherm sluiten</div>
            <h1>{{ title }}</h1>
            <h3>Indeling laden</h3>
            <p>
              U kunt een eerder opgeslagen indeling gebruiken door hieronder in het veld een keuze te maken. Daardoor 
              worden de kolommen automatisch ingesteld. Wilt u liever een nieuwe indeling maken, dan kunt u kolommen 
              vanuit de <i>Beschikbare kolommen</i> verplaatsen naar de lijsten met draai- en overzichtkolommen 
              aan de rechterkant.
            </p>

            <form v-if="pivotConfigs != null">
              <Multiselect 
                v-model="pivotConfig.pivotConfigId" 
                :options="pivotConfigs"
                placeholder="Maak een keuze..."
                noOptionsText="Geen opties gevonden."
                style="float:left;width:78%;margin-right:2%"
                @update:modelValue="loadConfiguration"
              />
              <input type="submit" class="submit red" value="Verwijderen..." @click.prevent="removeConfiguration" style="width:20%">
            </form>
            <div style="clear:both"></div>
            
            <div class="filter-list">
              <div class="drag" style="height: 50vh;float:left">
                <h3>Beschikbare kolommen</h3>
                <div>
                  <template v-for="(column, index) in this.availColumns">
                    <span>
                      {{ this.translateUpper(column.name) }}
                      <span>{{ column.type }}</span>
                      <span class="opt" @click="addToPivot(column)">+ Toevoegen aan draaikolommen</span>
                      <span class="opt" @click="addToOverview(column)">+ Toevoegen aan overzichtskolommen</span>
                    </span>
                  </template>
                </div>
              </div>

              <div class="drag" style="height: 24vh;float:right">
                <h3>Draaikolommen</h3>
                <div>
                  <template v-for="(column, index) in renderStore.options.columnsPivot">
                    <span>
                      {{ this.translateUpper(column.name) }}
                      <img src="img/ico/close.png" @click="removeFromPivot(column)">
                      <img v-if="index > 0" src="img/ico/collapse.png" @click="moveUp(index, 'pivot')">
                      <img v-else src="img/ico/collapse.png" style="opacity:0.2">
                      <img v-if="index < renderStore.options.columnsPivot.length - 1" src="img/ico/expand.png" @click="moveDown(index, 'pivot')">
                      <img v-else src="img/ico/expand.png" style="opacity:0.2">
                    </span>
                  </template>
                </div>
              </div>

              <div class="drag" style="height: 24vh;float:right">
                <h3>Overzichtkolommen</h3>
                <div>
                  <template v-for="(column, index) in renderStore.options.columnsOverview">
                    <span>
                      {{ this.translateUpper(column.name) }}
                      <img src="img/ico/close.png" @click="removeFromOverview(column)">
                      <img v-if="index > 0" src="img/ico/collapse.png" @click="moveUp(index, 'overview')">
                      <img v-else src="img/ico/collapse.png" style="opacity:0.2">
                      <img v-if="index < renderStore.options.columnsOverview.length - 1" src="img/ico/expand.png" @click="moveDown(index, 'overview')">
                      <img v-else src="img/ico/expand.png" style="opacity:0.2">
                    </span>
                  </template>
                </div>
              </div>
              <template v-if="store.isUser">
                <input type="submit" class="submit" value="Deze indeling opslaan als..." @click.prevent="saveConfiguration">
              </template>
              <div style="clear:both"></div>
            </div>
            <div style="clear:both;height:30px"></div>

            <h3>Wat zijn draai- en overzichtskolommen?</h3>
            <p>
              Draaikolommen zijn kolommen waarover subtotalen worden opgeteld. U kunt deze verschillende lagen
              in de resulterende tabel in- en uitklappen. U kunt maximaal 5 kolommen opnemen in de lijst. Zo kunt 
              u bijvoorbeeld een overzicht maken van subtotalen per ruimte voor de verschillende onderwerpen die
              in het document aanwezig zijn.
            </p>
            <p>
              Overzichtskolommen bevatten extra informatie die wel wordt getoond in de tabel, maar waarover 
              géén afzonderlijk subtotaal wordt berekend.
            </p>
          </form>
        </perfect-scrollbar>
      </div>
    </div>
  </transition>
</template>

<script>
import listingService from '@/services/listing.service'
import Multiselect from '@vueform/multiselect'
import pivotConfigService from '@/services/app/pivot.config.service';

export default {
  emits: ['showMessage', 'updateParent'],
  data() {
    return {
      availColumns: [],
      pivotConfig: { pivotConfigId: null },
      pivotConfigs: [],
      title: "Instellingen draaitabel",
      submitText: "Wijzigingen opslaan",
      invalidConfig: false
    };
  },
  computed: {
    store() {
      return this.$parent.store;
    },
    renderStore() {
      return this.$parent.renderStore;
    },
    currentPopup() {
      return this.store.current.popup[this.store.current.popup.length-1];
    },
    pivotColumns() {
      return this.$parent.renderStore.options.columnsPivot;
    },
    overviewColumns() {
      return this.$parent.renderStore.options.columnsOverview;
    }
  },
  watch: {
    async currentPopup(value) {
      if(value == "setup-pdf-pivot") {
        this.invalidConfig = false;
        this.store.current.data.pdf.pivotConfig = null;
        this.renderStore.annotations.data.rawColumns.sort((a,b) => this.translateUpper(a.name).localeCompare(this.translateUpper(b.name)));
        this.fetch();
      }
    },
    pivotColumns: {
      handler() {
        this.updateAvailableColumns();
      },
      deep: true
    },
    overviewColumns: {
      handler() {
        this.updateAvailableColumns();
      },
      deep: true
    }
  },
  methods: {
    addToPivot(column) {
      column.pivot = true;
      column.name_nl = this.translateUpper(column.name).replace(" (BB)", "").toLowerCase();
      this.renderStore.options.columnsPivot.push(column);
      /*var i = 0, ii = -1; this.renderStore.annotations.data.rawColumns.forEach(rcolumn => {
        if(rcolumn.name.toLowerCase() == column.name.toLowerCase()) {
          ii = i;
          return;
        }
        i++;
      });*/
      //if(ii >= 0) this.renderStore.annotations.data.rawColumns.splice(ii, 1);
    },
    addToOverview(column) {
      column.pivot = false;
      column.name_nl = this.translateUpper(column.name).replace(" (BB)", "").toLowerCase();
      this.renderStore.options.columnsOverview.push(column);
      //var i = this.renderStore.annotations.data.rawColumns.indexOf(column);
      //if(i >= 0) this.renderStore.annotations.data.rawColumns.splice(i, 1);
      /*var i = 0, ii = -1; this.renderStore.annotations.data.rawColumns.forEach(rcolumn => {
        if(rcolumn.name.toLowerCase() == column.name.toLowerCase()) {
          ii = i;
          return;
        }
        i++;
      });*/
      //if(ii >= 0) this.renderStore.annotations.data.rawColumns.splice(ii, 1);
    },
    removeFromPivot(column) {
      if(column.name == "subject") return;
      delete column.sort;
      //this.renderStore.annotations.data.rawColumns.push(column);
      //this.renderStore.annotations.data.rawColumns.sort((a,b) => this.translateUpper(a.name).localeCompare(this.translateUpper(b.name)));
      var i = this.renderStore.options.columnsPivot.indexOf(column);
      if(i >= 0) this.renderStore.options.columnsPivot.splice(i, 1);

      if(this.renderStore.options.columnsPivot.length == 0) {
        this.renderStore.annotations.data.rawColumns.forEach(thisColumn => {
          if(thisColumn.name == "subject") {
            this.renderStore.options.columnsPivot.push(thisColumn);
          }
        });
        var i = this.renderStore.annotations.data.rawColumns.indexOf(column);
        //if(i >= 0) this.renderStore.annotations.data.rawColumns.splice(i, 1);
      }
    },
    removeFromOverview(column) {
      //this.renderStore.annotations.data.rawColumns.push(column);
      //this.renderStore.annotations.data.rawColumns.sort((a,b) => this.translateUpper(a.name).localeCompare(this.translateUpper(b.name)));
      var i = this.renderStore.options.columnsOverview.indexOf(column);
      if(i >= 0) this.renderStore.options.columnsOverview.splice(i, 1);
    },
    moveUp(index, type) {
      if(index == 0) return;
      if(type == "pivot") {
        var tmp = this.renderStore.options.columnsPivot[index-1];
        this.renderStore.options.columnsPivot[index-1] = this.renderStore.options.columnsPivot[index];
        this.renderStore.options.columnsPivot[index] = tmp;
      }
      else {
        var tmp = this.renderStore.options.columnsOverview[index-1];
        this.renderStore.options.columnsOverview[index-1] = this.renderStore.options.columnsOverview[index];
        this.renderStore.options.columnsOverview[index] = tmp;
      }
    },
    moveDown(index, type) {
      if(type == "pivot") {
        if(index == this.renderStore.options.columnsPivot.length - 1) return;
        var tmp = this.renderStore.options.columnsPivot[index+1];
        this.renderStore.options.columnsPivot[index+1] = this.renderStore.options.columnsPivot[index];
        this.renderStore.options.columnsPivot[index] = tmp;
      }
      else {
        if(index == this.renderStore.options.columnsOverview.length - 1) return;
        var tmp = this.renderStore.options.columnsOverview[index+1];
        this.renderStore.options.columnsOverview[index+1] = this.renderStore.options.columnsOverview[index];
        this.renderStore.options.columnsOverview[index] = tmp;
      }
    },
    async loadConfiguration() {
      if(!this.pivotConfig || !this.pivotConfig.pivotConfigId) return;

      this.invalidConfig = false;
      const pivotConfig = await pivotConfigService.select(this.pivotConfig);
      var allColumns = [...this.renderStore.annotations.data.rawColumns];

      // Check validity
      this.pivotConfig = { 
        pivotConfigId: pivotConfig.pivotConfigId,
        name: pivotConfig.name, 
        isDefault: pivotConfig.isDefault,
        config: JSON.parse(pivotConfig.config)
      };
      var valid = true;
      for(const [key, fconfig] of Object.entries(this.pivotConfig.config)) {
        var found = false;
        allColumns.forEach(column => {
          if(!valid) return;
          if(column.name.toLowerCase() == fconfig.name.toLowerCase()) {
            found = true;
          }
        });
        if(!found) valid = false;
      }

      if(valid) {
        this.renderStore.options.columnsOverview = [];
        this.renderStore.options.columnsPivot = [];
        this.renderStore.annotations.data.rawColumns = [...allColumns];
       

        for(const [id, fconfig] of Object.entries(this.pivotConfig.config)) {
          if(fconfig.pivot) this.addToPivot(fconfig);
          else this.addToOverview(fconfig);
        }
        this.renderStore.annotations.data.rawColumns.sort((a,b) => this.translateUpper(a.name).localeCompare(this.translateUpper(b.name)));
      }
      else {
        this.invalidConfig = true;
        this.$emit('showMessage', "Oeps! Het lijkt erop dat de kolommen in dit document niet overeen komen met de indeling die u probeert in te lezen.");
      } 
    },
    saveConfiguration() {
      this.store.current.data.pdf.pivotConfig = { ...this.pivotConfig };
      this.store.current.popup = ["add-pdf-pivot-config"];
    },
    removeConfiguration() {
      this.store.current.data.pdf.pivotConfig = { ...this.pivotConfig };
      this.store.current.popup.push("unlink-pdf-pivot-config");
    },
    updateAvailableColumns() {
      var availColumns = [...this.renderStore.annotations.data.rawColumns];
      for(var i = availColumns.length-1; i >= 0; i--) {
        var column = availColumns[i];
        //availColumns[i].name_nl = this.translateUpper(column.name);
        this.renderStore.options.columnsPivot.forEach(pColumn => {
          if(column.name.toLowerCase() == pColumn.name.toLowerCase()) {
            delete availColumns[i];
          }
        });
        this.renderStore.options.columnsOverview.forEach(pColumn => {
          if(column.name.toLowerCase() == pColumn.name.toLowerCase()) {
            delete availColumns[i];
          }
        });
      }
      availColumns.sort((a,b) => a.name_nl.localeCompare(b.name_nl));
      this.availColumns = {...availColumns};
    },
    async fetch() {
      if(this.store.isUser) {
        this.pivotConfigs = await listingService.pivotConfigs();
      }
    },
    translateUpper(header) {
      switch(header) {
        case "subject":
          return "ONDERWERP (BB)";
        case "space":
          return "RUIMTE (BB)";
        case "pageNumber":
          return "PAGINANUMMER";
          case "pageLabel":
          return "PAGINALABEL";
        case "width":
          return "BREEDTE (BB)";
        case "height":
          return "HOOGTE (BB)";
        case "depth":
          return "DIEPTE (BB)";
        case "risedrop":
          return "STIJGPIJP (BB)";
        case "count":
          return "AANTAL (BB)";
        case "length":
          return "LENGTE (BB)";
        case "area":
          return "OPPERVLAKTE (BB)";
        case "wallArea":
          return "WANDOPPERVLAKTE (BB)";
        case "volume":
          return "VOLUME (BB)";
        case "angle":
          return "HOEK (BB)";
        case "slope":
          return "HELLING (BB)";
        case "unit":
          return "EENHEID (BB)";
        default:
          return header.toUpperCase();
      }
    },
    close() {
      this.renderStore.options.columns = [...this.renderStore.options.columnsPivot, ...this.renderStore.options.columnsOverview];
      this.renderStore.options.expandToLevel = 1;
      this.renderStore.annotations.data.charCountAvailable = false;
      this.renderStore.annotations.data.subjectAt = -1;
      this.renderStore.annotations.data.totalAt = -1;
      this.renderStore.annotations.data.unitAt = -1;
      this.store.current.popup.splice(
        this.store.current.popup.indexOf('setup-pdf-pivot'), 
        1
      );
      this.$emit('updateParent');
    },
  },
  components: {
    Multiselect
  }
}
</script>
