<template>
  <perfect-scrollbar class="form" v-if="store.current.form === 'view-pdf-project'"> 
    <div v-if="project">
      <h1>Projectgegevens</h1>
      <div v-if="!init" class="loader">
        <div>Even geduld, content wordt geladen...</div>
      </div>
      <div v-else>
        <div class="data-holder">
          <div class="sidebar" id="gmap" style="width:25%"></div>
          <div class="enum">
            <h2>{{ project.name }}</h2>
            <span>Projecttype</span> {{ project.projectTypeName }}<br/>
            <span>Aangemaakt door</span> {{ project.sharedBy }}<br/>
            <span>Aanmaakdatum</span> {{ project.dateCreated }}
          </div>
        </div>

        <div v-if="store.isAdmin" class="adder" @click="add()">Toevoegen</div>
        <div class="adder gray" @click="fetchDocuments"><img src="img/ico/sync.png">Ververs lijst</div>
        <SearchField @search="search" />
        <h1>Documenten</h1>
        <div>
          <!--<div class="sidebar">
            <div class="enum">
              <span>Aantal documenten</span> {{ project.documentCount }}<br/><br/>
              <span>Opslag in gebruik</span> {{ Math.round(project.usedStorage/1048576, 1)  }} MB
            </div>
          </div>-->
          <template v-if="!project.documents">
            <div class="loader">
              <div>Even geduld, content wordt geladen...</div>
            </div>
          </template>
          <template v-else-if="project.documents && project.documents.length > 0">
            <table class="listing doc share">
              <tbody>
                <tr>
                  <th title="Omschrijving" style="width:70%"><span>Omschrijving</span></th>
                  <th v-if="store.isAdmin" class="opth" style="width:0%"></th>
                </tr>
                <tr v-for="(document, index) in project.documents" v-bind:key="index" 
                  @mouseenter="showDetails"
                  @mouseleave="hideDetails">
                  <td>
                    <img v-if="document.verified" src="img/ico/verified.png" class="ver" title="Geverifieerd">
                    <img v-else src="img/ico/not-verified.png" class="ver" title="Niet geverifieerd">
                    <img
                      :style="{backgroundImage: 'url('+document.thumbnail+')' }"
                      :thumbnail="document.thumbnail" 
                      :icon=document.icon
                      class="thumb"
                      @click="viewDocument(document)"
                    />

                    <div class="enum det sd">
                      <label v-if="document.pending" class="green">
                        <img src="img/ico/sync.png" class="icon">
                        Nieuwe versie wordt verwerkt...
                      </label>
                      <h3>{{ document.name }}</h3>
                      <span>Laatste wijziging</span> {{ document.dateUpdated }}<br/>
                      <span>Huidige versie</span> {{ document.currentVersion }}
                    </div>
                    
                    <div class="enum det" id="inline-opt">
                      <a v-if="document.currentVersion > 0" href="#" @click="viewDocument(document)">Bekijk document</a>
                      <a v-if="store.isAdmin" href="#" @click="manageDocumentHistory(document)">Versie- en toegangsbeheer</a>
                      <a v-if="store.isAdmin" href="#" @click="quickShare(document)">Delen...</a>
                    </div>
                  </td>
                  <td v-if="store.isAdmin" class="opt">
                    <div>
                      <div>
                        Opties bij regel
                        <a href="#" @click="editDocument(document)">Pas aan</a>
                        <a href="#" @click="unlinkDocument(document)">Verwijder</a>
                        <div class="clear"></div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <Pagination @change-offset="changeOffset" :query-args="queryArgs"></Pagination>
          </template>
          <template v-else>
            <div class="data-holder">
              <p style="padding-bottom:0">
                <i>
                  Er kunnen geen documenten worden gevonden. Heeft u binnen het project nog geen documenten toegevoegd,
                  dan kunt u dat via de knop hierboven doen. Het kan ook zijn dat u de lijst heeft gefilterd op een zoekterm
                  die geen resultaten oplevert.
                </i>
              </p>
            </div>
          </template>
        </div>
      </div>
    </div>
    <div v-else class="nodata">
      Dit project is niet toegankelijk omdat u hiervoor niet de juiste rechten heeft.
      Neem contact op met de persoon waarvan u deze link heeft ontvangen.
    </div>
  </perfect-scrollbar>
  <PopupAddDocument 
    @update-parent="fetch"
    @show-message="showMessage" 
  />
  <PopupAddPermission 
    @update-parent="fetch"
    @show-message="showMessage"
  />
  <PopupReviseDocument 
    @update-parent="fetch"
    @show-message="showMessage" 
  />
  <PopupUnlinkDocument 
    @update-parent="fetch"
    @show-message="showMessage"
  />
  <PopupUnlinkPermission 
    @update-parent="fetch"
    @show-message="showMessage"
  />
  <PopupManageDocumentHistory 
    @update-parent="fetch"
    @show-message="showMessage"
  />
  <PopupBackupDocumentHistory
    @show-message="showMessage"
  />
  <PopupUnlinkDocumentHistory 
    @update-parent="fetch"
    @show-message="showMessage"
  />
  <PopupManagePermissions
    @update-parent="fetch"
    @show-message="showMessage"
  />
  <PopupApproveTransaction
    @update-parent="fetch"
    @show-message="showMessage"
  />
</template>

<script>
import projectService from '@/services/app/project.service'
import documentService from '@/services/app/document.service'
import SearchField from '@/components/SearchField.vue'
import Pagination from '@/components/Pagination.vue'

import PopupAddDocument from '@/popups/app/AddDocument.vue'
import PopupAddPermission from '@/popups/app/AddPermission.vue'
import PopupReviseDocument from '@/popups/app/ReviseDocument.vue'
import PopupUnlinkDocument from '@/popups/app/UnlinkDocument.vue'
import PopupUnlinkPermission from '@/popups/app/UnlinkPermission.vue'
import PopupManageDocumentHistory from '@/popups/app/ManageDocumentHistory.vue'
import PopupBackupDocumentHistory from '@/popups/app/BackupDocumentHistory.vue'
import PopupUnlinkDocumentHistory from '@/popups/app/UnlinkDocumentHistory.vue'
import PopupManagePermissions from '@/popups/app/ManagePermissions.vue'
import PopupApproveTransaction from '@/popups/app/ApproveTransaction.vue'

export default {
  emits: ['showMessage'],
  data() {
    return {
      project: {},
      queryArgs: {
        orderType: "asc",
        orderBy: "name",
        query: "",
        offset: 0,
        pageSize: 5,
        count: 0,
        projectId: null
      },
      title: 'Project niet beschikbaar',
      init: false,
      pending: false
    };
  },
  computed: {
    store() {
      return this.$parent.store;
    },
    currentForm() {
      return this.store.current.form;
    }
  },
  watch: {
    currentForm: {
      handler(val) {
        if(val == "view-pdf-project") {
          this.fetch();
        }
      },
      immediate: true
    }
  },
  methods: {
    async fetch() {
      this.init = false;
      this.queryArgs.offset = 0;
      this.queryArgs.query = "";
      this.project = await projectService.select(this.store.current.data.pdf.project, this.queryArgs);
      if(this.project) {
        this.queryArgs.count = this.project.documentCount;
        document.title = this.project.name + " | " + this.store.appName;
        this.title = this.project.name;
        this.renderMap(this.project.latLng);
        //this.updateDocuments();
      }
      else {
        document.title = "Project niet beschikbaar | " + this.store.appName;
        this.title = "Project niet beschikbaar";
      }

      this.fetchDocuments();
      this.init = true;
    },
    async fetchDocuments() {
      if(this.project) {
        this.project.documents = null;
        this.queryArgs.projectId = this.project.projectId;
        const response = await documentService.listing(this.queryArgs);
        if(response) {
          if(response.status == 200) {
            this.project.documents = response.data.results;
            this.queryArgs.count = response.data.count;
            this.updateDocuments();
          }
          else {
            this.$emit('showMessage', response);
          }
        }
      }
    },
    async search(query) {
      if(this.queryArgs.query != query) {
        this.queryArgs.projectId = this.project.projectId;
        this.queryArgs.query = query;
        this.queryArgs.offset = 0;
        await this.fetchDocuments();
      }
    },
    changeOffset(offset) {
      this.queryArgs.offset = offset;
      this.fetchDocuments();
    },
    async renderMap(latLng) {
      const position = { lat: parseFloat(latLng.split(',')[0]), lng: parseFloat(latLng.split(',')[1]) };
      const { Map } = await google.maps.importLibrary("maps");
      const { AdvancedMarkerElement } = await google.maps.importLibrary("marker");

      const map = new Map(document.getElementById("gmap"), {
        zoom: 9,
        center: position,
        mapId: "proj-loc",
      });

      const marker = new AdvancedMarkerElement({
        map: map,
        position: position,
        title: "Projectlocatie",
      });
    },
    add() {
      this.store.current.data.pdf.document = null;
      this.store.current.popup = ["add-pdf-document"];
    },
    revise(document) {
      this.store.current.data.pdf.document = document;
      this.store.current.popup = ["revise-pdf-document"];
    },
    viewDocument(document) {
      if(document.pending) return;
      if(document.currentVersion == 0) return;
      this.store.current.data.pdf.document = {...document};
      this.store.current.data.pdf.contribution = null;
      this.store.current.form = "view-pdf-document";
      this.store.current.popup = [];
    },
    manageDocumentHistory(document) {
      this.store.current.data.pdf.document = {...document};
      this.store.current.popup = ["manage-pdf-document-history"];
    },
    quickShare(document) {
      this.store.current.data.pdf.document = {...document};
      this.store.current.data.pdf.documentHistory = { 
        documentHistoryId: document.documentHistoryId,
        version: document.currentVersion
      };
      this.store.current.popup = ["manage-pdf-permissions"];
    },
    editDocument(document) {
      this.store.current.data.pdf.document = {...document};
      this.store.current.popup = ["add-pdf-document"];
    },
    unlinkDocument(document) {
      this.store.current.data.pdf.document = {...document};
      this.store.current.popup = ["unlink-pdf-document"];
    },
    showMessage(e) {
      this.$emit("showMessage", e);
    },
    showDetails(e) {
      //const image = e.target.querySelector('img');
      const det = e.target.querySelector('#inline-opt');
      det.classList.add("sd");
      //image.src = image.attributes.thumbnail.value;
      //image.classList.add("thumb");
    },
    hideDetails(e) {
      //const image = e.target.querySelector('img');
      const det = e.target.querySelector('#inline-opt');
      det.classList.remove("sd");
      //image.src = image.attributes.thumbnail.value;
      //image.classList.remove("thumb");
    },
    updateDocuments() {
      if(this.project.documents != null) {
        this.project.documents.forEach((document) => {
          if(document.thumbnail != null) {
            document.thumbnail = 'data:image/jpeg;base64,'+document.thumbnail;
          }
          else document.thumbnail = "img/def-thumb.jpg";
        });
      }
    }
  },
  components: {
    PopupAddDocument,
    PopupAddPermission,
    PopupReviseDocument,
    PopupUnlinkDocument,
    PopupUnlinkPermission,
    PopupManageDocumentHistory,
    PopupBackupDocumentHistory,
    PopupUnlinkDocumentHistory,
    PopupManagePermissions,
    PopupApproveTransaction,
    SearchField,
    Pagination
  }
}
</script>