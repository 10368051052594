<template>
  <div class="form" v-if="store.current.form === 'view-pdf-document'"> 
    <div v-if="document">
      <div v-if="!init" class="loader">
        <div>Even geduld, content wordt geladen...</div>
      </div>
      <div v-else-if="!document.needsAccessKey">
        <PdfViewer 
          :init="this.init"
          :document="this.document"
          :get-data="this.getData"
          :update-contributions="this.updateContributions"
          @show-message="showMessage" 
        /> 
      </div>
    </div>
    <div v-else class="nodata">
      Dit document is niet toegankelijk omdat u hiervoor niet de juiste rechten heeft.
      Neem contact op met de persoon waarvan u deze link heeft ontvangen.
    </div>
  </div>
  <LoginTempUser @update-parent="setAccessKey" /> 
</template>

<script>
import PdfViewer from '@/components/app/viewer/PdfViewer.vue'
import LoginTempUser from '@/components/app/LoginTempUser.vue'

import documentService from '@/services/app/document.service'

export default {
  emits: ['showMessage'],
  data() {
    return {
      accessKey: null,
      document: {},
      getData: {},
      imageData: {},
      annotationData: {},
      updateContributions: false,
      title: 'Document niet beschikbaar',
      init: false,
      pending: false
    };
  },
  computed: {
    store() {
      return this.$parent.store;
    },
    currentForm() {
      return this.store.current.form;
    }
  },
  watch: {
    currentForm: {
      async handler(val) {
        if(val == "view-pdf-document") {
          this.init = false;
          this.store.menuSticky = false;
          if(typeof this.$route.query.u !== 'undefined' && typeof this.$route.query.v !== 'undefined') {
            if(typeof this.$route.query.e !== 'undefined' && typeof this.$route.query.t !== 'undefined') {
              this.getData = {
                "uuid": this.$route.query.u,
                "version": this.$route.query.v,
                "userEmail": this.$route.query.e,
                "userToken": this.$route.query.t
              };
            }
            else {
              this.getData = {
                "uuid": this.$route.query.u,
                "version": this.$route.query.v
              };
            }

            if(this.accessKey != null) {
              this.getData["accessKey"] = this.accessKey;
            }

            this.document = await documentService.select(this.getData);
            
            if(this.document) {
              this.document.documentId = 0;
              this.document.uuid = this.$route.query.u;
              this.document.version = this.$route.query.v;
            }
          }
          else if(this.store.current.data.pdf.document != null) { 
            var requestedVersion = this.store.current.data.pdf.document.version;
            this.getData = {
              "documentId": this.store.current.data.pdf.document.documentId,
              "uuid": this.store.current.data.pdf.document.uuid,
              "version": this.store.current.data.pdf.document.version
            };
            
            this.document = await documentService.select(this.getData);
            if(this.document) {
              if(requestedVersion != null) this.document.version = requestedVersion;
              else this.document.version = this.document.latestVersion;
            }
          }

          if(this.document) {
            document.title = this.document.name + " | " + this.store.appName;
            this.title = this.document.name;
          }
          else {
            document.title = "Document niet beschikbaar | " + this.store.appName;
            this.title = "Document niet beschikbaar";
          }

          if(this.document != null && this.document.needsAccessKey) {
            this.store.current.form = "login-temp-user";
          }
          if(this.document != null && this.document.isMigratable) {
            this.store.current.popup.push("migrate-pdf-data");
          }
          this.store.current.data.pdf.document = this.document;
          this.init = true;
        }
      },
      immediate: true
    }
  },
  beforeDestroy() {
    this.store.data = [];
    this.store.images.data = [];
  },
  methods: {
    showMessage(e) {
      this.$emit("showMessage", e);
    },
    setAccessKey(key) {
      this.accessKey = key;
      this.store.current.form = "";
      this.store.current.form = "view-pdf-document";
    }
  },
  components: {
    PdfViewer,
    LoginTempUser
  }
}
</script>