<template>
  <transition name="fade">
    <div class="popup small" v-if="store.current.popup.includes('approve-pdf-transaction')">
      <div>
        <perfect-scrollbar style="height:100%">
          <form @submit.prevent="approve">
            <h1>{{ title }}</h1>
            <div v-if="init">
              <p>
                U staat op het punt om een document te laten verwerken door onze server.
                Voor deze actie worden tokens in rekening gebracht. Hieronder kunt u het
                tarief voor dit document vinden. U dient de transactie goed te keuren; eerder
                zal het document niet worden verwerkt.
              </p>
              <div class="token-est">
                <template v-if="failed">
                  Het systeem kon uw document op dit moment niet verwerken, probeer het later opnieuw.
                </template>
                <template v-else-if="transaction == null || Object.values(transaction).length == 1">
                  <img src="img/spinner.gif">
                  De analyse van uw document wordt gestart, even geduld...
                </template>
                <template v-else-if="transaction != null && (transaction.amount == 0 && transaction.error == null)">
                  <img src="img/spinner.gif">
                  <template v-if="delayed">
                    Het lijkt erop dat het analyseren langer duurt dan normaal, nog even geduld alstublieft...
                  </template>
                  <template v-else>Het systeem analyseert uw document, even geduld...</template>
                </template>
                <template v-else-if="transaction.error != null">
                  <div class="upload-error">{{ transaction.error }}</div>
                </template>
                <template v-else>
                  <div class="amount">Tarief: {{ Math.round(-1000 * transaction.amount) / 1000 }} tokens*</div>
                  <span><b>Beschikbaar bedrijfssaldo:</b> {{ Math.round(1000 * transaction.balance) / 1000 }} tokens</span>
                  <span>
                    * De hoeveelheid tokens is afhankelijk van het papierformaat
                    van pagina's, de totale omvang van uw document (aantal pagina's en
                    vereiste opslag) en de hoeveelheid markeringen. Lees ook onze 
                    <a href="" target="_blank">restitutievoorwaarden</a>.
                  </span>
                  <span v-if="transaction.amount > 0 && !transaction.migratable" class="err">
                    Het systeem heeft gedetecteerd dat het voor deze versie van het document niet 
                    mogelijk is om binnen Foundation toegevoegde gegevens over te zetten. Het kan zijn dat 
                    de volgorde van pagina's is gewijzigd of dat paginatitels of -schalen zijn
                    aangepast. U kunt het document alsnog goedkeuren als u zich bewust bent van 
                    deze consequentie.
                  </span>
                </template>
              </div>
              <input type="submit" :value="submitText" :class="{locked:isLocked}" style="width:48%">
              <div class="submit red" @click="reject" :class="{locked:isPendingLocked}" style="width:48%;margin-left:4%">Transactie afbreken</div>
            </div>
            <div v-else class="loader">
              <div>Even geduld, content wordt geladen...</div>
            </div>
          </form>
        </perfect-scrollbar>
      </div>
    </div>
  </transition>
</template>

<script>
import transactionService from '@/services/app/transaction.service'

export default {
  emits: ['showMessage', 'updateParent'],
  props: {
    getData: Object
  },
  data() {
    return {
      transaction: {},
      title: "",
      submitText: "",
      init: false,
      pending: false,
      failed: false,
      retryAfter: 3,
      retryDelayedDuration: 30,
      retryMaxDuration: 1800,
      retryDuration: 0
    };
  },
  computed: {
    store() {
      return this.$parent.store;
    },
    renderStore() {
      return this.$parent.renderStore;
    },
    currentPopup() {
      return this.store.current.popup[this.store.current.popup.length-1];
    },
    isLocked() {
      if(this.failed || this.pending || !this.init || this.transaction.error != null) return true;
      else return false;
    },
    isPendingLocked() {
      if(this.pending || !this.init) return true;
      else return false;
    }
  },
  watch: {
    async currentPopup(value) {
      if(value == "approve-pdf-transaction") {
        this.init = true;
        this.pending = true;
        this.failed = false;
        this.delayed = false;
        this.retryDuration = 0;

        this.title = "Uw goedkeuring is vereist";
        this.submitText = "Ja, ik keur deze transactie goed";

        this.transaction = { transactionId: this.store.current.data.pdf.transaction.transactionId };
        await this.fetch();
      }
    }
  },
  methods: {
    async approve() {
      if(this.failed || this.pending || this.transaction.error != null) return;
      this.pending = true;

      const response = await transactionService.approve(this.transaction.transactionId);
      if(response) {
        if(response.status == 200) {
          this.$emit('updateParent');
          this.close();
        }
        else {
          this.$emit('showMessage', response);
        }
      }
      this.pending = false;
    },
    async reject() {
      if(this.pending) return;
      this.pending = true;

      const response = await transactionService.reject(this.transaction.transactionId);
      if(response) {
        if(response.status == 200) {
          this.$emit('updateParent');
          this.close();
        }
        else {
          this.$emit('showMessage', response);
        }
      }
    },
    close() {
      this.store.current.data.pdf.transaction = null;
      this.store.current.popup.splice(
        this.store.current.popup.indexOf('approve-pdf-transaction'), 
        1
      );
    },
    async fetch() {
      if(this.transaction.transactionId) {
        this.transaction = await transactionService.select(this.transaction, this.getData);
        if(this.transaction == null || (this.transaction.amount == 0 && this.transaction.error == null)) {
          await new Promise(r => setTimeout(r, this.retryAfter * 1000));
          if(this.retryDuration < this.retryMaxDuration) {
            if(this.retryDuration > this.retryDelayedDuration) {
              this.delayed = true;
            }
            this.fetch();
          }
          else {
            this.pending = false;
            this.failed = true;
          }
          this.retryDuration += this.retryAfter;
        }
        else this.pending = false;
      }
    }
  }
}
</script>
