<template>
  <Menu ref="wmn" />
  <perfect-scrollbar 
    @ps-scroll-y="onScrollEvent" 
    :options={wheelSpeed:0.3}
    style="height:100%"
  >
    <div class="ct">
      <Leader_Subscriptions />
      <Logos />
      <Customers />
      <Footer />
    </div>
  </perfect-scrollbar>
</template>

<script>
import Menu from '@/components/Menu.vue'
import Leader_Subscriptions from '@/components/content/Leader_Subscriptions.vue'
import Customers from '@/components/others/Customers.vue'
import Logos from '@/components/carroussels/Logos.vue'
import Footer from '@/components/others/Footer.vue'

export default {
  name: "Abonnementen",
  data() {
    return {
      pageName: "Abonnementen",
      error: ""
    };
  },
  computed: {
    
  },
  components: { 
    Menu,
    Leader_Subscriptions,
    Customers,
    Logos,
    Footer
  }
};
</script>