<template>
  <div class="content info" v-if="activeSideMenu === 'contributions' || activeSideMenu === 'toolbox'">
    <template v-if="activeSideMenu === 'contributions' && this.store.current.data.pdf.contributionTemplate == null">
      <div class="cm" @click="closeSideMenu()" title="Scherm sluiten">
        <img src="img/ico/close-1.png">
      </div>
      <h3 style="margin-bottom:20px">Wat wilt u toevoegen?</h3>
      <template v-for="template in sortedContributionTemplates">
        <a v-if="!template.disabled" href="#" @click="setContributionTemplate(template)" class="temp-sel">
          <div class="ver">Versie: {{ template.version }}</div>
          <h3>{{ template.name }}</h3>
          <p>
            {{ template.description }}
          </p>
        </a>
      </template>
    </template>
    <template v-if="(activeSideMenu === 'contributions' || activeSideMenu == 'toolbox') && this.store.current.data.pdf.contributionTemplate != null">
      <h3>Bijdrage plaatsen binnen document</h3>
      <p style="padding-bottom:0">
        <h4>Welke acties kunt u uitvoeren?</h4>
        Tijdens het plaatsen van de bijdrage zijn de volgende opties beschikbaar:
        <ul>
          <li><i>Enkele klik</i>: leg de positie van de bijdrage of de leader vast;</li>
          <li><i>Backspace</i>: verwijder de laatst toegevoegde positie;</li>
          <li><i>Dubbelklik of enter</i>: meting afsluiten;</li>
          <li><i>Escape</i>: plaatsing annuleren</li>
        </ul>

        <template v-if="scaledShapes.indexOf(this.store.current.data.pdf.contributionTemplate.viewerOutput.shape.type) >= 0">
          <h4>Eigenschappen huidige meting</h4>
          <template v-if="this.store.current.data.pdf.contributionTemplate.viewerOutput.shape.type != 'count'">
            <label v-if="area != 0">
              Oppervlakte
              <span>{{ area }} m&#178;</span>
            </label>
            <label v-if="distance != 0">
              Lengte
              <span>{{ distance }} m</span>
            </label>
          </template>
          <template v-else>
            <label v-if="count != 0">
              Aantal
              <span>{{ count }} st</span>
            </label>
          </template>
        </template>
      </p>
      <div class="submit small" @click="cancel" style="margin-top:25px">
        <img src="img/ico/close-1.png" style="filter:invert()">
        Annuleren
      </div>
    </template>
  </div>
</template>

<script>
import { renderAnnotations } from '@/components/app/viewer/PdfCanvas.js'

export default {
  props: {
    document: Object,
    getData: Object
  },
  data() {
    return {
      foundScale: false,
      pageHasScale: false,
      distance: 0,
      area: 0,
      count: 0,
      scaledShapes: ["count", "length", "poly", "area"]
    }
  },
  computed: {
    store() {
      return this.$parent.store;
    },
    renderStore() {
      return this.$parent.renderStore;
    },
    activeSideMenu() {
      return this.$parent.renderStore.options.activeSideMenu;
    },
    contributionTemplates() {
      return this.renderStore.contributionTemplates;
    },
    sortedContributionTemplates() {
      return Object.values(this.contributionTemplates).sort((a, b) => a.name.localeCompare(b.name));
    },
    measure() {
      if(this.store.current.data.pdf.contribution != null) {
        return this.store.current.data.pdf.contribution.positions;
      }
      return null;
    }
  },
  watch: {
    measure: {
      handler(arr, old) {
        if(arr == null) return;
        if(arr.length == 0) {
          this.distance = 0;
          this.area = 0;
          this.count = 0;
          return;
        }

        if(this.store.current.data.pdf.contributionTemplate == null) return;
        var shape = this.store.current.data.pdf.contributionTemplate.viewerOutput.shape.type;

        var pageInfo = this.renderStore.pages[this.renderStore.options.pageNumber - 1];
        if(!pageInfo || pageInfo.scale == 0 || pageInfo.unitConversion == 0) {
          this.pageHasScale = false;
          return;
        }
        this.pageHasScale = true;
        
        var page = this.renderStore.data[this.renderStore.options.pageNumber].annotations["PAGE"];
        var viewports = {...this.renderStore.data[this.renderStore.options.pageNumber].annotations["VIEWPORT"]};
        var offset = 0;
        
        for(const vi in viewports) {
          const v = viewports[vi];
          if(v.bbox.x1 == page.bbox.x1 && v.bbox.x2 == page.bbox.x2) {
            if(v.bbox.y1 == page.bbox.y1 && v.bbox.y2 == page.bbox.y2) {
              offset = Math.max(page.bbox.y1, page.bbox.y2);
              delete viewports[vi];
            }
          }
        }

        var pointInWhich = [];
        arr.forEach((p, pi) => {
          pointInWhich[pi] = [];
          Object.entries(viewports).forEach((v, vi) => {
            var inViewport = this.pointInBbox(v[1].bbox, p, offset);
            if(inViewport) pointInWhich[pi].push(v[0]);
          });
          if(pointInWhich[pi].length == 0) {
            pointInWhich[pi].push(-1);
          }
        });

        if(pointInWhich.length == 0) return;
        
        this.foundScale = true;
        var vi = null;
        pointInWhich.forEach(p => {
          if(!this.foundScale) return;
          if(p.length > 1) this.foundScale = false;
          if(vi == null) vi = p[0];
          if(p[0] != vi) this.foundScale = false;
        });

        if(!this.foundScale) {
          this.renderStore.options.measureScale = null;
          return;
        }

        if(vi == -1) {
          var f = pageInfo.unitConversion / pageInfo.scale * 4;
          this.renderStore.options.measureScale = f;
        }
        else {
          var measure = this.renderStore.data[this.renderStore.options.pageNumber].annotations["MEASURE"][viewports[vi].refMeasure];
          var f = measure.unitConversion / measure.scale * 4;
          this.renderStore.options.measureScale = f;
        }

        if(arr != null) {
          this.count = arr.length/2;
          if(arr.length > 1) {
            this.distance = 0;
            for(var i = 0; i < arr.length-2; i = i+2) {
              var p1 = {x: arr[i], y: arr[i+1]};
              var p2 = {x: arr[i+2], y: arr[i+3]};
              var d = Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2));
              this.distance += d * f;
            }

            if(shape == "area") {
              var p1 = {x: arr[arr.length-2], y: arr[arr.length-1]};
              var p2 = {x: arr[0], y: arr[1]};
              var d = Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2));
              this.distance += d * f;
            }
            this.distance = Math.round(1000 * this.distance) / 1000;
          }

          if(arr.length > 2) {
            this.area = 0;
            if(!this.renderStore.options.measureIntersects) {
              for(var i = 0; i < arr.length; i = i+2) {
                var p1 = {x: arr[i], y: arr[i+1]};
                var p2 = {x: arr[i+2], y: arr[i+3]};
                if(p2.x == null || p2.y == null) p2 = {x: arr[0], y: arr[1]};
                this.area += (p1.x * f) * (p2.y * f) - (p1.y * f) * (p2.x * f);
              }
              this.area = Math.abs(this.area) / 2.0;
              this.area = Math.round(1000 * this.area) / 1000;
            }
          }
        }
      },
      deep: true
    }
  },
  methods: {
    setContributionTemplate(template) {
      this.store.current.data.pdf.contributionTemplate = {...template};
      this.store.current.popup = ['add-pdf-contribution'];
      //this.renderStore.options.activeSideMenu = null;
    },
    pointInBbox(bbox, p, o) {
      var px = 4 * p.x;
      var py = 4 * p.y;
      if(px >= bbox.x1 && px <= bbox.x2) {
        if(-py + o >= bbox.y1 && -py + o <= bbox.y2) {
          return true;
        }
      }
      return false;
    },
    cancel() {
      this.renderStore.options.selectedAnnotationId = -1;
      this.renderStore.options.selectedContributionId = -1;
      this.renderStore.options.activeContribution = null;
      this.renderStore.options.activeAnnotation = null;
      this.renderStore.options.activeToolboxItem = null;
      this.renderStore.options.measureEnd = true;
      this.store.current.data.pdf.contribution = null;
      this.store.current.data.pdf.contributionTemplate = null;
      this.store.current.data.pdf.answer = null;
      this.store.current.popup = [];
      renderAnnotations(true);
    },
    closeSideMenu() {
      this.renderStore.options.activeSideMenu = null;
    }
  },
  components: {
    
  }
}
</script>