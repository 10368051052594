<template>
  <transition name="fade">
    <div class="popup small" v-if="store.current.popup.includes('add-pdf-answer')">
      <div>
        <perfect-scrollbar style="height:100%">
          <form @submit.prevent="confirm">
            <div class="closer" @click="close()">Scherm sluiten</div>
            <h1>{{ title }}</h1>

            <template v-if="init">
              <div v-if="labelData.content" class="before-input" :class="{active:labelData.content.active}">{{ labelData.content.text }}</div>
              <textarea
                name="content" 
                v-model="answer.content"
                :class="{hasLabel:labelData.content.active}" 
                @focusin="empty($event)" 
                @focusout="reset($event)">
              </textarea>
              <div v-if="formErrors.content" class="error-field">{{ defaultData.content[0] + " " + formErrors.content }}!</div>

              <input type="submit" :value="submitText" :class="{locked:isLocked}">
            </template>
            <div v-else class="loader">
              <div>Even geduld, content wordt geladen...</div>
            </div>
          </form>
        </perfect-scrollbar>
      </div>
    </div>
  </transition>
</template>

<script>
import answerService from '@/services/app/answer.service'
import errorHandler from '@/common/ErrorHandler'
import { formatContribution } from '@/functions/FormatContribution.js'

export default {
  emits: ['showMessage'],
  props: {
    getData: Object
  },
  data() {
    return {
      answer: {
        content: ""
      },
      defaultData: {
        content: ['Uw antwoord', "Wat wilt u antwoorden?"]
      },
      labelData: {},
      formErrors: {},
      title: "",
      submitText: "",
      pending: false,
      init: false,
      exists: false
    };
  },
  computed: {
    store() {
      return this.$parent.store;
    },
    renderStore() {
      return this.$parent.renderStore;
    },
    currentPopup() {
      return this.store.current.popup[this.store.current.popup.length-1];
    },
    isLocked() {
      if(this.pending || !this.init) return true;
      else return false;
    }
  },
  watch: {
    async currentPopup(value) {
      if(value == "add-pdf-answer") {
        this.init = false;
        this.clear();

        if(this.store.current.data.pdf.answer != null) {
          this.answer = { 
            answerId: this.store.current.data.pdf.answer.answerId,
            contributionId: this.store.current.data.pdf.contribution.contributionId,
            content: this.store.current.data.pdf.answer.content
          };
          //await this.fetch();

          this.title = "Antwoord aanpassen";
          this.submitText = "Opslaan";
        }
        else {
          this.answer = {
            answerId: 0,
            contributionId: this.store.current.data.pdf.contribution.contributionId,
            content: ""
          }
          this.title = "Antwoord toevoegen";
          this.submitText = "Toevoegen";
        }
        this.init = true;
      }
    },
    answer(listing) {
      for(const[key, value] of Object.entries(listing)) {
        if(!this.defaultData[key]) continue;
        if(value && value.toString().length > 0) {
          this.labelData[key] = {text: this.defaultData[key][0], active: true};
        }
        else {
          this.labelData[key] = {text: '', active: false};
          this.answer[key] = this.defaultData[key][1];
        }
      }
    }
  },
  methods: {
    async confirm() {
      if(this.pending) return;
      this.pending = true;
      
      var response = null;
      if(this.answer.answerId) response = await answerService.update(this.answer, this.defaultData);
      else response = await answerService.add(this.answer, this.defaultData);
      if(response) {
        if(response.status == 200) {
          this.renderStore.contributions.forEach(contrib => {
            if(contrib.contributionId == this.answer.contributionId) {
              const contributionTemplate = this.renderStore.contributionTemplates[contrib.contributionTemplateId];
              contrib = formatContribution({...response.data}, contributionTemplate);
              this.renderStore.options.activeContribution = {...contrib};
            }
          });
          this.close();
        }
        else {
          this.$emit('showMessage', response);
          this.formErrors = errorHandler.process(response.data, "");
        }
      }
      this.pending = false;
    },
    empty(e) {
      const val = this.answer[e.target.name];
      if(this.defaultData[e.target.name] && (val == "" || val == this.defaultData[e.target.name][1])) {
        this.labelData[e.target.name] = {text: this.defaultData[e.target.name][0], active: true};
        this.answer[e.target.name] = "";
      }
    },
    reset(e) {
      const val = this.answer[e.target.name];
      if(val == "") {
        this.labelData[e.target.name] = {text: '', active: false};
        this.answer[e.target.name] = this.defaultData[e.target.name][1];
      }
    },
    clear() {
      for(const[key, val] of Object.entries(this.defaultData)) {
        this.labelData[key] = {text: '', active: false};
        this.answer[key] = val[1];
        this.formErrors = {};
      }
    },
    close() {
      this.store.current.popup = [];
      this.store.current.data.pdf.answer = null;
    },
  },
  components: {
    
  }
}
</script>
