<template>
  <transition name="fade">
    <div class="popup" v-if="store.current.popup.includes('reactivate-user')">
      <div>
        <perfect-scrollbar style="height:100%">
          <form @submit.prevent="reactivate">
            <div class="closer" @click="close()">Scherm sluiten</div>
            <h1>{{ title }}</h1>
            <div v-if="init">
              <p>
                U kunt de gebruiker opnieuw uitnodigen voor het activeren van zijn/haar account door hieronder 
                op de knop te drukken. Let op: met deze actie komt de voorgaande email te vervallen en de gebruiker
                kan dus niet meer via dat bericht zijn/haar account activeren. Gebruik deze optie dus alleen wanneer 
                de gebruiker géén email heeft ontvangen of wanneer de activatietermijn is verstreken.
              </p>
              <p>
                <b>Gebruiker: </b> {{ user.email }}<br/>
                <b>Huidige link actief tot: </b> {{ user.dateActivationDue }}
              </p>
              <p>
                De gebruiker zal ook een willekeurig nieuw wachtwoord ontvangen, omdat het systeem het eerder verstuurde
                wachtwoord niet kan achterhalen.
              </p>
              <input type="submit" :value="submitText" class="red" :class="{locked:isLocked}">
            </div>
            <div v-else class="loader">
              <div>Even geduld, content wordt geladen...</div>
            </div>
          </form>
        </perfect-scrollbar>
      </div>
    </div>
  </transition>
</template>

<script>
import userService from '@/services/user.service'

export default {
  emits: ['showMessage', 'updateParent'],
  data() {
    return {
      userId: null,
      user: {},
      title: "",
      submitText: "",
      init: false,
      pending: false
    };
  },
  computed: {
    store() {
      return this.$parent.store;
    },
    currentPopup() {
      return this.store.current.popup[this.store.current.popup.length-1];
    },
    isLocked() {
      if(this.pending || !this.init) return true;
      else return false;
    }
  },
  watch: {
    async currentPopup(value) {
      if(value == "reactivate-user") {
        this.init = false;

        this.title = "Nieuw activatiebericht versturen";
        this.submitText = "Ja, versturen!";
        
        this.userId = this.store.current.data.user.userId;
        await this.fetch();

        this.init = true;
        this.pending = false;
      }
    }
  },
  methods: {
    async reactivate() {
      if(this.pending) return;
      this.pending = true;
      const response = await userService.reactivate(this.userId);
      if(response) {
        if(response.status == 200) {
          this.close();
        }
        else if(response.data.code == "ACT_EXC") {
          this.$emit('showMessage', "De activatie kon niet worden vernieuwd omdat deze aanvraag te kort volgt op een vorige aanvraag. Probeer het later opnieuw.");
        }
        else {
          this.$emit('showMessage', response);
        }
      }
      this.pending = false;
    },
    close() {
      this.store.current.popup.splice(
        this.store.current.popup.indexOf('reactivate-user'), 
        1
      );
      this.store.current.data.user = null;
    },
    async fetch() {
      if(this.userId) {
        this.user = await userService.select(this.userId);
      }
    }
  }
}
</script>
