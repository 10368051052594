import { defineStore } from 'pinia'

export const useStore = defineStore('main', {
  state: () => {
    return { 
      appName: "Foundation",
      user: (localStorage.getItem("user") != "null") ? localStorage.getItem("user") : null,
      accessToken: (localStorage.getItem("accessToken") != "null") ? localStorage.getItem("accessToken") : null,
      refreshToken: (localStorage.getItem("refreshToken") != "null") ? localStorage.getItem("refreshToken") : null,
      sessionRefreshingState: false,
      sessionRefreshingTime: null,
      securityToken: null,
      imageThreads: 0,
      isUser: (localStorage.getItem("isUser") == "true") ? true : false,
      isAdmin: (localStorage.getItem("isAdmin") == "true") ? true : false,
      isRegistrar: (localStorage.getItem("isRegistrar") == "true") ? true : false,
      menuSticky: false,
      pivotFullScreen: false,
      current: {
        scrollY: 0,
        page: '',
        form: 'list-pdf-projects',
        popup: [],
        data: {
          openai: {
            category: null,
            chapter: null,
            paragraph: null,
            endpoint: null
          },
          pdf: {
            project: null
          },
          user: null
        }
      },
      components: [],
      messageTimer: null,
      fileToUpload: null,
      uploadProgress: 0
    }
  },
  actions: {
    reset() {
      this.sessionRefreshingState = false;
      this.sessionRefreshingTime = new Date(2000, 1, 1);
      this.imageThreads = 0;
      this.current.popup = [];
      this.current.form = "list-pdf-projects";
    },
    persistSession() {
      localStorage.setItem("user", this.user);
      localStorage.setItem("accessToken", this.accessToken);
      localStorage.setItem("refreshToken", this.refreshToken);
      localStorage.setItem("isUser", this.isUser);
      localStorage.setItem("isAdmin", this.isAdmin);
      localStorage.setItem("isRegistrar", this.isRegistrar);
    }
  },
  persist: {
    enabled: true,
    storage: localStorage
  }
})