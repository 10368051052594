<template>
  <template v-if="store.current.popup.includes('add-pdf-contribution')">
    <template v-if="!contributionTemplate">
      <transition name="fade">
        <div class="popup">
          <div>
            <div class="closer" @click="close()">Scherm sluiten</div>
            <h1>Wat wilt u toevoegen?</h1>
            <template v-for="template in Object.values(renderStore.contributionTemplates)">
              <a v-if="!(template.disabled || template.name == 'Toolbox')" href="#" @click="setContributionTemplate(template, true)" class="temp-sel">
                <div class="ver">Versie: {{ template.version }}</div>
                <h3>{{ template.name }}</h3>
                <p>
                  {{ template.description }}
                </p>
              </a>
            </template>
          </div>
        </div>
      </transition>
    </template>
    <template v-else-if="renderStore.options.measureEnd || (!this.contributionTemplate.maxPoints && positions != null)">
      <transition name="fade">
        <div class="side">
          <div class="sz">
            <div class="sz">
              <perfect-scrollbar style="height:100%">
                <div style="padding-right:25px">
                  <form @submit.prevent="add">
                    <div class="adder small gray" @click="closeWithoutSave" title="Editor sluiten"><img src="img/ico/close-1.png"></div>

                    <h1 style="margin-bottom:5px">
                      {{ contributionTemplate.name }}
                      <div class="ver">{{ contributionTemplate.version }}</div>
                    </h1>
                    <p>{{ contributionTemplate.description }}</p>

                    <template v-if="renderStore.options.measureIntersects">
                      <div class="not-avail">
                        Deze meting kan niet worden aangemaakt omdat er geen oppervlakte kan worden gedefinieerd.
                      </div>
                    </template>
                    
                    <template v-if="contributionTemplate && contributionTemplate.viewerOutput && contributionTemplate.viewerOutput.shape.scale && !renderStore.options.measureScale && !contribution.values.msc">
                      <div class="not-avail">
                        Deze meting kan niet worden aangemaakt omdat er geen schaal bekend is voor het document.
                      </div>
                    </template>

                    <template v-for="(input, key) in contributionTemplate.inputs">

                      <template v-if="input['type'] === 'head'">
                        <h3 style="clear:both">{{ input.title }}</h3>
                      </template>

                      <template v-if="!input['internal']">
                        <template v-if="input['type'] === 'text' || input['type'] === 'number' || input['type'] === 'float'">
                          <div :key="key">
                            <div v-if="input.labelShort" class="before-input" :class="{active:labelData[input.name].active}">{{ input.labelShort }}</div>
                            <input type="text"
                              :name="input.name"
                              ref="textInput"
                              v-model="contribution.values[input.name]"
                              :class="{hasLabel:labelData[input.name].active}" 
                              @focusin="empty($event)" 
                              @focusout="reset($event)"
                              @input="valueChanged(input.name)"
                              :style="(contribution && contribution.changedValues && Object.keys(contribution.changedValues).indexOf(input.name) >= 0)?'background:linear-gradient(40deg, rgb(69 106 141 / 10%) 0%, rgb(62 94 132 / 20%) 100%)':''" />
                            <div v-if="formErrors[input.name]" class="error-field">{{ input.labelShort + " " + formErrors[input.name] }}!</div>
                          </div>
                        </template>

                        <template v-if="input['type'] === 'textarea'">
                          <div :key="key">
                            <div v-if="input.labelShort" class="before-input" :class="{active:labelData[input.name].active}">{{ input.labelShort }}</div>
                            <textarea
                              :name="input.name"
                              v-model="contribution.values[input.name]"
                              :class="{hasLabel:labelData[input.name].active}" 
                              @focusin="empty($event)" 
                              @focusout="reset($event)"
                              @input="valueChanged(input.name)"
                              :style="(contribution && contribution.changedValues && Object.keys(contribution.changedValues).indexOf(input.name) >= 0)?'background:linear-gradient(40deg, rgb(69 106 141 / 10%) 0%, rgb(62 94 132 / 20%) 100%)':''" />
                            <div v-if="formErrors[input.name]" class="error-field">{{ input.labelShort + " " + formErrors[input.name] }}!</div>
                          </div>
                        </template>
                        
                        <template v-if="input['type'] === 'select'">
                          <div :key="key">
                            <label>
                              {{ input.label }}
                              <Multiselect 
                                :name="input.name"
                                v-model="contribution.values[input.name]" 
                                :options="input.options" 
                                @update:modelValue="valueChanged(input.name)"
                                placeholder="Maak een keuze..." 
                                noOptionsText="Geen opties gevonden."
                                :style="(contribution && contribution.changedValues && Object.keys(contribution.changedValues).indexOf(input.name) >= 0)?'background:linear-gradient(40deg, rgb(69 106 141 / 10%) 0%, rgb(62 94 132 / 20%) 100%)':''"
                              />
                            </label>
                            <div v-if="formErrors[input.name]" class="error-field">{{ input.labelShort + " " + formErrors[input.name] }}!</div>
                          </div>
                        </template>
                        
                        <template v-if="input['type'] === 'rgb'">
                          <div class="color-choice" style="float:left;width:65%" :style="(contribution && contribution.changedValues && Object.keys(contribution.changedValues).indexOf(input.name) >= 0)?'background:linear-gradient(40deg, rgb(69 106 141 / 10%) 0%, rgb(62 94 132 / 20%) 100%) !important':''">
                            <span>{{ input.labelShort }}</span>
                            <template v-for="(rgb, name) in rgbColors">
                              <div :title="name" :style="{backgroundColor: '#'+rgb}" :class="{sel:(contribution.values[input.name] == rgb)}" @click="setColor(input.name, rgb)"></div>
                            </template>
                            <div class="cl"></div>
                          </div>
                        </template>

                        <template v-if="input['type'] === 'range'">
                          <div :key="key" :style="(input.float)?'float:right;width:30%':''">
                            <div v-if="input.labelShort" class="before-input" :class="{active:labelData[input.name].active}">{{ input.labelShort }}</div>
                            <input type="number"
                              :name="input.name"
                              :min="input.min"
                              :max="input.max"
                              :step="input.step"
                              v-model="contribution.values[input.name]"
                              :class="{hasLabel:labelData[input.name].active}" 
                              @focusin="empty($event)" 
                              @focusout="reset($event)"
                              @input="valueChanged(input.name)"
                              :style="(contribution && contribution.changedValues && Object.keys(contribution.changedValues).indexOf(input.name) >= 0)?'background:linear-gradient(40deg, rgb(69 106 141 / 10%) 0%, rgb(62 94 132 / 20%) 100%)':''" />
                            <div v-if="formErrors[input.name]" class="error-field">{{ input.labelShort + " " + formErrors[input.name] }}!</div>
                          </div>
                        </template>

                        <template v-if="input['type'] === 'checkbox'">
                          <div :key="key">
                            <label>
                              {{ input.label }}
                              <input type="checkbox" 
                                v-model="contribution.values[input.name]" 
                                @click="toggle($event)"
                                @change="valueChanged(input.name)"
                                :style="(contribution && contribution.changedValues && Object.keys(contribution.changedValues).indexOf(input.name) >= 0)?'background:linear-gradient(40deg, rgb(69 106 141 / 10%) 0%, rgb(62 94 132 / 20%) 100%)':''">
                              <span :class="{active:contribution[input.name]}"></span>
                            </label>
                            <div v-if="formErrors[input.name]" class="error-field">{{ input.labelShort + " " + formErrors[input.name] }}!</div>
                          </div>
                        </template>

                        <template v-if="input['type'] === 'icon'">
                          <div class="ico-choice" :style="(contribution && contribution.changedValues && Object.keys(contribution.changedValues).indexOf(input.name) >= 0)?'background:linear-gradient(40deg, rgb(69 106 141 / 10%) 0%, rgb(62 94 132 / 20%) 100%)':''">
                            <span>Kies een icoon voor het item</span>
                            <template v-for="icon in icons">
                              <img :class="(contribution.values.ico == icon) ? 'sel' : ''" :src="'img/tbx/'+icon+'.png'" :val="icon" @click="setIcon(icon)">
                            </template>
                          </div>
                        </template>
                      </template>
                    </template>

                    <h3 style="clear:both">Algemeen</h3>
                    <label>
                      Openbaar
                      <input type="checkbox" 
                        v-model="contribution.isPublic" 
                        @click="toggle($event)">
                      <span :class="{active:contribution.isPublic}"></span>
                    </label>
                    
                    <template v-if="contributionTemplate.allowAttachment && !contribution.multi">
                      <h3 style="clear:both;margin-top:20px;">Gekoppelde foto's</h3>
                      <div class="fup">
                        <div v-for="(attachment, index) in contribution.athumbnails" class="thumb">
                          <img :src="'data:image/jpg;base64,'+attachment" title="Klik om te verwijderen" @click="removeAttachment(index-1)">
                        </div>
                        <div style="clear:both"></div>

                        <template v-for="file in this.filesToUpload">
                          <FileUpload 
                            :maxSize="10000" 
                            :file=file
                            accept="png,jpg"
                            removable
                            @show-message="$emit('showMessage')"
                            @file-changed="validateUpload"
                            @file-removed="removeUpload(file)"/>
                        </template>

                        <template v-if="(this.filesToUpload.length + this.filesToKeep.length) < 3">
                          <FileUpload 
                            :maxSize="10000" 
                            :file="new Object()"
                            accept="png,jpg"
                            removable
                            @show-message="$emit('showMessage')"
                            @file-changed="validateUpload"
                            @file-removed="removeUpload(file)"/>
                        </template>
                      </div>
                    </template>
                    <input type="submit" value="Opslaan" :class="{locked:isLocked}">
                  </form>
                </div>
              </perfect-scrollbar>
            </div>
          </div>
        </div>
      </transition>
    </template>
  </template>
</template>

<script>
import contributionService from '@/services/app/contribution.service'
import errorHandler from '@/common/ErrorHandler'

import FileUpload from '@/components/FileUpload'
import Multiselect from '@vueform/multiselect/src/Multiselect'
import { resetSelection, renderAnnotations } from '@/components/app/viewer/PdfCanvas.js'
import { formatContribution } from '@/functions/FormatContribution.js'

import * as easelGL from 'easel-gl'
import { nextTick } from 'vue'

export default {
  emits: ['showMessage', 'updateParent', 'updateParentMulti', 'resetParent'],
  props: {
    document: Object,
    pageNumber: Number,
    getData: Object
  },
  data() {
    return {
      contribution: {
        contributionId: 0,
        contributionTemplateId: 1,
        annotationId: "",
        isPublic: false
      },
      updatePositions: [],
      updateValues: {},
      contributionTemplate: null,
      rgbColors: {
        "Indian Red": "cd5c5c",
        "Salmon": "fa8072",
        "Crimson": "dc143c",
        "Red": "ff0000",
        "Fire Brick": "b22222",
        "Dark Red": "8b0000",
        "Pink": "ffc0cb",
        "Hot Pink": "ff69b4",
        "Medium Violet Red": "c71585",
        "Coral": "ff7f50",
        "Orange Red": "ff4500",
        "Dark Orange": "ff8c00",
        "Orange": "ffa500",
        "Gold": "ffd700",
        "Yellow": "ffff00",
        "Lemon Chiffon": "fffacd",
        "Moccasin": "ffe4b5",
        "Khaki": "f0e68c",
        "Dark Khaki": "bdb76b",
        "Lavender": "e6e6fa",
        "Plum": "dda0dd",
        "Violet": "ee82ee",
        "Magenta": "ff00ff",
        "Medium Purple": "9370db",
        "Rebecca Purple": "663399",
        "Dark Magenta": "8b008b",
        "Indigo": "4b0082",
        "Slate Blue": "6a5acd",
        "Dark Slate Blue": "483d8b",
        "Green Yellow": "adff2f",
        "Lawn Green": "7cfc00",
        "Lime Green": "32cd32",
        "Light Green": "90ee90",
        "Medium Sea Green": "3cb371",
        "Green": "008000",
        "Dark Green": "006400",
        "Yellow Green": "9acd32",
        "Olive": "808000",
        "Medium Aquamarine": "66cdaa",
        "Dark Cyan": "008b8b",
        "Teal": "008080",
        "Aqua": "00ffff",
        "Light Cyan": "e0ffff",
        "Pale Turquoise": "afeeee",
        "Aquamarine": "7fffd4",
        "Turquoise": "40e0d0",
        "Cadet Blue": "5f9ea0",
        "Steel Blue": "4682b4",
        "Light Steel Blue": "b0c4de",
        "Powder Blue": "b0e0e6",
        "Sky Blue": "87ceeb",
        "Deep Sky Blue": "00bfff",
        "Dodger Blue": "1e90ff",
        "Royal Blue": "4169e1",
        "Foundation Blue": "516f9d",
        "Blue": "0000ff",
        "Midnight Blue": "191970",
        "Blanched Almond": "ffebcd",
        "Navajo White": "ffdead",
        "Burly Wood": "deb887",
        "Rosy Brown": "bc8f8f",
        "Sandy Brown": "f4a460",
        "Goldenrod": "daa520",
        "Chocolate": "d2691e",
        "Saddle Brown": "8b4513",
        "Brown": "a52a2a",
        "Gainsboro": "dcdcdc",
        "Silver": "c0c0c0",
        "Gray": "808080",
        "Light Slate Gray": "778899",
        "Black": "000000"
      },
      icons: [
        'bao', 'bar', 'bri', 'cne', 'dem', 'exc', 'flo', 'ham', 'pai', 'pip', 'sho', 'spa', 'ste',
        'mea', 'lnd', 'mix', 'rei', 'wlp', 'flr', 'wal', 'win', 'doo', 'sta',
        'rof', 'lam', 'fla', 'wat', 'ven', 'wod', 'til', 'the', 'smh', 'mir',
        'ch1', 'ch2', 'ch3', 'ch4', 'ch5'
      ],
      filesToUpload: [],
      filesToKeep: [],
      defaultData: {},
      labelData: {},
      formErrors: {},
      title: "",
      init: false,
      pending: false,
      accepted: false
    };
    
  },
  computed: {
    store() {
      return this.$parent.store;
    },
    renderStore() {
      return this.$parent.renderStore;
    },
    currentPopup() {
      return this.store.current.popup[this.store.current.popup.length-1];
    },
    activeAnnotation() {
      return this.renderStore.options.activeAnnotation;
    },
    pinnedScreenshot() {
      return this.renderStore.pinnedScreenshot;
    },
    measureEnd() {
      return this.renderStore.options.measureEnd;
    },
    isLocked() {
      if(this.pending || !this.init) return true;
      else return false;
    },
    positions() {
      if(this.store.current.data.pdf.contribution != null) {
        return this.store.current.data.pdf.contribution.positions;
      }
      return null;
    }
  },
  watch: {
    pageNumber(value) {
      this.contribution.pageNumber = value;
    },
    async currentPopup(value) {
      if(value == "add-pdf-contribution") {
        this.init = false;
        this.contributionTemplate = null;
        this.renderStore.options.measureEnd = false;
        this.filesToUpload = [];
        this.filesToKeep = [];
        this.formErrors = [];
        document.addEventListener("keyup", this.onKeyUp);

        this.contribution.contributionId = 0;
        this.contribution.pageNumber = this.pageNumber;

        if(this.renderStore.options.activeToolboxItem != null) {
          var toolboxItem = {...this.renderStore.options.activeToolboxItem};
          var contributionTemplateId = toolboxItem.contributionTemplateId;

          this.contribution.contributionId = 0;
          this.contribution.annotationId = null;
          this.contribution.pageNumber = this.pageNumber;
          this.contribution.values = {...toolboxItem.values};
          this.contribution.values.sj = toolboxItem.name;
          this.contribution.values.mc = toolboxItem.code;
          this.contribution.positions = [];
          this.contribution.multi = false;
          this.contribution.contributionIds = null;
          
          this.setContributionTemplate(this.renderStore.contributionTemplates[contributionTemplateId], false);
          this.setData(this.contributionTemplate.inputs, this.contribution.values);
          this.contribution.minPoints = this.contributionTemplate.minPoints;
          
          this.accepted = false;
          this.renderStore.options.measureEnd = (this.contributionTemplate.maxPoints == 0) ? true : false;
          
          resetSelection();
          this.contribution.cache = this.renderStore.options.activeToolboxItem.cache;
          this.store.current.data.pdf.contributionTemplate = {...this.contributionTemplate};
        }
        else if(this.store.current.data.pdf.contributionTemplate != null) {
          this.setContributionTemplate(this.store.current.data.pdf.contributionTemplate, true);
          this.setData(this.contributionTemplate.inputs, this.contribution.values);

          this.contribution.contributionId = 0;
          this.contribution.annotationId = null;
          this.contribution.pageNumber = this.pageNumber;
          this.contribution.positions = [];
          this.contribution.minPoints = this.contributionTemplate.minPoints;
          this.contribution.multi = false;
          this.contribution.contributionIds = null;

          this.accepted = false;
          this.renderStore.options.measureEnd = (this.contributionTemplate.maxPoints == 0) ? true : false;

          if(this.contributionTemplate.viewerOutput.shape.type == 'count') {
            this.contribution.values.is = 6;
            this.setIcon('ch4');
          }
          if(this.contributionTemplate.viewerOutput.shape.type == 'icon') {
            this.contribution.values.ico = 'ch1';
          }
          
          resetSelection();
          this.store.current.data.pdf.contributionTemplate = {...this.contributionTemplate};
        }
        else if(this.store.current.data.pdf.contribution != null) {
          if(!this.store.current.data.pdf.contribution.copy) {
            this.contribution = {...this.store.current.data.pdf.contribution};
            this.contribution.values = {...this.store.current.data.pdf.contribution.values};

            this.accepted = true;
            this.renderStore.options.measureEnd = true;

            this.setContributionTemplate(this.renderStore.contributionTemplates[this.contribution.contributionTemplateId], false);
            this.contribution.values = this.updateSelectValues(this.contributionTemplate.inputs, this.contribution.values);
            this.setData(this.contributionTemplate.inputs, this.contribution.values);
            
            if(this.contribution.athumbnails != null) {
              for(const key in this.contribution.athumbnails) {
                this.filesToKeep.push(key);
              }
            }
            this.store.current.data.pdf.contributionTemplate = {...this.contributionTemplate};

            if(this.contribution.positions != null) {
              this.updatePositions = [...this.contribution.positions];
            }
            this.updateValues = {...this.store.current.data.pdf.contribution.values};
          }
          else {
            this.contribution = { ...this.store.current.data.pdf.contribution };
            this.contribution.values = {...this.store.current.data.pdf.contribution.values};
            delete this.contribution.values.mr;
            this.contribution.contributionId = 0;
            this.setContributionTemplate(this.renderStore.contributionTemplates[this.contribution.contributionTemplateId], false);
            this.contribution.annotationId = null;
            this.contribution.pageNumber = this.pageNumber;
            this.contribution.positions = [];
            this.contribution.minPoints = this.contributionTemplate.minPoints;
            this.contribution.multi = false;
            this.contribution.contributionIds = null;
            
            this.accepted = false;
            this.renderStore.options.measureEnd = (this.contributionTemplate.maxPoints == 0) ? true : false;

            this.contributionTemplate.inputs.forEach(input => {
              if(input.type == "select" || input.type == "radio") {
                this.contribution.values[input.name] = input.options[this.contribution.values[input.name]];
              }
            });

            resetSelection();
            this.store.current.data.pdf.contribution = {...this.contribution};
            this.store.current.data.pdf.contribution.positions = [];
            this.store.current.data.pdf.contributionTemplate = {...this.contributionTemplate};
          }
        }
        else {
          this.restart();
        }

        this.init = true;
        this.pending = false;
      }
    },
    contribution: {
      handler(listing) {
        if(listing == null) return;
        for(const[key, value] of Object.entries(listing)) {
          if(!this.defaultData[key]) continue;
          if(value && value.toString().length > 0) {
            this.labelData[key] = {text: this.defaultData[key][0], active: true};
          }
          else {
            this.labelData[key] = {text: '', active: false};
            this.contribution[key] = this.defaultData[key][1];
          }
        }

        this.store.current.data.pdf.contribution = listing;
      },
      deep: true
    },
    activeAnnotation(annot) {
      if(annot) this.contribution.annotationId = annot.id;
      else this.contribution.annotationId = null;
    },
    positions: {
      handler(val) {
        if(this.contributionTemplate != null && this.contributionTemplate.maxPoints && val != null) {
          var self = this;
          nextTick(function() {
            if(self.$refs.textInput != null && self.$refs.textInput[0] != null) {
              self.$refs.textInput[0].focus();
            }
          });
        }    
      }
    },
    measureEnd: {
      handler(state) {
        if(state) {
          var self = this;
          nextTick(function() {
            if(self.$refs.textInput != null && self.$refs.textInput[0] != null) {
              self.$refs.textInput[0].focus();
            }
          });
        }    
      }
    },
  },
  methods: {
    async add() {
      if(this.pending) return;
      var isUpdate = false;
      let response = null;

      if(this.contribution.values.msc > 0) {
        this.renderStore.options.measureScale = this.contribution.values.msc;
      }

      if(this.renderStore.options.measureIntersects) return;
      if(this.contributionTemplate.viewerOutput.shape.scale && !this.renderStore.options.measureScale) return;
      

      this.pending = true;
      if(this.contribution.multi) {
        response = await contributionService.updateMulti({
          contributionIds: this.contribution.contributionIds,
          values: this.contribution.changedValues
        }, this.defaultData, this.getData);
      }
      else if(!this.contribution.contributionId) {
        this.contribution.values.msc = this.renderStore.options.measureScale;
        if(this.store.current.data.pdf.contribution.positions != null) {
          this.contribution.positions = [...this.store.current.data.pdf.contribution.positions];
        }
        response = await contributionService.add(this.contribution, this.pageNumber, this.document, this.defaultData, this.filesToUpload, this.getData);
      }
      else {
        this.contribution.values.msc = this.renderStore.options.measureScale;
        if(this.store.current.data.pdf.contribution.positions != null) {
          this.contribution.positions = [...this.store.current.data.pdf.contribution.positions];
        }
        response = await contributionService.update(this.contribution, this.defaultData, this.filesToKeep, this.filesToUpload, this.getData);
        isUpdate = true;
      }

      if(response) {
        const activeToolboxItem = {...this.renderStore.options.activeToolboxItem};
        if(response.status == 200) {
          if(this.store.current.data.pdf.contribution && this.store.current.data.pdf.contribution.copy) {
            this.store.current.data.pdf.contribution = null;
            this.renderStore.options.activeContribution = null;
          }

          if(response.data.contributionValues) {
            var contributionsToUpdate = [];
            for(var i = 0; i < this.renderStore.contributions.length; i++) {
              Object.entries(response.data.contributionValues).forEach(valuesForContribution => {
                if(this.renderStore.contributions[i].contributionId == parseInt(valuesForContribution[0])) {
                  const contributionTemplate = this.renderStore.contributionTemplates[this.renderStore.contributions[i].contributionTemplateId];
                  this.renderStore.contributions[i].values = JSON.parse(valuesForContribution[1]);
                  this.renderStore.contributions[i].title = this.parseValues(contributionTemplate.listingOutput.title, valuesForContribution[1]);

                  let dateModified = new Date();
                  this.renderStore.contributions[i].dateModifiedUnix = dateModified.getTime();
                  this.renderStore.contributions[i].dateModified = dateModified.toLocaleDateString("nl-nl") + " om " + dateModified.toLocaleTimeString("nl-nl");
                  
                  contributionsToUpdate.push(this.renderStore.contributions[i]);
                }
              });
            }
            this.$emit('updateParentMulti', contributionsToUpdate, false);
          }
          else if(this.contribution.contributionId) {
            const contributionTemplate = this.renderStore.contributionTemplates[response.data.contributionTemplateId];
            response.data = formatContribution(response.data, contributionTemplate);

            this.updateValues = {...response.data.values};
            this.updatePositions = [];
            this.$emit('updateParent', response.data, false);
          }
          else {
            const contributionTemplate = this.renderStore.contributionTemplates[response.data.contributionTemplateId];
            response.data = formatContribution(response.data, contributionTemplate);

            this.updateValues = {};
            this.updatePositions = [];
            this.$emit('updateParent', response.data, true);
          }

          this.renderStore.options.showContributions = true;
          this.renderStore.options.showAnnotations = false;
          this.close();

          if(activeToolboxItem && activeToolboxItem.toolboxItemId) {
            var self = this;
            nextTick(function() {
              self.renderStore.options.activeToolboxItem = {...activeToolboxItem};
              self.store.current.popup = ["add-pdf-contribution"];
            });
          }
        }
        else {
          this.$emit('showMessage', response);
          this.formErrors = errorHandler.process(response.data, "");
        }
      }
      this.pending = false;
    },
    parseValues(format, values) {
      for(const [key,value] of Object.entries(JSON.parse(values))) {
        format = format.replaceAll("{{"+key+"}}", value);
      }
      if(format.startsWith(": ")) format = format.substring(2);
      return format;
    },
    toggle(e) {
      this.contribution.values[e.target.name] = !this.contribution.values[e.target.name];
    },
    empty(e) {
      const val = this.contribution.values[e.target.name];
      if(this.defaultData[e.target.name] && (val == "" || val == this.defaultData[e.target.name][1])) {
        this.labelData[e.target.name] = {text: this.defaultData[e.target.name][0], active: true};
        this.contribution.values[e.target.name] = "";
      }
    },
    reset(e) {
      const val = (this.contribution[e.target.name]) ? this.contribution[e.target.name] : this.contribution.values[e.target.name];
      if((!val || val.length == 0) && this.defaultData[e.target.name][1]) {
        this.labelData[e.target.name] = {text: '', active: false};
        this.contribution.values[e.target.name] = this.defaultData[e.target.name][1];
      }
    },
    clear() {
      for(const[key, val] of Object.entries(this.defaultData)) {
        this.labelData[key] = {text: '', active: false};
        this.contribution.values[key] = val[1];
      }
      this.formErrors = {};
    },
    restart() {
      //this.contribution.contributionId = -1;
      //this.contributionTemplate = null;
      this.accepted = false;
      this.renderStore.options.measureEnd = false;
    },
    proceed() {
      this.accepted = true;
      this.renderStore.options.measureEnd = false;
    },
    closeWithoutSave() {
      this.renderStore.options.activeToolboxItem = null;
      this.$emit('resetParent', this.updatePositions, this.updateValues, false);
      this.close();
    },
    close() {
      this.contribution.contributionId = 0;
      this.contribution.annotationId = null;
      this.contributionTemplate = null;
      this.renderStore.options.measureEnd = true;
      this.store.current.data.pdf.toolboxItem = null;
      this.store.current.data.pdf.contribution = null;
      this.store.current.data.pdf.contributionTemplate = null;
      this.store.current.popup.splice(
        this.store.current.popup.indexOf('add-pdf-contribution'), 
        1
      );
    },
    setContributionTemplate(template, resetValues) {
      this.contributionTemplate = {...template};
      //this.contributionTemplate.inputs = JSON.parse(this.contributionTemplate.inputs);
      this.contribution.contributionTemplateId = this.contributionTemplate.contributionTemplateId;
      this.setData(this.contributionTemplate.inputs, this.contribution.values);
      if(resetValues) {
        this.contribution.isPublic = this.contributionTemplate.public;
        this.setContributionValues(this.contributionTemplate.inputs);
        this.clear();
      }
      this.renderStore.options.showAnnotations = false;
      this.renderStore.options.showContributions = true;
      this.store.current.data.pdf.contributionTemplate = {...this.contributionTemplate};
    },
    setContributionValues(inputs) {
      this.contribution.values = {};
      inputs.forEach(input => {
        this.contribution.values[input.name] = "";
      });
    },
    updateSelectValues(inputs, values) {
      var updatedValues = {};
      inputs.forEach(input => {
        if(input.type == "select" || input.type == "radio") {
          updatedValues[input.name] = input.options[values[input.name]];
        }
        else updatedValues[input.name] = values[input.name];
      });
      return updatedValues;
    },
    setData(inputs, values) {
      this.labelData = {};
      this.defaultData = {};
      inputs.forEach(input => {
        this.defaultData[input.name] = [
          input.labelShort,
          input.label
        ];
        this.labelData[input.name] = {
          active: false,
          text: input.labelShort
        };

        if(values != null) {
          if(values[input.name] == input.label && (input.type == "text" || input.type == "textarea" || input.type == "float")) {
            this.labelData[input.name].active = false;
          }
          else if(values[input.name] != null && String(values[input.name]).length > 0) {
            this.labelData[input.name].active = true;
          }
          else {
            values[input.name] = this.defaultData[input.name][1];
            this.labelData[input.name].active = false;
          }
        }
      });

      var self = this;
      nextTick(function() {
        if(self.$refs.textInput != null && self.$refs.textInput[0] != null) {
          self.$refs.textInput[0].focus();
        }
      });
    },
    async setIcon(icon) {
      if(!this.contribution.changedValues) this.contribution.changedValues = {};
      this.contribution.values.ico = icon;
      this.contribution.changedValues.ico = icon;
      if(this.contributionTemplate.viewerOutput.shape.type == 'count') {
        this.updateTempCache();
      }
    },
    valueChanged(field,) {
      if(!this.contribution.changedValues) this.contribution.changedValues = {};
      this.contribution.changedValues[field] = this.contribution.values[field];
    },
    validateUpload(file) {
      this.filesToUpload.push(file);
    },
    removeUpload(file) {
      this.filesToUpload = this.filesToUpload.filter(x => x.data !== file.data);
    },
    removeAttachment(index) {
      if(this.contribution.athumbnails.length > 1) {
        this.contribution.athumbnails = this.contribution.athumbnails.splice(index, 1);
        this.filesToKeep = this.filesToKeep.splice(index, 1);
      }
      else {
        this.contribution.athumbnails = [];
        this.filesToKeep = [];
      }
    },
    padNumber(number) {
      var str = String(number);
      if(str.indexOf("-") != -1) return null;

      var dP = str.indexOf(".");
      var dC = 0;
      if(dP >= 0) dC = str.length - dP - 1;

      var iC = str.length - dC - 1;
      
      while(dC > 3) {
        str = str.substring(0, str.length - 1);
        dC--;
      }
      /*while(iC < 6) {
        str = "0"+str;
        iC++;
      }*/

      if(dC == 0) str += ".";
      while(dC < 3) {
        str = str+"0";
        dC++;
      }
      return str;
    },
    onKeyUp(e) {
      switch(e.keyCode) {
        // ENTER (ALSO NUMPAD)
        case 13:
        case 176:
          if(this.store.current.data.pdf.contribution != null) {
            if(this.store.current.data.pdf.contribution.positions != null && this.store.current.data.pdf.contribution.positions.length/2 >= this.store.current.data.pdf.contribution.minPoints) {
              this.renderStore.options.measureEnd = true;
            }
          }
          break;

        // ESCAPE
        case 27:
          this.closeWithoutSave();
          break;
      }
    },
    setColor(field, rgb) {
      if(!this.contribution.changedValues) this.contribution.changedValues = {};
      this.contribution.values[field] = rgb;
      this.contribution.changedValues[field] = rgb;
      if(field == "ic") {
        this.updateTempCache();
      }
    },
    updateTempCache() {
      let ic = new easelGL.Bitmap("img/tbx/"+this.contribution.values.ico+".png");
      let rgb = this.hexToRgb(this.contribution.values.ic);
      let self = this;
      ic.image.onload = function() {
        ic.filters = [
          new easelGL.ColorFilter(0, 0, 0, 1, rgb.r, rgb.g, rgb.b)
        ];
        ic.cache(-ic.image.width, -ic.image.height, ic.image.width*2, ic.image.height*2);
        delete self.contribution.tempCache;
        self.contribution.tempCache = ic.cacheCanvas;
        ic.uncache();
      };
    },
    hexToRgb(hex) {
      var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      } : null;
    }
  },
  components: {
    Multiselect,
    FileUpload
  }
}
</script>
