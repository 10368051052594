import { createWebHistory, createRouter } from "vue-router"

import Home from '@/components/pages/Home.vue'
import App from '@/components/pages/App.vue'
import Subscriptions from '@/components/pages/Subscriptions.vue'
import Safety from '@/components/pages/Safety.vue'
import About from '@/components/pages/About.vue'
import Contact from '@/components/pages/Contact.vue'

const routes = [
  { path: "/", component: App },
  //{ path: "/", component: Home },
  { path: "/app", component: App },
  { path: "/abonnementen", component: Subscriptions },
  { path: "/data-beveiliging", component: Safety },
  { path: "/over-het-pakket", component: About },
  { path: "/contact", component: Contact }
];
const router = createRouter({
  mode: 'history',
  history: createWebHistory('/'),
  routes
});
export default router;
