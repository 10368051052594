<template>
  <div class="content" v-if="this.renderStore.options.activeSideMenu === 'bookmarks'">
    <div class="cm" @click="closeSideMenu()" title="Scherm sluiten">
      <img src="img/ico/close-1.png">
    </div>
    <h3>Bladwijzers</h3>
    <template v-if="this.renderStore.outlines != null">
      <template v-for="outline in this.renderStore.outlines">
        <div class="outl" :class="{active: outline.pageNumber == this.renderStore.options.pageNumber, avail: outline.pageNumber == 0 }" @click="goToPage(outline.pageNumber)">
          <div :style="{'margin-left': (outline.depth * 15)+'px'}">
            <span v-if="this.renderStore.data[outline.pageNumber] && this.renderStore.data[outline.pageNumber]['annotations']['PAGE'].isNew">Nieuw</span>
            {{ parsedText(outline.title) }}
          </div>
        </div>
      </template>
    </template>
    <template v-else>
      <div class="not-avail">
        Er zijn geen bladwijzers opgenomen in dit document.
      </div>
    </template>
  </div>
</template>

<script>
import { resetSelection } from '@/components/app/viewer/PdfCanvas.js'

export default {
  computed: {
    store() {
      return this.$parent.store;
    },
    renderStore() {
      return this.$parent.renderStore;
    }
  },
  methods: {
    goToPage(pageNumber) {
      if(pageNumber > 0) {
        resetSelection();
        this.renderStore.options.pageNumber = parseInt(pageNumber);
      }
    },
    parsedText(text) {
      try { return text.replace(/&#(\d+);/g, function (m, n) { 
        return String.fromCharCode(n); }); 
      }
      catch(e) { return ""; }
    },
    closeSideMenu() {
      this.renderStore.options.activeSideMenu = null;
    },
  }
}
</script>
