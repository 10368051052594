<template>
  <div id="pivot">
    <table ref="dataHolder" class="pivot">
      <tr class="ph header">
        <!--<template v-if="showPivotLevels">
          <template v-for="(value, index) in tlayerCount" :key="index">
            <td :i="tlayerCount - index - 1"></td>
          </template>
        </template>-->
        <template v-for="(value, index) in getColumnWidths()" :key="index">
          <template v-if="!thead[index].hidden">
            <td :style="getColumnWidth(index)"></td>
          </template>
        </template>
      </tr>
      <tr class="ph">
        <template v-for="(header, index) in thead" :key="index">
          <template v-if="!header.hidden">
            <th v-if="index > 0 && index < this.renderStore.options.columnsPivot.length" :title="header.name_nl">
              <div>
                <span>{{ header.name_nl }}</span>
                <!--<span @click="filterTableBy(index)">
                  <img src="img/ico/filter.png">
                </span>-->
                <span @click="sortTableBy(index)">
                  <img v-if="header.sort == 'desc'" src="img/ico/expand.png">
                  <img v-else src="img/ico/collapse.png">
                </span>
              </div>
            </th>
            <th v-else-if="index > 0" :title="header.name_nl">{{ header.name_nl }}</th>
            <template v-else>
              <th v-if="!showPivotLevels" :title="header.name_nl">
                <div>
                  <span>{{ header.name_nl }}</span>
                  <!--<span @click="filterTableBy(index)">
                    <img src="img/ico/filter.png">
                  </span>-->
                  <span @click="sortTableBy(index)">
                    <img v-if="header.sort == 'desc'" src="img/ico/expand.png">
                    <img v-else src="img/ico/collapse.png">
                  </span>
                </div>
              </th>
              <th v-else :colspan="this.renderStore.options.columnsPivot.length + 1">
                <div>
                  <span :style="'padding-left:20px'">{{ header.name_nl }}</span>
                  <!--<span @click="filterTableBy(index)">
                    <img src="img/ico/filter.png">
                  </span>-->
                  <span @click="sortTableBy(index)">
                    <img v-if="header.sort == 'desc'" src="img/ico/expand.png">
                    <img v-else src="img/ico/collapse.png">
                  </span>
                </div>
              </th>
            </template>
          </template>
        </template>
      </tr>
      <tr class="ph">
        <!--<template v-if="showPivotLevels">
          <template v-for="(value, index) in tlayerCount" :key="index">
            <td :i="tlayerCount - index - 1"></td>
          </template>
        </template>-->
        <template v-for="(value, index) in getColumnWidths()" :key="index">
          <template v-if="!thead[index].hidden">
            <td :style="getColumnWidth(index)"></td>
          </template>
        </template>
      </tr>

      <template v-if="pivotData != null && Object.entries(pivotData).length > 0">
        <AnnotationRow 
          v-if="isReady"
          :pivotData="pivotData"
          :pivotValues="new Array()"
          :thead="thead" 
          :tlayer=0 
          :tlayerCount="tlayerCount"
          :activePage=parseInt(renderStore.options.pageNumber)
          :activeIndex=parseInt(renderStore.options.selectedAnnotationId)
          :activeLevel=parseInt(renderStore.options.expandToLevel)
          :columnWidths="getColumnWidths()"
          :isReady=isReady
          :showPivotLevels=showPivotLevels
          :filterCount=filteredData.length
          @update-unit="updateUnit"
        />
      </template>

      <template v-if="!showPivotLevels && renderStore.annotations.data.filter.indexOf('@!@') >= 0">
        <template v-for="(rowData, index) in filteredData" :key="index">
          <tr @click="goToAnnotation(rowData)" class="sin" :pid="rowData[rowData.length - 2]" :mid="rowData[rowData.length - 1]" :class="{active:(renderStore.options.pageNumber == rowData[rowData.length-2] && renderStore.options.selectedAnnotationId == rowData[rowData.length-1])}">
            <td>
              <img v-if="rowData[rowData.length - 3] == 'L'" src="img/ico/length.png">
              <img v-else-if="rowData[rowData.length - 3] == 'A'" src="img/ico/area.png">
              <img v-else-if="rowData[rowData.length - 3] == 'V'" src="img/ico/volume.png">
              <img v-else-if="rowData[rowData.length - 3] == 'C'" src="img/ico/count.png">
              <img v-else-if="rowData[rowData.length - 3] == 'D'" src="img/ico/angle.png">
              <img v-else src="img/ico/undef.png">
              {{ findColumnValue(rowData, 0) }}
            </td>
            <template v-for="(n,i) in tlayerCount - 1" :key="i">
              <td><span>{{ findColumnValue(rowData, n) }}</span></td>
            </template>
            <template v-for="(n,i) in rowData.length - tlayerCount - 3" :key="i">
              <template v-if="!thead[tlayerCount + i].hidden">
                <td><span>{{ findColumnValue(rowData, tlayerCount + i) }}</span></td>
              </template>
            </template>
          </tr>
        </template>
      </template>
    </table>
    <template v-if="pivotData == null || Object.entries(pivotData).length == 0">
      <table class="pivot">
        <tr class="ph red">
          <td :colspan="thead.length">
            Er kunnen geen gegevens worden getoond. Mogelijk voldoen geen van de markeringen aan 
            uw filtercriteria of bevat dit document geen gegevens.
          </td>
        </tr>
      </table>
    </template>
  </div>
</template>

<script>
import AnnotationRow from '@/components/app/viewer/AnnotationRow.vue'
import { selectAnnotation, renderAnnotations } from '@/components/app/viewer/PdfCanvas.js'
import { nextTick } from 'vue'

var selectedByMouse = false;

export default {
  data() {
    return {
      collapse: false,
      unit: ""
    }
  },
  props: {
    pivotData: {
      type: Object,
      required: true
    },
    filteredData: {
      type: Array,
      required: true
    },
    index: {
      type: Number,
      required: false
    },
    thead: {
      type: Array,
      required: true
    },
    tlayer: {
      type: Number,
      required: true
    },
    tlayerCount: {
      type: Number,
      required: true
    },
    isReady: {
      type: Boolean,
      required: true
    },
    showPivotLevels: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    store() {
      return this.$parent.store;
    },
    renderStore() {
      return this.$parent.renderStore;
    },
    activeAnnotation() {
      return this.$parent.renderStore.options.activeAnnotation;
    }
  },
  mounted() {
    this.renderStore.components.annotationTable = this;
  },
  watch: {
    activeAnnotation: {
      handler() {
        var self = this;
        nextTick(function() {
          if(!selectedByMouse && self.renderStore.options.selectedAnnotationId != -1) {
            const row = document.querySelectorAll(".sin.active");
            if(row != null && row[0] != null) {
              const prevRow = $(row[0]).prev().prev()[0];
              prevRow.scrollIntoView();
              return true;
            }
          }
          selectedByMouse = false;
        });
      },
      deep: true
    }
  },
  methods: {
    isObject() {
      if(typeof Object.values(this.pivotData)[0] == "object") return true;
      else return false;
    },
    getColumnWidth(colId) {
      if(this.renderStore.options.columns[colId] && this.renderStore.options.columns[colId].width) {
        return "width:"+this.renderStore.options.columns[colId].width.toFixed(2)+"%";
      }
      else return "width:0%";
    },
    sortTableBy(colId) {
      this.renderStore.sortAnnotationsBy(colId);
    },
    getColumnWidths() {
      return this.renderStore.options.columns;
    },
    expandToLevel(level) {
      var allRows = this.$refs.dataHolder.getElementsByTagName("tr");
      for(const row of allRows) {
        if(row.classList.contains("ph")) continue;
        var thisLevel = row.getAttribute("n");
        if(thisLevel == null) thisLevel = -1;

        if(thisLevel == level - 1) {
          row.classList.add("collapsed");
          row.classList.remove("inv");
        }
        else if(thisLevel < level - 1) {
          //row.classList.add("collapsed");
          row.classList.add("inv");
        }
        else {
          row.classList.remove("collapsed");
          row.classList.remove("inv");
        }
      }
    },
    findColumnValue(rowData, colId) {
      var col = this.thead[colId];
      if(col != null && (col.type == 'Number' || col.type == 'Formula') && this.isNumeric(rowData[colId])) {
        if(parseFloat(rowData[colId]) == 0) {
          if(col.unit == "") return (0).toFixed(col.precision);
          else return "";
        }
        else if(col.unit != null) {
          return parseFloat(rowData[colId]).toFixed(col.precision) + " " + this.convertToText(col.unit);
        }
        else if(col.name == "totaal") {
          return parseFloat(rowData[colId]).toFixed(col.precision) + " " + this.convertToText(this.unit);
        }
        else return parseFloat(rowData[colId]).toFixed(col.precision);
      }
      else return this.convertToText(rowData[colId]);
    },
    goToAnnotation(data) {
      selectedByMouse = true;
      var pageNumber = data[data.length - 2];
      var annotationId = data[data.length - 1];
      nextTick(function() {
        selectAnnotation(pageNumber, annotationId, true);
        renderAnnotations(true);
      });
    },
    convertToText(value) {
      try { return value.replace(/&#(\d+);/g, function (m, n) { 
        return String.fromCharCode(n); }); 
      }
      catch(e) { return ""; }
    },
    scrollToActiveRow() {
      document.querySelectorAll(".sin").forEach(row => {
        row.classList.remove("active");
      });
      
      const row = document.querySelectorAll(".sin[pid=\""+this.renderStore.options.pageNumber+"\"][mid=\""+this.renderStore.options.selectedAnnotationId+"\"]");
      if(row != null && row[0] != null) {
        row[0].classList.add("active");
        row[0].scrollIntoView();
        return true;
      }
      else return false;
    },
    isNumeric(n) {
      return !isNaN(parseFloat(n)) && isFinite(n);
    },
    updateUnit(unit) {
      this.unit = unit;
    }
  },
  components: {
    AnnotationRow
  }
}
</script>