<template>
  <transition name="fade">
    <div class="popup small" v-if="store.current.popup.includes('download-pdf-pivot')">
      <div>
        <perfect-scrollbar style="height:100%">
          <form @submit.prevent="download">
            <div class="closer" @click="close()">Scherm sluiten</div>
            <h1>{{ title }}</h1>
            <div v-if="init">
              <p>
                U kunt hier een kopie van de uittrekstaat downloaden in de opmaak zoals 
                op dit moment actief in de lijst van projecttotalen en -subtotalen. Wilt u
                de gegevens anders opdelen, dan dient u de lijst eerst anders in te stellen. 
              </p>
              <a :href=blobUrl class="href-hidden" ref="file-download"></a>
              <input type="submit" :value="submitText" :class="{locked:isLocked}">
            </div>
            <div v-else class="loader">
              <div>Even geduld, content wordt geladen...</div>
            </div>
          </form>
        </perfect-scrollbar>
      </div>
    </div>
  </transition>
</template>

<script>
import documentService from '@/services/app/document.service'

export default {
  emits: ['showMessage', 'updateParent'],
  props: {
    document: Object,
    getData: Object,
    pivotData: Object,
    columns: Object
  },
  data() {
    return {
      transaction: {},
      title: "Download uittrekstaat",
      submitText: "Download starten",
      init: false,
      pending: false
    };
  },
  computed: {
    store() {
      return this.$parent.store;
    },
    renderStore() {
      return this.$parent.renderStore;
    },
    currentPopup() {
      return this.store.current.popup[this.store.current.popup.length-1];
    },
    isLocked() {
      if(this.pending || !this.init) return true;
      else return false;
    }
  },
  watch: {
    async currentPopup(value) {
      if(value == "download-pdf-pivot") {
        this.init = true;
        this.pending = false;
        this.blobUrl = "";
      }
    }
  },
  methods: {
    async download() {
      this.pending = true;
      const url = await documentService.downloadAnnotations(
        this.document,
        this.pivotData,
        this.columns,
        this.getData.userEmail,
        this.getData.userToken,
        this.getData.accessKey
      );

      var a = this.$refs["file-download"];
      a.href = url;
      a.download = this.document.currentUuid + ".xlsx";
      a.click();
      window.URL.revokeObjectURL(url);

      this.pending = false;
    },
    close() {
      this.store.current.popup.splice(
        this.store.current.popup.indexOf('download-pdf-pivot'), 
        1
      );
    }
  }
}
</script>
