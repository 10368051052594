<template>
<template v-if="init">
    <div class="lead">
      <div class="in">
        <div v-if="$route.hash == '#aanvraag-proefperiode'" class="tab">
          <h3 id="aanvraag-proefperiode" style="scroll-margin:600px">Wilt u Foundation proberen?</h3>
          <p>
            U kunt Foundation kosteloos uitproberen om te kijken of 
            het pakket bij uw bedrijf past. Laat uw gegevens achter en 
            we nemen spoedig contact met u op voor het inplannen 
            van een introductie.
          </p>
        </div>
        <div v-else class="tab">
          <h3>Wilt u direct een vraag stellen?</h3>
          <p>
            Wilt u een proefperiode aanvragen of een vraag stellen? Dan kunt u dat 
            doen door dit contactformulier in te vullen. Wanneer u ervoor zorgt dat 
            uw vraag duidelijk omschreven is, proberen we altijd binnen enkele 
            werkdagen een antwoord te geven.
          </p>
        </div>

        <form @submit.prevent="send">
          <template v-if="$route.hash == '#aanvraag-proefperiode'">
            <h4>Aanvraagformulier proefperiode</h4>
          </template>
          <template v-else>
            <label>
              {{ defaultData.questionTypeId[0] }}
              <Multiselect 
                v-model="formData.questionTypeId" 
                :options="questionTypes" 
                placeholder="Maak een keuze..."
                noOptionsText="Geen opties gevonden.">
              </Multiselect>
            </label>
            <div v-if="formErrors.questionTypeId" class="error-field">{{ defaultData.questionTypeId[0] + " " + formErrors.questionTypeId }}!</div>     
          </template>

          <div v-if="labelData.personName" class="before-input" :class="{active:labelData.personName.active}">{{ labelData.personName.text }}</div>
          <input type="text"
            name="personName"
            v-model="formData.personName" 
            :class="{hasLabel:labelData.personName.active}"
            @focusin="empty($event)" 
            @focusout="reset($event)">
          <div v-if="formErrors.personName" class="error-field">{{ defaultData.personName[0] + " " + formErrors.personName }}!</div>

          <div v-if="labelData.companyName" class="before-input" :class="{active:labelData.companyName.active}">{{ labelData.companyName.text }}</div>
          <input type="text"
            name="companyName"
            v-model="formData.companyName" 
            :class="{hasLabel:labelData.companyName.active}"
            @focusin="empty($event)" 
            @focusout="reset($event)">
          <div v-if="formErrors.companyName" class="error-field">{{ defaultData.companyName[0] + " " + formErrors.companyName }}!</div>
        
          <div v-if="labelData.email" class="before-input" :class="{active:labelData.email.active}">{{ labelData.email.text }}</div>
          <input type="text" 
            name="email"
            v-model="formData.email" 
            :class="{hasLabel:labelData.email.active}"
            @focusin="empty($event)" 
            @focusout="reset($event)">
          <div v-if="formErrors.email" class="error-field">{{ defaultData.email[0] + " " + formErrors.email }}!</div>

          <div v-if="labelData.phoneNumber" class="before-input" :class="{active:labelData.phoneNumber.active}">{{ labelData.phoneNumber.text }}</div>
          <input type="text" 
            name="phoneNumber"
            v-model="formData.phoneNumber" 
            :class="{hasLabel:labelData.phoneNumber.active}"
            @focusin="empty($event)" 
            @focusout="reset($event)">
          <div v-if="formErrors.phoneNumber" class="error-field">{{ defaultData.phoneNumber[0] + " " + formErrors.phoneNumber }}!</div>
        
          <div v-if="labelData.content" class="before-input" :class="{active:labelData.content.active}">{{ labelData.content.text }}</div>
          <textarea
            name="content" 
            v-model="formData.content"
            :class="{hasLabel:labelData.content.active}" 
            @focusin="empty($event)" 
            @focusout="reset($event)">
          </textarea>
          <div v-if="formErrors.content" class="error-field">{{ defaultData.content[0] + " " + formErrors.content }}!</div>

          <label class="nm">
            <input type="checkbox" 
              v-model="formData.consent" 
              @click="toggle($event)">
            {{ defaultData.consent[0] }}
          </label>
        
          <input v-if="errorText.length == 0" type="submit" :value="submitText" :class="{locked:locked}">
          <div v-else class="error-field">{{ errorText }}</div>
        </form>
      </div>
    </div>
  </template>
</template>

<script>
import webService from '@/services/web.service'
import Multiselect from '@vueform/multiselect'

export default {
  data() {
    return {
      questionTypes: {
        1: "Aanvraag proefperiode",
        2: "Algemene vraag",
        3: "Support",
      },
      submitText: 'Vraag versturen',
      errorText: '',
      formData: {
        personName: '',
        companyName: '',
        phoneNumber: '',
        email: '',
        questionTypeId: 0,
        content: '',
        consent: false
      },
      defaultData: {
        personName: ['Naam', 'Wat is uw naam?'],
        companyName: ['Bedrijfsnaam', 'Van welk bedrijf bent u?'],
        phoneNumber: ['Telefoonnummer', 'Wat is uw telefoonnummer?'],
        questionTypeId: ['Categorie', 'Wat omschrijft uw vraag het beste?'],
        email: ['Email-adres', 'Wat is uw email-adres?'],
        content: ['Vraag', 'Wat wilt u vragen?'],
        consent: ['Ik geef toestemming voor het verwerken van mijn persoonsgegevens (zie onze algemene voorwaarden voor meer informatie)', '']
      },
      labelData: { },
      formErrors: { },
      init: false,
      locked: false
    };
  },
  computed: {
    
  },
  mounted() {
    this.init = false;

    if(this.$route.hash == "#aanvraag-proefperiode") this.submitText = "Aanvraag versturen";
    else this.submitText = "Vraag versturen";
    this.clear();
    this.init = true;
  },
  methods: {
    async send() {
      this.locked = true;
      this.submitText = "Uw vraag wordt verstuurd, even geduld...";

      let response = await webService.sendContact(this.formData, this.defaultData);
      if(response) {
        if(response.status == 200) {
          this.sent = true;
          this.locked = false;
          this.submitText = "Vraag versturen";
          this.errorText = "";
        }
        else {
          this.errorText = response;
        }
      }
      else {
        this.sent = false;
        this.locked = false;
        this.submitText = "Vraag versturen";
        this.errorText = "Uw vraag kan op dit moment niet worden verstuurd, probeer het later opnieuw.";
      }
    },
    empty(e) {
      const val = this.formData[e.target.name];
      if(this.defaultData[e.target.name] && (val == "" || val == this.defaultData[e.target.name][1])) {
        this.labelData[e.target.name] = {text: this.defaultData[e.target.name][0], active: true};
        this.formData[e.target.name] = "";
      }
    },
    reset(e) {
      const val = this.formData[e.target.name];
      if(val == "") {
        this.labelData[e.target.name] = {text: '', active: false};
        this.formData[e.target.name] = this.defaultData[e.target.name][1];
      }
    },
    clear() {
      for(const[key, val] of Object.entries(this.defaultData)) {
        this.labelData[key] = {text: '', active: false};
        this.formData[key] = val[1];
      }
    },
    toggle(e) {
      this.formData[e.target.name] = !this.formData[e.target.name];
    },
  },
  components: { 
    Multiselect
  }
};
</script>

<style scoped>
h3 {
  font-size: 24px;
  font-style: normal;
}

h4 {
  font-size: 18px
}

p {
  font-size: 14px;
  margin-bottom: 25px;
}

.lead {
  background: rgba(255,255,255,0.7);
  position: relative;
  text-align: center;
}

.in {
  width: 1050px;
  margin: 0 auto;
  padding: 75px 0;
  text-align: left;
  display: table;
}

.tab {
  display: table-cell;
  width: 40%;
  padding: 0 25px;
}

form {
  display: table-cell;
  width: 70%;
  padding: 0 25px;
}

input[type="submit"] {
  background: rgba(81, 111, 157, 0.8) !important;
  transition-duration: 0.3s;
}

input[type="submit"]:hover {
  background: rgba(81, 111, 157, 1) !important;
}

input[type="checkbox"] {
  display: block;
  float: left;
  width: 20px;
  height: 20px;
  margin: 0;
  margin-right: 20px;
  margin-bottom: 20px;
}

.error-field {
  background: rgba(180, 0, 0, 1) !important;
  color: #fff;
  line-height: 38px;
  text-align: center;
  font-style: normal;
  margin: 0;
  padding: 0 20px;
  border-radius: 20px;
}

label.nm {
  background: none;
  float: none;
  width: auto;
  height: auto;
  padding: 0 !important;
  color: #494949;
  text-shadow: none;
  font-size: 12px;
  line-height: 20px;
}

label.nm::before {
  display: none;
}

label.nm:hover {
  background:none;
}
</style>