import api from './api';
import tokenService from "./token.service";

class ListingService {
  async categories() {
    try {
      await tokenService.awaitSessionRefresh();
      const response = await api.get(
        "/listing/categories"
      );
      return response.data;
    }
    catch (error) {
      return [];
    }
  }

  async categoryTypes() {
    try {
      await tokenService.awaitSessionRefresh();
      const response = await api.get(
        "/listing/category-types"
      );
      return response.data;
    }
    catch (error) {
      return [];
    }
  }

  async embedModels() {
    try {
      await tokenService.awaitSessionRefresh();
      const response = await api.get(
        "/listing/embed-models"
      );
      return response.data;
    }
    catch (error) {
      return [];
    }
  }

  async completionsModels() {
    try {
      await tokenService.awaitSessionRefresh();
      const response = await api.get(
        "/listing/completions-models"
      );
      return response.data;
    }
    catch (error) {
      return [];
    }
  }

  async genders() {
    try {
      await tokenService.awaitSessionRefresh();
      const response = await api.get(
        "/listing/genders"
      );
      return response.data;
    }
    catch (error) {
      return [];
    }
  }

  async endpoints() {
    try {
      await tokenService.awaitSessionRefresh();
      const response = await api.get(
        "/listing/endpoints"
      );
      return response.data;
    }
    catch (error) {
      return [];
    }
  }

  async projectTypes() {
    try {
      await tokenService.awaitSessionRefresh();
      const response = await api.get(
        "/listing/project-types"
      );
      return response.data;
    }
    catch (error) {
      return [];
    }
  }

  async integrations() {
    try {
      await tokenService.awaitSessionRefresh();
      const response = await api.get(
        "/listing/integrations"
      );
      return response.data;
    }
    catch (error) {
      return [];
    }
  }

  async integrationTypes() {
    try {
      await tokenService.awaitSessionRefresh();
      const response = await api.get(
        "/listing/integration-types"
      );
      return response.data;
    }
    catch (error) {
      return [];
    }
  }

  async contributionTypes() {
    try {
      await tokenService.awaitSessionRefresh();
      const response = await api.get(
        "/listing/contribution-types"
      );
      return response.data;
    }
    catch (error) {
      return [];
    }
  }

  async filterTypes() {
    try {
      await tokenService.awaitSessionRefresh();
      const response = await api.get(
        "/listing/filter-types"
      );
      return response.data;
    }
    catch (error) {
      return [];
    }
  }

  async pivotConfigs() {
    try {
      await tokenService.awaitSessionRefresh();
      const response = await api.get(
        "/listing/pivot-configs"
      );
      return response.data;
    }
    catch (error) {
      return [];
    }
  }

  async countries() {
    try {
      await tokenService.awaitSessionRefresh();
      const response = await api.get(
        "/listing/countries"
      );
      return response.data;
    }
    catch (error) {
      return [];
    }
  }

  async addressTypes() {
    try {
      await tokenService.awaitSessionRefresh();
      const response = await api.get(
        "/listing/address-types"
      );
      return response.data;
    }
    catch (error) {
      return [];
    }
  }

  async contributionTemplates() {
    try {
      await tokenService.awaitSessionRefresh();
      const response = await api.get(
        "/listing/contribution-templates"
      );
      return response.data;
    }
    catch (error) {
      return [];
    }
  }
}
export default new ListingService();