<template>
  <div class="lead">
    <div class="in">
      <div class="lt lt25">
        <h2>
          Wilt u in contact komen met onze klantenservice?
        </h2>
        <p>
          Bij Foundation komt service altijd op de eerste
          plaats! Wij zijn tijdens kantoortijden op verschillende
          manieren te bereiken.
        </p>
      </div>
      <div class="lt lt25" style="float:right">
        <h3>Klantsupport</h3>
        <p>
          Heeft u support nodig? Bel ons & klik alvast op onderstaande 
          knop om onze TeamViewer module te downloaden.
        </p>
        <a href="https://teamviewer.com" target="_blank">Download TeamViewer QuickSupport module</a>
      </div>
      <div class="lt lt25" style="float:right">
        <h3>Of... stuur een email!</h3>
        <p>
          Is uw vraag minder urgent, dan kunt u ons ook een 
          mailtje sturen. Mails worden altijd binnen twee
          werkdagen beantwoord.
        </p>
        <span>
          <strong>Minder urgente vraag?</strong><br/>
          info@digitable.nl
        </span>
      </div>
      <div class="lt lt25" style="float:right">
        <h3>Via de telefoon</h3>
        <p>
          Een beller is vaak sneller! Zijn we er even niet, 
          dan wordt u zo snel mogelijk teruggebeld. Altijd 
          binnen enkele uren.
        </p>
        <span>
          <strong>Direct contact</strong><br/>
          +31 (0)40 256 7850
        </span>
      </div>
      <div class="clear"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {

    };
  },
  computed: {
    
  },
  components: { 
    
  }
};
</script>

<style scoped>
h2 {
  font-size: 24px;
  font-style: normal;
  font-weight: bold;
  color: #fff;
}

h3 {
  font-size: 20px;
  color: #fff;
  margin: 0; padding: 0;
  padding-bottom: 10px;
}

p {
  font-size: 14px;
}

span, a {
  font-size: 14px;
  line-height: 1.5em;
  border-left: 5px solid #fff;
  background-color: rgba(255,255,255,0.1);
  padding: 5px 15px;
  display: block;
  border-radius: 10px;
  margin-top: 20px;
}

a {
  transition-duration: 0.3s;
  text-decoration: none;
  color: #fff;
  font-weight: bold;
  border: none;
}

a:hover {
  background-color: rgba(255,255,255,0.3);
}

.lead {
  color: #fff;

  .in  {
    width: 1050px;
    margin: 0 auto;
    padding: 75px 0;
  }
}

.lt25 {
  width: 25%;
  float: left;
  box-sizing: border-box;
  padding: 0 25px;
}
</style>