<template>
  <div class="form" v-if="store.current.form === 'about'">
    <h1>Over deze applicatie</h1>
    <div class="data-holder">
      <h2>Wat kan ik met Foundation?</h2>
      <p>
        Met Foundation wordt het delen van markeringen op PDF-bestanden een stuk simpeler. Het voornaamste doel van 
        ons softwarepakket is het uitlezen van metingen die op schaal aan een tekening zijn toegevoegd. Foundation leest 
        deze metingen uit en presenteert ze als uittrekstaat binnen de online viewer, zoals u gewend bent in 
        bijvoorbeeld Bluebeam.
      </p>

      <h2>Test-omgeving</h2>
      <p>
        Deze versie is een testversie voor het pakket dat wij later dit jaar commercieel beschikbaar zullen maken. 
        Heeft u ideeën voor nieuwe functionaliteit of opmerkingen/vragen over de huidige functies, dan kunt 
        u contact opnemen met Digitable op het telefoonnummer +31 (0)402 5678 50. 
      </p>

      <h2>Verwerking van persoonsgegevens</h2>
      <p>
        Graag maken wij u erop attent dat wij de persoonsgegevens die u ons verstrekt zullen verzamelen en gebruiken 
        omdat dit noodzakelijk is om een eventuele overeenkomst met u te sluiten en uit te voeren. Dat geldt zowel 
        voor onze (potentiële) klanten als voor partijen bij wie wij zaken en/of diensten inkopen.
      </p>

      <p>
        U bent niet verplicht om ons uw persoonsgegevens te verstrekken. Als u ons geen of onvoldoende 
        persoonsgegevens verstrekt, dan is het echter wel mogelijk dat de software niet (in zijn geheel)
        zal kunnen functioneren.
      </p>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      
    };
  },
  computed: {
    store() {
      return this.$parent.store;
    },
    currentForm() {
      return this.store.current.form;
    }
  },
  watch: {
    currentForm(value) {
      if(value == "about") {
        document.title = "About | " + this.store.appName;
      }
    }
  },
  methods: {
    
  },
  components: {
    
  }
}
</script>
