<template>
  <div class="top-icon red" style="right:190px" title="Er is een filter actief">
    <img src="img/ico/filter.png">
    <div>
      <p>
        Er is een filter geactiveerd binnen de lijst met projecttotalen, waardoor
        niet alle markeringen op de tekening worden getoond. Ook kunnen sommige 
        functies hierdoor niet beschikbaar zijn.
      </p>
    </div>
  </div>
</template>