<template>
  <transition name="fade">
    <div class="popup" v-if="store.current.popup.includes('login-user')">
      <div v-if="init">
        <perfect-scrollbar style="height:100%">
          <form @submit.prevent="login">
            <div class="closer" @click="close()">Scherm sluiten</div>
            <h1>{{ title }}</h1>
            <div v-if="init">
              <div v-if="!user.isValid">
                <div v-if="labelData.username" class="before-input" :class="{active:labelData.username.active}">{{ labelData.username.text }}</div>
                <input type="text" 
                  name="username"
                  v-model="user.username" 
                  :class="{hasLabel:labelData.username.active}"
                  @focusin="empty($event)" 
                  @focusout="reset($event)">
                <div v-if="formErrors.username" class="error-field">{{ defaultData.username[0] + " " + formErrors.username }}!</div>

                <div v-if="labelData.password" class="before-input" :class="{active:labelData.password.active}">{{ labelData.password.text }}</div>
                <input type="text" 
                  name="password"
                  v-model="user.password" 
                  :class="{hasLabel:labelData.password.active}"
                  @focusin="empty($event)" 
                  @focusout="reset($event)"
                  @input="togglePassword">
                <div v-if="formErrors.password" class="error-field">{{ defaultData.password[0] + " " + formErrors.password }}!</div>
              </div>
              <div v-else>
                <div v-if="labelData.multiToken" class="before-input" :class="{active:labelData.multiToken.active}">{{ labelData.multiToken.text }}</div>
                <input type="text" 
                  name="multiToken"
                  inputmode="numeric" pattern="[0-9]*"
                  v-model="user.multiToken" 
                  :class="{hasLabel:labelData.multiToken.active}"
                  @focusin="empty($event)" 
                  @focusout="reset($event)">
                <div v-if="invalidTry" class="error-field">Sorry, maar dit is niet de juiste code.</div>
              </div>
              <input type="submit" :value="submitText" style="width:49%;margin-right:2%" :class="{locked:isLocked}">
              <a class="submit red" @click="store.current.popup = ['reset-user']" style="width:49%">Wachtwoord vergeten?</a>
              <div class="clear"></div>
            </div>

            <!--<div v-if="!user.isValid">
              <h2 style="margin-top:5vh">Registreren</h2>
              <p>
                Wilt u graag gebruik maken van onze AI-omgeving? Registreer u dan via ons registratieformulier. U kunt zich registreren
                door <a href="#" @click="store.current.popup = 'register-user'">op deze link</a> te klikken.
              </p>
            </div>-->
          </form>
        </perfect-scrollbar>
      </div>
    </div>
  </transition>
</template>

<script>
import authService from '@/services/auth.service'
import errorHandler from '@/common/ErrorHandler'

export default {
  emits: ['showMessage'],
  data() {
    return {
      user: {
        username: 'dlansdaal',
        password: 'GeLiWeDe22!!',
        multiToken: '',
        isValid: false
      },
      defaultData: {
        username: ['Gebruikersnaam of e-mailadres', 'Met welke gebruikersnaam of e-mailadres wilt u inloggen?'],
        password: ['Wachtwoord', 'Wat is het wachtwoord van uw account?'],
        multiToken: ['Toegangscode', 'Welke toegangscode heeft u via e-mail ontvangen?'],
      },
      labelData: {},
      formErrors: {},
      invalidTry: false,
      init: false,
      pending: false,
      title: "",
      submitText: ""
    };
  },
  computed: {
    store() {
      return this.$parent.store;
    },
    currentPopup() {
      return this.store.current.popup[this.store.current.popup.length-1];
    },
    isLocked() {
      if(this.pending || !this.init) return true;
      else return false;
    }
  },
  watch: {
    async currentPopup(value) {
      if(value == "login-user") {
        this.init = false;
        this.clear();
      
        this.title = "Inloggen";
        this.submitText = "Log mij in";

        this.user.isValid = false;
        this.init = true;
        this.pending = false;
        this.invalidTry = false;
      }
    }
  },
  methods: {
    async login() {
      if(this.pending) return;
      if(!this.user.isValid) this.loginUser();
      else this.login2FA();
    },
    async loginUser() {
      this.pending = true;
      const response = await authService.login(this.user, this.defaultData);
      if(response) {
        if(response.status == 200) {
          this.user.isValid = true;
        }
        else {
          this.$emit('showMessage', response);
          //this.formErrors = errorHandler.process(response.data, "");
        }
      }
      else {
        this.$emit('showMessage', null);
      }
      this.pending = false;
    },
    async login2FA() {
      this.pending = true;
      const response = await authService.login2FA(this.user, this.defaultData);
      if(response) {
        if(response.status == 200) {
          this.store.current.popup = [];
          this.store.current.form = "list-pdf-projects";
          this.$router.go();
          this.pending = false;
        }
        else {
          this.invalidTry = true;
          this.$emit('showMessage', response);
          this.formErrors = errorHandler.process(response.data, "");

          let timeout = 10;
          this.submitText = "U kunt het opnieuw proberen over "+timeout+" seconden";
          let cd = setInterval(() => {
            if(timeout > 0) {
              timeout--;
              this.submitText = "U kunt het opnieuw proberen over "+timeout+" seconden";
            }
            else { 
              clearInterval(cd);
              this.pending = false;
              this.submitText = "Log mij in";
            }
          }, 1000);
        }
      }
    },
    close() {
      this.store.current.popup.splice(
        this.store.current.popup.indexOf('login-user'), 
        1
      );
    },
    empty(e) {
      const val = this.user[e.target.name];
      if(this.defaultData[e.target.name] && (val == "" || val == this.defaultData[e.target.name][1])) {
        this.labelData[e.target.name] = {text: this.defaultData[e.target.name][0], active: true};
        this.user[e.target.name] = "";
      }
    },
    reset(e) {
      const val = this.user[e.target.name];
      if(val == "") {
        this.labelData[e.target.name] = {text: '', active: false};
        this.user[e.target.name] = this.defaultData[e.target.name][1];
      }
    },
    clear() {
      for(const[key, val] of Object.entries(this.defaultData)) {
        this.labelData[key] = {text: '', active: false};
        this.user[key] = val[1];
        this.formErrors = {};
      }
    },
    togglePassword(e) {
      if(e.target.value.length == 0 || e.target.value == this.defaultData[e.target.name]) {
        e.target.type = "text";
      }
      else {
        e.target.type = "password";
      }
    }
  }
}
</script>
