<template>
  <div class="content info" v-if="this.renderStore.options.activeSideMenu === 'annotation'">
    <template v-if="contribution != null">
      <template v-if="contribution.canModify && this.renderStore.options.selectedContributionId != -1">
        <div class="adder small gray" @click="close()" title="Hef selectie op">
          <img src="img/ico/close-1.png">
        </div>
        <div class="adder small gray" @click="deleteContribution(contribution)" title="Verwijderen">
          <img src="img/ico/action-remove.png">
        </div>
        <div class="adder small gray" @click="editContribution(contribution)" title="Aanpassen">
          <img src="img/ico/markup.png">
        </div>
        <div class="adder small gray" @click="copyContribution(contribution)" title="Kopiëren">
          <img src="img/ico/download.png">
        </div>
      </template>

      <h3>Details van markering</h3>
      <h4>Algemene gegevens</h4>
      <label :title="contribution.title">
        Onderwerp
        <span>{{ contribution.title }}</span>
      </label>
      <label title="Foundation">
        Gegevensbron
        <span>Foundation</span>
      </label>
      <label :title="contribution.templateName + ' v' + contribution.templateVersion">
        Formulier
        <span>{{ contribution.templateName }} (v{{ contribution.templateVersion }})</span>
      </label>
      <label :title="contribution.dateCreated">
        Aanmaakdatum
        <span>{{ contribution.dateCreated }}</span>
      </label>
      <label v-if="contribution.dateModifiedUnix != 0" :title="contribution.dateModified">
        Aangepast op
        <span>{{ contribution.dateModified }}</span>
      </label>
      <label :title="contribution.askedBy">
        Auteur
        <span>{{ contribution.askedBy }}</span>
      </label>

      <label>
        Openbaar
        <span v-if="contribution.isPublic">Ja</span>
        <span v-else>Nee</span>
      </label>

      <template v-if="contribution.values != null">
        <template v-if="contribution.values.content != null">
          <h4>Inhoud</h4>
          <span>{{ contribution.values.content }}</span>
        </template>
        <template v-if="contribution.values.ct != null">
          <h4>Inhoud</h4>
          <span>{{ contribution.values.ct }}</span>
        </template>

        <template v-if="contribution.values.mn > 0 || contribution.values.mle > 0 || contribution.values.mar > 0">
          <h4>Hoeveelheden op schaal</h4>
          <label v-if="contribution.values.mvo > 0">
            Volume
            <span>{{ contribution.values.mvo }} m&#179;</span>
          </label>
          <label v-if="contribution.values.mar > 0">
            Oppervlakte
            <span>{{ contribution.values.mar }} m&#178;</span>
          </label>
          <label v-if="contribution.values.mwa > 0">
            Wandoppervlakte
            <span>{{ contribution.values.mwa }} m&#178;</span>
          </label>
          <label v-if="contribution.values.mle > 0">
            Lengte
            <span>{{ contribution.values.mle }} m</span>
          </label>
          <label v-if="contribution.values.mn > 0">
            Aantal
            <span>{{ contribution.values.mn }} st</span>
          </label>
        </template>

        <template v-if="(String(contribution.values.mc) != 'undefined' && contribution.values.mc != '') || contribution.values.mh > 0 || contribution.values.ma > 0 || contribution.values.mm1 != 1 || contribution.values.mm2 != 1">
          <h4>Rekenvelden</h4>
          <label v-if="String(contribution.values.mc) != 'undefined' && contribution.values.mc != ''">
            Code
            <span>{{ contribution.values.mc }}</span>
          </label>
          <label v-if="contribution.values.mh > 0">
            Hoogte
            <span>{{ contribution.values.mh }} m</span>
          </label>
          <label v-if="contribution.values.ma > 0">
            Hoek
            <span>{{ contribution.values.ma }} º</span>
          </label>
          <label v-if="contribution.values.mm1 && contribution.values.mm1 != 1">
            Multiplier 1
            <span>{{ contribution.values.mm1 }}</span>
          </label>
          <label v-if="contribution.values.mm2 && contribution.values.mm2 != 1">
            Multiplier 2
            <span>{{ contribution.values.mm2 }}</span>
          </label>
        </template>
      </template>

      <template v-if="contribution.annotationId != null">
        <h4>Gekoppelde markering</h4>
        <span>
          Deze bijdrage is gekoppeld aan één van de markeringen die in het document zijn opgenomen. 
          <a href="#" @click="selectLinked">Klik hier</a> voor meer details.
        </span>
      </template>
      
      <template v-if="contribution.athumbnails">
        <h4>Gekoppelde foto's</h4>
        <template v-for="(attachment, index) in contribution.athumbnails">
          <img class="thumb" :src="'data:image/jpg;base64,'+attachment" title="Klik voor volledige versie" @click="viewAttachment(index+1)">
        </template>
      </template>

      <template v-if="this.renderStore.options.selectedContributionId != -1">
        <div class="submit small" @click="addToolboxItem(contribution)" style="margin:25px 0">
          <img src="img/ico/download.png">
          Toevoegen aan tools
        </div>
        <div style="clear:both"></div>
      </template>

      <template v-if="contribution.answers != null && contribution.answers.length > 0">
        <h3 style="margin-top: 20px;margin-bottom:10px;clear:both">Opmerkingen</h3>
        <div class="quest-answer" v-for="thisAnswer in contribution.answers" :key="thisAnswer.answerId">
          <div class="subline2"><i>Antwoord van <b>{{ thisAnswer.answeredBy }}</b> op {{ thisAnswer.dateCreated }}:</i></div>
          <div v-if="this.answer.answerId == thisAnswer.answerId">
            <form @submit.prevent="addAnswer">
              <textarea 
                name="content" 
                v-model="answer.content"
                :class="{hasLabel:labelData.content.active}" 
                @focusin="empty($event)" 
                @focusout="reset($event)"
                :disabled="this.answer.answerId == 0">
              </textarea>
              <div v-if="formErrors.content" class="error-field">{{ defaultData.content[0] + " " + formErrors.content }}!</div>
              <input type="submit" :value="updateText" :class="{locked:isLocked}">
              <a href="#" @click="this.clear()" class="submit red">Actie annuleren</a>
              <div style="clear:both"></div>
            </form>
          </div>
          <div v-else>
            {{ thisAnswer.content }}
            <div class="opt">
              <a v-if="thisAnswer.canModify" href="#" @click="editAnswer(contribution, thisAnswer)">Aanpassen</a>
              <a v-if="thisAnswer.canModify" href="#" @click="deleteAnswer(thisAnswer)">Verwijderen</a>
              <div style="clear:both"></div>
            </div>
          </div>
        </div>
      </template>
      <template v-else-if="contribution.canReply && this.renderStore.options.selectedContributionId != -1">
        <div class="not-avail">
          Er zijn nog geen opmerkingen bij deze bijdrage. In het veld hieronder heeft u de mogelijkheid
          om een opmerking te plaatsen.
        </div>
      </template>

      <template v-if="contribution.canReply && this.answer.answerId == 0 && this.renderStore.options.selectedContributionId != -1">
        <form @submit.prevent="addAnswer">
          <div v-if="labelData.content" class="before-input" :class="{active:labelData.content.active}">{{ labelData.content.text }}</div>
          <textarea 
            name="content" 
            v-model="answer.content"
            :class="{hasLabel:labelData.content.active}" 
            @focusin="empty($event)" 
            @focusout="reset($event)"
            :disabled="this.answer.answerId != 0">
          </textarea>
          <div v-if="formErrors.content" class="error-field">{{ defaultData.content[0] + " " + formErrors.content }}!</div>
          <input type="submit" :value="submitText" class="submit" :class="{locked:isLocked}">
        </form>
      </template>
    </template>
    <template v-else-if="annotation != null">
      <template v-if="this.renderStore.options.selectedAnnotationId != -1">
        <div class="adder small gray" @click="close()" title="Hef selectie op">
          <img src="img/ico/close-1.png">
        </div>
        <div class="adder small gray" @click="search()" title="Zoek vergelijkbare markeringen">
          <img src="img/ico/search-1.png">
        </div>
      </template>

      <h3>Details van markering</h3>
      <h4>Algemene gegevens</h4>
      <label :title="parsedText(annotation.columnData.subject)">
        Onderwerp
        <span>{{ parsedText(annotation.columnData.subject) }}</span>
      </label>
      <label title="Extern">
        Gegevensbron
        <span>Extern</span>
      </label>
      <label v-if="annotation.columnData.space != ''" :title="parsedText(annotation.columnData.space)">
        Ruimte
        <span>{{ annotation.columnData.space }}</span>
      </label>
      <label v-if="annotation.columnData.label != null" :title="parsedText(annotation.columnData.label)">
        Label
        <span>{{ parsedText(annotation.columnData.label) }}</span>
      </label>
      <label v-if="annotation.columnData.pageLabel != null" :title="parsedText(annotation.columnData.pageLabel)">
        Paginalabel
        <span>{{ parsedText(annotation.columnData.pageLabel) }}</span>
      </label>
      <label>
        Paginanummer
        <span>{{ annotation.columnData.pageNumber }}</span>
      </label>

      <template v-if="annotation.columnData.width > 0 || annotation.columnData.volume > 0 || annotation.columnData.area > 0 || annotation.columnData.length > 0 || annotation.columnData.count > 0 || annotation.columnData.angle > 0">
        <h4>Hoeveelheden op schaal</h4>
        <label v-if="annotation.columnData.volume > 0">
          Volume
          <span>{{ annotation.columnData.volume }} m&#179;</span>
        </label>
        <label v-if="annotation.columnData.area > 0">
          Oppervlakte
          <span>{{ annotation.columnData.area }} m&#178;</span>
        </label>
        <label v-if="annotation.columnData.wallArea > 0">
          Wandoppervlakte
          <span>{{ annotation.columnData.wallArea }} m&#178;</span>
        </label>
        <label v-if="annotation.columnData.length > 0">
          Lengte
          <span>{{ annotation.columnData.length }} m</span>
        </label>
        <label v-if="annotation.columnData.count > 0">
          Aantal
          <span>{{ annotation.columnData.count }} st</span>
        </label>
        <label v-if="annotation.columnData.width > 0">
          Afmetingen
          <span>{{ annotation.columnData.width }} × {{ annotation.columnData.height }} m</span>
        </label>
        <label v-if="annotation.columnData.risedrop > 0">
          Lengte
          <span>{{ annotation.columnData.risedrop }} m</span>
        </label>
        <label v-if="annotation.columnData.slope > 0">
          Helling
          <span>{{ annotation.columnData.slope }} º</span>
        </label>
        <label v-if="annotation.columnData.angle > 0">
          Hoek
          <span>{{ annotation.columnData.angle }}º</span>
        </label>
      </template>
      
      <h4>Extra kolommen</h4>
      <div class="spacer"></div>
      <template v-for="column in renderStore.options.columns">
        <label v-if="!excludeColumn(column)" :title="parsedText(appendUnit(annotation.columnData, column.name))">
          {{ column.name }}
          <span>{{ parsedText(appendUnit(annotation.columnData, column.name)) }}</span>
        </label>
      </template>

      <template v-if="containsHiddenColumns">
        <h4>Verborgen kolommen</h4>
        <div class="spacer"></div>
        <template v-for="column in renderStore.annotations.data.rawColumns">
          <label v-if="!excludeForHiddenColumn(column)" :title="parsedText(appendUnit(annotation.columnData, column.name))">
            <span class="le">{{ column.name }}</span>
            <span>{{ parsedText(appendUnit(annotation.columnData, column.name)) }}</span>
          </label>
        </template>
      </template>
    </template>
    <template v-else-if="contributionIds.length > 0">
      <template v-if="!invalidSelection">
        <h3>Selectie in bulk aanpassen</h3>
        <p>
          U kunt de geselecteerde bijdragen tegelijkertijd aanpassen door op de knop hieronder te klikken. Let op: het is niet mogelijk 
          bijdragen aan te passen die door andere gebruikers zijn aangemaakt.
        </p>
        <div class="submit small" @click="editContributions(contributionIds)" style="margin-top:10px">
          <img src="img/ico/markup.png" style="filter:invert()">
          Bijdragen aanpassen
        </div>
      </template>
      <template v-else>
        <h3>Selectie ongeldig</h3>
        <p>
          U kunt enkel bijdragen in bulk aanpassen die zijn aangemaakt vanuit hetzelfde type. Daarnaast dienen ze door uzelf 
          aangemaakt te zijn binnen het document.
        </p>
      </template>
    </template>
    <template v-else>
      <div class="cm" @click="closeSideMenu()" title="Scherm sluiten">
        <img src="img/ico/close-1.png">
      </div>
      <h3>Details van markering</h3>
      <div class="not-avail" style="margin-top:20px;line-height:1.3em">
        Selecteer een markering op de pagina om de bijhorende details in te zien. 
      </div>
    </template>
  </div>
</template>

<script>
import { resetSelection, rescaleCanvas, findAnnotationById, selectAnnotation } from '../PdfCanvas';
import contributionService from '@/services/app/contribution.service'
import answerService from '@/services/app/answer.service'
import { formatContribution } from '@/functions/FormatContribution.js'
import { nextTick } from 'vue'

export default {
  emits: ['showMessage'],
  data() {
    return {
      collapse: false,
      foundScale: false,
      pageHasScale: false,
      contribution: null,
      distance: 0,
      area: 0,
      excluded: ["subject", "pageNumber", "pageLabel", "count", "length", "area", "volume", "angle", "slope", "space", "wallArea", "risedrop", "width", "height", "depth", "sj", "fc", "fo", "lc", "lo", "lw", "as", "msc", "mh", "ma", "mm1", "mm2", "mc", "mle", "mar", "mvo", "mwa"],
      unitColumns: ["eh", "eenheid", "unit", "eh"],
      answer: {
        answerId: 0,
        content: "Wat wilt u antwoorden?"
      },
      submitText: "Opmerking opslaan",
      updateText: "Wijzigingen opslaan",
      defaultData: {
        answerId: ['', 0],
        contributionId: ['', 0],
        content: ['Uw antwoord', 'Wat wilt u antwoorden?']
      },
      labelData: {'content': {text: 'Test', active: false}},
      formErrors: {},
      init: false,
      invalidSelection: false,
      pending: false
    }
  },
  props: ["annotation", "contributionRaw", "contributionIds", "getData"],
  computed: {
    store() {
      return this.$parent.store;
    },
    renderStore() {
      return this.$parent.renderStore;
    },
    bottomOffset() {
      var offset = this.renderStore.components.annotationViewer.$refs.annotationViewer.clientHeight;
      if(this.renderStore.options.showAnnotations) {
        offset += 60;
      }
      else offset += 20;

      return offset + "px";
    },
    containsHiddenColumns() {
      for(var column of this.renderStore.annotations.data.rawColumns) {
        if(!this.excludeForHiddenColumn(column)) {
          return true;
        }
      }
      return false;
    },
    isLocked() {
      if(this.pending || !this.init) return true;
      else return false;
    }
  },
  watch: {
    contributionRaw: {
      async handler(contrib) {
        if(contrib != null && this.annotation == null) {
          this.contribution = {...contrib};
          this.set();
        }
        else {
          this.contribution = null;
          this.clear();
        }
      }
    },
    contributionIds: {
      handler() {
        var contributionTemplateId;
        this.invalidSelection = false;
        if(this.contributionIds.length == 0) return;
        Object.entries(this.renderStore.filteredContributions).forEach(contribution => {
          for(var contributionId of this.contributionIds) {
            if(contribution[1].contributionId != contributionId) continue;
            if(!contributionTemplateId) contributionTemplateId = contribution[1].contributionTemplateId;
            else if(contribution[1].contributionTemplateId != contributionTemplateId) {
              this.invalidSelection = true;
              break;
            }
            if(!contribution[1].canModify) {
              this.invalidSelection = true;
              break;
            }
          }
        });
      },
      deep: true
    },
    answer(listing) {
      for(const[key, value] of Object.entries(listing)) {
        if(!this.defaultData[key]) continue;
        if(value && value.toString().length > 0) {
          this.labelData[key] = {text: this.defaultData[key][0], active: true};
        }
        else {
          this.labelData[key] = {text: '', active: false};
          this.answer[key] = this.defaultData[key][1];
        }
      }
    }
  },
  mounted() {

  },
  methods: {
    async set() {
    
      if(this.contribution == null) return;

      this.init = false;
      //this.clear();
      this.answer.answerId = 0;

      this.contributionTemplate = {...this.renderStore.contributionTemplates[this.contribution.contributionTemplateId]};
      this.store.current.data.pdf.contribution = {...this.contribution};
      this.answer.contributionId = this.contribution.contributionId;

      
      if(this.renderStore.options.selectedContributionId != -1) {
        this.fetchAttachments();
      }
      this.init = true;
    },
    excludeColumn(column) {
      if(typeof column == "object") {
        if(this.excluded.includes(column.name)) return true;
        if(this.unitColumns.includes(column.name.toLowerCase())) return true;
        return false;
      }
      else {
        if(this.excluded.includes(column)) return true;
        if(this.unitColumns.includes(column.toLowerCase())) return true;
      }
    },
    excludeForHiddenColumn(column) {
      if(column.name == "label") return true;
      if(this.excluded.includes(column.name)) return true;
      if(this.unitColumns.includes(column.name.toLowerCase())) return true;
      for(var thisColumn of this.renderStore.options.columns) {
        if(column.name == thisColumn.name) return true;
      }
      return false;
    },
    appendUnit(data, index) {
      if(index == "totaal") {
        if(this.renderStore.annotations.data.unitAt >= 0) {
          return data[index] + " " + data[this.renderStore.options.columns[this.renderStore.annotations.data.unitAt].name];
        }
        else return data[index];
      }
      else return data[index];
    },
    parsedText(text) {
      try { return text.replace(/&#(\d+);/g, function (m, n) { return String.fromCharCode(n); }); }
      catch(e) { return text; }
    },
    pointInBbox(bbox, p, o) {
      var px = 4 * p.x;
      var py = 4 * p.y;
      if(px >= bbox.x1 && px <= bbox.x2) {
        if(-py + o >= bbox.y1 && -py + o <= bbox.y2) {
          return true;
        }
      }
      return false;
    },
    search() {
      if(this.renderStore.annotations.data.filter.length == 0 && this.renderStore.options.activeSideMenu == "annotation") {
        this.renderStore.searchAnnotations(this.renderStore.options.activeAnnotation.columnData.subject);
        this.renderStore.options.showAnnotations = true;
        this.renderStore.options.pivotFullScreen = false;

        var self = this;
        nextTick(function() { 
          self.renderStore.components.annotationViewer.$refs.searcher.query = self.renderStore.options.activeAnnotation.columnData.subject;
          self.renderStore.components.annotationViewer.$refs.searcher.isActive = true;
          resetSelection();
          rescaleCanvas();
        });
      }
    },
    async fetchAttachments() {
      if(this.contribution.athumbnails != null) {
        this.store.current.data.pdf.contribution.attachments = await contributionService.fetchAttachments(this.contribution, this.getData);
      }
    },
    async addAnswer() {
      if(this.pending) return;
      this.pending = true;
      let response = null;

      if(this.answer.answerId == 0) {
        response = await answerService.add(this.answer, this.defaultData, this.getData);
      }
      else {
        response = await answerService.update(this.answer, this.defaultData, this.getData);
      }

      if(response) {
        if(response.status == 200) {
          for(var i = 0; i < this.renderStore.contributions.length; i++) {
            if(this.renderStore.contributions[i].contributionId == this.answer.contributionId) {
              const contributionTemplate = this.renderStore.contributionTemplates[response.data.contributionTemplateId];
              var contribution = formatContribution(response.data, contributionTemplate);
              this.renderStore.contributions[i] = {...contribution};
              this.renderStore.options.activeContribution = {...contribution};
            }
          }

          this.clear();
          this.answer.answerId = 0;
          this.answer.content = "Wat wilt u antwoorden?";
        }
        else {
          this.$emit('showMessage', response);
          this.formErrors = errorHandler.process(response.data, "");
        }
      }
      this.pending = false;
    },
    parseValues(format, values) {
      for(const [key,value] of Object.entries(JSON.parse(values))) {
        format = format.replaceAll("{{"+key+"}}", value);
      }
      if(format.startsWith(": ")) format = format.substring(2);
      return format;
    },

    viewAttachment(attachmentId) {
      this.store.current.popup = ["view-pdf-attachments"];
      this.renderStore.options.activeAttachment = attachmentId;
    },

    editContribution(item) {
      this.store.current.data.pdf.contribution = {...item};
      this.store.current.popup = ["add-pdf-contribution"];
      selectAnnotation(item.pageNumber, "Q"+item.contributionId, true);
    },
    editContributions(contributionIds) {
      if(this.invalidSelection) return;
      if(contributionIds.length == 0) return;

      this.store.current.data.pdf.contribution = null;
      Object.entries(this.renderStore.filteredContributions).forEach(contribution => {
        for(var contributionId of contributionIds) {
          if(contribution[1].contributionId == contributionId) {
            if(!this.store.current.data.pdf.contribution) {
              this.store.current.data.pdf.contribution = {};
              this.store.current.data.pdf.contribution.multi = true;
              this.store.current.data.pdf.contribution.contributionIds = contributionIds;
              this.store.current.data.pdf.contribution.contributionTemplateId = contribution[1].contributionTemplateId;
              this.store.current.data.pdf.contribution.values = {...contribution[1].values};
            }
            Object.entries(contribution[1].values).forEach(value => {
              if(typeof value[1] == "string" && value[1].length > 0 && value[1] != this.store.current.data.pdf.contribution.values[value[0]]) {
                this.store.current.data.pdf.contribution.values[value[0]] = "";
              }
              else if(value[1] != this.store.current.data.pdf.contribution.values[value[0]]) {
                this.store.current.data.pdf.contribution.values[value[0]] = 0;
              }
            });
          }
        }
      });
      this.store.current.popup = ["add-pdf-contribution"];
    },
    copyContribution(item) {
      this.store.current.data.pdf.contribution = {...item};
      this.store.current.data.pdf.contribution.copy = true;
      this.store.current.popup = ["add-pdf-contribution"];
      this.renderStore.options.activeSideMenu = 'contributions';
    },
    deleteContribution(item) {
      this.store.current.data.pdf.contribution = {...item};
      this.store.current.popup = ["unlink-pdf-contribution"];
    },
    selectLinked() {
      findAnnotationById(this.contribution.pageNumber, this.contribution.annotationId);
    },
    addToolboxItem(item) {
      this.store.current.data.pdf.contribution = {...item};
      this.store.current.data.pdf.toolboxItem = null;
      this.store.current.popup = ["add-pdf-toolbox-item"];
    },

    empty(e) {
      const val = this.answer[e.target.name];
      if(this.defaultData[e.target.name] && (val == "" || val == this.defaultData[e.target.name][1])) {
        this.labelData[e.target.name] = {text: this.defaultData[e.target.name][0], active: true};
        this.answer[e.target.name] = "";
      }
    },
    reset(e) {
      const val = this.answer[e.target.name];
      if(val == "") {
        this.labelData[e.target.name] = {text: '', active: false};
        this.answer[e.target.name] = this.defaultData[e.target.name][1];
      }
    },
    clear() {
      for(const[key, val] of Object.entries(this.defaultData)) {
        this.labelData[key] = {text: '', active: false};
        this.answer[key] = val[1];
        this.formErrors = {};
      }
    },
    close() {
      resetSelection();
    },
    closeSideMenu() {
      this.renderStore.options.activeSideMenu = null;
    },
    editAnswer(contrib, answer) {
      this.store.current.data.pdf.answer = {...answer};
      this.store.current.data.pdf.contribution = {...contrib};
      this.store.current.popup = ["add-pdf-answer"];
    },
    deleteAnswer(item) {
      this.store.current.data.pdf.answer = {...item};
      this.store.current.popup = ["unlink-pdf-answer"];
    },
  }
}
</script>