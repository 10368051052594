<template>
  <perfect-scrollbar class="form" v-if="store.current.form === 'view-company'"> 
    <div v-if="company">
      <div class="adder" @click="editCompany()">Wijzigen</div>
      <h1>Accountgegevens</h1>
      <div v-if="!init" class="loader">
        <div>Even geduld, content wordt geladen...</div>
      </div>
      <div v-else>
        <div class="data-holder">
          <div class="sidebar" id="gmap2" style="width:25%"></div>
          <div class="enum">
            <h2>{{ company.name }}</h2>
            <span>BTW-nummer</span> {{ company.vatNumber }}<br/>
            <span>Algemeen telefoonnummer</span> {{ company.phoneNumber }}<br/><br/>

            <template v-if="company.addresses != null && company.addresses.length > 0">
              <table class="address">
                <tr>
                  <td v-for="(address, key) in company.addresses">
                    <h3>{{ address.addressType }}</h3>
                    <template v-if="address.name != null">
                      <b>{{ address.name }}</b><br/>
                    </template>
                    {{ address.street1 }}<br/>
                    <template v-if="address.street2 != null">
                      {{ address.street2 }}<br/>
                    </template>
                    {{ address.postalCode }}&nbsp;&nbsp;&nbsp;{{ address.city.toUpperCase() }}<br/>
                    {{ address.country.toUpperCase() }}
                  </td>
                </tr>
              </table>
            </template>
          </div>
        </div>

        <div class="adder" @click="addUser()">Toevoegen</div>
        <SearchField @search="search" />
        <h1>Gebruikers</h1>
        <div>
          <!--<div class="sidebar">
            <div class="enum">
              <span>Aantal documenten</span> {{ project.documentCount }}<br/><br/>
              <span>Opslag in gebruik</span> {{ Math.round(project.usedStorage/1048576, 1)  }} MB
            </div>
          </div>-->
          <template v-if="company.users && company.users.length > 0">
            <table class="listing">
              <tbody>
                <tr>
                  <th title="Naam" style="width:30%"><span>Naam</span></th>
                  <th title="Email-adres" style="width:30%"><span>Email-adres</span></th>
                  <th title="Laatst online"><span>Laatst online</span></th>
                  <th title="Rol"><span>Rol</span></th>
                  <th title="Opslag in gebruik"><span>Opslag</span></th>
                  <th class="opth" style="width:0%"></th>
                </tr>
                <tr v-for="(user, index) in company.users" v-bind:key="index" >
                  <td>{{ user.name }} {{ user.surname }}</td>
                  <td>{{ user.email }} ({{ user.username }})</td>
                  <td>
                    {{ user.dateOnline }}
                    <template v-if="!user.isActive">
                      (uitnodiging verstuurd, <a href="#" @click="reactivateUser(user)" class="nl">verstuur opnieuw</a>)
                    </template>
                  </td>
                  <td>{{ user.role }}</td>
                  <td>{{ user.diskUsage }} MB</td>
                  <td class="opt">
                    <div>
                      <div>
                        Opties bij regel
                        <a href="#" @click="editUser(user)">Pas aan</a>
                        <a href="#" @click="unlinkUser(user)">Verwijder</a>
                        <div class="clear"></div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr class="tot">
                  <td>Totale opslag in gebruik</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>{{ company.diskUsage }} MB</td>
                  <td class="opt"></td>
                </tr>
              </tbody>
            </table>
            <Pagination @change-offset="changeOffsetUsers" :query-args="queryArgsUsers"></Pagination>
          </template>
          <div v-else class="data-holder">
            <p style="padding-bottom:0">
              <i>
                Er kunnen geen gebruikers worden gevonden. U heeft wellicht nog geen gebruikers aan dit 
                account toegevoegd. Het kan ook zijn dat u de lijst heeft gefilterd op een zoekterm
                die geen resultaten oplevert.
              </i>
            </p>
          </div>
        </div>
        <div style="clear:both;height:50px"></div>

        <div class="adder" @click="addTransaction()">Toevoegen</div>
        <SearchField @search="search" />
        <h1>Transacties</h1>
        <div>
          <!--<div class="sidebar">
            <div class="enum">
              <span>Aantal documenten</span> {{ project.documentCount }}<br/><br/>
              <span>Opslag in gebruik</span> {{ Math.round(project.usedStorage/1048576, 1)  }} MB
            </div>
          </div>-->
          <template v-if="company.transactions && company.transactions.length > 0">
            <table class="listing">
              <tbody>
                <tr>
                  <th style="width:62.5%"><span>Omschrijving</span></th>
                  <th title="Datum"><span>Datum</span></th>
                  <th title="Mutatie"><span>Mutatie</span></th>
                  <th title="Status"><span>Status</span></th>
                  <th class="opth" style="width:0%"></th>
                </tr>
                <tr v-for="(transaction, index) in company.transactions" v-bind:key="index" >
                  <td>{{ transaction.description.replaceAll("\r\n", "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;") }}</td>
                  <td>{{ transaction.dateRegistered }}</td>
                  <td>
                    <div v-if="transaction.amount >= 0" class="pos">{{ Math.round(10000 * transaction.amount) / 10000 }} tokens</div>
                    <div v-else class="neg">{{ Math.round(10000 * transaction.amount) / 10000 }} tokens</div>
                  </td>
                  <td>{{ transaction.state }}</td>
                  <td class="opt">
                    <div>
                      <div>
                        Opties bij regel
                        <a href="#" @click="refundTransaction(transaction)">Terugbetaling</a>
                        <div class="clear"></div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr class="tot">
                  <td>Huidig bedrijfssaldo</td>
                  <td></td>
                  <td>{{ Math.round(1000 * company.balance) / 1000 }} tokens</td>
                  <td></td>
                  <td class="opt"></td>
                </tr>
              </tbody>
            </table>
            <Pagination @change-offset="changeOffsetTrans" :query-args="queryArgsTrans"></Pagination>
          </template>
          <div v-else class="data-holder">
            <p style="padding-bottom:0">
              <i>
                Er kunnen geen transacties worden gevonden. Wellicht heeft dit bedrijf nog geen transacties
                uitgevoerd. Het kan ook zijn dat u de lijst heeft gefilterd op een zoekterm
                die geen resultaten oplevert.
              </i>
            </p>
          </div>
        </div>

      </div>
    </div>
    <div v-else class="nodata">
      Dit account is niet toegankelijk omdat u hiervoor niet de juiste rechten heeft.
    </div>
  </perfect-scrollbar>

  <PopupAddUser 
    @update-parent="fetch"
    @show-message="showMessage" 
  />
  <PopupReactivateUser 
    @update-parent="fetch"
    @show-message="showMessage" 
  />
  <PopupUnlinkUser 
    @update-parent="fetch"
    @show-message="showMessage"
  />

  <PopupAddTransaction
    @update-parent="fetch"
    @show-message="showMessage" 
  />
</template>

<script>
import companyService from '@/services/company.service'
import userService from '@/services/user.service'
import transactionService from '@/services/app/transaction.service'

import SearchField from '@/components/SearchField.vue'
import Pagination from '@/components/Pagination.vue'

import PopupAddUser from '@/popups/AddUser.vue'
import PopupReactivateUser from '@/popups/ReactivateUser.vue'
import PopupUnlinkUser from '@/popups/UnlinkUser.vue'
import PopupAddTransaction from '@/popups/app/AddTransaction.vue'

export default {
  emits: ['showMessage'],
  data() {
    return {
      company: {},
      queryArgsUsers: {
        orderType: "asc",
        orderBy: "name",
        query: "",
        offset: 0,
        pageSize: 5,
        count: 0,
        companyId: null
      },
      queryArgsTrans: {
        orderType: "desc",
        orderBy: "dateRegistered",
        query: "",
        offset: 0,
        pageSize: 10,
        count: 0,
        companyId: null
      },
      title: 'Account niet beschikbaar',
      init: false,
      pending: false
    };
  },
  computed: {
    store() {
      return this.$parent.store;
    },
    currentForm() {
      return this.store.current.form;
    }
  },
  watch: {
    currentForm: {
      handler(val) {
        if(val == "view-company") {
          this.fetch();
        }
      },
      immediate: true
    }
  },
  methods: {
    async fetch() {
      this.init = false;
      this.company = await companyService.select(this.store.current.data.company.companyId);
      if(this.company) {
        document.title = this.company.name + " | " + this.store.appName;
        //this.title = this.project.name;
        
        this.company.addresses.forEach(address => {
          if(address.addressTypeId == 1) {
            this.renderMap(address.street1, address.city);
          }
        });

        this.queryArgsUsers.companyId = this.company.companyId;
        this.queryArgsUsers.count = this.company.userCount;

        this.queryArgsTrans.companyId = this.company.companyId;
        this.queryArgsTrans.count = this.company.transactionCount;
      }
      else {
        document.title = "Project niet beschikbaar | " + this.store.appName;
        this.title = "Project niet beschikbaar";
      }
      this.init = true;
    },
    async fetchUsers() {
      //this.company.users = [];
      const response = await userService.listing(this.queryArgsUsers);
      if(response) {
        if(response.status == 200) {
          this.company.users = response.data.results;
          this.queryArgsUsers.count = response.data.count;
        }
        else {
          this.$emit('showMessage', response);
        }
      }
    },
    async fetchTransactions() {
      //this.company.transactions = [];
      const response = await transactionService.listing(this.queryArgsTrans);
      if(response) {
        if(response.status == 200) {
          this.company.transactions = response.data.results;
          this.queryArgsTrans.count = response.data.count;
        }
        else {
          this.$emit('showMessage', response);
        }
      }
    },
    async searchUsers(query) {
      if(this.queryArgsUsers.query != query) {
        this.queryArgsUsers.companyId = this.company.companyId;
        this.queryArgsUsers.query = query;
        this.queryArgsUsers.offset = 0;
        await this.fetchUsers();
      }
    },
    async searchTransactions(query) {
      if(this.queryArgsTrans.query != query) {
        this.queryArgsTrans.companyId = this.company.companyId;
        this.queryArgsTrans.query = query;
        this.queryArgsTrans.offset = 0;
        await this.fetchUsers();
      }
    },
    async renderMap(street1, city) {
      const address = street1 + "," + city;
      const { Map } = await google.maps.importLibrary("maps");
      const { AdvancedMarkerElement } = await google.maps.importLibrary("marker");

      var geocoder = new google.maps.Geocoder();
      geocoder.geocode( { 'address': address}, function(results, status) {
        if (status == google.maps.GeocoderStatus.OK) {
          if (status != google.maps.GeocoderStatus.ZERO_RESULTS) {
            const map = new Map(document.getElementById("gmap2"), {
              center: results[0].geometry.location,
              zoom: 6,
              mapId: "comp-loc"
            });

            const marker = new AdvancedMarkerElement({
              map: map,
              position: results[0].geometry.location,
              title: "Adres klant",
            });
          }
        }
      });
    },
    editCompany() {
      this.store.current.data.company = {...this.company};
      this.store.current.popup = ["add-company"];
    },
    addUser() {
      this.store.current.data.user = { 
        companyId: this.company.companyId
      };
      this.store.current.popup = ["add-user"];
    },
    editUser(user) {
      this.store.current.data.user = user;
      this.store.current.popup = ["add-user"];
    },
    unlinkUser(user) {
      this.store.current.data.user = {...user};
      this.store.current.popup = ["unlink-user"];
    },
    reactivateUser(user) {
      this.store.current.data.user = {...user};
      this.store.current.popup = ["reactivate-user"];
    },
    reactivateUserConfirmed() {
      userService.reactivate(user.userId);
    },
    addTransaction() {
      this.store.current.popup = ["add-pdf-transaction"];
    },
    showMessage(e) {
      this.$emit("showMessage", e);
    },
    changeOffsetUsers(offset) {
      this.queryArgsUsers.offset = offset;
      this.fetchUsers();
    },
    changeOffsetTrans(offset) {
      this.queryArgsTrans.offset = offset;
      this.fetchTransactions();
    },
  },
  components: {
    PopupAddUser,
    PopupReactivateUser,
    PopupUnlinkUser,
    PopupAddTransaction,
    SearchField,
    Pagination
  }
}
</script>