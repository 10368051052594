<template>
  <div class="navbar-bottom" ref="navbarBottom">
    <template v-if="this.renderStore.data[this.renderStore.options.pageNumber]">
      <div class="nav" @click="prevPage()" title="Vorige pagina">«</div>
      <div class="lab" @click="inputPageNumber" ref="pageInput" :title="(this.renderStore.pageLabels && this.renderStore.pageLabels[this.renderStore.options.pageNumber-1]) ? parsedText(this.renderStore.pageLabels[this.renderStore.options.pageNumber-1]) : 'Paginalabel onbekend'">
        <template v-if="pageInput">
          <span>Voer een paginanummer in:</span>
          <input 
            type="text" 
            inputmode="numeric" pattern="[0-9]*" 
            @keydown="inputPageNumberKeyDown" 
            @focusout="pageInput = false"
          >
        </template>
        <template v-else>
          <span>{{ (this.renderStore.pageLabels && this.renderStore.pageLabels[this.renderStore.options.pageNumber-1]) ? parsedText(this.renderStore.pageLabels[this.renderStore.options.pageNumber-1]) : "Paginalabel onbekend" }}</span> {{ this.renderStore.options.pageNumber }} / {{ this.renderStore.data.length-1 }}
        </template>
      </div>
      <div class="nav" @click="nextPage()" title="Volgende pagina">»</div>
    </template>
    <div v-on:click="zoomReset" class="button" style="margin-left:8px;float:left" title="Viewport resetten">
      <img src="img/ico/action-fittoscreen.png">
    </div>
    <div class="numb scale">
      <b>Paginaschaal</b><br/>
      {{ this.pageScale }}
    </div>
    
    <!--<div v-on:click="toggleMarkup" class="button red" :class="{ selected: this.renderStore.options.hideMarkup }" title="Markeringen tonen/verbergen">
      <img src="img/ico/markup.png">
    </div>
    <div v-on:click="toggleContributions" class="button red" :class="{ selected: this.renderStore.options.hideContributions }" title="Bijdragen tonen/verbergen">
      <img src="img/ico/contributions.png">
    </div>-->
    <div v-on:click="toggleGrayscale" class="button" :class="{ selected: this.renderStore.options.grayScale }" title="Grijswaarden">
      <img src="img/ico/black-white.png">
    </div>

    <label>
      Dimpercentage
      <span style="float:right">{{ 100 - Math.round(this.renderStore.options.opacity * 100) }}%</span>
      <input type="range" 
        v-model="this.renderStore.options.opacity"
        @change="opacityChanged"
        min="0.1"
        max="1"
        step="0.05" />
    </label>
  </div>
</template>

<script>
import { zoomReset, resetSelection } from '@/components/app/viewer/PdfCanvas.js'

export default {
  props: {
    document: Object
  },
  data() {
    return {
      pageInput: false
    };
  },
  computed: {
    store() {
      return this.$parent.store;
    },
    renderStore() {
      return this.$parent.renderStore;
    },
    pageScale() {
      try {
        let scale = this.renderStore.pages[this.renderStore.options.pageNumber - 1].scale;
        switch(scale) {
          case 0:
            return "Niet ingesteld";
          case 0.001:
            return "1 : 1000";
          case 0.002:
            return "1 : 500";
          case 0.005:
            return "1 : 200";
          case 0.01:
            return "1 : 100";
          case 0.02:
            return "1 : 50";
          case 0.05:
            return "1 : 20";
          case 0.1:
            return "1 : 10";
          case 1:
            return "1 : 1";
          default:
            return "1 cm = " + Math.round(1/scale)/100 + " m";
        }
      }
      catch { return "Niet ingesteld"; }
    }
  },
  mounted() {
    this.renderStore.components.navbarBottom = this.$refs.navbarBottom;
  },
  methods: {
    prevPage() {
      if(this.renderStore.options.pageNumber > 1) {
        this.goToPage(this.renderStore.options.pageNumber - 1);
      }
    },
    nextPage() {
      if(this.renderStore.options.pageNumber < this.document.pageCount) {
        this.goToPage(this.renderStore.options.pageNumber + 1);
      } 
    },
    goToPage(pageNumber) {
      if(pageNumber > 0) {
        resetSelection();
        this.renderStore.options.pageNumber = parseInt(pageNumber);
      }
    },
    toggleGrayscale() {
      this.renderStore.options.grayScale = !this.renderStore.options.grayScale;
    },
    zoomReset() {
      zoomReset();
    },
    opacityChanged() {
      
    },
    inputPageNumber() {
      this.pageInput = true;
      
      setTimeout(() => {
        this.$refs.pageInput.children[1].focus();
      }, 300);
    },
    inputPageNumberKeyDown(e) {
      if(e.keyCode == 13 || e.keyCode == 176) {
        var pageNumber = parseFloat(this.$refs.pageInput.children[1].value);
        if(pageNumber >= 1 && pageNumber <= this.document.pageCount) {
          this.goToPage(pageNumber);
        }
        this.pageInput = false;
      }
    },
    parsedText(text) {
      try { return text.replace(/&#(\d+);/g, function (m, n) { 
        return String.fromCharCode(n); }); 
      }
      catch(e) { return ""; }
    }
  }
}
</script>