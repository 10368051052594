<template>
  <div class="form" v-if="store.current.form === 'list-pdf-projects'">
    <div v-if="store.isAdmin" class="adder" @click="add()">Toevoegen</div>
    <SearchField @search="searchProjects" />
    <h1>Projecten</h1>
    <div v-if="refreshProjects" class="loader">
      <div>Even geduld, content wordt geladen...</div>
    </div>
    <div v-else-if="projects && projects.length == 0" class="nodata">
      Er zijn geen resultaten die hier kunnen worden getoond.
      Wilt u misschien iets <a href="#" @click="add()">toevoegen</a>?
    </div>
    <div v-else>
      <table class="listing">
        <tbody>
          <tr>
            <th title="Naam" style="width:50%">Naam</th>
            <th title="Aangemaakt door" style="width:20%">Aangemaakt door</th>
            <th title="Aangemaakt door" style="width:10%">Aanmaakdatum</th>
            <th v-if="store.isAdmin" class="opth" style="width:0%"></th>
          </tr>
          <tr v-for="(item, index) in projects" v-bind:key="index" @click="goto(item)">
            <td>{{ item.name }}</td>
            <td>{{ item.createdBy }}</td>
            <td>{{ item.dateCreated }}</td>
            <td v-if="store.isAdmin" class="opt">
              <div>
                <div>
                  Opties bij regel
                  <a href="#" @click.stop="edit(item)">Pas aan</a>
                  <a href="#" @click.stop="unlink(item)">Verwijder</a>
                  <div class="clear"></div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <Pagination @change-offset="changeOffsetProjects" :query-args="queryArgsProjects"></Pagination>
    </div>

    <div style="clear:both;height:50px"></div>

    <SearchField @search="searchShares" />
    <h1>Gedeelde documenten</h1>
    <div v-if="refreshShares" class="loader">
      <div>Even geduld, content wordt geladen...</div>
    </div>
    <div v-else-if="shares && shares.length == 0" class="nodata">
      Er zijn geen resultaten die hier kunnen worden getoond.
      <template v-if="queryArgsShares.query.length > 0">
        Probeer uw zoekopdracht te wijzigen of minder specifiek te maken.
      </template>
    </div>
    <div v-else>
      <table class="listing doc share">
        <tbody>
          <tr>
            <th title="Omschrijving" style="width:70%">Omschrijving</th>
            <th title="Gedeeld op">Gedeeld op</th>
          </tr>
          <tr v-for="(item, index) in shares" v-bind:key="index"
            @mouseenter="showDetails"
            @mouseleave="hideDetails">
            <td>
              <img v-if="item.verified" src="img/ico/verified.png" class="ver">
              <img v-else src="img/ico/not-verified.png" class="ver">
              <img
                :style="{backgroundImage: 'url('+item.thumbnail+')' }"
                :thumbnail="item.thumbnail" 
                :icon=item.icon
                class="thumb"
                @click="viewDocument(item)"
              />

              <div class="enum det sd">
                <span>Document</span> {{ item.documentName }}<br/>
                <span>Project</span> {{ item.projectName }}<br/>
                <span>Gedeeld door</span> {{ item.sharedBy }}
              </div>
              
              <div class="enum det" id="inline-opt">
                <a href="#" @click="viewDocument(item)">Bekijk document</a>
              </div>
            </td>
            <td>
              {{ item.dateGranted }}
            </td>
          </tr>
        </tbody>
      </table>
      <Pagination @change-offset="changeOffsetShares" :query-args="queryArgsShares"></Pagination>
    </div>

    <div v-if="store.isUser" class="nodata cond" @click="conditions">
      Op het gebruik van deze testomgeving zijn voorwaarden van toepassing. Deze kunt u raadplegen
      door hier te klikken.
    </div>
  </div>
  <PopupAddProject
    @fetch-listing="fetchProjects"
    @show-message="showMessage" 
  />
  <PopupUnlinkProject 
    @fetch-listing="fetchProjects"
    @show-message="showMessage"
  />
</template>

<script>
import projectService from '@/services/app/project.service'
import permissionService from '@/services/app/permission.service'

import PopupAddProject from '@/popups/app/AddProject.vue'
import PopupUnlinkProject from '@/popups/app/UnlinkProject.vue'
import Pagination from '@/components/Pagination.vue'
import SearchField from '@/components/SearchField.vue'

export default {
  emits: ['showMessage'],
  data() {
    return {
      projects: [],
      shares: [],
      queryArgsProjects: {
        orderType: "desc",
        orderBy: "dateCreated",
        query: "",
        offset: 0,
        pageSize: 5,
        count: 0
      },
      queryArgsShares: {
        orderType: "desc",
        orderBy: "dateGranted",
        query: "",
        offset: 0,
        pageSize: 5,
        count: 0
      },
      init: false,
      refreshProjects: false,
      refreshShares: false,
      eventHandlers: {
        'fetch-project-listing': this.fetch
      }
    };
  },
  computed: {
    store() {
      return this.$parent.store;
    },
    currentForm() {
      return this.store.current.form;
    }
  },
  watch: {
    currentForm: {
      async handler(val) {
        if(val == "list-pdf-projects") {
          document.title = "Overzicht projecten | " + this.store.appName;

          this.init = false;

          this.queryArgsProjects.offset = 0;
          this.queryArgsProjects.query = "";
          this.fetchProjects();

          this.queryArgsShares.offset = 0;
          this.queryArgsShares.query = "";
          this.fetchShares();

          this.init = true;
        }
      },
      immediate: true
    }
  },
  methods: {
    add() {
      this.store.current.popup = ["add-pdf-project"];
    },
    edit(item) {
      this.store.current.data.pdf.project = {...item};
      this.store.current.popup = ["add-pdf-project"];
    },
    unlink(item) {
      this.store.current.data.pdf.project = {...item};
      this.store.current.popup = ["unlink-pdf-project"];
    },
    async fetchProjects() {
      this.refreshProjects = true;
      const response = await projectService.listing(this.queryArgsProjects);
      if(response) {
        if(response.status == 200) {
          this.projects = response.data.results;
          this.queryArgsProjects.count = response.data.count;
        }
        else {
          this.$emit('showMessage', response);
        }
      }
      this.refreshProjects = false;
    },
    async fetchShares() {
      this.refreshShares = true;
      const response = await permissionService.shares(this.queryArgsShares);
      if(response) {
        if(response.status == 200) {
          this.shares = response.data.results;
          this.queryArgsShares.count = response.data.count;
        }
        else {
          this.$emit('showMessage', response);
        }

        this.shares.forEach((share) => {
          if(share.thumbnail != null) {
            share.thumbnail = 'data:image/jpeg;base64,'+share.thumbnail;
          }
          else share.thumbnail = "img/def-thumb.jpg";
        });
      }
      this.refreshShares = false;
    },
    changeOffsetProjects(offset) {
      this.queryArgsProjects.offset = offset;
      this.fetchProjects();    
    },
    changeOffsetShares(offset) {
      this.queryArgsShares.offset = offset;
      this.fetchShares();   
    },
    searchProjects(query) {
      if(this.queryArgsProjects.query != query) {
        this.queryArgsProjects.query = query;
        this.queryArgsProjects.offset = 0;
        this.fetchProjects();
      }
    },
    searchShares(query) {
      if(this.queryArgsShares.query != query) {
        this.queryArgsShares.query = query;
        this.queryArgsShares.offset = 0;
        this.fetchShares();
      }
    },
    showMessage(e) {
      this.$emit("showMessage", e);
    },
    goto(item) {
      this.store.current.data.pdf.project = {...item};
      this.store.current.form = "view-pdf-project";
    },
    showDetails(e) {
      const det = e.target.querySelector('#inline-opt');
      det.classList.add("sd");
    },
    hideDetails(e) {
      const det = e.target.querySelector('#inline-opt');
      det.classList.remove("sd");
    },
    viewDocument(document) {
      this.store.current.data.pdf.document = {...document};
      this.store.current.data.pdf.contribution = null;
      this.store.current.form = "view-pdf-document";
    },
    conditions() {
      window.open("https://fpdf.cloud/dl/20240801 Overeenkomst Foundation v1.2.pdf", '_blank').focus();
    }
  },
  components: {
    PopupAddProject,
    PopupUnlinkProject,
    Pagination,
    SearchField
  }
}
</script>

<style scoped>
.cond {
  position: fixed;
  bottom: 20px;
  left: 20px;
  right: 20px; 
  opacity: 0.5;
}

.cond:hover {
  opacity: 1;
  cursor: pointer;
}
</style>
