<template>
  <Menu ref="wmn" />
  <perfect-scrollbar 
    @ps-scroll-y="onScrollEvent" 
    :options={wheelSpeed:0.3}
    style="height:100%"
  >
    <div class="ct">
      <Leader_Contact />
      <Map />
      <Form_Contact />
      <Contact1 />
      <Logos />
      <Footer />
    </div>
  </perfect-scrollbar>
</template>

<script>
import Menu from '@/components/Menu.vue'
import Leader_Contact from '@/components/content/Leader_Contact.vue'
import Contact1 from '@/components/content/Contact1.vue'
import Form_Contact from '@/components/forms/Contact.vue'
import Map from '@/components/others/Map.vue'
import Logos from '@/components/carroussels/Logos.vue'
import Footer from '@/components/others/Footer.vue'

import { useStore } from '@/store/pinia'

export default {
  name: "Home",
  data() {
    return {
      store: useStore(),
      pageName: "Contact",
      error: ""
    };
  },
  computed: {
    
  },
  components: { 
    Menu,
    Leader_Contact,
    Form_Contact,
    Contact1,
    Map,
    Logos,
    Footer
  }
};
</script>