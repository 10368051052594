<template>
  <transition name="fade">
    <div class="popup small" v-if="store.current.popup.includes('unlink-pdf-toolbox-item')">
      <div>
        <perfect-scrollbar style="height:100%">
          <form @submit.prevent="unlink">
            <div class="closer" @click="close()">Terug naar vorig scherm</div>
            <h1>{{ title }}</h1>
            <div v-if="init">
              <p>
                U staat op het punt om het item "{{ toolboxItem.name }}" te verwijderen.
                Deze actie kan niet ongedaan worden gemaakt. Wilt u doorgaan?
              </p>
              <input type="submit" :value="submitText" class="red" :class="{locked:isLocked}">
            </div>
            <div v-else class="loader">
              <div>Even geduld, content wordt geladen...</div>
            </div>
          </form>
        </perfect-scrollbar>
      </div>
    </div>
  </transition>
</template>

<script>
import toolboxService from '@/services/app/toolbox.service';

export default {
  emits: ['showMessage', 'updateParent'],
  data() {
    return {
      toolboxItem: {},
      title: "",
      submitText: "",
      init: false,
      pending: false
    };
  },
  computed: {
    store() {
      return this.$parent.store;
    },
    currentPopup() {
      return this.store.current.popup[this.store.current.popup.length-1];
    },
    isLocked() {
      if(this.pending || !this.init) return true;
      else return false;
    }
  },
  watch: {
    async currentPopup(value) {
      if(value == "unlink-pdf-toolbox-item") {
        this.init = false;
        this.title = "Weet u dit zeker?";
        this.submitText = "Ja, ik weet het zeker!";
        
        this.toolboxItem.toolboxItemId = this.store.current.data.pdf.toolboxItem.toolboxItemId;
        await this.fetch();

        this.init = true;
        this.pending = false;
      }
    }
  },
  methods: {
    async unlink() {
      if(this.pending) return;
      this.pending = true;

      const response = await toolboxService.unlinkItem(this.toolboxItem.toolboxItemId);
      if(response) {
        if(response.status == 200) {
          this.close();
        }
        else {
          this.$emit('showMessage', response);
        }
      }
      this.pending = false;
    },
    close() {
      this.store.current.popup = [];
      this.store.current.data.pdf.toolboxItem = null;
      this.$emit('updateParent');
    },
    async fetch() {
      if(this.toolboxItem.toolboxItemId) {
        this.toolboxItem = await toolboxService.selectItem(this.toolboxItem);
      }
    }
  }
}
</script>
