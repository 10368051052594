<template>
  <Menu ref="wmn" />
  <perfect-scrollbar 
    @ps-scroll-y="onScrollEvent" 
    :options={wheelSpeed:0.3}
    style="height:100%"
  >
    <div class="ct">
      <Leader_Safety />
      <Safety1 />
      <Logos />
      <Footer />
    </div>
  </perfect-scrollbar>
</template>

<script>
import Menu from '@/components/Menu.vue'
import Leader_Safety from '@/components/content/Leader_Safety.vue'
import Safety1 from '@/components/content/Safety1.vue'
import Logos from '@/components/carroussels/Logos.vue'
import Footer from '@/components/others/Footer.vue'

import { useStore } from '@/store/pinia'

export default {
  name: "Home",
  data() {
    return {
      store: useStore(),
      pageName: "Databeveiliging",
      error: ""
    };
  },
  computed: {
    
  },
  components: { 
    Menu,
    Leader_Safety,
    Safety1,
    Logos,
    Footer
  }
};
</script>