<template>
  <div class="form" v-if="store.isRegistrar && store.current.form === 'list-log-requests'">
    <h1 v-html="title"></h1>
    <div class="loader" v-if="!init">
      <div>Even geduld, content wordt geladen...</div>
    </div>
    <div class="nodata" v-else-if="chartData.length === 0">
      Er zijn geen resultaten die hier kunnen worden getoond.
    </div>
    <div v-else-if="chartType === 'line'">
      <GChart
        type="AreaChart"
        :data="chartData"
        :options="chartOptionsLine"
        @ready="onChartReady"
      />
    </div>
    <div v-else-if="chartType === 'bar'">
      <GChart
        type="BarChart"
        :data="chartData"
        :options="chartOptionsBar"
        @ready="onChartReady"
      />
      <div class="back" @click="reset">⏎ terug naar vorige pagina</div>
    </div>
  </div>
</template>

<script>
import logService from '@/services/log.service'
import { loadGoogleCharts , GChart } from 'vue-google-charts'
loadGoogleCharts('current', { packages: ['corechart'] })

export default {
  emits: ['showMessage'],
  data() {
    return {
      logRequests: [],
      queryArgs: {
        orderType: "asc",
        orderBy: "dateLogged",
        offset: 0,
        pageSize: 1000,
        count: 0,
        query: null,
        dateFrom: null,
        dateTo: null
      },
      title: '',
      chartLabels: ['Tijdstip', "Aantal verzoeken"],
      chart: null,
      chartType: "",
      chartData: [],
      chartOptionsLine: {
        theme: "material",
        hAxis: {
          title: 'Tijdstip',
          format: 'none',
          slantedText: false
        },
        vAxis: {
          title: 'Aantal verzoeken'
        },
        legend: 'none',
        height: 500
      },
      chartOptionsBar: {
        theme: "material",
        hAxis: {
          title: 'Aantal verzoeken'
        },
        vAxis: {
          title: 'Eindpunt'
        },
        chartArea: {
          width: '65%',
          left: '30%'
        },
        tooltip: {
          isHtml: true
        },
        legend: 'none',
        height: 500
      },
      title: "",
      init: false,
      eventHandlers: {
        'fetch-api-request-listing': this.fetch
      }
    };
  },
  computed: {
    store() {
      return this.$parent.store;
    },
    currentForm() {
      return this.store.current.form;
    }
  },
  watch: {
    currentForm: {
      handler(val) {
        if(val == "list-log-requests") {
          document.title = "Logboek API requests | " + this.store.appName;
          this.reset();
        }
      },
      immediate: true
    }
  },
  methods: {
    async fetch() {
      this.init = false;
      const response = await logService.listingRequests(this.queryArgs);
      if(response && response.status == 200) {
        for(const [key, result] of Object.entries(response.data.results)) {
          if(result.endpoint) {
            if(key == 0) {
              this.chartData = [['Eindpunt', 'Aantal verzoeken']];
              this.chartType = "bar";
            }
            this.chartData.push([result.endpoint, result.count]);
          }
          else {
            if(key == 0) {
              this.chartData = [['Tijdstip', 'Aantal verzoeken']];
              this.chartType = "line";
            }
            this.chartData.push([result.dateLogged, result.count]);
          }
        }
      }
      else {
        this.$emit('showMessage', response);
      }
      this.init = true;
    },
    reset() {
      this.queryArgs.dateFrom = null;
      this.queryArgs.dateTo = null;
      this.queryArgs.orderBy = "dateLogged";
      this.queryArgs.orderType = "asc";
      this.title = "Logboek API requests";
      this.fetch();
    },
    onChartReady(chart, google) {
      this.chart = chart;
      google.visualization.events.addListener(chart, 'select', this.selectHandler); 
    },
    selectHandler() {
      var selection = this.chart.getSelection();
      if(selection) {
        var selectedDate = this.chartData[selection[0].row+1][0];
        this.queryArgs.dateFrom = selectedDate;
        this.queryArgs.dateTo = selectedDate;
        this.queryArgs.orderBy = "endpoint";
        this.queryArgs.orderType = "asc";
        this.title = "Logboek API requests <span style=\"float:right\">"+selectedDate+"</span>";
        this.fetch();
      }
    }
  },
  components: {
    GChart
  }
}
</script>
