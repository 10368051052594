<template>
  <Menu ref="wmn" />
  <perfect-scrollbar
    :options={wheelSpeed:0.3}
    style="height:100%"
  >
    <div class="ct">
      <Leader_About />
      <About1 />
      <Logos />
      <Footer />
    </div>
  </perfect-scrollbar>
</template>

<script>
import Menu from '@/components/Menu.vue'
import Leader_About from '@/components/content/Leader_About.vue'
import About1 from '@/components/content/About1.vue'
import Logos from '@/components/carroussels/Logos.vue'
import Footer from '@/components/others/Footer.vue'

import { useStore } from '@/store/pinia'

export default {
  name: "Home",
  data() {
    return {
      store: useStore(),
      pageName: "Contact",
      error: ""
    };
  },
  methods: {
    
  },
  components: { 
    Menu,
    Leader_About,
    About1,
    Logos,
    Footer
  }
};
</script>