<template>
  <div class="lead">
    <div class="in">
      <div class="lt lt50">
        <h2>
          Een nieuw pakket waarvan u de komende tijd veel mag verwachten
        </h2>
        <p>
          Elke stap vooruit is er volgens ons één in de juiste richting.
          Ondanks dat we een nieuwe speler zijn op gebied van cloud-toepassingen, 
          is het team achter Foundation 100% betrokken bij de bouw en we weten 
          dus wat er speelt in de branche.
        </p>
        <p>
          We zijn vastberaden om de komende jaren steeds meer functionaliteit
          aan het pakket toe te voegen om zo dé perfecte tool te creëren die 
          communicatie en samenwerking tussen bouwbedrijven optimaliseert.
        </p>
      </div>
      <div class="lt lt50">
        <div class="yt">
          <iframe width="560" height="315" 
            src="https://www.youtube.com/embed/eNdl6KnRdtc?si=Het3iFTLMlhSFC10" 
            title="YouTube video player" 
            frameborder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
            referrerpolicy="strict-origin-when-cross-origin" 
            allowfullscreen>
          </iframe>
        </div>
      </div>
      <div class="clear"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {

    };
  },
  computed: {
    
  },
  components: { 
    
  }
};
</script>

<style scoped>
h2 {
  font-size: 24px;
  font-style: normal;
  font-weight: bold;
  color: #fff;
}

h3 {
  font-size: 20px;
  color: #fff;
  margin: 0; padding: 0;
  padding-bottom: 10px;
}

p {
  font-size: 14px;
}

span {
  font-size: 14px;
  line-height: 1.5em;
  border-left: 5px solid #fff;
  background-color: rgba(255,255,255,0.1);
  padding: 5px 15px;
  display: block;
  border-radius: 10px;
  margin-top: 20px;
}

.lead {
  color: #fff;

  .in  {
    width: 1050px;
    margin: 0 auto;
    padding: 75px 0;
  }
}

.lt50 {
  width: 50%;
  float: left;
  box-sizing: border-box;
  padding: 0 25px;
}

.yt {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  height: 0;
}

iframe {
  position: absolute;
  top: 0; left: 0;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  box-shadow: 2px 2px 10px 3px rgba(0,0,0,0.2);
}
</style>