<template>
  <div class="form" v-if="store.current.form === 'menu-admin'">
    <div>
      <h1>Algemeen</h1>
      <div 
        @click.stop="goto('list-pdf-projects')"
        class="tile">
          <span>Beheer projecten</span>
      </div>
      <div class="clear"></div>      

      <h1 style="margin-top:5vh">Beheer</h1>
      <div 
        @click.stop="goto('list-companies')"
        class="tile">
          <span>Beheer accounts</span>
      </div>
      <div 
        @click.stop="goto('list-log-requests')"
        class="tile">
          <span>Logboek API requests</span>
      </div>
      <div class="clear"></div>
    </div>
  </div>
</template>

<script>
export default {
  emits: ['showMessage'],
  data() {
    return {
      endpoints: [],
      queryArgs: {
        orderType: "asc",
        orderBy: "name",
        query: "",
        offset: 0,
        pageSize: 100,
        count: 0
      },
      init: false,
      eventHandlers: {
        'fetch-endpoint-listing': this.fetch
      }
    };
  },
  computed: {
    store() {
      return this.$parent.store;
    },
    currentForm() {
      return this.store.current.form;
    }
  },
  watch: {
    currentForm(value) {
      if(value == "menu-admin") {
        document.title = "Menu | " + this.store.appName;
      }
    }
  },
  methods: {
    login() {
      this.store.current.popup = ["login-user"];
    },
    goto(item) {
      this.store.current.data.endpoint = null;
      this.store.current.data.category = null;
      this.store.current.popup = [];
      this.store.current.form = item;
    }
  }
}
</script>

<style scoped>
.tile {
  background: linear-gradient(40deg, rgb(200, 200, 200) 0%, rgb(240, 240, 240) 100%);
  box-shadow: 1px 1px 3px rgba(0,0,0,0.2);
  padding: 2vh 3vh;
  width: 30vw;
  display: table;
  border-radius: 1vh;
  cursor: pointer;
  box-sizing: border-box;
  opacity: 1;
  transition-duration: 0.3s;
  float: left;
  margin-right: 1vh;
  margin-bottom: 1vh
}

.tile:hover {
  opacity: 0.8;
  transition-duration: 0.3s
}

.tile span {
  display: table-cell;
  text-align: left;
  vertical-align: middle;
  height: 100%;
  font-size: 20px;
  font-style: italic
}
</style>