<template>
  <div class="form" v-if="init && store.current.form === 'login-temp-user'" style="margin: 0 auto; width: 50vw;padding-top:25vh">
    <h1>Aanmelden met toegangscode</h1>
    <div class="data-holder">
      <p>
        Controleer uw email en voer hieronder de toegangscode in die u van ons heeft ontvangen. Het kan even duren voordat
        u het bericht ontvangt. <b>Controleer ook de spamfolder.</b> 
      </p>
      <p>
        Ontvangt u binnen 5 minuten geen toegangscode? U kunt dan het beste de link opnieuw openen vanuit de email, u zult
        dan een nieuwe toegangscode ontvangen.
      </p>

      <div v-if="labelData.accessKey" class="before-input" :class="{active:labelData.accessKey.active}">{{ labelData.accessKey.text }}</div>
      <form @submit.prevent="set">
        <input type="text" 
          name="accessKey"
          inputmode="numeric" pattern="[0-9]*"
          v-model="tempUser.accessKey" 
          :class="{hasLabel:labelData.accessKey.active}"
          @focusin="empty($event)" 
          @focusout="reset($event)">
        <input type="submit" value="Doorgaan">
        <div class="clear"></div>
      </form>
    </div>
  </div>
</template>

<script>

export default {
  emits: ['updateParent'],
  data() {
    return {
      tempUser: {
        accessKey: null
      },
      defaultData: {
        accessKey: ['Toegangscode', 'Welke toegangscode heeft u via e-mail ontvangen?'],
      },
      labelData: {},
      formErrors: {},
      init: false
    };
  },
  computed: {
    store() {
      return this.$parent.store;
    },
    currentForm() {
      return this.store.current.form;
    }
  },
  watch: {
    currentForm(value) {
      if(value == "login-temp-user") {
        this.init = false;
        this.accessKey = null;
        this.clear();
        this.init = true;
      }
    }
  },
  methods: {
    set() {
      this.$emit('updateParent', this.tempUser.accessKey);
    },
    empty(e) {
      const val = this.tempUser[e.target.name];
      if(this.defaultData[e.target.name] && (val == "" || val == this.defaultData[e.target.name][1])) {
        this.labelData[e.target.name] = {text: this.defaultData[e.target.name][0], active: true};
        this.tempUser[e.target.name] = "";
      }
    },
    reset(e) {
      const val = this.tempUser[e.target.name];
      if(val == "") {
        this.labelData[e.target.name] = {text: '', active: false};
        this.tempUser[e.target.name] = this.defaultData[e.target.name][1];
      }
    },
    clear() {
      for(const[key, val] of Object.entries(this.defaultData)) {
        this.labelData[key] = {text: '', active: false};
        this.tempUser[key] = val[1];
        this.formErrors = {};
      }
    }
  },
  components: {
    
  }
}
</script>
