import api from '../api';
import tokenService from "../token.service";

class AnswerService {

  async select(answer, getData) {
    try {
      await tokenService.awaitSessionRefresh();
      const response = await api.post(
        "/app/answer/select", {
          contributionId: answer.contributionId,
          answerId: answer.answerId,
          userEmail: getData.userEmail,
          userToken: getData.userToken,
          accessKey: getData.accessKey
        }
      );
      return response.data;
    }
    catch (error) {
      return null;
    }
  }

  async add(formData, defaultData, getData) {
    try {
      await tokenService.awaitSessionRefresh();
      const response = await api.post(
        "/app/answer/add", Object.assign(this.toSubmitData(formData, defaultData), getData)
      );
      return response;
    }
    catch (error) {
      return error.response;
    }
  }

  async update(formData, defaultData, getData) {
    try {
      await tokenService.awaitSessionRefresh();
      const response = await api.post(
        "/app/answer/update", Object.assign(this.toSubmitData(formData, defaultData), getData)
      );
      return response;
    }
    catch (error) {
      return error.response;
    }
  }

  async unlink(answer, getData) {
    try {
      await tokenService.awaitSessionRefresh();
      const response = await api.post(
        "/app/answer/unlink", {
          answerId: answer.answerId,
          userEmail: getData.userEmail,
          userToken: getData.userToken,
          accessKey: getData.accessKey
        }
      );
      return response;
    }
    catch (error) {
      return error.response;
    }
  }

  async listing(queryArgs, getData) {
    try {
      await tokenService.awaitSessionRefresh();
      const response = await api.post(
        "/app/answer/listing", Object.assign(queryArgs, getData)
      );
      return response;
    }
    catch (error) {
      return error.response;
    }
  }

  toSubmitData(formData, defaultData) {
    const submitData = {...formData};
    for(const [key, val] of Object.entries(submitData)) {
      if(!isNaN(val)) continue;
      if(defaultData[key] && val == defaultData[key][1]) {
        submitData[key] = null;
      }
    }
    return submitData;
  }
}
export default new AnswerService();