<template>
  <transition name="fade">
    <div class="popup small" v-if="store.current.popup.includes('backup-pdf-document-history')">
      <div>
        <perfect-scrollbar style="height:100%">
          <form @submit.prevent="backup">
            <div class="closer" @click="close()">Terug naar vorig scherm</div>
            <h1>{{ title }}</h1>
            <div v-if="init">
              <p>
                Deze functie is op dit moment nog niet beschikbaar.
              </p>
              <a :href=blobUrl class="href-hidden" ref="file-download"></a>
              <input type="submit" :value="submitText" class="red" :class="{locked:isLocked}">
            </div>
            <div v-else class="loader">
              <div>Even geduld, content wordt geladen...</div>
            </div>
          </form>
        </perfect-scrollbar>
      </div>
    </div>
  </transition>
</template>

<script>
import documentHistoryService from '@/services/app/document.history.service'

export default {
  emits: ['showMessage', 'updateParent'],
  props: {
    getData: Object
  },
  data() {
    return {
      documentHistory: {},
      title: "",
      submitText: "",
      blobUrl: "",
      init: false,
      pending: false
    };
  },
  computed: {
    store() {
      return this.$parent.store;
    },
    renderStore() {
      return this.$parent.renderStore;
    },
    currentPopup() {
      return this.store.current.popup[this.store.current.popup.length-1];
    },
    isLocked() {
      if(this.pending || !this.init) return true;
      else return false;
    }
  },
  watch: {
    async currentPopup(value) {
      if(value == "backup-pdf-document-history") {
        this.init = false;
        this.title = "Backup van documentversie";
        this.submitText = "Ja, ik weet het zeker!";

        //console.log(this.store.current.data.pdf.contribution);
        this.documentHistory = { documentHistoryId: this.store.current.data.pdf.documentHistory.documentHistoryId };
        await this.fetch();

        this.init = true;
        this.pending = false;
      }
    }
  },
  methods: {
    async backup() {
      this.pending = true;
      
      /*const url = await documentHistoryService.downloadBackup(
        this.documentHistory
      );

      var a = this.$refs["file-download"];
      a.href = url;
      a.download = this.documentHistory.uuid+" ("+this.documentHistory.name+").pdf";
      a.click();
      window.URL.revokeObjectURL(url);*/

      this.pending = false;
    },
    close() {
      this.store.current.popup = ["manage-pdf-document-history"];
      this.$emit('updateParent');
    },
    async fetch() {
      if(this.documentHistory.documentHistoryId) {
        this.documentHistory = await documentHistoryService.select(this.documentHistory, this.getData);
      }
    }
  }
}
</script>
