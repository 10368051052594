<template>
  <div class="icon-menu" ref="buttons">
    <div @click="showDocumentInfo()" class="nav-icon" :class="{ selected: this.renderStore.options.activeSideMenu == 'docinfo' }" style="top: 0px" title="Over dit document"><img src="img/ico/doc-info.png"></div>
    <div @click="showMiniatures()" class="nav-icon" :class="{ selected: this.renderStore.options.activeSideMenu == 'miniatures' }" style="top: 40px" title="Navigatie"><img src="img/ico/nav-page.png"></div>
    <div @click="showBookmarks()" class="nav-icon" :class="{ selected: this.renderStore.options.activeSideMenu == 'bookmarks' }" style="top: 80px" title="Bladwijzers"><img src="img/ico/nav-bookmark.png"></div>
    <div @click="showAnnotation()" class="nav-icon" :class="{ selected: this.renderStore.options.activeSideMenu == 'annotation' }" style="top: 120px" title="Details van markering"><img src="img/ico/nav-annot.png"></div>
    <div @click="showContributions()" class="nav-icon" :class="{ selected: this.renderStore.options.activeSideMenu == 'contributions' }" style="top: 160px; border-top: 2px solid #fff" title="Voeg iets toe"><img src="img/ico/add.png"></div>
    <div @click="showToolbox()" class="nav-icon" :class="{ selected: this.renderStore.options.activeSideMenu == 'toolbox' }" style="top: 200px" title="Toolbox"><img src="img/ico/nav-toolbox.png"></div>
    
    <div @click="showChangelog()" class="nav-icon" :class="{ selected: this.renderStore.options.showChangelog }" style="bottom: 40px" title="Meldingen"><img src="img/ico/nav-history.png"></div>
    <div @click="showProblem()" class="nav-icon" :class="{ selected: this.renderStore.options.activeSideMenu == 'problem' }" style="bottom: 0px" title="Een probleem melden"><img src="img/ico/nav-warning.png"></div>
  
    <div class="id">
      {{ document.currentUuid }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    document: Object
  },
  computed: {
    renderStore() {
      return this.$parent.renderStore;
    },
    store() {
      return this.$parent.store;
    }
  },
  methods: {
    showDocumentData() {
      if(this.renderStore.options.activeSideMenu != 'documentData') {
        this.renderStore.options.activeSideMenu = 'documentData';
      }
      else this.toggleNavSide();
    },
    showAnnotationData() {
      if(this.renderStore.options.activeSideMenu != 'annotationData') {
        this.renderStore.options.activeSideMenu = 'annotationData';
      }
      else this.toggleNavSide();
    },
    showBookmarks() {
      if(this.renderStore.options.activeSideMenu != 'bookmarks') {
        this.renderStore.options.activeSideMenu = 'bookmarks';
      }
      else this.toggleNavSide();
    },
    showProblem() {
      if(this.renderStore.options.activeSideMenu != 'problem') {
        this.renderStore.options.activeSideMenu = 'problem';
      }
      else this.toggleNavSide();
    },
    showToolbox() {
      if(this.renderStore.options.activeSideMenu != 'toolbox') {
        this.renderStore.options.activeSideMenu = 'toolbox';
      }
      else this.toggleNavSide();
    },
    showContributions() {
      if(this.renderStore.options.activeSideMenu != 'contributions') {
        this.renderStore.options.activeSideMenu = 'contributions';
      }
      else this.toggleNavSide();
    },
    showAnnotation() {
      if(this.renderStore.options.activeSideMenu != 'annotation') {
        this.renderStore.options.activeSideMenu = 'annotation';
      }
      else this.toggleNavSide();
    },
    showMiniatures() {
      if(this.renderStore.options.activeSideMenu != 'miniatures') {
        this.renderStore.options.activeSideMenu = 'miniatures';
      }
      else this.toggleNavSide();
    },
    showChangelog() {
      this.renderStore.options.showChangelog = !this.renderStore.options.showChangelog;
    },
    showDocumentInfo() {
      if(this.renderStore.options.activeSideMenu != 'docinfo') {
        this.renderStore.options.activeSideMenu = 'docinfo';
      }
      else this.toggleNavSide();
    },
    toggleNavSide() {
      if(this.renderStore.options.activeSideMenu != null) {
        this.renderStore.options.activeSideMenu = null;
      }
    }
  }
}
</script>
