<template>
  <div class="menu-web">
    <div>
      <img class="hov web" @click="$router.push('/')" title="Home">
      <div class="button" @click="$router.push('/over-het-pakket')" :class="{active:$route.path.indexOf('/over-het-pakket') == 0}" title="Over het pakket"><span></span>Over het pakket</div>
      <div class="button" @click="$router.push('/abonnementen')"  :class="{active:$route.path.indexOf('/abonnementen') == 0}" title="Abonnementen"><span></span>Abonnementen</div>
      <div class="button" @click="$router.push('/contact')"  :class="{active:$route.path.indexOf('/contact') == 0}" title="Contact"><span></span>Contact</div>
      
      <div class="button outline" @click="app()" style="float:right">Projecten</div>
      <div class="clear"></div>
    </div>
  </div>
  <div class="clear"></div>
</template>

<script>
import { useStore } from '@/store/pinia'

export default {
  data() {
    return {
      store: useStore(),
    }
  },
  methods: {
    
  }
}
</script>

