<template>
  <transition name="fade">
    <div class="popup" v-if="store.current.popup.includes('setup-pdf-contrib')">
      <div>
        <perfect-scrollbar style="height:100%">
          <form @submit.prevent="add">
            <div class="closer" @click="close()">Scherm sluiten</div>
            <h1>{{ title }}</h1>
            
            <div class="filter-list">
              <div class="drag" style="height: 50vh;float:left">
                <h3>Beschikbare kolommen</h3>
                <div>
                  <template v-for="(column, index) in this.availColumns">
                    <span>
                      {{ column.name_nl.toUpperCase() }}
                      <span>{{ column.type }}</span>
                      <span class="opt" @click="add(column)">+ Toevoegen</span>
                    </span>
                  </template>
                </div>
              </div>

              <div class="drag" style="height: 50vh;float:right">
                <h3>Getoonde kolommen</h3>
                <div>
                  <template v-for="(column, index) in renderStore.options.columnsContrib">
                    <span>
                      {{ column.name_nl.toUpperCase() }}
                      <img src="img/ico/close.png" @click="remove(column)">
                      <img v-if="index > 0" src="img/ico/collapse.png" @click="moveUp(index)">
                      <img v-else src="img/ico/collapse.png" style="opacity:0.2">
                      <img v-if="index < renderStore.options.columnsContrib.length - 1" src="img/ico/expand.png" @click="moveDown(index)">
                      <img v-else src="img/ico/expand.png" style="opacity:0.2">
                    </span>
                  </template>
                </div>
              </div>
              <div class="clear"></div>

              <template v-if="!saved && store.isUser">
                <input type="submit" class="submit" value="Als standaard instellen" @click.prevent="save"  :class="{locked:isLocked}">
              </template>
              <template v-else-if="saved">
                <div class="saved">
                  De instellingen werden succesvol opgeslagen. Vanaf nu wordt de markeringslijst standaard getoond in 
                  deze layout.
                </div>
              </template>
              <div style="clear:both"></div>
            </div>
            <div style="clear:both;height:30px"></div>
          </form>
        </perfect-scrollbar>
      </div>
    </div>
  </transition>
</template>

<script>
import userService from '@/services/user.service' 
import errorHandler from '@/common/ErrorHandler'

export default {
  emits: ['showMessage', 'updateParent'],
  data() {
    return {
      title: "Instellingen markeringslijst",
      submitText: "Wijzigingen opslaan",
      availColumns: [],
      excludeColumns: ["sj", "msc", "fc", "fo", "lc", "lo", "lw", "fs", "as", "is", "ic"],
      pending: false,
      saved: false
    };
  },
  computed: {
    store() {
      return this.$parent.store;
    },
    renderStore() {
      return this.$parent.renderStore;
    },
    currentPopup() {
      return this.store.current.popup[this.store.current.popup.length-1];
    },
    columnsContrib() {
      return this.$parent.renderStore.options.columnsContrib;
    },
    isLocked() {
      if(this.pending) return true;
      else return false;
    }
  },
  watch: {
    async currentPopup(value) {
      if(value == "setup-pdf-contrib") {
        this.updateAvailableColumns();
        this.pending = false;
        this.saved = false;
      }
    },
    columnsContrib: {
      handler() {
        this.updateAvailableColumns();
      },
      deep: true
    }
  },
  methods: {
    add(column) {
      this.renderStore.options.columnsContrib.push(column);
      this.renderStore.setColumnWidths();
    },
    remove(column) {
      if(column.name == "subject") return;
      delete column.sort;
      var i = this.renderStore.options.columnsContrib.indexOf(column);
      if(i >= 0) this.renderStore.options.columnsContrib.splice(i, 1);
      this.renderStore.setColumnWidths();
    },
    moveUp(index) {
      if(index == 0) return;
      var tmp = this.renderStore.options.columnsContrib[index-1];
      this.renderStore.options.columnsContrib[index-1] = this.renderStore.options.columnsContrib[index];
      this.renderStore.options.columnsContrib[index] = tmp;
    },
    moveDown(index) {
      if(index == this.renderStore.options.columnsContrib.length - 1) return;
      var tmp = this.renderStore.options.columnsContrib[index+1];
      this.renderStore.options.columnsContrib[index+1] = this.renderStore.options.columnsContrib[index];
      this.renderStore.options.columnsContrib[index] = tmp;
    },
    async save() {
      if(this.pending) return;
      this.pending = true;

      var columns = JSON.parse(JSON.stringify(this.renderStore.options.columnsContrib));
      let response = await userService.setColumnLayout(columns);
      if(response) {
        if(response.status == 200) {
          this.saved = true;
        }
        else {
          this.$emit('showMessage', response);
          this.formErrors = errorHandler.process(response.data, "");
        }
      }
      this.pending = false;
    },
    updateAvailableColumns() {
      var availColumns = [...this.renderStore.columnsContribDef];
      if(this.renderStore.contributionTemplates != null) {
        for(const ctid in this.renderStore.contributionTemplates) {
          const ct = this.renderStore.contributionTemplates[ctid];
          //const cti = JSON.parse(ct.inputs);
          for(var input of Object.values(ct.inputs)) {
            if(this.excludeColumns.indexOf(input.name) >= 0) continue;
            switch(input.type) {
              case "head":
              case "icon":
              case "textarea":
                continue;
            }

            var found = false;
            for(var aCol of availColumns) {
              if(aCol.name == input.name || aCol.name == "values."+input.name) {
                found = true;
              }
            }

            if(!found) {
              input.name_nl = input.labelShort;
              availColumns.push(input);
            }
          }
        }
      }
      for(var i = availColumns.length-1; i >= 0; i--) {
        var column = availColumns[i];
        this.renderStore.options.columnsContrib.forEach(pColumn => {
          if(column.name.toLowerCase() == pColumn.name.toLowerCase()) {
            delete availColumns[i];
          }
        });
      }
      availColumns.sort((a,b) => a.name_nl.localeCompare(b.name_nl));
      this.availColumns = {...availColumns};
    },
    close() {
      this.store.current.popup.splice(
        this.store.current.popup.indexOf('setup-pdf-contrib'), 
        1
      );
      this.$emit('updateParent');
    },
  },
  components: {
    
  }
}
</script>
