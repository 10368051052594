<template>
  <transition name="fade">
    <div class="popup" v-if="store.current.popup.includes('manage-pdf-permissions')">
      <div>
        <perfect-scrollbar style="height:100%">
          <div class="closer" @click="close()">Terug naar vorig scherm</div>
          <div v-if="this.store.current.data.pdf.document.currentVersion == this.store.current.data.pdf.documentHistory.version" class="adder" @click="add()">Toevoegen</div>
          <h1>{{ title }}</h1>
          <template v-if="init">
            <template v-if="permissions != null">
              <table v-if="permissions.length > 0" class="listing">
                <tbody>
                  <tr>
                    <th style="width:30%">Gebruiker</th>
                    <th style="width:45%">Instellingen</th>
                    <th style="width:20%">Toegang sinds</th>
                    <th class="opth" style="width:0%"></th>
                  </tr>
                  <tr v-for="(item, index) in permissions" v-bind:key="index" class="high-line">
                    <td>
                      <b>Naam:</b> {{ item.userGender + " " + item.userSurname }}<br/>
                      <b>Email-adres:</b> {{ item.userEmail }}<br/>
                      <b>Bedrijf:</b> {{ item.userCompany }}
                    </td>
                    <td>
                      <b>Filter:</b> <div v-html="(item.filter != null) ? item.filter.replaceAll('\{\{', '<i>').replaceAll('\}\}', '</i>') : 'niet ingesteld'"></div><br/>
                      <b>Kolommenstructuur:</b> {{ (item.pivotConfigName != null) ? item.pivotConfigName : 'niet ingesteld' }}<br/>
                      <b>Bijdragen:</b> {{ (item.contributionTemplateNames != null) ? item.contributionTemplateNames : 'niet ingesteld' }}
                    </td>
                    <td>
                      {{ item.dateGranted }}
                    </td>
                    <td class="opt">
                      <div>
                        <div>
                          Opties bij regel
                          <a href="#" @click.stop="edit(item)">Toegang wijzigen</a>
                          <a href="#" @click.stop="unlink(item)">Toegang intrekken</a>
                          <div class="clear"></div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div v-else>
                <p>
                  U heeft nog géén gebruikers toegang gegeven tot dit document.
                  <template v-if="this.store.current.data.pdf.document.currentVersion == this.store.current.data.pdf.documentHistory.version">
                    Klik hierboven op de knop "Toevoegen" om het document te delen.
                  </template>
                  <template v-else>
                    Op deze versie is het niet mogelijk om toestemming te verlenen, omdat 
                    het niet de huidige versie van het document is.
                  </template>
                </p>
              </div>
              <!--<Pagination @change-offset="changeOffset" :query-args="queryArgs"></Pagination>-->
            </template>
          </template>
          <div v-else class="loader">
            <div>Even geduld, content wordt geladen...</div>
          </div>
        </perfect-scrollbar>
      </div>
    </div>
  </transition>
</template>

<script>
import permissionService from '@/services/app/permission.service'
import errorHandler from '@/common/ErrorHandler'

export default {
  emits: ['showMessage', 'updateParent'],
  data() {
    return {
      documentHistory: {
        documentHistoryId: 0
      },
      permissions: null, 
      queryArgs: {
        orderType: "desc",
        orderBy: "dateGranted",
        offset: 0,
        pageSize: 5
      },
      labelData: {},
      formErrors: {},
      title: "",
      submitText: "",
      init: false,
      pending: false
    };
  },
  computed: {
    store() {
      return this.$parent.store;
    },
    currentPopup() {
      return this.store.current.popup[this.store.current.popup.length-1];
    },
    isLocked() {
      if(this.pending || !this.init) return true;
      else return false;
    }
  },
  watch: {
    async currentPopup(value) {
      if(value == "manage-pdf-permissions") {
        this.init = false;
        this.clear();

        this.title = "Toegangsbeheer";
        this.submitText = "Wijzigingen opslaan";
        this.documentHistory.documentHistoryId = this.store.current.data.pdf.documentHistory.documentHistoryId;
        
        await this.fetch();
        this.init = true;
        this.pending = false;
      }
    }
  },
  methods: {
    add() {
      this.store.current.data.pdf.permission = null;
      this.store.current.popup = ["add-pdf-permission"];
    },
    edit(permission) {
      this.store.current.data.pdf.permission = {...permission};
      this.store.current.popup = ["add-pdf-permission"];
    },
    unlink(permission) {
      this.store.current.data.pdf.permission = {...permission};
      this.store.current.popup = ["unlink-pdf-permission"];
    },
    close() {
      this.store.current.data.pdf.permission = null;
      this.store.current.popup = ["manage-pdf-document-history"];
      //this.$emit('updateParent');
    },
    async fetch() {
      if(this.documentHistory.documentHistoryId) {
        this.queryArgs.documentHistoryId = this.documentHistory.documentHistoryId;
        const response = await permissionService.listing(this.queryArgs);
        if(response) {
          if(response.status == 200) {
            this.permissions = response.data.results;
            this.queryArgs.count = response.data.count;
          }
          else {
            this.$emit('showMessage', response);
          }
        }
      }
    },
    clear() {

    }
  },
  components: {
    
  }
}
</script>
