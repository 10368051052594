<template>
  <div class="lead">
    <div class="in">
      <div class="tab tab20">
        <img src="img/logo-bg.png">
      </div>
      <div class="tab tab30">
        <span>Foundation</span>
        Sprookjesbosch 44<br/>
        5629JZ Eindhoven<br/>
        <br/>
        Tel.nr.&nbsp;&nbsp;+31 (0)40 256 7850<br/>
        Mail&nbsp;&nbsp;&nbsp;<a href="mailto:info@digitable.nl">info@digitable.nl</a>
      </div>
      <div class="tab tab25">
        <h5>Handige links</h5>
        <div @click="$router.push('/over-het-pakket')">Over het pakket</div>
        <div @click="$router.push('/data-beveiliging')">Databeveiliging</div>
        <div @click="$router.push('/abonnementen')">Abonnementen</div>
      </div>
      <div class="tab tab25">
        <h5>Meer informatie</h5>
        <div @click="$router.push('/contact')">Contact</div>
      </div>
    </div>
    <div class="spl"></div>
    <div class="in pd">
      © 2024
      <div><a href="#">Algemene voorwaarden</a></div>
      <div><a href="#">Privacyverklaring</a></div>
      <div class="cp" @click="window.open('https://digitable.nl', '_blank')">Foundation PDF Tools is een handelsnaam van Digitable</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {

    };
  },
  computed: {
    
  },
  components: { 
    
  }
};
</script>

<style scoped>
.lead {
  background: rgba(0,0,0,0.1);
  color: #fff;
  font-size: 14px;
  line-height: 24px;
}

.in {
  width: 1000px;
  margin: 0 auto;
  display: table;
  padding: 0;
  padding-top: 50px;
  padding-bottom: 50px;
}

.in.pd {
  padding-top: 25px;
  padding-bottom: 25px;
}

.in.pd div {
  display: inline-block;
  margin-left: 50px;
}

.in.pd div:hover {
  cursor: pointer;
  text-decoration: underline;
}

.tab {
  display: table-cell;
  vertical-align: top;
  padding-right: 50px;
}

.tab20 {
  width: 20%;
}

.tab25 {
  width: 25%;
}

.tab30 {
  width: 30%;
}

.tab40 {
  width: 40%;
}

img {
  width: 100%;
  display: block;
}

span {
  display: block;
  line-height: 28px;
  font-size: 18px;
  font-weight: bold;
}

.spl {
  height: 1px;
  width: 1000px;
  margin: 0 auto;
  background-color: #fff;
}

h5 {
  font-size: 18px;
  margin: 0;
  margin-bottom: 10px;
}

a {
  color: #fff;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.tab div:hover {
  text-decoration: underline;
  cursor: pointer;
}

.cp {
  opacity: 0.7;
  font-size: 14px;
  float: right;
}

.cp img {
  margin-bottom: 10px;
}
</style>