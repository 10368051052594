<template>
  <div class="pag" :class="{hide:!isVisible}">
    <div>U bevindt zich hier: </div>
    <div v-for="page, id in pageTree" :key="page" @click="goto(pageTree.length - id - 1)" class="goto">{{page}}</div>
  </div>
</template>
  
<script>
export default {
  emits: ["changePage"],
  name: "PageTree",
  props: {
    currentData: Object
  },
  computed: {
    isVisible() {
      return this.pageTree.length > 0 ? true : false;
    },
    pageTree() {
      var tree = [];
      if(!this.currentData) return tree;

      var category = this.currentData.category;
      if(category && category.name) {
        tree.push("Home");
        tree.push("Categorie: " + category.name);
      
        var chapter = this.currentData.chapter;
        if(chapter && chapter.title) tree.push("Hoofdstuk: " + chapter.title);
        return tree;
      }

      var endpoint = this.currentData.endpoint;
      if(endpoint) {
        tree.push("Home");
        tree.push("Eindpunt: " + endpoint.name);
      }

      return tree;
    }
  },
  methods: {
    goto(page) {
      this.$emit('changePage', page);
    }
  },
};
</script>

<style scoped>
.hide {
  display: none
}
.pag {
  padding: 1vh;
  padding-left: 2vh;
  padding-top:2vh;
  width: 100%;
  box-sizing: border-box;
  border-top-left-radius: 2vh;
  border-top-right-radius: 2vh;
  position: relative;
  background: rgba(255,255,255,0.8);
  height: 8.5vh;
  margin-bottom: -2vh;
  font-size: 2vh;
  line-height: 3vh;
  font-weight: bold;
  font-style: italic;
  color: rgba(0,0,0,0.7)
}

.pag > div {
  float: left;
  margin-right: 2vh
}

.goto {
  display: block;
  cursor: pointer;
  padding: 0.5vh 1vh;
  margin-right: 1vh;
  float: left;
  background: rgb(81,111,157);
  background: linear-gradient(40deg, rgb(81, 127, 157) 0%, rgb(56, 83, 124) 100%);
  color: #fff;
  font-weight: bold;
  border-radius: 1vh;
  opacity: 0.5;
  transition-duration: 0.3s;
  line-height: 2vh
}

.goto.active, .goto:hover {
  opacity: 1;
  transition-duration: 0.3s;
}

.prev {
  cursor: pointer;
  float: left;
  margin-right: 2vh
}
</style>
