<template>
  <transition name="fade">
    <div class="popup" v-if="store.current.popup.includes('add-pdf-permission')">
      <div>
        <perfect-scrollbar style="height:100%">
          <div class="closer" @click="close()">Scherm sluiten</div>
          <h1>{{ title }}</h1>

          <template v-if="init">
            <div v-if="!presets && !invite">
              <template v-if="contacts.length > 0">
                <SearchField @search="searchContacts" />
                <h2>Zoeken in contactpersonen</h2>
                <table v-if="filteredContacts.length > 0" class="listing">
                  <tbody>
                    <tr>
                      <th>Email-adres</th>
                      <th>Filter</th>
                      <th>Kolommen</th>
                      <th width="20%">Laatst gedeeld op</th>
                      <th width="12%"></th>
                    </tr>
                    <tr v-for="contact in filteredContacts">
                      <td>{{ contact.email }}</td>
                      <td v-html="(contact.filter.length > 0) ? contact.filter.replace(/\n/g, '<br/>') : '-'"></td>
                      <td>{{ (contact.pivotConfigName != null) ? contact.pivotConfigName : '-' }}</td>
                      <td>{{ contact.lastShared }}</td>
                      <td><a href="#" @click.prevent="addFromRecent(contact)" class="blue-link">Verleen toegang</a></td>
                    </tr>
                  </tbody>
                </table>
                <div v-else class="not-avail">
                  Er zijn geen contactpersonen gevonden die aan uw zoekterm voldoen.
                </div>
                <div style="clear:both;height:40px"></div>
              </template>

              <form @submit.prevent="submit">
                <h2>Zoeken via email-adres</h2>
                <p>
                  Wilt u iemand toestemming verlenen die u nog niet in uw contactpersonen heeft opgenomen?
                  Vul hier het email-adres van de persoon in en u kunt daarna de voorkeuren instellen 
                  voor delen met deze persoon.
                </p>

                <div v-if="labelData.email" class="before-input" :class="{active:labelData.email.active}">{{ labelData.email.text }}</div>
                <input type="text" 
                  name="email" 
                  v-model="permission.email"
                  :class="{hasLabel:labelData.email.active}" 
                  @focusin="empty($event)" 
                  @focusout="reset($event)">
                <div v-if="formErrors.email" class="error-field">{{ defaultData.email[0] + " " + formErrors.email }}!</div>
                <div v-if="exists" class="error-field">Deze gebruiker heeft al toegang tot dit document!</div> 
                <input type="submit" :value="submitText" :class="{locked:isLocked}">
              </form>
            </div>
            <div v-else>
              <div v-if="presets && presets.name">
                <h3>Geselecteerde gebruiker</h3>
                <p>
                  <b>Naam:</b> {{ presets.name }}<br/>
                  <b>Email:</b> {{ presets.email }}<br/>
                  <b>Bedrijfsnaam:</b> {{ presets.company }}
                </p>
              </div>
              <div v-else-if="permission.user">
                <h3>Geselecteerde gebruiker</h3>
                <p>
                  <b>Naam:</b> {{ permission.user.name }} {{ permission.user.surname }}<br/>
                  <b>Email:</b> {{ permission.user.email }}<br/>
                  <b>Bedrijfsnaam:</b> {{ permission.user.companyName }}
                </p>
              </div>
              <div v-else-if="permission.tempUser">
                <h3>Selectie</h3>
                <p>
                  <b>Email:</b> {{ permission.tempUser.email }}
                </p>
              </div>
              <div v-else-if="permission.email" class="error-field inline">
                <h3>Gebruiker niet gevonden ({{ permission.email }})</h3>
                <p>
                  Het lijkt erop dat deze persoon bij ons niet geregistreerd staat als gebruiker.
                </p>
              </div>
              
              <form @submit.prevent="submit">
                <h2>Gegevens filteren</h2>
                <p>
                  De gebruiker krijgt rechten toegewezen om het volledige document met daarbij 
                  horende hoeveelheden in te zien. Wilt u bepaalde informatie uitsluiten, dan kunt u dat via 
                  het filter hieronder aangeven. Indien hier al iets wordt getoond, zijn deze instellingen 
                  gekopieerd van de voorgaande keer dat u deze persoon rechten heeft verleend. 
                </p>

                <p>
                  Kies een datakolom waarop u wilt filteren, definieer het filtertype en vul de gewenste 
                  waarden in. Waarden dienen te worden gescheiden met een komma. Het is enkel toegestaan om
                  letters, cijfers, spaties, mintekens (-) en dubbele punten (:) te gebruiken in het filter.
                  Geeft u meerdere filters in, dan geldt dat dataregels moeten voldoen aan alle filters ("en").
                </p>

                <div class="filter-list">
                  <template v-if="permission.filter && permission.filter.length > 0">
                  </template>
                  <template v-for="(value, index) in permission.filter" :key="index">
                    <Multiselect
                      v-model="value[0]" 
                      :options="dataColumns"
                      placeholder="Maak een keuze..."
                      noOptionsText="Geen opties gevonden."
                      style="float:left;width:25%;margin-right:2%"
                    />

                    <Multiselect
                      v-model="value[1].type" 
                      :options="filterTypes"
                      placeholder="Maak een keuze..."
                      noOptionsText="Geen opties gevonden."
                      style="float:left;width:25%;margin-right:2%"
                    />

                    <img class="img-button" src="img/ico/action-remove.png" @click="removeFilter(index)">

                    <input type="text"
                      v-model="value[1].value"
                      @focusin="empty($event)" 
                      @focusout="reset($event)"
                      style="float:right;width:40%"
                    >
                  </template>

                  <div class="submit red" @click="addFilter">Veld-filter toevoegen</div>
                  <div style="clear:both"></div>
                </div>
                <div style="clear:both;height:25px"></div>

                <h2>Kolommenstructuur</h2>
                <p>
                  Door hier een keuze te maken kunt u restricties opleggen aan kolommen die de gebruiker kan inzien.
                  U kunt deze lijst beheren door in een document de kolommenstructuur in te stellen en daar een configuratie
                  op te slaan. De gebruiker krijgt standaard de gekozen kolommen te zien, maar kan daarnaast alle standaardkolommen
                  inzien en kiezen (waaronder onderwerp, label, ruimte en alle hoeveelheidskolommen).
                </p>
                <Multiselect
                  v-model="permission.pivotConfigId" 
                  :options="pivotConfigs"
                  placeholder="Maak een keuze..."
                  noOptionsText="Geen opties gevonden."
                />
                <div style="clear:both;height:25px"></div>
                <h2>Bijdragen</h2>
                <p>
                  Kies hieronder de formulieren die voor deze gebruiker beschikbaar moeten zijn tijdens het aanmaken van een bijdrage
                  binnen het document.
                </p>
                
                <Multiselect
                  v-model="permission.contributionTemplateIds" 
                  :options="contributionTemplates"
                  :close-on-select="false"
                  placeholder="Maak een keuze..."
                  mode="tags"
                />
                
                <input type="submit" :value="submitText" :class="{locked:isLocked}">
              </form>
            </div>
          </template>
          <div v-else class="loader">
            <div>Even geduld, content wordt geladen...</div>
          </div>
        </perfect-scrollbar>
      </div>
    </div>
  </transition>
</template>

<script>
import documentHistoryService from '@/services/app/document.history.service'
import permissionService from '@/services/app/permission.service'
import listingService from '@/services/listing.service'
import errorHandler from '@/common/ErrorHandler'

import Multiselect from '@vueform/multiselect'
import SearchField from '@/components/SearchField.vue'

export default {
  emits: ['showMessage', 'fetchListing'],
  data() {
    return {
      presets: {},
      dataColumns: [],
      filterTypes: [],
      pivotConfigs: [],
      contributionTemplates: [],
      permission: {
        documentHistoryId: 0,
        pivotConfigId: 0,
        contributionTemplateIds: [],
        email: "",
        emailExisting: "",
        filter: [],
        fromRecent: false
      },
      user: null,
      contacts: [],
      filteredContacts: [],
      selectedContacts: [],
      defaultData: {
        email: ['Email-adres', 'Voer het zakelijke email-adres in van de gebruiker...'],
        emailExisting: ['Email-adres contactpersoon', 'Voer het email-adres in van de contactpersoon...']
      },
      labelData: {},
      formErrors: {},
      title: "",
      submitText: "",
      init: false,
      pending: false,
      invite: false,
      exists: false
    };
  },
  computed: {
    store() {
      return this.$parent.store;
    },
    currentPopup() {
      return this.store.current.popup[this.store.current.popup.length-1];
    },
    isLocked() {
      if(this.pending || !this.init) return true;
      else return false;
    }
  },
  watch: {
    async currentPopup(value) {
      if(value == "add-pdf-permission") {
        this.init = false;
        this.exists = false;
        this.invite = false;
        this.presets = null;
        this.clear();
        this.permission =  {
          documentHistoryId: 0,
          pivotConfigId: 0,
          contributionTemplateIds: [],
          email: "",
          emailExisting: "",
          filter: [],
          fromRecent: false
        };

        this.permission.documentHistoryId = this.store.current.data.pdf.documentHistory.documentHistoryId;
        if(this.store.current.data.pdf.permission != null) {
          this.title = "Toegang aanpassen";
          this.submitText = "Wijzigingen opslaan";
          this.permission.permissionId = this.store.current.data.pdf.permission.permissionId;
          this.invite = true;
          this.presets = [];
        }
        else {
          this.title = "Toegang verlenen";
          this.submitText = "Deze gebruiker toegang verlenen";
        }
        
        await this.fetch();
        this.init = true;
        this.pending = false;
      }
    },
    permission(listing) {
      for(const[key, value] of Object.entries(listing)) {
        if(!this.defaultData[key]) continue;
        if(value && value.toString().length > 0) {
          this.labelData[key] = {text: this.defaultData[key][0], active: true};
        }
        else {
          this.labelData[key] = {text: '', active: false};
          this.permission[key] = this.defaultData[key][1];
        }
      }
    }
  },
  methods: {
    async submit() {
      if(this.invite || this.presets) this.add();
      else this.selectUser();
    },
    async selectUser() {
      if(this.pending) return;
      this.pending = true;
      
      if(this.invite) {
        this.permission.userId = -1;
        this.pending = false;
        return;
      }

      const response = await permissionService.findPresets(this.permission, this.defaultData);
      if(response) {
        if(response.status == 200) {
          const data = response.data;
          this.presets = data;
          if(data.dataColumns != null && data.dataColumns.length > 0) {
            this.presets.dataColumns = JSON.parse(data.dataColumns);
          }
          if(data.filter != null && data.filter.length > 0) {
            this.permission.filter = JSON.parse(data.filter);
          }
        }
        else if(response.data.code == "SRV_PER_FPR_ENF") {
          this.invite = true;
          this.submitText = "Begrepen, ik wil deze persoon alsnog toegang verlenen";
        }
        else if(response.data.code == "SRV_PER_FPR_PEX") {
          this.exists = true;
        }
        else {
          this.$emit('showMessage', response);
          this.formErrors = errorHandler.process(response.data, "");
        }
      }
      this.pending = false;
    },
    async add() {
      if(this.pending) return;
      this.pending = true;
      let response = null;

      if(!this.permission.permissionId) {
        response = await permissionService.add(this.permission, this.defaultData);
      }
      else {
        response = await permissionService.update(this.permission, this.defaultData);
      }

      if(response) {
        if(response.status == 200) {
          this.store.current.popup = ['manage-pdf-permissions'];
        }
        else {
          this.$emit('showMessage', response);
          this.formErrors = errorHandler.process(response.data, "");
        }
      }
      this.pending = false;
    },
    async addFromRecent(contact) {
      this.permission.email = contact.email;
      this.permission.fromRecent = true;
      await this.add();
    },
    empty(e) {
      const val = this.permission[e.target.name];
      if(this.defaultData[e.target.name] && (val == "" || val == this.defaultData[e.target.name][1])) {
        this.labelData[e.target.name] = {text: this.defaultData[e.target.name][0], active: true};
        this.permission[e.target.name] = "";
      }
    },
    reset(e) {
      const val = this.permission[e.target.name];
      if(val == "") {
        this.labelData[e.target.name] = {text: '', active: false};
        this.permission[e.target.name] = this.defaultData[e.target.name][1];
      }
    },
    clear() {
      for(const[key, val] of Object.entries(this.defaultData)) {
        this.labelData[key] = {text: '', active: false};
        this.permission[key] = val[1];
        this.formErrors = {};
      }
    },
    close() {
      this.store.current.popup = ["manage-pdf-permissions"];
      this.store.current.data.pdf.permission = null;
      this.$emit('fetchListing');
    },
    async fetch() {
      if(this.permission.permissionId) {
        this.permission = await permissionService.select(this.permission);
        try {
          this.permission.filter = JSON.parse(this.permission.filter);
        }
        catch {
          this.permission.filter = [];
        }
        try {
          if(this.presets == null) this.presets = {};
          this.presets.dataColumns = JSON.parse(this.permission.dataColumns).sort();
        }
        catch { 
          if(this.presets == null) this.presets = {};
          this.presets.dataColumns = [];
        }
      }
      else {
        this.permission.filter = [];
      }

      this.fetchListings();
      this.contacts = await permissionService.contacts(this.store.current.data.pdf.documentHistory.documentHistoryId);
      this.filteredContacts = [...this.contacts];
      this.query = "";
    },
    async fetchListings() {
      this.dataColumns = await documentHistoryService.fetchDataColumns(this.store.current.data.pdf.documentHistory.documentHistoryId);
      this.filterTypes = await listingService.filterTypes();
      this.pivotConfigs = await listingService.pivotConfigs();
      this.contributionTemplates = await listingService.contributionTemplates();
    },
    addFilter() {
      var filter = ["", {"type":"","value":""}];
      this.permission.filter.push(filter);
    },
    removeFilter(index) {
      this.permission.filter.splice(index, 1);
    },
    searchContacts(query) {
      this.filteredContacts = [];
      this.contacts.forEach(contact => {
        var selected = false;
        this.selectedContacts.forEach(selectedContact => {
          if(selectedContact.email == contact.email) {
            selected = true;
          }
        });
        if(!selected && contact.email.toLowerCase().indexOf(query.toLowerCase()) >= 0) {
          this.filteredContacts.push(contact);
        }
      });
    },
  },
  components: {
    Multiselect,
    SearchField
  }
}
</script>
