import api from '../api';
import tokenService from "../token.service";

class ProjectService {

  async select(project, queryArgs) {
    try {
      var args = {
        projectId: project.projectId,
        uuid: project.uuid
      };
      if(queryArgs != null) {
        args.documentCount = queryArgs.pageSize;
        args.documentOrderBy = queryArgs.orderBy;
        args.documentOrderType = queryArgs.orderType;
      }

      await tokenService.awaitSessionRefresh();
      const response = await api.post(
        "/app/project/select", args
      );
      return response.data;
    }
    catch (error) {
      return null;
    }
  }

  async add(formData, defaultData) {
    try {
      await tokenService.awaitSessionRefresh();
      const response = await api.post(
        "/app/project/add", this.toSubmitData(formData, defaultData)
      );
      return response;
    }
    catch (error) {
      return error.response;
    }
  }

  async update(formData, defaultData) {
    try {
      await tokenService.awaitSessionRefresh();
      const response = await api.post(
        "/app/project/update", this.toSubmitData(formData, defaultData)
      );
      return response;
    }
    catch (error) {
      return error.response;
    }
  }

  async unlink(project) {
    try {
      await tokenService.awaitSessionRefresh();
      const response = await api.post(
        "/app/project/unlink", {
          projectId: project.projectId
        }
      );
      return response;
    }
    catch (error) {
      return error.response;
    }
  }

  async listing(queryArgs) {
    try {
      await tokenService.awaitSessionRefresh();
      const response = await api.post(
        "/app/project/listing", queryArgs
      );
      return response;
    }
    catch (error) {
      return error.response;
    }
  }

  async shares(queryArgs) {
    try {
      await tokenService.awaitSessionRefresh();
      const response = await api.post(
        "/app/project/shares", queryArgs
      );
      return response;
    }
    catch (error) {
      return error.response;
    }
  }

  toSubmitData(formData, defaultData) {
    const submitData = {...formData};
    for(const [key, val] of Object.entries(submitData)) {
      if(!isNaN(val)) continue;
      if(defaultData[key] && val == defaultData[key][1]) {
        submitData[key] = null;
      }
    }
    return submitData;
  }
}
export default new ProjectService();