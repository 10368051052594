<template>
  <template v-if="store.current.popup.includes('view-pdf-attachments')">
    <transition name="fade">
      <div class="popup attach" style="pointer-events: all">
        <div v-if="attachments != null && attachments.length > 0">
          <img :src="'data:image/jpg;base64,'+attachments[index-1]">
          <div class="prev" @click="prev">«</div>
          <div class="next" @click="next">»</div>
        </div>
        <div class="loader" v-else>
          <div>Even geduld, content wordt geladen...</div>
        </div>
        <div class="closer" @click="close" title="Scherm sluiten">×</div>
        <div class="closer" @click="download('data:image/jpg;base64,'+attachments[index-1])" style="right:90px;" title="Afbeelding downloaden"><img src="img/ico/download.png"></div>
      </div>
    </transition>
  </template>
</template>

<script>
export default {
  data() {
    return {
      index: 1
    }
  },
  computed: {
    store() {
      return this.$parent.store;
    },
    renderStore() {
      return this.$parent.renderStore;
    },
    attachments() {
      try { return this.$parent.store.current.data.pdf.contribution.attachments }
      catch { return null; }
    },
    currentPopup() {
      return this.store.current.popup[this.store.current.popup.length-1];
    }
  },
  watch: {
    async currentPopup(value) {
      if(value == "view-pdf-attachments") {
        this.index = this.renderStore.options.activeAttachment;
      }
      else {
        this.fileToUpload = null;
      }
    },
    index(value) {
      if(value > this.attachments.length) this.index = 1;
      if(value < 1) this.index = this.attachments.length;
    }
  },
  methods: {
    prev() {
      this.index--;
    },
    next() {
      this.index++;
    },
    async download(src) {
      let link = document.createElement("a");
      document.documentElement.append(link);
      await fetch(src)
        .then(res => res.blob())
        .then(blob => {
          let objectURL = URL.createObjectURL(blob);
          link.setAttribute("download", "image.jpg");
          link.href = objectURL;
          link.click();
      });
    },
    close() {
      this.store.current.popup = [];
    }
  }
}
</script>
